import React from "react";
import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";

import { colors } from "theme";
import twitterIcon from "assets/images/icon-twitter@24.png";
import linkedInIcon from "assets/images/icon-linkedin@24.png";
import facebookIcon from "assets/images/icon-facebook@24.png";
import instagramIcon from "assets/images/icon-instagram@24.png";

import { EditorWrapper } from "./components/postComposer/captions/caption/styles";

export const Message = styled(Box)`
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.257143px;
  color: ${props => (props.hasError ? props.theme.colors.red : "#8B93A6")};
`;

export const MessageHighlight = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: 600;
`;

export const ComposerContent = styled.div`
  overflow: auto;
  max-height: 61vh;
  width: 502px;
  padding: 24px 24px 0 24px;

  ${props =>
    props.inProMode
      ? css`
          padding: 24px;
          border-radius: 16px;
          background-color: ${props.theme.colors.grey05};

          ${SelectorWrapper} {
            height: 100%;
          }

          ${ComposerBody} {
            height: 100%;
          }

          ${EditorWrapper} {
            max-height: calc(61vh - 68px);
          }
        `
      : css`
          ${SelectorWrapper} {
            margin: 16px 0px;
          }
        `}
`;

export const ComposerFooter = styled(Flex)`
  gap: 8px;
  align-items: center;
  padding: 16px 24px 24px;
  justify-content: flex-start;
  border-top: 1px solid ${props => props.theme.colors.grey03};
`;

export const AttachmentToolbar = styled(Flex)`
  gap: 8px;
  padding-right: 8px;
  align-items: center;
  justify-content: flex-start;

  ${props =>
    props.showBorder &&
    css`
      border-right: 1px solid ${props => props.theme.colors.grey03};
    `};
`;
AttachmentToolbar.defaultProps = {
  showBorder: false
};

export const CaptionTab = styled.div`
  background-color: ${props => (props.selected ? "#dee8fb" : "white")};
  color: ${props => (props.selected ? "#1e62e0" : "#606770")};
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  margin-right: 16px;
`;

const messageTypes = {
  generic: {
    background: colors.white,
    emphasisColor: colors.text02
  },
  info: {
    iconClass: "icon-error-circle",
    background: colors.primarySubtle,
    emphasisColor: colors.blue
  },
  warning: {
    iconClass: "icon-error-circle",
    background: colors.lightYellow,
    emphasisColor: colors.yellow
  },
  error: {
    iconClass: "icon-error-circle",
    background: colors.rebSubtle,
    emphasisColor: colors.red
  }
};

const getServiceIcon = service => {
  if (service.includes("twitter")) {
    return twitterIcon;
  } else if (service.includes("instagram")) {
    return instagramIcon;
  } else if (service.includes("facebook")) {
    return facebookIcon;
  } else if (service.includes("linkedin")) {
    return linkedInIcon;
  } else {
    return null;
  }
};

const Notifier = styled.div`
  i {
    font-size: 24px;
  }
`;

const SystemMessageContainer = styled(Flex)`
  padding: 6px 8px;
  background-color: ${props => messageTypes[props.type].background};
  border-radius: 12px;
  min-height: 44px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  ${props =>
    props.type === "generic" &&
    css`
      box-shadow: ${props.theme.shadows.shadowS};
    `}

  ${Notifier} > i {
    color: ${props => messageTypes[props.type].emphasisColor};
  }

  i.info {
    cursor: pointer;
    font-size: 20px;
    margin-left: 4px;
    color: ${props => props.theme.colors.text04};

    &:hover {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

export const MessageText = styled(Flex)`
  display: flex;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  align-items: center;
  letter-spacing: -0.005em;
  color: ${props => props.theme.colors.text02};

  em {
    font-weight: 600;
    color: ${props => props.emphasisColor};
  }
`;

const ServiceIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background: ${props => `url(${props.iconSrc})`};
  background-size: contain;
  overflow: visible;

  ${Notifier} > i {
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 15px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.white};
  }
`;

export const SystemMessage = ({
  type,
  children,
  service = null,
  infoUrl = null,
  actionComponent = null
}) => {
  const serviceIcon = !!service && getServiceIcon(service);
  const { iconClass, emphasisColor } = messageTypes[type];

  const gotoInfoUrl = e => {
    if (!!infoUrl) {
      e.stopPropagation();
      window.open(infoUrl, "_blank");
    }
  };

  return (
    <SystemMessageContainer type={type}>
      <Flex alignItems="center">
        {(!!serviceIcon || iconClass) && (
          <Flex
            marginRight={8}
            alignItems="center"
            minWidth={!!serviceIcon ? 31 : 24}
          >
            {!!serviceIcon ? (
              <ServiceIcon iconSrc={serviceIcon}>
                {iconClass && (
                  <Notifier>
                    <i className={`${iconClass}`} />
                  </Notifier>
                )}
              </ServiceIcon>
            ) : (
              iconClass && (
                <Notifier>
                  <i className={`${iconClass}`} />
                </Notifier>
              )
            )}
          </Flex>
        )}
        <MessageText emphasisColor={emphasisColor}>{children}</MessageText>
        {!!infoUrl && <i className="icon-help info" onClick={gotoInfoUrl} />}
      </Flex>
      {!!actionComponent && (
        <Flex justifyContent="center" minWidth="unset">
          {actionComponent}
        </Flex>
      )}
    </SystemMessageContainer>
  );
};

export const ContentTypeSelect = styled(Box)`
  flex-grow: 1;
  margin-right: 15px;
  max-width: 190px;
`;

export const SelectorWrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-x: auto;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  background: rgba(96, 116, 128, 0.08);
`;

export const SectionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: ${props => (props.center ? "center" : "flex-start")};

  ${props =>
    props.verticle &&
    css`
      gap: 8px;
      flex-direction: column;

      ${SectionContent} {
        width: 100%;
      }
    `}

  ${props =>
    props.fillHeight
      ? css`
          height: calc(100% - ((64px + 12px) * 2));
        `
      : css`
          height: fit-content;
        `}
`;

export const SectionLabel = styled.h6`
  display: flex;
  width: 72px;
  height: 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.25;
  color: #808082;
`;

export const SectionContent = styled.div`
  display: flex;
  box-sizing: border-box;
  width: calc(100% - 72px - 24px);

  ${props =>
    props.isList &&
    css`
      min-height: 60px;
      overflow-y: auto;
      padding-right: 10px;
      scrollbar-gutter: stable;
    `};
`;

export const ComposerBody = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const LeftPanel = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  width: 385px;
  padding: 24px;
`;

export const RightPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 433px;
  padding: 24px;
`;

export const CenterPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  ${props =>
    props.inProMode &&
    css`
      padding: 24px;
    `};
`;
