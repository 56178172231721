import axios from "axios";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  Scroller,
  ImageList,
  PostImage,
  ImageWrapper,
  ScrollerButton,
  AttachmentAction,
  AttachmentActions,
  AttachmentContainer
} from "./styles";
import ImageUpload from "./ImageUpload";
import { useToaster } from "@hellocontento/maillard";
import { ToolTip } from "components/schedule/common/styles";

const ImageAttachment = ({
  images,
  onRemove,
  onUpload,
  onReorder,
  onUploadStart
}) => {
  const addToast = useToaster();
  const scrollOffset = 5;
  const isScrollRef = useRef();
  const imageListRef = useRef(HTMLDivElement);
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(false);

  const setMove = state => (isScrollRef.current = state);

  if (images && !Array.isArray(images)) {
    images = [images];
  }

  const reorderImages = result => {
    const { source, destination } = result;

    if (!!source && !!destination) {
      onReorder(source.index, destination.index);
    }
  };

  const handleScrollerVisibility = useCallback(() => {
    if (!!imageListRef?.current) {
      const offsetWidth = imageListRef.current.offsetWidth;
      const scrollWidth = imageListRef.current.scrollWidth;
      const scrollLeft = imageListRef.current.scrollLeft;

      if (offsetWidth >= scrollWidth) {
        setIsLeftScrollable(false);
        setIsRightScrollable(false);
      } else {
        if (scrollLeft > 0) {
          setIsLeftScrollable(true);
        } else {
          setMove(false);
          setIsLeftScrollable(false);
        }

        if (scrollLeft + offsetWidth < scrollWidth) {
          setIsRightScrollable(true);
        } else {
          setMove(false);
          setIsRightScrollable(false);
        }
      }
    }
  }, []);

  const lengthOfImages = images?.length ?? 0;

  useEffect(() => {
    handleScrollerVisibility();
  }, [lengthOfImages, handleScrollerVisibility]);

  useEffect(() => {
    if (!!imageListRef?.current) {
      imageListRef.current.addEventListener("scroll", handleScrollerVisibility);
    }

    return () => {
      if (!!imageListRef?.current) {
        imageListRef.current.removeEventListener(
          "scroll",
          handleScrollerVisibility
        );
      }
    };
  });

  const scrollLeft = () => {
    if (!!imageListRef?.current && isScrollRef.current) {
      imageListRef.current.scrollLeft -= scrollOffset;
      requestAnimationFrame(scrollLeft);
    }
  };

  const scrollRight = () => {
    if (!!imageListRef?.current && isScrollRef.current) {
      imageListRef.current.scrollLeft += scrollOffset;
      requestAnimationFrame(scrollRight);
    }
  };

  const getFilename = imageUrl => {
    let filename = imageUrl.split("/").reverse()[0];
    filename =
      filename
        .split("_")
        ?.slice(1)
        ?.join("_") ?? filename;

    return filename;
  };

  const downloadImage = imageUrl => {
    axios
      .get(imageUrl, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const filename = getFilename(imageUrl);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        addToast(`${filename} downloaded.`, "success");
      })
      .catch(() =>
        addToast(
          "There was downloading the image. Please try again later or contact support.",
          "error"
        )
      );
  };

  return (
    <AttachmentContainer>
      <DragDropContext onDragEnd={reorderImages}>
        <Droppable droppableId="image-attachments" direction="horizontal">
          {provided => (
            <ImageList
              ref={ref => {
                provided.innerRef(ref);
                imageListRef.current = ref;
              }}
              {...provided.droppableProps}
            >
              {images &&
                images.length > 0 &&
                images.map((image, index) => (
                  <Draggable key={image} index={index} draggableId={image}>
                    {(provided, snapshot) => (
                      <ImageWrapper
                        key={image}
                        className={"image"}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        style={{
                          ...provided.draggableProps.style
                        }}
                        data-tip={getFilename(image)}
                        data-for="image-attachment"
                      >
                        <AttachmentActions>
                          <AttachmentAction
                            onClick={() => downloadImage(image)}
                            data-tip="Download"
                            data-for="attachment-action"
                          >
                            <i className="icon-download-lg" />
                          </AttachmentAction>
                          <AttachmentAction
                            onClick={() => onRemove(image)}
                            data-tip="Remove"
                            data-for="attachment-action"
                          >
                            <i className="icon-cancel" />
                          </AttachmentAction>
                        </AttachmentActions>
                        <PostImage src={image} />
                        <ToolTip
                          id="attachment-action"
                          place="top"
                          effect="solid"
                          className="tooltip"
                        />
                        <ToolTip
                          id="image-attachment"
                          place="bottom"
                          effect="solid"
                          size="medium"
                          className="tooltip"
                          delayShow={1000}
                        />
                      </ImageWrapper>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
              {(!images || images.length < 10) && (
                <ImageUpload
                  onUpload={onUpload}
                  onUploadStart={onUploadStart}
                  handleScrollerVisibility={handleScrollerVisibility}
                />
              )}
            </ImageList>
          )}
        </Droppable>
      </DragDropContext>
      {isLeftScrollable && (
        <Scroller direction="left">
          <ScrollerButton
            direction="left"
            onMouseDown={() => {
              setMove(true);
              scrollLeft();
            }}
            onMouseUp={() => setMove(false)}
          >
            <i className="icon-arrowleft" />
          </ScrollerButton>
        </Scroller>
      )}
      {isRightScrollable && (
        <Scroller direction="right">
          <ScrollerButton
            direction="right"
            onMouseDown={() => {
              setMove(true);
              scrollRight();
            }}
            onMouseUp={() => setMove(false)}
          >
            <i className="icon-arrowright" />
          </ScrollerButton>
        </Scroller>
      )}
    </AttachmentContainer>
  );
};

export default ImageAttachment;
