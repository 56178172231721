import { Dispatch } from "react";

import {
  OPEN_COMPOSER,
  CLOSE_COMPOSER,
  ENABLE_PRO_MODE,
  DISABLE_PRO_MODE,
  SET_IN_DRAFT_MODE,
  SET_POST_DATA,
  RESET_POST_DATA,
  ENABLE_SPLIT_MODE,
  DISABLE_SPLIT_MODE,
  SET_TAGS,
  SET_EDITOR_STATE_OBJ,
  SET_VISIBLE_CAPTION,
  SET_VALIDATION_ERRORS,
  SET_CHOSEN_SUGGESTED_CAPTION,
  SET_POST_CONCEPTS,
  SET_POST_IDEA_CAPTIONS,
  SET_ATTACHMENT_TYPE,
  SET_IS_UPLOADING_ATTACHMENTS,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  REMOVE_ARTICLE_ATTACHMENT,
  REORDER_ATTACHMENT,
  SET_ATTACHMENT_VALIDATION_ERROR,
  SET_ATTACHMENTS,
  SET_IS_SUBMITTING,
  SET_IS_DELETING,
  SET_WAS_SCHEDULE_CHANGED,
  SET_PICKED_DATE,
  SET_MOOD_FILTER
} from "./actions";
import { tomorrow } from "utils/date";
import { IActions } from "./actionCreators";
export interface IMoodFilter {
  label: string;
  value: string;
}

export const MOOD_FILTER_OPTIONS: IMoodFilter[] = [
  {
    label: "Funny",
    value: "funny"
  },
  {
    label: "Clever",
    value: "clever"
  },
  {
    label: "Sarcastic",
    value: "sarcastic"
  },
  {
    label: "Inspiring",
    value: "inspiring"
  }
];

// TODO: Turn these into a proper defined interface
export interface IPostData {
  [key: string]: any;
}

export interface ITag {
  [key: string]: any;
}

export interface IValidationError {
  [key: string]: {
    [key: string]: any;
  };
}

export interface ICaption {
  id: string;
  title: string;
  caption: string;
}

export interface IPostIdea {
  id: string;
  title: string;
  subtitle: string;
  captions: ICaption[];
}

export interface IPostConcept {
  id: string;
  primaryColor: string;
  secondaryColor: string;
  shortDescription: string;
  slug: string;
  title: string;
  postIdeas: IPostIdea[];
}

export type AttachmentType = "photo" | "video" | "article";

export interface IUploadedAttachment {
  url: string;
  metaData: { [key: string]: any };
}

export interface IScrappedAttachment {
  [key: string]: any;
}

export interface IUploadedAttachments {
  isUploading: boolean;
  attachments: IUploadedAttachment[];
  validationErrors: any[];
}

export interface IScrappedAttachments {
  isScrapping: boolean;
  attachment: IScrappedAttachment | null;
  validationErrors: any[];
}

export interface IChannelAttachments {
  type: AttachmentType | null;
  photoAttachments: IUploadedAttachments;
  videoAttachments: IUploadedAttachments;
  articleAttachments: IScrappedAttachments;
}

export interface IAttachments {
  [key: string]: IChannelAttachments;
}

export interface IScheduler {
  isSubmitting: boolean;
  isDeleting: boolean;
  wasScheduleChanged: boolean;
  pickedDate: Date;
}

export interface IComposerState {
  isComposerOpen: boolean;
  inProMode: boolean;
  inDraftMode: boolean;
  postData: IPostData;
  inSplitMode: boolean;
  tags: ITag[];
  editorStateObj: any;
  visibleCaption: string;
  validationErrors: IValidationError[];
  chosenSuggestedCaption: { [key: string]: string };
  postConcepts: IPostConcept[];
  postIdea: IPostIdea | null;
  attachments: IAttachments;
  scheduler: IScheduler;
  moodFilter: IMoodFilter | null;
}

export type IComposerDispatch = Dispatch<IActions>;

const uploadedAttachmentsInitialValue: IUploadedAttachments = {
  attachments: [],
  isUploading: false,
  validationErrors: []
};

const scrappedAttachmentsInitialValue: IScrappedAttachments = {
  attachment: null,
  isScrapping: false,
  validationErrors: []
};

export const initialValues: IComposerState = {
  isComposerOpen: false,
  inProMode: true,
  inDraftMode: false,
  postData: {},
  inSplitMode: false,
  tags: [],
  editorStateObj: {},
  visibleCaption: "all",
  validationErrors: [],
  chosenSuggestedCaption: {},
  postConcepts: [],
  postIdea: null,
  attachments: {
    all: {
      type: null,
      photoAttachments: { ...uploadedAttachmentsInitialValue },
      videoAttachments: { ...uploadedAttachmentsInitialValue },
      articleAttachments: { ...scrappedAttachmentsInitialValue }
    }
  },
  scheduler: {
    isSubmitting: false,
    isDeleting: false,
    wasScheduleChanged: false,
    pickedDate: tomorrow()
  },
  moodFilter: null
};

export const composerReducer = (
  state: IComposerState = initialValues,
  action: IActions
): IComposerState => {
  switch (action.type) {
    case OPEN_COMPOSER:
      return {
        ...state,
        isComposerOpen: true
      };

    case CLOSE_COMPOSER:
      return {
        ...state,
        isComposerOpen: false
      };

    case ENABLE_PRO_MODE:
      return {
        ...state,
        inProMode: true
      };

    case DISABLE_PRO_MODE:
      return {
        ...state,
        inProMode: false
      };

    case SET_IN_DRAFT_MODE:
      return {
        ...state,
        inDraftMode: action.payload
      };

    case SET_POST_DATA: {
      return {
        ...state,
        postData: action.payload
      };
    }

    case RESET_POST_DATA: {
      return {
        ...initialValues
      };
    }

    case ENABLE_SPLIT_MODE:
      return {
        ...state,
        inSplitMode: true
      };

    case DISABLE_SPLIT_MODE:
      return {
        ...state,
        inSplitMode: false
      };

    case SET_TAGS: {
      return {
        ...state,
        tags: action.payload
      };
    }

    case SET_EDITOR_STATE_OBJ: {
      return {
        ...state,
        editorStateObj: action.payload
      };
    }

    case SET_VISIBLE_CAPTION: {
      return {
        ...state,
        visibleCaption: action.payload,
        // ? initiate attachment object for the channel
        // ? (only applicable when there is a need for different attachments for different channels)
        attachments: {
          ...state.attachments,
          [action.payload]: {
            ...(state.attachments[action.payload] ||
              initialValues.attachments["all"])
          }
        }
      };
    }

    case SET_VALIDATION_ERRORS: {
      return {
        ...state,
        validationErrors: action.payload
      };
    }

    case SET_CHOSEN_SUGGESTED_CAPTION: {
      return {
        ...state,
        chosenSuggestedCaption: {
          ...state.chosenSuggestedCaption,
          ...action.payload
        }
      };
    }

    case SET_POST_CONCEPTS: {
      return {
        ...state,
        postConcepts: action.payload
      };
    }

    case SET_POST_IDEA_CAPTIONS: {
      const { postConceptIndex, postIdeaIndex } = state.postConcepts.reduce(
        (acc, postConcept, postConceptIndex) => {
          const postIdeaIndex = postConcept.postIdeas.findIndex(
            postIdea => postIdea.id === action.postIdeaId
          );

          if (postIdeaIndex > -1) {
            acc.postConceptIndex = postConceptIndex;
            acc.postIdeaIndex = postIdeaIndex;
          }
          return acc;
        },
        { postConceptIndex: -1, postIdeaIndex: -1 }
      );

      if (postConceptIndex === -1 || postIdeaIndex === -1) {
        return state;
      }

      const updatedPostConcept = {
        ...state.postConcepts[postConceptIndex],
        postIdeas: [
          ...state.postConcepts[postConceptIndex].postIdeas.slice(
            0,
            postIdeaIndex
          ),
          {
            ...state.postConcepts[postConceptIndex].postIdeas[postIdeaIndex],
            captions: action.payload
          },
          ...state.postConcepts[postConceptIndex].postIdeas.slice(
            postIdeaIndex + 1
          )
        ]
      };

      return {
        ...state,
        postConcepts: [
          ...state.postConcepts.slice(0, postConceptIndex),
          updatedPostConcept,
          ...state.postConcepts.slice(postConceptIndex + 1)
        ]
      };
    }

    case SET_ATTACHMENT_TYPE: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            type: action.payload
          }
        }
      };
    }

    case SET_IS_UPLOADING_ATTACHMENTS: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"]),
        type: action.attachmentType
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            isUploading: action.payload
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            isUploading: action.payload
          }
        };
      } else {
        newValue = {
          ...newValue,
          articleAttachments: {
            ...(state.attachments[action.channel].articleAttachments ||
              scrappedAttachmentsInitialValue),
            isScrapping: action.payload
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case ADD_ATTACHMENTS: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"]),
        type: action.attachmentType
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.photoAttachments.attachments,
              ...action.payload
            ]
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [...action.payload] // ? replacing the video
          }
        };
      } else {
        newValue = {
          ...newValue,
          articleAttachments: {
            ...(state.attachments[action.channel].articleAttachments ||
              scrappedAttachmentsInitialValue),
            attachment: {
              ...action.payload
            }
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REMOVE_ATTACHMENT: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.photoAttachments.attachments.slice(0, action.index),
              ...newValue.photoAttachments.attachments.slice(action.index + 1)
            ]
          }
        };
      } else {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.videoAttachments.attachments.slice(0, action.index),
              ...newValue.videoAttachments.attachments.slice(action.index + 1)
            ]
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REMOVE_ARTICLE_ATTACHMENT: {
      const newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"]),
        type: null,
        articleAttachments: {
          ...(state.attachments[action.channel].articleAttachments ||
            scrappedAttachmentsInitialValue),
          attachment: null,
          validationErrors: []
        }
      };

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REORDER_ATTACHMENT: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        const attachments = [...newValue.photoAttachments.attachments];
        const [selectedItem] = attachments.splice(action.sourceIndex, 1);
        attachments.splice(action.destinationIndex, 0, selectedItem);

        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments
          }
        };
      } else if (action.attachmentType === "video") {
        const attachments = [...newValue.videoAttachments.attachments];
        const [selectedItem] = attachments.splice(action.sourceIndex, 1);
        attachments.splice(action.destinationIndex, 0, selectedItem);

        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case SET_ATTACHMENT_VALIDATION_ERROR: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            validationErrors: [...action.payload]
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            validationErrors: [...action.payload]
          }
        };
      } else {
        newValue = {
          ...newValue,
          articleAttachments: {
            ...(state.attachments[action.channel].articleAttachments ||
              scrappedAttachmentsInitialValue),
            validationErrors: [...action.payload]
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case SET_ATTACHMENTS: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            ...action.payload
          }
        }
      };
    }

    case SET_IS_SUBMITTING: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          isSubmitting: action.payload
        }
      };
    }

    case SET_IS_DELETING: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          isDeleting: action.payload
        }
      };
    }

    case SET_WAS_SCHEDULE_CHANGED: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          wasScheduleChanged: action.payload
        }
      };
    }

    case SET_PICKED_DATE: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          pickedDate: action.payload
        }
      };
    }

    case SET_MOOD_FILTER: {
      return {
        ...state,
        moodFilter: action.payload
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};
