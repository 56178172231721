import store from "state/store";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { callApi } from "utils/ContentoApi";

import { updateAccount } from "state/actions/AccountActions";

export const skipStep = async (step: OnboardingStepsEnum) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/onboarding/${step}/skip`
    });

    store.dispatch(
      updateAccount({
        ...account,
        onboardingProgress: [
          ...new Set([...account.onboardingProgress.concat(step as string)])
        ]
      })
    );

    return results;
  } catch (error) {
    throw new Error(`There was an error skipping the step.`);
  }
};
