import styled, { css } from "styled-components";
import { Box, Flex, Text, Card, Image } from "rebass/styled-components";
import { Link } from "react-router-dom";

import facebookIcon from "../../assets/images/icon-facebook@24.png";
import twitterIcon from "../../assets/images/icon-twitter@24.png";
import linkedInIcon from "../../assets/images/icon-linkedin@24.png";
import instagramIcon from "../../assets/images/icon-instagram@24.png";

import RSSIcon from "../../assets/images/social/rss-black-circle.png";

import { theme } from "../../theme";

export const Body = styled(Box)`
  margin: 0;
  color: ${props => props.theme.colors.gray};
`;

export const Display01 = styled(Text)`
  font-size: 45px;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: -1.8px;
  color: ${props => props.theme.colors.text01};
`;

export const Display02 = styled(Text)`
  font-size: 40px;
  font-family: ${props => props.theme.fonts.headline};
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colors.text01};
`;
export const Body6 = styled(Text)`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.body};
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.colors.text03};
`;

export const SubTitle2 = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #242627;
`;

export const Description = styled.span`
  font-size: ${props => (props.size === "lg" ? "22px" : "14px")};
  line-height: ${props => (props.size === "lg" ? "28.6px" : "24px")};
  margin: 8px 0;
  max-width: ${props => (props.size === "sm" ? "480px" : "none")};
  font-weight: 400;
  color: ${props => props.theme.colors.text03};
`;

export const Headline1 = styled(Text)`
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.headline};
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.96px;
  line-height: 1.3;
`;

export const Headline2 = styled.h2`
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.headline};
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.75px;
  line-height: 1.3;
  ${props =>
    props.mb >= 0 &&
    css`
      margin-bottom: ${props.mb};
    `};

  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

export const Headline3 = styled(Text)`
  color: ${props => props.theme.colors.text01};
  font-family: ${props =>
    props.font ? props.theme.fonts[props.font] : props.theme.fonts.headline};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02px;
  line-height: 23.75px;
  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

Headline3.defaultProps = {
  as: "h3"
};

export const Headline5 = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text01};
  margin-bottom: 0;
`;

Headline5.defaultProps = {
  as: "h5"
};

export const Headline6 = styled(Text)`
  color: ${props =>
    props.color ??
    (props.isLight ? props.theme.colors.text03 : props.theme.colors.text01)};
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 600;

  & small {
    color: ${props => props.theme.colors.text04};
    display: block;
    margin: 2px 0 8px;
  }
`;

Headline6.defaultProps = {
  as: "h6"
};

export const Caption2 = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #242627;
`;

export const InlineLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: -0.32px;

  &:hover {
    text-decoration: underline;
  }
`;

export const InlineButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: ${props =>
    props.variant === "secondary"
      ? props.theme.colors.text03
      : props.theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: inherit;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 1.19;
  padding: 0 0;
  &:hover {
    text-decoration: underline;
  }
`;

export const Layout = styled(Flex)`
  flex-direction: column;
  margin: 0 auto 0 auto;
  @media only screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    display: block;
  }
`;

export const DashboardLayout = styled.div`
  height: calc(100vh - 95px);
  min-height: auto;
  overflow-y: hidden;
  margin: 24px auto 0 auto;
`;

export const AccountSettingsLayout = styled.div`
  background-color: rgb(253, 253, 253);
  margin-top: -32px;
  padding-top: 32px;
`;

export const MainContainer = styled(Flex)`
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  padding: 24px 0;
  width: 100%;
  z-index: 0;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.xs}) {
    padding: 0 16px;
  }
`;

export const MainWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  max-width: 1288px;
  margin: 0 32px;
`;

export const NavigationZone = styled(Box)`
  flex: 1;
  max-width: 236px;
  margin-right: 64px;
  height: 100%;
  position: sticky;
  top: 110px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    margin-right: 32px;
  }
  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin: 0 auto 32px;
    max-width: 400px;
    width: 100%;
  }
`;

export const ContentZone = styled(Box)`
  flex: 1;
`;

export const Content = styled(Box)``;

export const EnclosedContent = styled(Box)`
  max-width: ${props => (props.size === "sm" ? "512px" : "785px")};
  width: 100%;
`;

export const Brick = styled(Box)`
  background: white;
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  box-sizing: border-box;
  color: ${props => props.theme.colors.text01};
  margin: 4px 0;
  padding: ${props => (props.p ? props.p : "0 0")};

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    padding: 24px;
  }
`;

export const ProgressCard = styled(Card)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108),
    0 1.6px 3.6px rgba(0, 0, 0, 0.132);
  padding: 12px;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`;

export const ProgressDetail = styled(Box)`
  font-size: 12px;
  line-height: 16px;
  margin-top: 7px;
`;

export const CardTitle = styled.h4`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 9px;
  color: ${props => props.theme.colors.black};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: #b4daff;
  border-radius: 2px;
`;

export const ProgressIndicator = styled.div`
  background: #0061ff;
  height: 100%;
  border-radius: 2px;
  width: 0;
  transition: width 1s;
`;

export const TooltipContainer = styled.div`
  overflow: visible;
  z-index: 2;
  position: relative;
  text-align: center;
`;

export const TooltipTitle = styled.div`
  background: ${props => props.theme.colors.text01};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  color: white;
  cursor: ${props => (props.onClick !== undefined ? "pointer" : "default")};
  line-height: 1;
  position: absolute;
  font-size: 14px;
  padding: 6px 8px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  min-width: max-content;
  max-width: 240px;
  ${props =>
    props.visible &&
    css`
      visibility: visible;
      opacity: 1;
    `};

  &::after {
    content: "";
    position: absolute;
    left: calc(50% - 6px);
    border-width: 6px;
    border-style: solid;
    border-color: ${props =>
        props.placement === "top" ? props.theme.colors.text01 : "transparent"}
      transparent
      ${props =>
        props.placement === "bottom"
          ? props.theme.colors.text01
          : "transparent"}
      transparent;
    top: ${props => (props.placement === "top" ? "100%" : "-12px")};
  }
  & span {
    max-width: 220px;
  }
`;

export const IconButton = styled(Box)`
  cursor: pointer;
`;

export const ModalTitle = styled(Headline3)`
  letter-spacing: -0.2px;
  flex-grow: 1;
`;

export const Label = styled(Flex)`
  align-items: center;
  font-weight: bold;
  line-height: 19px;
  font-size: 14px;
  color: ${props =>
    props.isLight ? props.theme.colors.text03 : props.theme.colors.text01};
`;

export const Small = styled.small`
  line-height: 15.6px;
  font-size: 12px;
  color: ${props => props.theme.colors.text02};
`;

export const Paragraph = styled(Text)`
  line-height: 21px;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.body};
  margin-bottom: ${props => props.mb ?? 20}px;
  color: ${props =>
    props.variant === "light"
      ? props.theme.colors.text02
      : props.variant === "lightest"
      ? props.theme.colors.text03
      : props.theme.colors.text01};
  font-weight: 400;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : "none")};

  & strong {
    color: ${props => props.theme.colors.text01};
  }
  & a:hover {
    text-decoration: underline;
  }
`;

Paragraph.defaultProps = {
  as: "p"
};

export const SingleParagraph = styled(Paragraph)`
  margin-bottom: 0;
`;

export const Portal = styled(Flex)`
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  border: 1px solid #eaebeb;
  margin-bottom: 8px;
  position: relative;
  border-radius: 8px;
  padding: 20px 40px 20px 40px;
`;

export const Loading = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const SubSectionHeader = styled(Flex)`
  margin-bottom: 26px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeced;
  justify-content: space-between;
`;

export const SubSectionTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 26px;
  padding-bottom: 16px;
  line-height: 36px;
  border-bottom: 1px solid #eaeced;
  letter-spacing: -0.33px;
  position: relative;
`;

export const Subtitle = styled.h3`
  color: ${props => props.theme.colors.black};
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;

  & small {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.colors.text02};
  }
`;

export const SideNavigationList = styled.div`
  margin-top: 8px;
`;

export const SideNavigationTitle = styled(Text)`
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.text03};
`;

export const MainContent = styled(Box)`
  background: ${props => props.theme.colors.white};
`;

export const TrialBanner = styled(Flex)`
  background-color: ${props => props.theme.colors.text01};
  border-radius: 16px 16px 0 0;
  color: white;
  font-size: 14px;
  left: calc(50% - 242px);
  padding: 12px 30px;
  position: fixed;
  top: calc(100vh - 38px);
  z-index: 9;

  & a {
    border-bottom: 1px solid white;
    color: white;
    font-weight: bold;
    margin-left: 3px;
    text-shadow: -1px 0 0 ${props => props.theme.colors.text01},
      1px 0 0 ${props => props.theme.colors.text01};

    &:hover {
      color: white;
      opacity: 0.66;
    }
  }
`;

export const PersistentBanner = styled(TrialBanner)`
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.blue};
  color: ${props => props.textColor || props.theme.colors.white};
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: max-content;
`;

export const Avatar = styled(Image)`
  border: ${props => (props.noShadow ? "none" : "1px solid #ffffff")};
  box-shadow: ${props =>
    props.noShadow ? "none" : "0px 1px 2px rgba(0, 0, 0, 0.3);"};
  border-radius: ${props => (props.isRounded ? "50%" : "6px")};
`;

export const AvatarWrapper = styled.figure`
  background-color: white;
  border-radius: 32px;
  outline: 1px solid ${props => props.theme.colors.grey03};
  height: ${props => (props.height ? props.height + "px" : "auto")};
  position: relative;
`;

export const ChannelAvatar = styled(Box)`
  position: relative;
  display: inline-block;
  opacity: ${props => (props.enabled ? 1 : 0.2)};
  cursor: ${props => (props.clickable ? "pointer" : "default")};
  vertical-align: middle;
`;

export const serviceIconMap = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  linkedin: linkedInIcon,
  instagram: instagramIcon,
  rss: RSSIcon
};

export const Service = styled(Box)`
  height: ${props => (props.size ? props.size + "px" : "24px")};
  width: ${props => (props.size ? props.size + "px" : "24px")};
  border-radius: 20px;
  background-image: url(${props => serviceIconMap[props.type]});
  background-size: cover;
  position: ${props => (props.inline ? "relative" : "absolute")};
  bottom: ${props => (props.size && props.size > 12 ? 0 : "2px")};
  ${props =>
    !props.inline &&
    css`
      right: ${props.size ? -Math.floor(props.size / 2 - 2) + "px" : "-7px"};
      box-shadow: 0 0 0 ${props => (props.size > 24 ? 2 : 1.3)}px white,
        0 2px 2px rgba(0, 0, 0, 0.2);
    `};
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  padding: 10px;
`;

export const EmptyStateIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.grey01};
  color: ${props => props.theme.colors.grey03};
  margin-bottom: 20px;
  border-radius: 60px;
  height: 48px;
  width: 48px;
`;

export const EmptyStateHeading = styled.h4`
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  color: ${props => props.theme.colors.text03};
`;

export const EmptyStateDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 140%;
  color: ${props => props.theme.colors.text03};
`;

export const EmptyStateHelper = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 140%;
  color: ${props => props.theme.colors.text03};
  cursor: pointer;

  :hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.text01};
  }
`;

export const MediaObject = styled(Flex)`
  align-items: center;
`;

export const MediaThumbnail = styled.figure`
  align-items: center;
  border-radius: ${props => props.radius}px;
  display: flex;
  height: ${props => props.size}px;
  object-fit: cover;
  overflow: hidden;
  width: ${props => props.size}px;
  background-color: #eceef0;

  & img {
    height: 100%;
    width: 100%;
  }
`;

export const MediaContent = styled(Box)`
  flex: 1;
  margin: 0 24px;
`;

export const MediaTitle = styled(Text)`
  color: ${props => props.theme.colors.text01};
  font-weight: 700;
`;

export const MediaDescription = styled(Box)`
  font-size: 14px;

  & span {
    color: ${props => props.theme.colors.text03};
    display: block;
    margin: 4px 0;
  }
`;

export const PropertyTable = styled(Box)`
  border-radius: 16px;
  background-color: ${props => props.theme.colors.grey01};
  padding: 16px;
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
`;

export const PropertyField = styled(Box)`
  align-items: ${props => props.align || "center"};
  column-gap: 26px;
  display: grid;
  grid-template-columns: 72px 1fr;
  justify-content: flex-start;
  /* grid-template-rows: repeat(auto-fill, 36px); */
  justify-items: flex-start;
`;

export const MediaSubDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => theme.variants[props.variant].fontColor};
  display: block;
  margin: 4px 0;
  text-transform: capitalize;
`;

export const EmptyChannelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  background: rgba(96, 116, 128, 0.08);
  border-radius: 16px;
  text-align: center;

  p {
    margin: auto;
  }
`;

export const EmptyChannelInfoWrapper = styled.div`
  margin-top: 16px;
  max-width: 280px;
`;

export const EmptyChannelTitle = styled.p`
  font-weight: 600;
  color: ${props => props.theme.colors.text01};
`;

export const EmptyChannelDescription = styled.p`
  color: ${props => props.theme.colors.text03};
  line-height: 19.6px;
`;

export const SocialSetWrapper = styled(Flex)`
  flex-direction: row;
  gap: -3px;
`;

export const SocialSetContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-image: url(${props => props.src});
  background-size: cover;
  box-shadow: white 0px 0px 0px 1.5px;
`;

export const AddChannelButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  font-weight: 600;
  padding: 0px;
  color: ${props => props.theme.colors.blue};
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid ${props => props.theme.colors.blue};
  }
`;
