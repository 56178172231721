import React from "react";
import { ITag, ITags } from "./types";

import Tag from "./Tag";

import { TagGroupWrapper } from "./styles";

const TagGroup: React.FC<ITags> = ({ tags }) => {
  return (
    <TagGroupWrapper>
      {tags.map((tag: ITag, index: number) => {
        return (
          <Tag key={index} id={tag.id} title={tag.title} lang={tag.lang} />
        );
      })}
    </TagGroupWrapper>
  );
};

export default TagGroup;
