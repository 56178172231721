import { Box } from "rebass";
import IconButton from "components/common/IconButton";
import Popper from "components/common/Popper";
import ContextMenu from "components/common/contextMenu";
import React, { useState } from "react";

import styled from "styled-components";
import { theme } from "theme";

export const IconBottonGroup = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

interface IContentItemOperations {
  id: string;
  liked: boolean;
  saved: boolean;
  onPost: () => void;
  onLike: () => void;
  onDislike: () => void;
  onBookmark: () => void;
  onPreviewArticle: () => void;
}

const ContentItemOperations: React.FC<IContentItemOperations> = ({
  id,
  liked,
  saved,
  onPost,
  onLike,
  onDislike,
  onBookmark,
  onPreviewArticle
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <IconBottonGroup>
      <IconButton
        // @ts-ignore
        size={32}
        iconSize={20}
        icon="icon-send"
        variant="secondaryQuiet"
        onClick={onPost}
      />
      {saved ? (
        <IconButton
          // @ts-ignore
          size={32}
          iconSize={20}
          icon="icon-bookmark-filled-20"
          variant={"orange"}
          onClick={onBookmark}
        />
      ) : (
        <IconButton
          // @ts-ignore
          size={32}
          iconSize={20}
          icon="icon-bookmark-20"
          variant="secondaryQuiet"
          onClick={onBookmark}
        />
      )}

      <Box ref={setReferenceElement}>
        <IconButton
          // @ts-ignore
          size={32}
          iconSize={20}
          icon="icon-dots"
          variant="secondaryQuiet"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            toggleShowOptions();
          }}
          isActive={showOptions}
        />
        {/* @ts-ignore */}
        <Popper
          usePortal={true}
          placement="bottom-end"
          referenceElement={referenceElement}
          visible={showOptions}
          onClose={() => setShowOptions(false)}
          exceptions={[referenceElement]}
        >
          <ContextMenu
            close={() => setShowOptions(false)}
            options={[
              {
                label: "More of this",
                onClick: onLike,
                iconElement: liked ? (
                  <span
                    className="icon-like-filled-20"
                    style={{ color: theme.colors.green }}
                  />
                ) : (
                  <span className="icon-like-20" />
                )
              },
              {
                label: "Less of this",
                onClick: onDislike,
                iconElement: <span className="icon-disliked-20" />
              },
              {
                label: "Read in reader mode",
                onClick: onPreviewArticle,
                icon: "icon-read"
              }
            ]}
          />
        </Popper>
      </Box>
    </IconBottonGroup>
  );
};

export default ContentItemOperations;
