export const UPDATE_FOCUS_DATE = "@@timeline/UPDATE_FOCUS_DATE";
export const UPDATE_LAST_UNSEEN = "@@timeline/UPDATE_LAST_UNSEEN";
export const UPDATE_TIMELINE_ACTIVITIES =
  "@@timeline/UPDATE_TIMELINE_ACTIVITIES";

export const updateFocusDate = focusDate => {
  return {
    type: UPDATE_FOCUS_DATE,
    focusDate
  };
};

export const updateLastUnseen = postDate => {
  return {
    type: UPDATE_LAST_UNSEEN,
    postDate
  };
};

export const updateTimelineActivities = payload => {
  return {
    type: UPDATE_TIMELINE_ACTIVITIES,
    payload
  };
};
