import React, { useRef, useEffect, useState, useCallback } from "react";
import qs from "qs";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import ScheduleHeader from "../ScheduleHeader";
import MonthGrid from "./MonthGrid";
import { useSelector } from "react-redux";
import { useContentoApi } from "../../../utils/useContentoApi";
import { getDate, getFormattedDate, getOffsetDate } from "../utils/dateUtils";
import { startOfDay, startOfMonth, endOfMonth, format } from "date-fns";
import { MonthLayout, MonthBody, MonthSidebar } from "./styles";

import { usePrevious } from "utils/usePrevious";

import TASK_TYPES from "../constants/taskType";

// FIXME: move this
import contentTypes from "../../common/contentTypes/data/content-types";
import TaskPanel from "../tasks/TaskPanel";
import DragDropContextWrapper from "../common/drag-and-drop/DragDropContextWrapper";

import * as draftActions from "state/actions/DraftActions";
import * as activityActions from "state/actions/ActivityActions";
import { useQueryParams } from "../../../utils/useQueryParams";

function Month({
  location,
  saveActivities,
  activities,
  modalState,
  changeDraftCountByValue,
  removeDraftById
}) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const queryParams = useQueryParams();

  const today = queryParams.date
    ? new Date(queryParams.date)
    : startOfDay(getDate({ timezone: account.timezone }));
  const queryTask = qs.parse(location.search.substring(1));

  const addToast = useToaster();

  const [currentDate, setCurrentDate] = useState(today);

  const [isLoading, setIsLoading] = useState(true);
  const [isPlannerMode, setPlannerMode] = useState(
    queryParams.taskId || queryParams.taskGroupId
  );
  const prevIsPlannerMode = usePrevious(isPlannerMode);

  const [fetchEntries] = useContentoApi(
    `/accounts/${account.id}/activities/calendar`
  );

  const [removeTask] = useContentoApi(`accounts/${account.id}/tasks`, "delete");

  const [removeTaskGroup] = useContentoApi(
    `accounts/${account.id}/task-groups`,
    "delete"
  );

  const handleChangeDate = date => {
    setCurrentDate(date);
  };

  const [selectedIds, setSelectedIds] = useState(
    account.channels.map(channel => channel.id)
  );

  const handleChannelChange = data => {
    setSelectedIds(data);
  };

  // new task from scratch
  const handleAddTask = (newTask = {}) => {
    let date = null;
    if (newTask.date) {
      date = newTask.date.toISOString();
    }

    history.push({
      search: `?taskId=new${date ? `&date=${date}` : ""}`
    });
  };

  // fetch a task by id and load it on state on edit
  const handleEditTask = async ({ task, type }) => {
    if (type === TASK_TYPES.SERIES) {
      history.push({
        search: `?taskGroupId=${task.taskGroupId}`
      });
    } else {
      history.push({
        search: `?taskId=${task.id}`
      });
    }
  };

  const handleRemoveTask = async ({ task, type }) => {
    let removePromise = null;

    if (type === TASK_TYPES.SERIES) {
      removePromise = removeTaskGroup({ url: task.taskGroupId });
    } else {
      removePromise = removeTask({ url: task.id || task.taskId });
    }

    removePromise
      .then(() => {
        addToast("Task successfully deleted", "success");
        handleCloseSidebar();
      })
      .catch(err => {
        addToast(
          err.message || "There was an error deleting your task",
          "error"
        );
        handleCloseSidebar();
      });
  };

  const handleCloseSidebar = () => {
    setPlannerMode(false);
    if (location.search !== "") {
      history.push(`/accounts/${account.id}/schedule/month`);
    } else {
      refreshEntries();
    }
  };

  useEffect(() => {
    if (queryParams.taskId || queryParams.taskGroupId) {
      setPlannerMode(true);
    }
  }, [queryParams.taskId, queryParams.taskGroupId]);

  const monthEndRef = useRef(null);

  const scrollToBottom = () => {
    if (monthEndRef?.current) {
      monthEndRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  useEffect(() => {
    const { post } = modalState?.modalProps ?? {};
    if (
      !!post &&
      modalState.isOpen &&
      modalState.modalType === "COMPOSER_MODAL"
    ) {
      const activeDate = post.postedAt || post.scheduledAt;

      if (!!activeDate) {
        setCurrentDate(new Date(activeDate));

        if (format(new Date(activeDate), "d") >= 15) {
          scrollToBottom();
        }
      }
    }
  }, [modalState.isOpen]);

  const refreshEntries = useCallback(async () => {
    const period = {
      start: getOffsetDate(startOfMonth(currentDate), { days: -7 }),
      end: getOffsetDate(endOfMonth(currentDate), { days: 7 })
    };
    setIsLoading(true);

    try {
      const params = {
        fromDate: getFormattedDate(period.start),
        toDate: getFormattedDate(period.end),
        timezone: account.timezone
      };

      if (selectedIds.length > 0) {
        params.channelIds = selectedIds.join(",");
      }
      const response = await fetchEntries({ params });

      const results = response.map(res => {
        return {
          ...res,
          isHidden: false
        };
      });

      saveActivities({ data: results, params });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addToast(err.message || "There was an error fetching slots", "error");
    }
  }, [
    currentDate,
    account.timezone,
    fetchEntries,
    saveActivities,
    addToast,
    selectedIds
  ]);

  useEffect(() => {
    if (queryParams.date && format(new Date(queryParams.date), "d") >= 15) {
      scrollToBottom();
    }
  }, [queryParams.date, isLoading]);

  useEffect(() => {
    if (!prevIsPlannerMode && isPlannerMode && prevIsPlannerMode !== undefined)
      return;

    refreshEntries();
  }, [refreshEntries, isPlannerMode, prevIsPlannerMode]);

  const onPostDeleted = post => {
    addToast(
      `Successfully deleted ${post.isDraft ? "draft" : "post"}`,
      "success"
    );
    refreshEntries();
    if (post.isDraft) {
      changeDraftCountByValue(-1);
      removeDraftById(post.id);
    }
  };

  return (
    <MonthLayout isSidebarVisible={isPlannerMode}>
      <MonthBody isSidebarVisible={isPlannerMode}>
        <ScheduleHeader
          isSidebarVisible={isPlannerMode}
          today={today}
          currentDate={currentDate}
          onChangeDate={handleChangeDate}
          onAddTask={handleAddTask}
          channels={account.channels}
          selectedChannelIds={selectedIds}
          handleChannelChange={handleChannelChange}
        />
        <DragDropContextWrapper>
          <MonthGrid
            currentDate={currentDate}
            entries={activities}
            isLoading={isLoading}
            onAddTask={handleAddTask}
            onEditTask={handleEditTask}
            onRemoveTask={handleRemoveTask}
            onAddPost={refreshEntries}
            onPostDeleted={onPostDeleted}
          />
          <div ref={monthEndRef} />
        </DragDropContextWrapper>
      </MonthBody>
      {isPlannerMode && (
        <MonthSidebar className="active">
          <TaskPanel
            onClose={handleCloseSidebar}
            account={account}
            defaultIdea={queryTask.idea}
            today={today}
            onRemove={handleRemoveTask}
            contentTypes={contentTypes}
            isLoading={isLoading}
          />
        </MonthSidebar>
      )}
    </MonthLayout>
  );
}

const mapStateToProps = state => {
  //hide activities for for which we are showing an updated preview
  const filteredActivities = state.activity.activities
    .filter(activity => {
      return !(
        activity.type === "TASK" &&
        (activity.task.id === state.activity.hideTaskId ||
          (state.activity.hideTaskGroupId &&
            activity.task.taskGroupId === state.activity.hideTaskGroupId))
      );
    })
    .sort((a, b) => {
      return a.time < b.time ? -1 : 1;
    });
  const activities = [...filteredActivities, ...state.activity.previews];
  return {
    activities: activities,
    modalState: state.modals
  };
};

export default connect(mapStateToProps, {
  saveActivities: activityActions.saveActivities,
  changeDraftCountByValue: draftActions.changeDraftCountByValue,
  removeDraftById: draftActions.removeDraftById
})(Month);
