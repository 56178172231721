import { IClientSettings, WindowSize } from "./reducer";
import { SET_WINDOW_SIZE, SET_CLIENT_SETTINGS } from "./actions";

export interface ISetWindowSizeAction {
  type: typeof SET_WINDOW_SIZE;
  payload: WindowSize;
}

export const setWindowSizeAction = (
  payload: WindowSize
): ISetWindowSizeAction => ({
  type: SET_WINDOW_SIZE,
  payload
});

export interface ISetClientSettingsAction {
  type: typeof SET_CLIENT_SETTINGS;
  payload: Partial<IClientSettings>;
}

export const setClientSettingsAction = (
  payload: Partial<IClientSettings>
): ISetClientSettingsAction => ({
  type: SET_CLIENT_SETTINGS,
  payload
});

export type IActions = ISetWindowSizeAction | ISetClientSettingsAction;
