import { Layout, Paragraph, Portal } from "../../components/common/styles";
import React from "react";
import { Flex, Text } from "rebass/styled-components";

const ContactPage = () => {
  return (
    <Layout>
      <Flex flexWrap="wrap" justifyContent="center">
        <Flex width={[1, 8 / 12]} px={50} flexDirection="column">
          <Portal>
            <Paragraph>
              <Text mb={20}>
                Feel free to contact your account manager using their email:
              </Text>
              <ul>
                <li>
                  Elisa:{" "}
                  <a href="mailto:elisa@willow.co">
                    elisa@hellocontento.com
                  </a>
                </li>
                <li>
                  Steven:{" "}
                  <a href="mailto:steven@willow.co">
                    steven@hellocontento.com
                  </a>
                </li>
                <li>
                  Ludwig:{" "}
                  <a href="mailto:ludwig@willow.co">
                    ludwig@hellocontento.com
                  </a>
                </li>
              </ul>
            </Paragraph>
          </Portal>
        </Flex>
      </Flex>
    </Layout>
  );
};
export default ContactPage;
