import axios from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import config from "./../config";
import authService from "./Auth";

async function callApi({ headers = {}, ...rest }, loginRedirect = true) {
  try {
    const token = authService.getAccessToken();
    const options = {
      baseURL: config.apiUrl,
      headers: {
        ...headers
      },
      ...rest
    };

    if (token && !options.headers["Authorization"]) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios(options);
    return response.data;
  } catch (error) {
    if (!error.response || !error.response.status) {
      let newError = new Error("Network error");
      newError.name = "NetworkError";
      throw newError;
    }

    if (error.response && error.response.data && error.response.data.message) {
      let newError = new Error(error.response.data.message);
      if (error.response.status === 400) {
        newError.name = "ValidationError";
      } else if (error.response.status === 401 && loginRedirect) {
        newError.name = "AuthorizationError";
        //Force reload. Ugly but practical.
        window.location.href = "/login";
        return;
      } else if (error.response.status === 403 && loginRedirect) {
        window.location.href = "/";
        return;
      }
      throw newError;
    } else {
      throw error;
    }
  }
}

async function callStreamApi({ headers = {}, url, data, ...rest }, callbacks) {
  const endpoint = `${config.apiUrl}${url}`;
  const token = authService.getAccessToken();
  const options = {
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "application/json",
      ...headers
    },
    onopen: res => {
      if (process.env.NODE_ENV !== "production") {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      }

      if (callbacks.onOpen) {
        callbacks.onOpen();
      }
    },
    onmessage: event => {
      if (process.env.NODE_ENV !== "production") {
        console.log("message", event.data);
      }

      if (callbacks.onMessage) {
        callbacks.onMessage(event.data);
      }
    },
    onclose: () => {
      if (process.env.NODE_ENV !== "production") {
        console.log("Connection closed by the server");
      }

      if (callbacks.onClose) {
        callbacks.onClose();
      }
    },
    onerror: err => {
      if (process.env.NODE_ENV !== "production") {
        console.log("There was an error from server", err);
      }

      if (callbacks.onError) {
        callbacks.onError(err);
      }
    },
    ...rest
  };

  if (!!data) {
    options["body"] = JSON.stringify(data);
  }

  if (token) {
    options.headers["authorization"] = `Bearer ${token}`;
  }

  await fetchEventSource(endpoint, options);
}

export { callApi, callStreamApi };
