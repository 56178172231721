import React, { createContext, useReducer } from "react";

export type AlertContext = {
  id: string | null;
  title: string;
  description: string;
  banner?: string;
  helpUrl: string;
  onProceed: () => void;
  persist?: boolean;
};

const initialContext: AlertContext = {
  id: null,
  title: "",
  description: "",
  banner: "",
  helpUrl: "",
  onProceed: () => {},
  persist: false
};

const FeatureAlertContext = createContext<{
  state: AlertContext;
  dispatch: React.Dispatch<any>;
}>({
  state: initialContext,
  dispatch: () => null
});

const featureAlertReducer = (state: AlertContext, action: any) => {
  const { payload, type } = action;
  switch (type) {
    case "ADD":
      return { ...payload };
    case "REMOVE":
      return {};
    default:
      return { ...state };
  }
};

interface IFeatureAlertProviderProps {
  children: JSX.Element[] | JSX.Element;
}

const FeatureAlertProvider: React.FC<IFeatureAlertProviderProps> = ({
  children
}) => {
  const [state, dispatch] = useReducer(featureAlertReducer, initialContext);

  return (
    <FeatureAlertContext.Provider value={{ state, dispatch }}>
      {children}
    </FeatureAlertContext.Provider>
  );
};

export { FeatureAlertContext };
export default FeatureAlertProvider;
