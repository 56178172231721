import _ from "lodash";
import React, { useState } from "react";

import ImageSuggestion from "./ImageSuggestion";
import CaptionSuggestion from "./CaptionSuggestion";
import IconButton from "components/common/IconButton";
import { ToolkitContainer, Toolbar, ToolContent } from "./styles";

interface ITool {
  order: number;
  icon: string;
  activeIcon: string;
  component: React.ComponentType;
}

const TOOLS: { [key: string]: ITool } = {
  captionSuggestion: {
    order: 0,
    icon: "icon-draft",
    activeIcon: "icon-draft-filled",
    component: CaptionSuggestion
  },
  imageSuggestion: {
    order: 1,
    icon: "icon-image",
    activeIcon: "icon-image-filled",
    component: ImageSuggestion
  }
};

type Tool = keyof typeof TOOLS;

const Toolkit: React.FC<{}> = () => {
  const [activeTool, setActiveTool] = useState<Tool>("captionSuggestion");

  const ActiveContent : any = TOOLS[activeTool].component;

  return (
    <ToolkitContainer>
      <Toolbar>
        {Object.keys(TOOLS)
          .sort((a, b) => TOOLS[a].order - TOOLS[b].order)
          .map(key => (
            <IconButton
              key={key}
              // @ts-ignore
              size={"sm"}
              iconSize={20}
              icon={
                activeTool === key ? TOOLS[key].activeIcon : TOOLS[key].icon
              }
              onClick={() => setActiveTool(key)}
              variant={"secondaryLoud"}
            />
          ))}
      </Toolbar>
      <ToolContent>
        <ActiveContent />
      </ToolContent>
    </ToolkitContainer>
  );
};

export default Toolkit;
