import * as timelineActions from "../actions/TimelineActions";

const initialState = {
  focusDate: null,
  lastUnseen: null,
  activities: {}
};

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case timelineActions.UPDATE_FOCUS_DATE:
      return {
        ...state,
        focusDate: action.focusDate
      };

    case timelineActions.UPDATE_LAST_UNSEEN:
      return {
        ...state,
        lastUnseen: action.postDate
      };

    case timelineActions.UPDATE_TIMELINE_ACTIVITIES:
      const { key, entries, postType, updateDateTime } = action.payload;

      return {
        ...state,
        activities: {
          ...state.activities,
          [key]: {
            ...state.activities[key],
            [postType]: {
              entries,
              updateDateTime
            }
          }
        }
      };

    default:
      return state;
  }
};

export default timelineReducer;
