import React, { useState, useEffect } from "react";
import { useToaster } from "@hellocontento/maillard";

import UploadZone from "./UploadZone";
import { callApi } from "utils/ContentoApi";
import { ImageWrapper, PostImage } from "./styles";
import Loader from "components/common/loading/Loader";

const getImageMetadata = image => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = () => {
      reject("Could not load image file");
    };

    const fileReader = new FileReader();
    fileReader.onload = () => {
      img.src = fileReader.result;
    };
    fileReader.onerror = () => {
      reject("Could not load image file");
    };
    fileReader.readAsDataURL(image);
  });
};

const ImageUpload = ({
  onUpload,
  onUploadStart,
  onCancel,
  openFileModalByDefault,
  handleScrollerVisibility
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const addToast = useToaster();

  useEffect(() => {
    if (handleScrollerVisibility) {
      handleScrollerVisibility();
    }
  }, [uploadedImages.length, handleScrollerVisibility]);

  const onDrop = images => {
    setIsUploading(true);
    if (onUploadStart) {
      onUploadStart();
    }

    for (const image of images) {
      if (image.size > 5 * 1024 * 1024) {
        addToast(
          "The selected image exceeds the 5mb limit. Please reduce it's size and try again.",
          "error"
        );
        setIsUploading(false);
        return;
      }
    }

    const uploads = images.map(image => {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("timestamp", (Date.now() / 1000) | 0);
      setUploadedImages(prevState => [
        ...prevState,
        {
          metaData: image,
          preview: URL.createObjectURL(image),
          progress: 0
        }
      ]);
      return callApi({
        method: "post",
        url: "images/upload",
        data: formData,
        headers: [{ "Content-Type": "multipart/form-data" }],
        onUploadProgress: function(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadedImages(prevState => {
            const imageIndex = prevState.findIndex(
              img => img.metaData?.name === image.name
            );

            if (imageIndex < 0) {
              return prevState;
            }

            return [
              ...prevState.slice(0, imageIndex),
              {
                ...prevState[imageIndex],
                progress: percentCompleted
              },
              ...prevState.slice(imageIndex + 1)
            ];
          });
        }
      });
    });

    Promise.all(images.map(getImageMetadata))
      .then(imagesMetadata => {
        //we have all metadata start uploading
        return Promise.all(uploads).then(imageUploadResults => {
          //flatten uploadresults, merge with metadata
          const allImages = [].concat
            .apply([], imageUploadResults)
            .map((result, i) => {
              return {
                ...result,
                metaData: imagesMetadata[i]
              };
            });
          setUploadedImages([]);
          setIsUploading(false);
          onUpload(allImages);
        });
      })
      .catch(err => {
        if (err.message === "File too large") {
          addToast(
            "The selected image exceeds the 5mb limit. Please reduce it's size and try again.",
            "error"
          );
        } else {
          addToast(
            "There was an error uploading the picture. Please try again later or contact support.",
            "error"
          );
        }

        setUploadedImages([]);
        setIsUploading(false);
        onUpload(false);
      });
  };

  return (
    <>
      {isUploading &&
        uploadedImages.map(image => (
          <ImageWrapper
            key={image.metaData.path}
            progress={100 - parseInt(image.progress)}
          >
            <PostImage src={image.preview} />
            <Loader location="center" size={24} color="#0063FB" />
          </ImageWrapper>
        ))}
      <UploadZone
        accept="image/*"
        isUploading={isUploading}
        onDrop={onDrop}
        onCancel={onCancel}
        openFileModalByDefault={openFileModalByDefault}
      />
    </>
  );
};

export default ImageUpload;
