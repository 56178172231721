import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState
} from "react";

type Props = {
  children: React.ReactNode;
};

export interface ILanguageData {
  label: string;
  value: string;
}

export interface ITopicData {
  value: string;
  total: number;
}

type Context = {
  searchValue: string;
  topics: Array<ITopicData>;
  selectedTopics: Array<string>;
  selectedLanguages: Array<ILanguageData>;
  setData: Dispatch<SetStateAction<Context>>;
};

const initialContext: Context = {
  topics: [],
  searchValue: "",
  selectedTopics: [],
  selectedLanguages: [],
  setData: (): void => {
    throw new Error("setData function must be overridden");
  }
};

const ArticleSearchContext = createContext<Context>(initialContext);

const ArticleSearchProvider = ({ children }: Props): JSX.Element => {
  const [contextState, setData] = useState<Context>(initialContext);

  return (
    <ArticleSearchContext.Provider value={{ ...contextState, setData }}>
      {children}
    </ArticleSearchContext.Provider>
  );
};

export { ArticleSearchContext, ArticleSearchProvider };
