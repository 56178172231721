import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import IconButton from "components/common/IconButton";
import Popper from "components/common/Popper";
import { useContentoApi } from "utils/useContentoApi";

import { TooltipInfo } from "./styles";

import * as draftActions from "state/actions/DraftActions";

import { DraftMenuWrapper, DraftsIndicatorWrapper } from "./styles";

import DraftPanel from "components/draft/DraftPanel";
import useWindowSize from "utils/useWindowSize";

export function DraftPost({ account, count, saveDraftCount }) {
  const [showOptions, setShowOptions] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const windowSize = useWindowSize();

  const [offset, setOffset] = useState([104, 12]);

  const [fetchDraftCount] = useContentoApi(
    `accounts/${account.id}/draft-posts?count=true`
  );

  const updateDraftCount = useCallback(() => {
    fetchDraftCount()
      .then(response => {
        saveDraftCount(response);
      })
      .catch(e => {
        console.log(e, " draft count");
      });
  }, [fetchDraftCount, saveDraftCount]);

  useState(() => {
    updateDraftCount();
  }, [updateDraftCount]);

  useEffect(() => {
    if (windowSize.size === "xsmall") {
      setOffset([104, 8]);
    } else {
      setOffset([104, 12]);
    }
  }, [windowSize.size]);

  const closeDraftPanel = () => {
    setShowOptions(false);
  };

  return (
    <DraftMenuWrapper ref={setReferenceElement} isOpen={showOptions}>
      <>
        <IconButton
          data-tip="Drafts"
          size={40}
          iconSize={24}
          icon="icon-draft"
          variant="primary"
          onClick={toggleShowOptions}
        />
        {count > 0 && <DraftsIndicatorWrapper>{count}</DraftsIndicatorWrapper>}
      </>
      <TooltipInfo effect={"solid"} place={"bottom"} />
      <Popper
        placement={"bottom-end"}
        offset={offset}
        strategy="fixed"
        referenceElement={referenceElement}
        visible={showOptions}
        exceptions={[referenceElement]}
        zIndex={9}
      >
        <DraftPanel
          onClose={closeDraftPanel}
          draftCount={count}
          fetchCount={updateDraftCount}
        />
      </Popper>
    </DraftMenuWrapper>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    count: state.draft.count
  };
};

const mapDispatchToProps = {
  saveDraftCount: draftActions.saveDraftCount
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftPost);
