import React from "react";
import { useSelector } from "react-redux";

import { DashboardGrid } from "./styles";
import TaskWidget from "./widgets/TaskWidget";
import AnalyticsWidgets from "./analyticsWidgets";

function DashboardWidgets({ fromDate, toDate }) {
  const today = new Date();
  const account = useSelector(state => state.account.data);

  return (
    <DashboardGrid>
      <TaskWidget account={account} fromDate={today} toDate={toDate} />
      <AnalyticsWidgets />
    </DashboardGrid>
  );
}

export default DashboardWidgets;
