import ReactLoading from "react-loading";
import { reject, isString } from "lodash";
import { Box } from "rebass/styled-components";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

// TODO: Refactor along future typography
import {
  SettingsSection,
  SettingsSubtitle,
  SettingsSubtitleSection
} from "components/settings/styles";
import { ChannelList } from "./styles";
import { callApi } from "utils/ContentoApi";
import AddService from "./services/AddService";
import { CHANNEL_SERVICES } from "utils/channels";
import { useAppState } from "contextApi/appContext";
import { useContentoApi } from "utils/useContentoApi";
import AvailableService from "./services/AvailableService";
import { WILLOW_PLAN, BILLING_STATUS } from "constants/plan";
import { channelLimitReached, usageLimitsInfo } from "utils/UsageLimits";

const ChannelSettingsView = ({
  account,
  onConnect,
  handleDisconnect,
  availableServices,
  connectedServices
}) => {
  const {
    clientSettings: { allowedChannels }
  } = useAppState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [fetchAccountUsers, cancelFetchAccountUsers] = useContentoApi(
    `accounts/${account.id}/users`
  );

  useEffect(() => {
    if (
      allowedChannels !== null ||
      account.billing?.willowPlan === WILLOW_PLAN.SMALL_BIZ ||
      account.billing?.billingStatus === BILLING_STATUS.IN_TRIAL
    ) {
      setTotal(allowedChannels);
      return;
    }
    setIsLoading(true);
    fetchAccountUsers()
      .then(accountUsers => {
        setIsLoading(false);
        const usageInfo = usageLimitsInfo(account, accountUsers);

        setTotal(usageInfo.channels.used + usageInfo.channels.remaining);
      })
      .catch(() => {
        setIsLoading(false);
      });
    return cancelFetchAccountUsers;
  }, [fetchAccountUsers, cancelFetchAccountUsers, account, allowedChannels]);

  return (
    <>
      {connectedServices.length > 0 && (
        <SettingsSection>
          <SettingsSubtitleSection>
            <SettingsSubtitle>Connected channels</SettingsSubtitle>
            {!isLoading && (
              <span>{`Using ${connectedServices.length} out of ${total} channels`}</span>
            )}
          </SettingsSubtitleSection>
          <ChannelList flexDirection="column">
            {connectedServices.map(card => (
              <AvailableService
                connected={true}
                service={card.service}
                channel={card.channel}
                status={card.status}
                key={card.key}
                onConnect={service =>
                  onConnect(isString(service) ? service : card.service)
                }
                onDisconnect={() =>
                  card.channel ? handleDisconnect(card.channel.id) : null
                }
              />
            ))}
          </ChannelList>
        </SettingsSection>
      )}
      <SettingsSection>
        <SettingsSubtitle>Connect new channel</SettingsSubtitle>
        <ChannelList flexDirection="column">
          {availableServices.map(card => (
            <AvailableService
              disabled={!Math.max(total - connectedServices.length, 0)}
              connected={false}
              service={card.service}
              channel={card.channel}
              status={card.status}
              key={card.key}
              onConnect={service =>
                onConnect(isString(service) ? service : card.service)
              }
              onDisconnect={() =>
                card.channel ? handleDisconnect(card.channel.id) : null
              }
            />
          ))}
        </ChannelList>
      </SettingsSection>
    </>
  );
};

const ChannelOnboardingView = ({
  availableServices,
  connectedServices,
  onConnect
}) => {
  return (
    <Box>
      {availableServices.map(card => {
        const isConnected = !!connectedServices.find(
          s => s.service === card.service
        );

        return (
          <AddService
            key={card.service}
            service={card.service}
            isConnected={isConnected}
            onConnect={onConnect}
          />
        );
      })}
    </Box>
  );
};

const ChannelConnectionList = ({
  account,
  onConnect,
  currentView = "settings"
}) => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const addToast = useToaster();

  useEffect(() => {
    callApi({
      method: "get",
      url: `/accounts/${account.id}/channels`
    })
      .then(channels => {
        setChannels(channels);
        setLoading(false);
      })
      .catch(() =>
        addToast(
          "There was an error connecting the channel. Please try again later or contact support.",
          "error"
        )
      );
  }, [account, addToast]);

  const handleDisconnectChannel = channelId => {
    if (
      window.confirm(
        "Are you sure you want to REMOVE this social media account? Please keep in mind that ALL the information and posts related to this account will be removed as well."
      )
    ) {
      callApi({
        method: "delete",
        url: `/accounts/${account.id}/channels/${channelId}`
      })
        .then(() => {
          setChannels(reject(channels, { id: channelId }));
        })
        .catch(() =>
          addToast(
            "There was an error deleting the channel. Please try again later or contact support.",
            "error"
          )
        );
    }
  };

  if (loading) {
    return <ReactLoading color="#bbb" type="cylon" />;
  }

  const connectedServices = channels.map(channel => {
    const serviceName = `${channel.service}_${channel.serviceType}`;
    return {
      key: channel.id,
      channel: channel,
      service: serviceName,
      status: channel.needsReconnection ? "toReconnect" : "connected"
    };
  });

  const availableServices = CHANNEL_SERVICES.map(service => {
    const limitReached = channelLimitReached(account, service);
    return {
      key: service,
      service: service,
      status: limitReached ? "toUpgrade" : "toConnect"
    };
  });

  return currentView === "settings" ? (
    <ChannelSettingsView
      account={account}
      availableServices={availableServices}
      connectedServices={connectedServices}
      onConnect={onConnect}
      handleDisconnect={handleDisconnectChannel}
    />
  ) : (
    <ChannelOnboardingView
      availableServices={availableServices}
      connectedServices={connectedServices}
      onConnect={onConnect}
    />
  );
};

export default ChannelConnectionList;
