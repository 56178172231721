import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState
} from "react";

type Props = {
  children: React.ReactNode;
};

export type Context = {
  id: string | null;
  type: string;
  title: string;
  isFollowing: boolean;
  meta?: any;
  setContext: Dispatch<SetStateAction<Context>>;
};

const initialContext: Context = {
  id: null,
  type: "",
  title: "",
  isFollowing: false,
  meta: null,
  setContext: (): void => {
    throw new Error("setContext function must be overridden");
  }
};

const ContentPageContext = createContext<Context>(initialContext);

const ContentPageProvider = ({ children }: Props): JSX.Element => {
  const [contextState, setContext] = useState<Context>(initialContext);

  return (
    <ContentPageContext.Provider value={{ ...contextState, setContext }}>
      {children}
    </ContentPageContext.Provider>
  );
};

export { ContentPageContext, ContentPageProvider };
