import React, { useState } from "react";

import MonthPost from "./MonthPost";
import { PostGroup, PostList } from "./styles";
import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";

export function MonthGroupPost({ posts, day, onClick, onPostDeleted }) {
  const [isExpanded, setExpanded] = useState(false);

  const channels = posts.map(entry => entry.post.channel);

  const handleExpandGroup = () => {
    setExpanded(true);
  };

  return isExpanded ? (
    <PostGroup>
      <PostList>
        {posts.map((item, index) => (
          <DraggableWrapper
            key={item.id}
            id={item.id}
            index={index}
            isDisabled={item.post?.postedAt}
          >
            <MonthPost
              key={item.id}
              day={day}
              post={item.post}
              onClick={onClick}
              onPostDeleted={onPostDeleted}
            />
          </DraggableWrapper>
        ))}
      </PostList>
    </PostGroup>
  ) : (
    <MonthPost
      day={day}
      post={posts[0].post}
      onClick={handleExpandGroup}
      customChannels={channels}
      onPostDeleted={onPostDeleted}
    />
  );
}
