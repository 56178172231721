import { useSelector } from "react-redux";
import { Flex } from "rebass/styled-components";
import React, { useEffect, useMemo } from "react";
import { startOfDay, endOfDay, addDays } from "date-fns";

import { DashboardContent } from "./styles";
import { useAppState } from "contextApi/appContext";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { DashboardProvider } from "contextApi/dashboardContext";
import DashboardHeader from "components/dashboard/dashboardHeader";
import DashboardWidgets from "components/dashboard/DashboardWidgets";
import DashboardNews from "components/dashboard/DashboardNewsSection";
import OnboardingList from "components/dashboard/onboarding/OnboardingList";
import DashboardInfluencers from "components/dashboard/DashboardInfluencers";
import DashboardCalendar from "components/dashboard/DashboardCalendarSection";

function Dashboard() {
  const {
    clientSettings: { client }
  } = useAppState();
  const isTelenetClient = client === "telenet";
  const account = useSelector(state => state.account.data);

  const now = new Date();
  const fromDate = startOfDay(addDays(now, -13)).toISOString();
  const toDate = endOfDay(addDays(now, 14)).toISOString();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  const hideOnboardingWizard = useMemo(() => {
    return (
      account.onboardingComplete ||
      account.onboardingProgress.includes(
        OnboardingStepsEnum.PENDING_ONBOARDING
      )
    );
  }, [account]);

  return (
    <DashboardProvider>
      <Flex flexDirection="column" alignItems="center">
        <DashboardContent>
          <DashboardHeader isOnboardingActive={!hideOnboardingWizard} />
          {!hideOnboardingWizard && <OnboardingList />}
          <DashboardWidgets fromDate={fromDate} toDate={toDate} />
          <DashboardCalendar />
          {!isTelenetClient && <DashboardNews />}
          <DashboardInfluencers showFooter={false} />
        </DashboardContent>
      </Flex>
    </DashboardProvider>
  );
}

export default Dashboard;
