import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";

import {
  OnboardingLayout,
  OnboardingHeader,
  OnboardingContainer
} from "../onboarding/styles";
import { useContentoApi } from "utils/useContentoApi";
import { getChargebeeInstance } from "utils/Chargebee";
import { FormTitle } from "components/common/form/styles";
import { updateAccountBilling } from "state/actions/AccountActions";
import willowLogo from "assets/images/willow-text-color@2x.png";
import WizardContainer from "components/wizard/WizardContainer";
import BillingPlans from "components/telenetCheckout/BillingPlans";
import {
  telenetCoachingAddonMapping as coachingAddOnMapping,
  telenetPlanMapping as planMapping
} from "constants/index";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import CheckoutBasket from "components/telenetCheckout/CheckoutBasket";
import Booking from "components/telenetCheckout/Booking";
import BillingCoachingAddon from "components/telenetCheckout/BillingCoachingAddon";

const sortedSteps = ["plan", "onboarding", "payment", "book_meeting"];

const progressSteps = {
  plan: "Plan",
  onboarding: "Onboarding",
  payment: "Payment",
  booking: "Booking"
};

export default function TelenetCheckoutPage({ match }) {
  const account = useSelector(state => state.account.data);
  const dispatch = useDispatch();
  const addToast = useToaster();
  const history = useHistory();

  const [step, setStep] = useState("plan");
  const [selectedWillowPlan, setSelectedWillowPlan] = useState("YEARLY");
  const [onboardingSelected, setonboardingSelected] = useState("PREMIUM");
  const [chargebeePlans, setChargebeePlans] = useState(null);
  const [chargebeeAddons, setChargebeeAddons] = useState(null);
  const [billingEstimate, setBillingEstimate] = useState();
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);

  const currency = "EUR";

  const steps = {
    plan: {
      name: "plan",
      path: "/plan",
      location: `/accounts/${account.id}/checkout/plan`,
      title: "Upgrade today and run your Social Media like a boss",
      subTitle: "Join over 350 companies that use Willow."
    },
    onboarding: {
      name: "onboarding",
      path: "/onboarding",
      location: `/accounts/${account.id}/checkout/onboarding`,
      title: "What kind of onboarding would you like to get from our experts?",
      subTitle: "85% of our customers prefer our Premium Onboarding Experience."
    },
    payment: {
      name: "payment",
      path: "/payment",
      location: `/accounts/${account.id}/checkout/payment`,
      title: "Upgrade today and run your Social Media like a boss",
      subTitle: "Join over 350 companies that use Willow."
    },
    booking: {
      name: "booking",
      path: "/booking",
      location: `/accounts/${account.id}/checkout/booking`,
      title: "Book your onboarding meeting today"
    }
  };

  const [fetchPlans, cancelFetchPlans] = useContentoApi(
    `/billing/${account.id}/plans?currency=${currency}`
  );
  const [fetchAddons, cancelFetchAddons] = useContentoApi(
    `/billing/${account.id}/addons?currency=${currency}`
  );
  const [createBillingEstimate] = useContentoApi(
    `/accounts/${account.id}/billing/estimate-charges`,
    "post"
  );
  const [createBilling] = useContentoApi(
    `/accounts/${account.id}/billing`,
    "post"
  );
  const [paymentSucceeded] = useContentoApi(
    `/accounts/${account.id}/payment-succeeded`
  );

  const cbInstance = getChargebeeInstance();

  const handleBack = () => {
    const idx = sortedSteps.findIndex(val => val === step);
    const prevStep = sortedSteps[idx - 1];

    setStep(prevStep);
    history.push(match.url + "/" + prevStep);
  };

  const handleNext = nextStep => {
    setStep(nextStep);
    history.push(match.url + "/" + nextStep);
  };

  const internalPlanId = planMapping[currency][selectedWillowPlan];
  const internalAddonId = onboardingSelected
    ? coachingAddOnMapping[currency][onboardingSelected]
    : null;

  const getAnalyticsEventData = () => {

    const invoice_estimate = billingEstimate.invoice_estimate ? billingEstimate.invoice_estimate : billingEstimate.next_invoice_estimate;
    console.log("invoice_estimate", invoice_estimate);

    return {
      order_id: account.id,
      value: invoice_estimate?.total / 100,
      revenue: invoice_estimate?.total / 100,
      currency: invoice_estimate?.currency_code,
      products: invoice_estimate?.line_items.map(lineItem => ({
        product_id: lineItem.entity_id,
        sku: lineItem.entity_id,
        name: lineItem.description,
        price: lineItem.amount / 100,
        quantity: 1
      }))
    };
  };

  const handleCheckout = () => {
    cbInstance.openCheckout({
      hostedPage: () => {
        return createBilling({
          data: {
            plan: internalPlanId,
            addon: internalAddonId
          }
        }).then(response => ({ ...response.hosted_page }));
      },
      error(error) {
        addToast(
          "Something went wrong with the checkout. Please contact support.",
          "error"
        );
        console.error(error);
      },
      loaded() {
        dispatch(
          trackAnalyticsEvent("Checkout Started", getAnalyticsEventData())
        );
      },
      step(step) {
        dispatch(
          trackAnalyticsEvent("Checkout Step Viewed", {
            checkout_id: account.id,
            step: step
          })
        );
      },
      success() {
        dispatch(
          trackAnalyticsEvent("Order Completed", getAnalyticsEventData())
        );
        paymentSucceeded()
          .then(() => {
            cbInstance.closeAll();
            dispatch(
              updateAccountBilling(
                {
                  willowPlan: internalPlanId,
                  willowCoachingPlan: internalAddonId
                },
                () => {
                  handleNext("booking");
                }
              )
            );
          })
          .catch(() => {
            addToast(
              "Something went wrong confirming the payment. Please contact support.",
              "error"
            );
          });
      },
      close: () => {}
    });
  };

  useEffect(() => {
    // Update step on path mismatch (if user tries to go back on history)
    if (
      history.location.pathname !== `/accounts/${account.id}/checkout/${step}`
    ) {
      const targetStep = Object.keys(steps).find(
        s => steps[s].location === history.location.pathname
      );
      setStep(targetStep);
    }
  }, [history.location, step, steps, account.id]);

  useEffect(() => {
    fetchPlans()
      .then(r => {
        setChargebeePlans(r);
      })
      .catch(error => {
        addToast(error.message, "error");
      });
    return cancelFetchPlans;
  }, [fetchPlans, addToast, cancelFetchPlans]);

  useEffect(() => {
    fetchAddons()
      .then(r => {
        setChargebeeAddons(r);
      })
      .catch(error => {
        addToast(error.message, "error");
      });
    return cancelFetchAddons;
  }, [fetchAddons, addToast, cancelFetchAddons]);

  useEffect(() => {
    if (step === "payment") {
      setIsLoadingEstimate(true);
      createBillingEstimate({
        data: { plan: internalPlanId, addon: internalAddonId }
      })
        .then(estimate => {
          setBillingEstimate(estimate);
          setIsLoadingEstimate(false);
        })
        .catch(() => {
          addToast(
            "Could not get an estimate for your subscription. Please contact support.",
            "error"
          );
          setIsLoadingEstimate(false);
        });
    }
  }, [
    account.id,
    addToast,
    createBillingEstimate,
    internalAddonId,
    internalPlanId,
    step
  ]);

  if (!chargebeePlans || !chargebeeAddons) {
    return null;
  }

  return (
    <WizardContainer
      activeStep={step}
      steps={progressSteps}
      onBack={step !== "plan" && step !== "booking" && handleBack}
      nextButtonText={"Next"}
    >
      <OnboardingLayout>
        <OnboardingHeader>
          <img src={willowLogo} alt="Willow" height="40" />
        </OnboardingHeader>
        <OnboardingContainer size="lg" marginTop={"2.5vh"}>
          <FormTitle>
            {steps[step]?.title}
            <small>{steps[step]?.subTitle}</small>
          </FormTitle>

          <Switch>
            <Route
              path={`${match.path}/plan`}
              render={() => (
                <BillingPlans
                  cbPlans={chargebeePlans}
                  currency={currency}
                  onSelect={planId => {
                    setSelectedWillowPlan(planId);
                    handleNext("onboarding");
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/onboarding`}
              render={() => (
                <BillingCoachingAddon
                  cbAddons={chargebeeAddons}
                  currency={currency}
                  onSelect={addonId => {
                    setonboardingSelected(addonId);
                    handleNext("payment");
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/payment`}
              render={() => (
                <CheckoutBasket
                  cycle={selectedWillowPlan}
                  isLoading={isLoadingEstimate}
                  estimate={billingEstimate}
                  onCheckout={() => handleCheckout()}
                />
              )}
            />
            <Route
              path={`${match.path}/booking`}
              render={() => (
                <Booking
                  currency={currency}
                  onBooking={() => {
                    if (
                      account?.billing?.willowCoachingPlan?.toLowerCase() ===
                      coachingAddOnMapping[currency]["LIGHT"].toLowerCase()
                    ) {
                      window.open(
                        "https://meetings.hubspot.com/virginie-dardenne/willow-light-onboarding-telenet-customers",
                        "_blank"
                      );
                      history.push(
                        `/accounts/${account.id}/dashboard?modal=checkout_success`
                      );
                    } else if (
                      account?.billing?.willowCoachingPlan?.toLowerCase() ===
                      coachingAddOnMapping[currency]["PREMIUM"].toLowerCase()
                    ) {
                      window.open(
                        "https://meetings.hubspot.com/virginie-dardenne/willow-premium-onboarding-telenet-customers-clone",
                        "_blank"
                      );
                      history.push(
                        `/accounts/${account.id}/dashboard?modal=checkout_success`
                      );
                    } else {
                      addToast(
                        "Onboarding meeting could not be booked.",
                        "error"
                      );
                      history.push(
                        `/accounts/${account.id}/dashboard?modal=checkout_success`
                      );
                    }
                  }}
                  onRedirect={() => {
                    history.push(`/accounts/${account.id}/checkout/plan`);
                  }}
                  onCancel={() => {
                    history.push(
                      `/accounts/${account.id}/dashboard?modal=checkout_success`
                    );
                  }}
                  onboarding={account?.billing?.willowCoachingPlan}
                />
              )}
            />
            <Redirect exact to={match.url + "/plan"} />
          </Switch>
        </OnboardingContainer>
      </OnboardingLayout>
    </WizardContainer>
  );
}
