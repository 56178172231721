import React from "react";
import { useSelector } from "react-redux";

import {
  SectionLabel,
  SectionContent,
  SectionWrapper,
  SelectorWrapper
} from "../../styles";
import { PropertiesContainer } from "./styles";
import { useComposerActions } from "contextApi/composerContext";
import EmptyChannelState from "components/common/EmptyChannelState";
import StatusSelector from "../../components/postComposer/statusSelector";
import ChannelSelector from "../../components/postComposer/channelSelector";
import CategorySelector from "../../components/postComposer/categorySelector";

const Properties: React.FC<{}> = () => {
  const account = useSelector<any, any>(state => state.account.data);
  const { closeComposer } = useComposerActions();

  return (
    <PropertiesContainer>
      <SelectorWrapper>
        {/* @ts-ignore */}
        <SectionWrapper verticle>
          <SectionLabel>Category</SectionLabel>
          <SectionContent>
            <CategorySelector />
          </SectionContent>
        </SectionWrapper>
        {/* @ts-ignore */}
        <SectionWrapper verticle fillHeight>
          <SectionLabel>Channels</SectionLabel>
          {/* @ts-ignore */}
          <SectionContent isList>
            {account.channels < 1 ? (
              <EmptyChannelState callback={closeComposer} />
            ) : (
              <ChannelSelector />
            )}
          </SectionContent>
        </SectionWrapper>
        {/* @ts-ignore */}
        <SectionWrapper verticle>
          <SectionLabel>Status</SectionLabel>
          <SectionContent>
            <StatusSelector />
          </SectionContent>
        </SectionWrapper>
      </SelectorWrapper>
    </PropertiesContainer>
  );
};

export default Properties;
