import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import {
  IdeaNav,
  IdeaPageContainer,
  IdeaPageDescription,
  IdeaPageHeader,
  IdeaPageTitle,
  IdeaWrapper
} from "../../components/ideas/styles";
import Button from "../../components/common/Button";
import { useContentoApi } from "../../utils/useContentoApi";
import { Switch } from "react-router";
import { matchPath, Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import IdeaGrid from "../../components/ideas/IdeaGrid";

export default function IdeasPage({ match }) {
  const account = useSelector(state => state.account.data);

  const [postConcepts, setPostConcepts] = useState(null);
  const addToast = useToaster();
  const history = useHistory();
  const matchedPath = matchPath(history.location.pathname, {
    path: `/accounts/${account.id}/ideas/:postConceptSlug`
  });
  const postConceptSlug = matchedPath && matchedPath.params.postConceptSlug;

  const [fetchPostConcepts, cancelFetchPostConcepts] = useContentoApi(
    "post-concepts"
  );

  useEffect(() => {
    fetchPostConcepts()
      .then(setPostConcepts)
      .catch(error => {
        addToast(error.message, "error");
      });
    return cancelFetchPostConcepts;
  }, [cancelFetchPostConcepts, fetchPostConcepts, addToast]);

  if (!postConcepts) {
    return null;
  }

  return (
    <IdeaPageContainer>
      <IdeaWrapper>
        <IdeaPageHeader>
          <IdeaPageTitle mb={12}>
            Discover Social Media Post Ideas
          </IdeaPageTitle>
          <IdeaPageDescription>
            With this library of content ideas for each of the major social
            platforms, you’ll be ahead of the curve. And you’ll never find
            yourself staring at a blank content calendar again.
          </IdeaPageDescription>
        </IdeaPageHeader>

        <IdeaNav>
          <Button
            size="lg"
            variant={postConceptSlug ? "secondary" : "black"}
            onClick={() => history.push(`/accounts/${account.id}/ideas`)}
            marginRight={8}
            isRounded
          >
            All
          </Button>
          {postConcepts.map(postConcept => (
            <Button
              key={postConcept.id}
              size="lg"
              variant={
                postConcept.slug === postConceptSlug ? "black" : "secondary"
              }
              onClick={() =>
                history.push(
                  `/accounts/${account.id}/ideas/${postConcept.slug}`
                )
              }
              marginRight={8}
              isRounded
            >
              {postConcept.title}
            </Button>
          ))}
        </IdeaNav>
        <Switch>
          <Route path={match.url + "/:postConceptSlug"} component={IdeaGrid} />
          <Route exact path={match.url} component={IdeaGrid} />
        </Switch>
      </IdeaWrapper>
    </IdeaPageContainer>
  );
}
