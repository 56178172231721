import React, { useEffect } from "react";
import * as accountActions from "../../state/actions/AccountActions";
import { useToaster } from "@hellocontento/maillard";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { callApi } from "../../utils/ContentoApi";
import qs from "qs";
import { withRouter } from "react-router-dom";
import * as modalActions from "../../state/actions/ModalActions";
import { getChannelConnectedRedirectPath } from "../../utils/channels";
import { useAppState } from "contextApi/appContext";

/**
 * On this page the user will select the specific facebook pages to connect to his contento account
 */
const ConnectInstagramBusinessPage = ({
  account,
  fbToken,
  redirect,
  history,
  fetchAccount,
  openModal
}) => {
  const addToast = useToaster();
  const {
    clientSettings: { allowedChannels }
  } = useAppState();

  useEffect(() => {
    callApi({
      url: `/accounts/${account.id}/connect/instagram/businesses?token=${fbToken}`
    })
      .then(async pages => {
        const connectPages = async selectedPages => {
          try {
            const availableChannelSlots = Math.max(
              allowedChannels - account.channels.length,
              0
            );
            const allowedPages = selectedPages.slice(0, availableChannelSlots);
            await Promise.all(allowedPages.map(page => createChannel(page)));
            //fetch account with new channels
            fetchAccount(account.id);
            history.push(getChannelConnectedRedirectPath(account, redirect));
          } catch (error) {
            addToast(
              "Something unexpected went wrong. Please refresh the page and try again.",
              "error"
            );
          }
        };

        const createChannel = async page => {
          const channelData = {
            service: "instagram",
            serviceType: "business",
            username: page.username,
            avatar: page.profile_picture_url,
            connection: {
              connectionType: "instagram_business",
              connectionDetails: {
                instagramUserId: page.id,
                accessToken: fbToken //instagram needs the user token instead of the page token
              }
            }
          };
          return callApi({
            method: "post",
            url: `/accounts/${account.id}/channels`,
            data: channelData
          });
        };

        if (pages.length === 1) {
          await connectPages(pages);
        } else {
          openModal("SELECT_INSTAGRAM_BUSINESS_MODAL", { pages, connectPages });
        }
      })
      .catch(() => {
        addToast(
          "Something went wrong while trying to fetch your instagram businesses. Please try again or contact support.",
          "error"
        );
      });
  }, [
    account.id,
    addToast,
    fbToken,
    openModal,
    fetchAccount,
    history,
    account,
    redirect
  ]);

  return <ReactLoading color={"#bbb"} type={"cylon"} />;
};

const mapStateToProps = (state, props) => {
  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  return {
    account: state.account.data,
    fbToken: queryParams.token,
    redirect: queryParams.redirect
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAccount: accountActions.fetchAccount,
    openModal: modalActions.openModal
  })(ConnectInstagramBusinessPage)
);
