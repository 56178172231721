import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { Box } from "rebass/styled-components";
import {
  OnboardingContainer,
  OnboardingHeader,
  OnboardingLayout
} from "../onboarding/styles";
import willowLogo from "../../assets/images/willow-text-color@2x.png";
import { FormTitle } from "../../components/common/form/styles";
import WizardContainer from "../../components/wizard/WizardContainer";
import BillingPlans from "../../components/checkout/BillingPlans";
import { useDispatch, useSelector } from "react-redux";
import { getChargebeeInstance } from "../../utils/Chargebee";
import { useToaster } from "@hellocontento/maillard";
import { useContentoApi } from "../../utils/useContentoApi";
import BillingCoachingAddOn from "../../components/checkout/BillingCoachingAddOn";
import { coachingAddOnMapping, planMapping } from "../../constants";
import CheckoutBasket from "../../components/checkout/CheckoutBasket";
import ButtonGroup from "components/common/ButtonGroup";
import { trackAnalyticsEvent } from "../../state/actions/AnalyticsActions";
import { fetchAccount } from "../../state/actions/AccountActions";

const sortedSteps = ["plan", "coaching", "payment"];

const progressSteps = {
  plan: "Plan",
  coaching: "Coaching",
  payment: "Payment"
};

const cycleButtons = [
  {
    label: "Billed yearly",
    addon: "-20%",
    value: "YEARLY"
  },
  {
    label: "Billed monthly",
    value: "MONTHLY"
  }
];

export default function CheckoutPage({ match }) {
  const account = useSelector(state => state.account.data);
  const dispatch = useDispatch();
  const addToast = useToaster();
  const history = useHistory();

  const [step, setStep] = useState("plan");
  const [billingCycle, setBillingCycle] = useState("YEARLY");
  const [selectedWillowPlan, setSelectedWillowPlan] = useState("SMALL_BIZ");
  const [coachingAddonSelected, setCoachingAddonSelected] = useState(false);
  const [chargebeePlans, setChargebeePlans] = useState(null);
  const [billingEstimate, setBillingEstimate] = useState();
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);

  const currency = account.billingCurrencyCode || "USD";

  const steps = {
    plan: {
      name: "plan",
      path: "/plan",
      location: `/accounts/${account.id}/checkout/plan`,
      title: "Upgrade today and run your Social Media like a boss",
      subTitle: "Join over 250 companies that use Willow."
    },
    coaching: {
      name: "coaching",
      path: "/coaching",
      location: `/accounts/${account.id}/checkout/coaching`,
      title: "Do you want monthly coaching from our experts?",
      subTitle: "85% of our customers follow our Social Media course."
    },
    payment: {
      name: "payment",
      path: "/payment",
      location: `/accounts/${account.id}/checkout/payment`,
      title: "Upgrade today and run your Social Media like a boss",
      subTitle: "Join over 250 companies that use Willow."
    }
  };

  const [fetchPlans, cancelFetchPlans] = useContentoApi(
    `/billing/${account.id}/plans?currency=${currency}`
  );
  const [createBillingEstimate] = useContentoApi(
    `/accounts/${account.id}/billing/estimate`,
    "post"
  );
  const [createBilling] = useContentoApi(
    `/accounts/${account.id}/billing`,
    "post"
  );
  const [paymentSucceeded] = useContentoApi(
    `/accounts/${account.id}/payment-succeeded`
  );

  const cbInstance = getChargebeeInstance();

  const handleBack = () => {
    const idx = sortedSteps.findIndex(val => val === step === 'payment' ? 'coaching' : 'payment');
    const prevStep = sortedSteps[idx - 1];

    setStep(prevStep);
    history.push(match.url + "/" + prevStep);
  };

  const handleNext = nextStep => {
    setStep(nextStep);
    history.push(match.url + "/" + nextStep);
  };

  const internalPlanId =
    planMapping[currency][billingCycle][selectedWillowPlan];
  const internalAddonId = coachingAddonSelected
    ? coachingAddOnMapping[currency][billingCycle]
    : null;

  const getAnalyticsEventData = () => {
    return {
      order_id: account.id,
      value: billingEstimate.invoice_estimate.total / 100,
      revenue: billingEstimate.invoice_estimate.total / 100,
      currency: billingEstimate.invoice_estimate.currency_code,
      products: billingEstimate.invoice_estimate.line_items.map(lineItem => ({
        product_id: lineItem.entity_id,
        sku: lineItem.entity_id,
        name: lineItem.description,
        price: lineItem.amount / 100,
        quantity: 1
      }))
    };
  };

  const handleCheckout = () => {
    cbInstance.openCheckout({
      hostedPage: () => {
        return createBilling({
          data: {
            plan: internalPlanId,
            addon: internalAddonId
          }
        }).then(response => ({ ...response.hosted_page }));
      },
      error(error) {
        addToast(
          "Something went wrong with the checkout. Please contact support.",
          "error"
        );
        console.error(error);
      },
      loaded() {
        dispatch(
          trackAnalyticsEvent("Checkout Started", getAnalyticsEventData())
        );
      },
      step(step) {
        dispatch(
          trackAnalyticsEvent("Checkout Step Viewed", {
            checkout_id: account.id,
            step: step
          })
        );
      },
      success() {
        dispatch(
          trackAnalyticsEvent("Order Completed", getAnalyticsEventData())
        );
        paymentSucceeded()
          .then(() => {
            cbInstance.closeAll();
            dispatch(fetchAccount(account.id));
            history.push(
              `/accounts/${account.id}/dashboard?modal=checkout_success`
            );
          })
          .catch(() => {
            addToast(
              "Something went wrong confirming the payment. Please contact support.",
              "error"
            );
          });
      },
      close: () => {}
    });
  };

  useEffect(() => {
    // Update step on path mismatch (if user tries to go back on history)
    if (
      history.location.pathname !== `/accounts/${account.id}/checkout/${step}`
    ) {
      const targetStep = Object.keys(steps).find(
        s => steps[s].location === history.location.pathname
      );
      setStep(targetStep);
    }
  }, [history.location, step, steps, account.id]);

  useEffect(() => {
    fetchPlans()
      .then((r) => { setChargebeePlans(r); })
      .catch(error => {
        addToast(error.message, "error");
      });
    return cancelFetchPlans;
  }, [fetchPlans, addToast, cancelFetchPlans]);

  useEffect(() => {
    if(step === 'payment') {
    setIsLoadingEstimate(true);
    createBillingEstimate({
      data: { plan: internalPlanId, addon: internalAddonId }
    })
      .then(estimate => {
        setBillingEstimate(estimate);
        setIsLoadingEstimate(false);
      })
      .catch(() => {
        addToast(
          "Could not get an estimate for your subscription. Please contact support.",
          "error"
        );
        setIsLoadingEstimate(false);
      });
   }
  }, [
    account.id,
    addToast,
    createBillingEstimate,
    internalAddonId,
    internalPlanId,
    step
  ]);

  if (!chargebeePlans) {
    return null;
  }

  return (
    <WizardContainer
      activeStep={step}
      steps={progressSteps}
      onBack={step !== "plan" && handleBack}
      nextButtonText={"Next"}
    >
      <OnboardingLayout>
        <OnboardingHeader>
          <img src={willowLogo} alt="Willow" height="40" />
        </OnboardingHeader>
        <OnboardingContainer size="lg" marginTop={"2.5vh"}>
          <FormTitle>
            {steps[step]?.title}
            <small>{steps[step]?.subTitle}</small>
          </FormTitle>
          {step !== "coaching" && (
            <Box my={32}>
              <ButtonGroup
                size="sm"
                isRounded={true}
                current={billingCycle}
                buttons={cycleButtons}
                onClick={setBillingCycle}
              />
            </Box>
          )}
          <Switch>
            <Route
              path={`${match.path}/plan`}
              render={() => (
                <BillingPlans
                  cbPlans={chargebeePlans}
                  currency={currency}
                  billingCycle={billingCycle}
                  onSelect={planId => {
                    setSelectedWillowPlan(planId);
                    handleNext("coaching");
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/coaching`}
              render={() => (
                <BillingCoachingAddOn
                  currency={currency}
                  billingCycle={billingCycle}
                  onSelect={() => {
                    setCoachingAddonSelected(true);
                    handleNext("payment");
                  }}
                  onSkip={() => {
                    setCoachingAddonSelected(false);
                    handleNext("payment");
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/payment`}
              render={() => (
                <CheckoutBasket
                  isLoading={isLoadingEstimate}
                  estimate={billingEstimate}
                  cycle={billingCycle}
                  onCheckout={() => handleCheckout()}
                />
              )}
            />
            <Redirect exact to={match.url + "/plan"} />
          </Switch>
        </OnboardingContainer>
      </OnboardingLayout>
    </WizardContainer>
  );
}
