import _ from "lodash";
import { Flex } from "rebass";
import React, { useState, useEffect, useContext } from "react";
import {
  SecondaryHeader,
  SecondaryHeaderContainer
} from "../../components/navigation/styles";
import Button from "components/common/Button";
import IconButton from "../../components/common/IconButton";
import Search from "../../components/navigation/Search";

import { ButtonContent, TopicStatus, TopicLabel } from "./styles";

import { IconBottonGroup } from "components/schedule/styles";
import { ContentPageContext } from "contextApi/ContentPageContext";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import ExploreSearch from "components/content/widgets/ExploreSearch";
import { follow, unfollow } from "services/news";
import { createKeyword, deleteKeyword } from "services/keyword";
import { IKeywordData } from "services/keyword.types";
import { useSelector } from "react-redux";
interface IContentHeaderProps extends RouteComponentProps {
  searchValue: string;
  searchSuggestions: Array<string>;
  setSearchValue: (value: string) => void;
}

const INTENAL = "internal";

const ContentHeader: React.FC<IContentHeaderProps> = ({
  location,
  searchValue,
  setSearchValue,
  searchSuggestions
}) => {
  const history = useHistory();
  const account = useSelector((state: any) => state.account.data);

  const [paths, setPaths] = useState<Array<any>>([]);
  const [topPath, setTopPath] = useState<number>(-1);
  const isExplorePage = location.pathname.includes("explore");

  const isForYouPage =
    location.pathname.includes("for-you") ||
    !!location.pathname.match(/\W*(content\/topics\/[A-Za-z0-9\-]*)\W*/g) ||
    !!location.pathname.match(/\W*(content\/domains\/[A-Za-z0-9\-]*)\W*/g) ||
    !!location.pathname.match(
      /\W*(content\/influencers\/[A-Za-z0-9\-]*)\W*/g
    ) ||
    !!location.pathname.match(/\W*(content\/rss\/[A-Za-z0-9\-]*)\W*/g) ||
    !!location.pathname.match(/\W*(content\/keywords\/[A-Za-z0-9\-]*)\W*/g);

  const [isSearchVisible, setSearchVisible] = useState(false);
  const [onHover, setOnHover] = useState<boolean>(false);
  const ctx = useContext(ContentPageContext);

  useEffect(() => {
    if (history.location.state === INTENAL) return;

    setPaths(paths => {
      if (topPath + 1 === paths.length) {
        return [...paths, history.location];
      } else {
        return [...paths.slice(0, topPath + 1), history.location];
      }
    });
    setTopPath(topPath => topPath + 1);
  }, [history.location]);

  const goBack = () => {
    if (topPath <= 0) return;

    const newTop = topPath - 1;
    setTopPath(newTop);

    history.push({
      ...paths[newTop],
      state: INTENAL
    });
  };

  const goForward = () => {
    const newTop = topPath + 1;

    if (paths.length === 1 || newTop >= paths.length) return;

    setTopPath(newTop);
    history.push({
      ...paths[newTop],
      state: INTENAL
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleUnfollow = async () => {
    if (!ctx.id) return;

    try {
      if (ctx.type === "keywords") {
        await deleteKeyword(ctx.id);
        history.push(`/accounts/${account.id}/content/for-you`);
      } else {
        await unfollow(ctx.type, ctx.id);
      }
      ctx.setContext({
        ...ctx,
        isFollowing: false
      });
    } catch (_) {}
  };

  const handleFollow = async () => {
    if (!ctx.id) return;

    try {
      if (ctx.type === "keywords") {
        const keyword: IKeywordData = _.pick({ ...ctx.meta }, [
          "name",
          "rules"
        ]);
        await createKeyword(keyword);
      } else {
        await follow(ctx.type, ctx.id);
      }

      ctx.setContext({
        ...ctx,
        isFollowing: true
      });
    } catch (_) {}
  };

  return (
    <SecondaryHeader>
      {!isSearchVisible ? (
        <SecondaryHeaderContainer>
          <Flex alignContent={"center"} justifyContent={"flex-start"}>
            <IconBottonGroup>
              <IconButton
                // @ts-ignore
                icon="icon-arrowleft"
                variant={"secondaryLoud"}
                size={32}
                iconSize={20}
                iconColor={"#646769"}
                onClick={goBack}
                disabled={topPath <= 0}
              />
              <IconButton
                // @ts-ignore
                icon="icon-arrowright"
                variant={"secondaryLoud"}
                size={32}
                iconSize={20}
                iconColor={"#646769"}
                onClick={goForward}
                disabled={paths.length === 1 || topPath >= paths.length - 1}
              />
            </IconBottonGroup>
            {isForYouPage && !!ctx.id && (
              <TopicStatus>
                {ctx.isFollowing ? (
                  // @ts-ignore
                  <Button
                    size="xs"
                    minWidth={94}
                    variant={onHover ? "dangerLite" : "secondary"}
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                    onClick={handleUnfollow}
                  >
                    {onHover ? "Unfollow" : "Following"}
                  </Button>
                ) : (
                  // @ts-ignore
                  <Button size="xs" variant="primary" onClick={handleFollow}>
                    Follow
                  </Button>
                )}

                <TopicLabel>{ctx.title}</TopicLabel>
              </TopicStatus>
            )}
          </Flex>
          {!isExplorePage ? (
            // @ts-ignore
            <Button
              variant="secondary"
              onClick={() => setSearchVisible(true)}
              py={"8px"}
              cursor="text"
            >
              <ButtonContent>
                <span className="icon-search"></span>
                Search an article
              </ButtonContent>
            </Button>
          ) : (
            <ExploreSearch />
          )}
        </SecondaryHeaderContainer>
      ) : (
        <Search
          placeholder={"Search an article"}
          searchValue={searchValue}
          suggestions={searchSuggestions}
          onChange={handleSearchChange}
          onRequestClose={() => {
            setSearchVisible(false);
            setSearchValue("");
          }}
        />
      )}
    </SecondaryHeader>
  );
};

export default withRouter(ContentHeader);
