import styled from "styled-components";

export const ToolkitContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Toolbar = styled.div`
  display: flex;
  width: 36px;
  flex-direction: column;
  gap: 16px;
`;

export const ToolContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 333px;
  height: 100%;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.grey05};
`;

export const ToolContentHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 16px 0;
`;

export const ToolContentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 16px 16px;
  overflow-y: auto;
  scrollbar-gutter: stable;
`;

export const FeatureTag = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;

  > i {
    font-size: 16px;
    color: ${props => props.theme.colors.text02};
  }

  span {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    line-height: 15.6px;
    margin-left: ${props => (props.isLoading ? 8 : 4)}px;
    color: ${props =>
      props.isLoading ? props.theme.colors.text03 : props.theme.colors.text02};
  }
`;
