import React, { useEffect, useState } from "react";
import IdeaEntry from "./IdeaEntry";
import { IdeaGridContainer } from "./styles";
import { useContentoApi } from "../../utils/useContentoApi";
import { useToaster } from "@hellocontento/maillard";
import { useParams } from "react-router";

export default function IdeaGrid({ limit = -1 }) {
  const [postIdeas, setPostIdeas] = useState([]);
  const addToast = useToaster();
  const { postConceptSlug } = useParams();

  const [fetchPostIdeas, cancelFetchPostIdeas] = useContentoApi(
    `post-ideas${postConceptSlug ? "?postConceptSlug=" + postConceptSlug : ""}`
  );

  useEffect(() => {
    fetchPostIdeas()
      .then(res => {
        limit !== -1 ? setPostIdeas(res.slice(0, limit)) : setPostIdeas(res);
      })
      .catch(error => {
        addToast(error.message, "error");
      });
    return cancelFetchPostIdeas;
  }, [limit, cancelFetchPostIdeas, fetchPostIdeas, addToast]);

  return (
    <IdeaGridContainer>
      {postIdeas &&
        postIdeas.map(postIdea => (
          <IdeaEntry key={postIdea.id} idea={postIdea} />
        ))}
    </IdeaGridContainer>
  );
}
