import React from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  SecondaryHeader,
  SecondaryHeaderContainer
} from "../navigation/styles";
import TabMenu from "../navigation/TabMenu";
import Tooltip from "components/common/Tooltip";
import PeriodSelect from "./widgets/PeriodSelect";
import { generatePdf } from "utils/pdfGeneration";
import IconButton from "components/common/IconButton";

const menuOptions = [
  {
    label: "Overview",
    value: "overview"
  },
  {
    label: "Posts",
    value: "posts"
  }
];

function AnalyticsMenu({ onTabClick, onPeriodSelect, period, isLoading }) {
  const addToast = useToaster();

  const pdfDownload = () => {
    try {
      generatePdf("analytics-report", "analytics-report-page");
    } catch (_) {
      addToast(
        "There was an error downloading the report. Please try again later or contact support.",
        "error"
      );
    }
  };

  return (
    <SecondaryHeader>
      <SecondaryHeaderContainer>
        <TabMenu options={menuOptions} onClick={onTabClick} />
        <div className="right-menu">
          <Tooltip title="Download report" placement="bottom">
            <IconButton
              size={"sm"}
              iconSize={20}
              icon="icon-download"
              disabled={isLoading}
              onClick={pdfDownload}
              variant={"secondary"}
            />
          </Tooltip>
          <PeriodSelect onSelect={onPeriodSelect} selected={period} />
        </div>
      </SecondaryHeaderContainer>
    </SecondaryHeader>
  );
}

export default AnalyticsMenu;
