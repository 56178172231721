import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef
} from "react";
import { ContentPageContext } from "contextApi/ContentPageContext";

import { connect } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import { Box } from "rebass";
import BuildingBlocks from "./buildingBlocks";
import RecommendedTopics from "./recommendations/topics";
import Loader from "components/common/loading/Loader";
import RecommendationTopicShelve from "./forYou/RecommendationTopicShelve";
import Shelve from "./shelve";
import { getFeedsByType } from "services/news";
import SuggestedContents from "./forYou/SuggestedContents";
import NewsOnboarding from "./onboarding";
import { OnboardingStepsEnum } from "types/Onboarding.types";

const ContentForYou = ({ account, excludedTld }) => {
  const addToast = useToaster();
  const ctx = useContext(ContentPageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState({ id: null, ratio: 0 });
  const [followedContent, setFollowedContent] = useState(null);

  const rootRef = useRef(null);
  const [singleRefs, setSingleRefs] = useState({});

  const refreshContentFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getFeedsByType("your-feed", { limit: 6 });
      setFollowedContent(response);
      const hasFollowedContent =
        response?.followedTopicsFeed?.length > 0 ||
        response?.sourcesAndDomainsFeed?.length > 0;

      setIsLoading(false);
      if (hasFollowedContent) {
        setSingleRefs(
          [
            ...response.followedTopicsFeed,
            ...response.sourcesAndDomainsFeed
          ].reduce((acc, value) => {
            if (value.contents.length < 1) return acc;
            acc[value.section.id] = {
              ref: React.createRef(),
              id: value.section.id,
              ratio: 0
            };

            return acc;
          }, {})
        );
      }
    } catch (err) {
      setIsLoading(false);
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  }, [addToast]);

  useEffect(() => {
    refreshContentFeed();
  }, [refreshContentFeed]);

  const onObserving = useCallback(entries => {
    entries.forEach(entry => {
      singleRefs[entry.target.id].ratio = entry.intersectionRatio;
    });

    const newActiveSection = Object.values(singleRefs).reduce(
      (acc, value) => (value.ratio > acc.ratio ? value : acc),
      activeSection
    );

    if (newActiveSection.ratio > activeSection.ratio) {
      setActiveSection(newActiveSection);
    }

    const inViewTopic = [
      ...followedContent.followedTopicsFeed,
      ...followedContent.sourcesAndDomainsFeed
    ].find(feed => feed.section.id === newActiveSection.id);

    if (inViewTopic) {
      const { section, sectionType } = inViewTopic;

      ctx.setContext({
        id: section.id,
        type: sectionType,
        meta: sectionType === "keywords" ? section : null,
        title: section.title || section.name,
        isFollowing: true
      });
    }
  });

  useEffect(() => {
    const observer = new IntersectionObserver(onObserving, {
      rootMargin: "20px"
    });

    Object.values(singleRefs).forEach(value => {
      observer.observe(value.ref.current);
    });

    return () => {
      Object.values(singleRefs).forEach(value => {
        if (value.ref.current) observer.unobserve(value.ref.current);
      });
    };
  }, [singleRefs]);

  const hasFollowedContent =
    followedContent &&
    (followedContent.followedTopicsFeed ||
      followedContent.sourcesAndDomainsFeed) &&
    (followedContent.followedTopicsFeed.length > 0 ||
      followedContent.sourcesAndDomainsFeed.length > 0);

  const hasSuggestedContent =
    followedContent &&
    followedContent.suggestedContents &&
    followedContent.suggestedContents.length > 0;

  const hasRecommendedContent =
    followedContent &&
    followedContent.recommendedTopicsFeed &&
    followedContent.recommendedTopicsFeed.length > 0;

  // only show the followed content area if has data
  const followedContentComponent = hasFollowedContent
    ? [
        ...followedContent.followedTopicsFeed,
        ...followedContent.sourcesAndDomainsFeed
      ].map(({ section, contents, sectionType }) => {
        if (contents.length < 1) return null;

        return (
          <div
            key={section.id}
            id={section.id}
            ref={singleRefs[section.id]?.ref}
          >
            <Shelve
              section={section}
              accountId={account.id}
              type={sectionType}
              contents={contents.slice(0, 6)}
              onRefresh={refreshContentFeed}
            />
          </div>
        );
      })
    : null;

  let suggestedContentComponent = hasSuggestedContent ? (
    // we have suggestions, list them...
    <SuggestedContents
      contents={followedContent.suggestedContents}
      refreshContentFeed={refreshContentFeed}
    />
  ) : // we do not have suggestions but we do have followed content
  // so just hide the suggestions and show the followed content
  null;

  let recommendedContentComponent = hasRecommendedContent
    ? followedContent.recommendedTopicsFeed.map(
        ({ section, contents, sectionType }) => {
          return (
            <RecommendationTopicShelve
              key={section.id}
              section={section}
              contents={contents}
              sectionType={sectionType}
              refreshContentFeed={refreshContentFeed}
            />
          );
        }
      ) // we do not have suggestions but we do have followed content
    : // so just hide the suggestions and show the followed content
      null;

  const isOnboardingActive = useMemo(() => {
    return !(
      account.onboardingComplete ||
      account.onboardingProgress.includes(
        OnboardingStepsEnum.PENDING_ONBOARDING
      )
    );
  }, [account]);

  if (isLoading) {
    return <Loader size={64} location="center" />;
  }

  const buildForYouPage = (() => {
    if (!hasFollowedContent) {
      return (
        <>
          <RecommendedTopics />
          {recommendedContentComponent}
        </>
      );
    } else if (hasFollowedContent && !hasSuggestedContent) {
      return (
        <>
          {followedContentComponent}
          <RecommendedTopics />
        </>
      );
    } else if (hasFollowedContent && hasSuggestedContent) {
      return (
        <>
          {suggestedContentComponent}
          {followedContentComponent}
          <RecommendedTopics />
        </>
      );
    }
  })();

  return (
    <Box flex={"1"} ref={rootRef}>
      {isOnboardingActive && (
        <NewsOnboarding hasRecommendedContent={hasSuggestedContent} />
      )}
      {buildForYouPage}
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld
  };
};

export default connect(mapStateToProps, {})(ContentForYou);
