import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "rebass/styled-components";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthLayout, AuthImage, AuthBody, TelenetSeparator, LogoImg } from "../styles";
import { FormContainer, FormLogo } from "../styles";
import willowLogo from "assets/images/willow-text-color@2x.png";
import telenetLogo from "assets/images/telenet-logo.png";
import bgImage from "assets/images/willow-signup-banner.jpg";
import config from "config";
import SignUpForm from "./SignUpForm";
import { base64Decode } from "utils/string";


export default function SignUpPage({ location }: any) {
  const history = useHistory();

  if (!location.isExact) {
    const encodedInviteCode = /signUp\/(.*)/i.exec(location.pathname);

    if (!!encodedInviteCode && !!encodedInviteCode[1]) {
      const decodedInviteCode = base64Decode(encodedInviteCode[1]);

      if (decodedInviteCode.match(/\?code=.*/)) {
        history.replace(`/signUp${decodedInviteCode}`);
      }
    }
  }

  const isTelenet = process.env.REACT_APP_CLIENT === "telenet";

  return (
  <GoogleOAuthProvider clientId={config.google.clientId}>
    <AuthLayout>
      <AuthBody>
        <FormContainer>
          <FormLogo>
            <LogoImg src={willowLogo} alt="Willow" />
            {isTelenet && (
              <>
                <TelenetSeparator>x</TelenetSeparator>
                <LogoImg src={telenetLogo} alt="telenetLogo" />
              </>
            )} 
          </FormLogo>

            <SignUpForm location={location} />
            <Box marginTop="auto" height={80} />
          </FormContainer>
        </AuthBody>
        <AuthImage bgImage={bgImage} position={"right"} />
      </AuthLayout>
    </GoogleOAuthProvider>
  );
}
