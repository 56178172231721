import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "rebass/styled-components";
import { Flex, Box } from "rebass/styled-components";
import React, { useMemo, useState, useEffect, useRef, useContext } from "react";

import { deleteKeyword } from "services/keyword";
import { ContentTopicImage } from "../../styles";
import { Headline1 } from "../../../common/styles";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";

import useOnScreen from "hooks/useOnScreen";
import { ContentPageContext } from "contextApi/ContentPageContext";

import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import TaskConfirmationModal from "components/schedule/form/TaskConfirmationModal";

const Title = styled(Headline1)`
  color: ${props => props.theme.colors.text01};
`;

const ButtonGroup = styled(Flex)`
  flex-direction: row;
  gap: 8px;
`;

const ButtonContent = styled(Flex)`
  flex-direction: row;
  gap: 8px;

  > span {
    font-weight: 600;
    font-size: 18px;
  }
`;

export const KeywordSectionWrapper = styled(Flex)`
  justify-content: center;
  border-radius: 16px;
  background: ${props => props.theme.colors.grey02};
  /* min-height: 242px; */
  padding: 32px 0px;
`;

interface IContentDetailBannerProps {
  pageData: any;
  sourceId: string;
  sourceType: string;
}

const KeywordDetailBanner: React.FC<IContentDetailBannerProps> = ({
  pageData,
  sourceId,
  sourceType
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false
  );
  const toggleConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const account = useSelector((state: any) => state.account.data);

  const ref = useRef();
  const history = useHistory();
  const isVisible = useOnScreen(ref);
  const ctx = useContext(ContentPageContext);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  const isLocalFollowed = useMemo(() => pageData.source.isFollowed, [
    pageData.source.isFollowed
  ]);

  const title = pageData.source.name;

  const rulesText = useMemo(() => {
    const includes = Object.keys(pageData.source.rules).reduce(
      (acc: Array<string>, value: string) => {
        const includeValues = pageData.source.rules[value].include;

        if (includeValues) {
          includeValues.forEach((element: any) => {
            acc.push(...element.values);
          });
        }
        return acc;
      },
      [] as Array<string>
    );

    if (includes.length < 2) return includes.join(" and ");
    else return `${includes.slice(0, 2).join(", ")} and many more`;
  }, [pageData.source.rules]);

  useEffect(() => {
    if (!isVisible) {
      // @ts-ignore
      ctx.setContext({
        id: pageData.source.id,
        type: "keywords",
        title: title,
        meta: pageData.source,
        isFollowing: isLocalFollowed
      });
    } else {
      // @ts-ignore
      ctx.setContext({
        id: null
      });
    }
  }, [isVisible, isLocalFollowed]);

  const handleRemoveKeyword = async () => {
    try {
      setIsProcessing(true);
      await deleteKeyword(pageData.source.id);
      setIsProcessing(false);
      toggleConfirmationModal();
    } catch (_) {
      setIsProcessing(false);
    }
  };

  const deletionModalProps = {
    title: "Unfollow",
    description: "You're deleting a keyword feed.",
    type: "series",
    showOptions: false,
    toggle: toggleConfirmationModal,
    buttonProps: {
      variant: "danger",
      label: "Delete",
      action: async () => {
        await handleRemoveKeyword();
        navigateTo("/for-you");
      }
    }
  };

  return (
    <>
      <TaskConfirmationModal
        isOpen={showConfirmationModal}
        {...deletionModalProps}
      />
      <KeywordSectionWrapper mb={40}>
        <Flex flexDirection="column" alignItems="center" margin={"auto"}>
          <ContentTopicImage
            ref={ref}
            src={KeywordIcon}
            // @ts-ignore
            borderRadius="50%"
          />
          <Title sx={{ textTransform: "capitalize", textAlign: "center" }}>
            {title}
          </Title>
          {pageData?.source ? (
            <Text
              fontSize="14px"
              marginTop={"4px"}
              color="#646769"
              fontWeight={400}
            >
              The latest articles that include the words: {rulesText}
            </Text>
          ) : null}

          <Box mt={32}>
            <ButtonGroup>
              {/* @ts-ignore */}
              <Button
                variant="secondary"
                onClick={() =>
                  navigateTo(`keywords/${pageData.source.id}/edit`)
                }
              >
                <ButtonContent>
                  <span className="icon-edit" />
                  Edit
                </ButtonContent>
              </Button>
              <IconButton
                // @ts-ignore
                size={40}
                iconSize={24}
                icon={"icon-delete"}
                variant="secondaryLoud"
                onClick={toggleConfirmationModal}
                disabled={isProcessing}
              />
            </ButtonGroup>
          </Box>
        </Flex>
      </KeywordSectionWrapper>
    </>
  );
};

export default KeywordDetailBanner;
