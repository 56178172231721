import React from "react";
import { PostActionsWrapper } from "./styles";

import IconButton from "components/common/IconButton";

const CalendarEntryActions = ({ visible, entry, editPost, removePost }) => {
  if (!visible) return null;

  const handleDelete = e => {
    e.stopPropagation();
    removePost();
  };

  const handleEdit = e => {
    e.stopPropagation();
    editPost(entry);
  };

  return (
    <PostActionsWrapper>
      {entry.task && (
        <IconButton
          variant={"secondaryLoud"}
          size={28}
          iconSize={14}
          onClick={handleEdit}
          icon={"icon-edit"}
        />
      )}
      <IconButton
        variant={"secondaryLoud"}
        size={28}
        iconSize={14}
        onClick={handleDelete}
        icon={"icon-delete"}
      />
    </PostActionsWrapper>
  );
};

export default CalendarEntryActions;
