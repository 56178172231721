import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass/styled-components";
import Button from "../../components/common/Button";
import FallbackImage from "../../components/common/FallbackImage";
import { FormBox, FormTitle } from "../../components/common/form/styles";
import { ForgotBox, ForgotLink } from "../../components/auth/styles";
import {
  OnboardingLayout,
  OnboardingHeader,
  OnboardingContainer
} from "../onboarding/styles";
import { Paragraph, AvatarWrapper, UserAvatar, AccountName } from "./styles";

import willowLogo from "../../assets/images/willow-text-color@2x.png";
import emptyProfileIcon from "../../assets/images/profile-icon.png";

export default function OnboardingPage() {
  const history = useHistory();
  const account = useSelector(state => state.account.data);

  const openHubSpotChat = e => {
    e.preventDefault();

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  };

  const handleCloseAccount = () => {
    // FIXME: trigger something else after logout as a matter of feedback
    history.push("/logout");
    history.push("/login?feedback=cancel");
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <OnboardingLayout>
      <OnboardingHeader>
        <img src={willowLogo} alt="Willow" height="40" />
      </OnboardingHeader>
      <Flex
        flexDirection="column"
        alignItems="center"
        marginTop={24}
        marginBottom={-24}
      >
        <AvatarWrapper my={12}>
          <UserAvatar>
            <FallbackImage
              alt={account.companyName}
              width={[1]}
              height={40}
              src={account.logo}
              fallbackSrc={emptyProfileIcon}
              sx={{ borderRadius: "50%", border: "solid 1px #dadde1" }}
            />
          </UserAvatar>
        </AvatarWrapper>
        <AccountName>{account.companyName}</AccountName>
      </Flex>
      <OnboardingContainer>
        <FormTitle>Are you sure you want to cancel your account?</FormTitle>
        <FormBox marginTop={32} maxWidth="480px">
          <Flex flexDirection="column" alignItems="stretch" marginBottom={16}>
            <Paragraph>
              We’ll be sorry to see you go, but thanks for giving Willow a try!
            </Paragraph>
            <Paragraph>
              <strong>When you cancel…</strong>
              <ul>
                <li>You won’t be billed again</li>
                <li>Your account will be closed immediately</li>
                <li>
                  Your data will be permanently deleted from our servers within
                  30 days and from all backups within 60 days.
                </li>
              </ul>
            </Paragraph>
            <Button
              variant="danger"
              size="lg"
              marginBottom={8}
              onClick={handleCloseAccount}
            >
              Cancel my account
            </Button>
            <Button variant="secondary" size="lg" onClick={handleGoBack}>
              Never mind
            </Button>
          </Flex>
        </FormBox>
        <ForgotBox marginBottom={32}>
          Need help? Just contact our{" "}
          <ForgotLink onClick={openHubSpotChat}>
            friendly support team
          </ForgotLink>
          .
        </ForgotBox>
      </OnboardingContainer>
    </OnboardingLayout>
  );
}
