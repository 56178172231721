import _ from "lodash";
import React from "react";

import {
  WidgetTitle,
  ContentMixBlock,
  WidgetStatsLabel,
  WidgetHeaderContent,
  AnalyticsWidgetHeader,
  AnalyticsContentWrapper,
  AnalyticsWidgetContainer
} from "../styles";
import { colors } from "theme";
import ContentMixItem from "./ContentMixItem";
import Loader from "components/common/loading/Loader";
import { useDashboardState } from "contextApi/dashboardContext";

const tipUrl =
  "https://www.willow.co/course-content/how-to-provide-a-content-mix";

const ContentMixAnalytics: React.FC<{}> = () => {
  const {
    socialHealth: { data: stats, isFetching }
  } = useDashboardState();
  const keys = !!stats?.contentMix ? Object.keys(stats.contentMix) : [];
  const values = !!stats?.contentMix ? Object.values(stats.contentMix) : [];
  const statsLabel = (() => {
    if (values.length < 1) {
      return "No data";
    } else if (values.length === 1) {
      return "Bad";
    } else if (values.length === 2) {
      return "Average";
    } else if (values.length >= 3) {
      return "Good";
    }
  })();

  const isGood = values.length >= 3;

  const handleTipClick = () => {
    window.open(tipUrl, "_blank");
  };

  return (
    <AnalyticsWidgetContainer colspan={1} rowspan={1} bgColor={colors.white}>
      <AnalyticsWidgetHeader>
        <WidgetTitle>Content mix</WidgetTitle>
        {!isFetching && (
          <WidgetHeaderContent>
            <WidgetStatsLabel>{statsLabel}</WidgetStatsLabel>
            {!!stats?.contentMix && !isGood && (
              <WidgetStatsLabel variant="highlight" onClick={handleTipClick}>
                <i className="icon-sparkle" />
                Tip
              </WidgetStatsLabel>
            )}
          </WidgetHeaderContent>
        )}
      </AnalyticsWidgetHeader>
      <AnalyticsContentWrapper className="content-mix">
        {isFetching ? (
          <Loader size={36} />
        ) : keys.length > 1 ? (
          keys
            .sort((a, b) => {
              if (a.toLowerCase() === "other") {
                return 1;
              }
              return a.localeCompare(b);
            })
            .map(key => (
              <ContentMixItem
                key={key}
                contentType={key}
                metaData={stats.contentMix}
                ratio={(stats.contentMix[key] / _.sum(values)) as number}
              />
            ))
        ) : (
          <ContentMixBlock contentType="none" ratio={1} />
        )}
      </AnalyticsContentWrapper>
    </AnalyticsWidgetContainer>
  );
};

export default ContentMixAnalytics;
