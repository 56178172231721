import React, { useState, useEffect, useRef } from "react";
import Loader from "components/common/loading/Loader";
import ReactLoading from "react-loading";

import { LoadingContent } from "./styles";

import { ContentWrapper } from "./styles";
import ContentItem from "./ContentItem";

function getSpinner() {
  return (
    <LoadingContent>
      <ReactLoading color="#bbb" type="cylon" />
    </LoadingContent>
  );
}

const ScrollableContentList = ({
  pageData,
  onSaveChange,
  onNextPage,
  emptyState,
  sourceId
}) => {
  const [allItems, setAllItems] = useState(pageData?.items || []);
  const [lastElement, setLastElement] = useState(null);

  const observer = useRef(null);

  useEffect(() => {
    if (pageData?.items) {
      setAllItems(pageData.items);
    }
  }, [pageData]);

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && !pageData.isFinished) {
        onNextPage(pageData.page + 1);
      }
    });

    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, pageData, onNextPage]);

  function handleDimissed(contentId, domain = null) {
    if (domain) {
      setAllItems(allItems.filter(item => item.domain !== domain));
    } else {
      setAllItems(allItems.filter(item => item.id !== contentId));
    }
  }

  return (
    <div>
      {!pageData ? (
        <Loader location="center" size={64} />
      ) : allItems.length < 1 ? (
        emptyState
      ) : (
        <>
          <ContentWrapper>
            {allItems.map((item, index) => {
              if (allItems.length - 1 === index) {
                return (
                  <div ref={setLastElement} key={`${item.id}-wrapper`}>
                    <ContentItem
                      key={index}
                      content={item}
                      onDismiss={handleDimissed}
                      onSaveChange={onSaveChange}
                      sourceId={sourceId}
                    />
                  </div>
                );
              } else {
                return (
                  <ContentItem
                    key={index}
                    content={item}
                    onDismiss={handleDimissed}
                    onSaveChange={onSaveChange}
                    sourceId={sourceId}
                  />
                );
              }
            })}
          </ContentWrapper>
          {!pageData.isFinished && getSpinner()}
        </>
      )}
    </div>
  );
};

export default ScrollableContentList;
