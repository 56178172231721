import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { useToaster } from "@hellocontento/maillard";
import React, { useRef, useEffect, useState } from "react";

import {
  EntryTop,
  EntryTime,
  PostEntry,
  EntryBottom,
  EntryCaption,
  EntryChannels
} from "./styles";
import Icon from "components/common/Icon";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import { SERVICES } from "constants/services";
import { openPostOnChannel } from "utils/channels";
import EntryStatus from "../../common/contentTypes/EntryStatus";
import TaskConfirmationModal from "../form/TaskConfirmationModal";
import { Avatar, ChannelAvatar, Service } from "../../common/styles";
import contentTypes from "../../common/contentTypes/data/content-types";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "../common/postContextMenu/PostContextMenu";
import { formatForTimeline } from "utils/date";

function MonthPost({
  post,
  reload,
  isDraft = false,
  isPostGroup = false,
  customChannels = null,
  onClick,
  onPostDeleted,
  isPhantom,
  dashboardEntry = false
}) {
  const addToast = useToaster();
  const account = useSelector(state => state.account.data);
  const ref = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const draggingItemId = useSelector(state => state.dragDrop.draggingItemId);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(
    false
  );
  const channels =
    isDraft || isPostGroup ? post.channels : customChannels ?? [post.channel];
  const isDragging = draggingItemId === post.id;

  const displayTime = format(
    parseISO(post.scheduledAt ?? post.postedAt),
    "H:mm"
  );

  const displayDate = formatForTimeline(
    new Date(post.postedAt || post.scheduledAt),
    true
  );

  const postIcon = {
    photo: "icon-image-filled",
    video: "icon-video-filled",
    article: "icon-clicks"
  };

  const caption =
    isDraft || isPostGroup
      ? Object.values(post.caption).sort((a, b) => {
          return b.length - a.length;
        })[0]
      : post.caption;

  async function removePost() {
    try {
      const params = post.isPostGroup
        ? {
            method: "delete",
            url: `/accounts/${account.id}/post-groups/${post.id}`
          }
        : post.isDraft
        ? {
            method: "delete",
            url: `/accounts/${account.id}/draft-posts/${post.id}`
          }
        : { method: "delete", url: `/posts/${post.id}` };

      await callApi(params);
      onPostDeleted(post);
    } catch (err) {
      addToast("Couldn't delete the post.", "error");
    }
  }

  const deletionModalProps = {
    title: `Delete ${post.isDraft ? "Draft" : "Post"}`,
    description: `Are you sure you want to delete this ${
      post.isDraft ? "draft" : "post"
    }?`,
    showOptions: false,
    toggle: () => setConfirmationDialogVisible(false),
    buttonProps: {
      variant: "danger",
      label: "Delete",
      action: () => {
        removePost();
      }
    }
  };

  const publishedPostDeletionModalProps = {
    title: `Delete ${capitalize(channels[0].service)} post`,
    description:
      channels[0].service === SERVICES.INSTAGRAM
        ? `In order to delete this post you need to go to Instagram and delete it there.`
        : `Are you sure you want to delete this post?`,
    showOptions: false,
    toggle: () => setConfirmationDialogVisible(false),
    buttonProps: {
      variant:
        channels[0].service === SERVICES.INSTAGRAM ? `primary` : `danger`,
      label:
        channels[0].service === SERVICES.INSTAGRAM
          ? `Go to Instagram`
          : `Delete`,
      action: () => {
        if (channels[0].service === SERVICES.INSTAGRAM) {
          openPostOnChannel(post);
        } else {
          removePost();
        }
      }
    }
  };

  const deleteEntry = () => setConfirmationDialogVisible(true);

  const editEntry = () => onClick(post);

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
    };
  }, [contextMenuPos]);

  return (
    <>
      <TaskConfirmationModal
        isOpen={confirmationDialogVisible}
        {...(post.status === "SENT"
          ? publishedPostDeletionModalProps
          : deletionModalProps)}
      />
      <PostEntry
        ref={ref}
        onClick={editEntry}
        isPhantom={isPhantom}
        borderColor={contentTypes[post.contentTypeId]?.color}
        isGroup={!post.isDraft && (customChannels || post.isPostGroup)}
        isDragging={isDragging}
        isContextMenuOpen={contextMenuPos.length > 0}
        dashboardEntry={dashboardEntry}
      >
        <EntryTop>
          <EntryTime>
            {dashboardEntry ? (
              <>
                {isDraft ? (
                  <span className="draft">{`${displayDate} | Draft`}</span>
                ) : (
                  displayDate
                )}
              </>
            ) : (
              <>
                <EntryStatus
                  status={
                    post.isStuck
                      ? "STUCK"
                      : post.isDraft
                      ? "DRAFT"
                      : post.status
                  }
                />
                {isDraft ? (
                  <span className="draft">{`${displayTime} Draft`}</span>
                ) : (
                  displayTime
                )}
              </>
            )}
          </EntryTime>
          <EntryChannels adjustWidth={post.isDraft || post.isPostGroup}>
            {channels.map((channel, idx) => (
              <ChannelAvatar ml={2} key={`${channel.id}-${idx}`} enabled={true}>
                <Avatar
                  src={channel.avatar}
                  width={20}
                  height={20}
                  noShadow={true}
                  isRounded={true}
                />
                <Service
                  key={channel.id}
                  title={channel.username}
                  type={channel.service}
                  size={10}
                />
              </ChannelAvatar>
            ))}
          </EntryChannels>
        </EntryTop>
        <EntryBottom ml={2}>
          {post.attachment ? (
            <Icon
              icon={`${postIcon[post.attachment.type]}`}
              variant="light"
              size="md"
            />
          ) : (
            <Icon icon={"icon-text-filled"} variant="light" size="md" />
          )}
          <EntryCaption dashboardEntry={dashboardEntry}>{caption}</EntryCaption>
        </EntryBottom>
        <RightClickPostContextMenu
          reload={reload}
          entry={{ post }}
          closeMenu={closeMenu}
          editEntry={editEntry}
          deleteEntry={deleteEntry}
          contextMenuPos={contextMenuPos}
        />
        <PostContextMenuWithButton
          reload={reload}
          entry={{ post }}
          editEntry={editEntry}
          contextWithPortal={true}
          deleteEntry={deleteEntry}
        />
      </PostEntry>
    </>
  );
}

export default MonthPost;
