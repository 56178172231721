import { connect } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ContentTypeBadge from "../../common/contentTypes/ContentTypeBadge";
import contentTypes from "../../common/contentTypes/data/content-types";

import { useContentoApi } from "utils/useContentoApi";
import {
  CalendarEntryFooter,
  CalendarSlot as Slot,
  CalendarSlotInfoWrapper,
  CalendarSlotTitle,
  CalendarSlotDescription
} from "./styles";

import TASK_TYPES from "../constants/taskType";

import CalendarEntryActions from "./CalendarEntryActions";
import { useToaster } from "@hellocontento/maillard";
import TaskConfirmationModal from "../form/TaskConfirmationModal";
import { TaskTitleWrapper, PostCreateButton, ToolTip } from "../common/styles";
import Popper from "components/common/Popper";
import TaskOptions from "../common/TaskOptions";

import CalendarEntryHeader from "./CalendarEntryHeader";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "../common/postContextMenu/PostContextMenu";

const CalendarTask = ({
  accountId,
  entry,
  addEntry,
  hover,
  reload,
  addDraftEntry,
  draggingItemId
}) => {
  const history = useHistory();
  const addToast = useToaster();

  const ref = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const [removeTask] = useContentoApi(`accounts/${accountId}/tasks`, "delete");
  const [removeTaskGroup] = useContentoApi(
    `accounts/${accountId}/task-groups`,
    "delete"
  );

  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const handleEditTask = async ({ task, type }) => {
    if (type === TASK_TYPES.SERIES) {
      history.push({
        pathname: `/accounts/${accountId}/schedule/month`,
        search: `?taskGroupId=${task.taskGroupId}`
      });
    } else {
      history.push({
        pathname: `/accounts/${accountId}/schedule/month`,
        search: `?taskId=${task.id}`
      });
    }
  };

  const handleDelete = async mode => {
    let removePromise = null;

    if (mode === TASK_TYPES.SERIES) {
      removePromise = removeTaskGroup({ url: entry.task.taskGroupId });
    } else {
      removePromise = removeTask({ url: entry.task.id || entry.task.taskId });
    }

    removePromise
      .then(() => {
        addToast("Task successfully deleted", "success");
        reload();
      })
      .catch(err => {
        addToast(
          err.message || "There was an error deleting your task",
          "error"
        );
      });
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const toggleConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const deletionModalProps = entry.task.taskGroupId
    ? {
        title: "Deleting a series of tasks",
        description:
          "You're deleting a task that is part of a series. Please choose the tasks you want to delete.",
        type: TASK_TYPES.SERIES,
        defaultChecked: TASK_TYPES.INSTANCE,
        showOptions: true,
        toggle: toggleConfirmationModal,
        buttonProps: {
          variant: "danger",
          label: "Delete",
          action: handleDelete
        }
      }
    : {
        title: "Delete task",
        description: "Are you sure you want to delete this task?",
        type: TASK_TYPES.INSTANCE,
        showOptions: false,
        toggle: toggleConfirmationModal,
        buttonProps: {
          variant: "danger",
          label: "Delete",
          action: handleDelete
        }
      };

  const isDragging = draggingItemId === entry.task.id;

  const addPostFromTask = () => addEntry(entry);

  const addTaskDraftEntry = () => addDraftEntry(entry);

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
    };
  }, [contextMenuPos]);

  return (
    <>
      <TaskConfirmationModal
        isOpen={showConfirmationModal}
        {...deletionModalProps}
      />
      <Slot
        contentType={entry.task.contentTypeId}
        onClick={toggleShowOptions}
        ref={ref}
        border={showOptions}
        isPhantom={entry.isPhantom}
        isDragging={isDragging}
        isPast={entry.isPast}
        isContextMenuOpen={contextMenuPos.length > 0}
      >
        <Popper
          placement={"right"}
          offset={[50, -50]}
          referenceElement={ref.current}
          visible={showOptions}
          onClose={() => setShowOptions(false)}
          exceptions={[ref.current]}
        >
          <TaskOptions
            close={() => setShowOptions(false)}
            onEditTask={handleEditTask}
            task={entry.task}
            handleAddPost={addPostFromTask}
            handleAddDraftEntry={addTaskDraftEntry}
            handleRemoveTask={toggleConfirmationModal}
            isPastTask={entry.isPast}
          />
        </Popper>
        <CalendarSlotInfoWrapper>
          <CalendarEntryHeader
            status={"TASK_SCHEDULE"}
            time={entry.time}
            channels={entry.channels}
          />
          <TaskTitleWrapper>
            <PostCreateButton
              data-tip
              data-for={`${entry.id}_POST`}
              className="icon-task-check action__create-post"
              onClick={e => {
                e.stopPropagation();
                addEntry(entry);
              }}
            >
              <span className="path1"></span>
              <span className="path2"></span>
            </PostCreateButton>
            <ToolTip
              id={`${entry.id}_POST`}
              place="left"
              effect="solid"
              className="tooltip"
              variant="info"
            >
              Click here to create and write your post
            </ToolTip>
            <CalendarSlotTitle>{entry.task.title}</CalendarSlotTitle>
          </TaskTitleWrapper>
          {entry.task.description && (
            <CalendarSlotDescription>
              {entry.task.description.length > 100
                ? entry.task.description.substring(0, 100) + "..."
                : entry.task.description}
            </CalendarSlotDescription>
          )}
        </CalendarSlotInfoWrapper>
        <RightClickPostContextMenu
          entry={entry}
          closeMenu={closeMenu}
          editTask={handleEditTask}
          contextMenuPos={contextMenuPos}
          addPostFromTask={addPostFromTask}
          addTaskDraftEntry={addTaskDraftEntry}
          deleteEntry={toggleConfirmationModal}
        />
        <PostContextMenuWithButton
          entry={entry}
          contextWithPortal={true}
          editTask={handleEditTask}
          addPostFromTask={addPostFromTask}
          addTaskDraftEntry={addTaskDraftEntry}
          deleteEntry={toggleConfirmationModal}
        />
        <CalendarEntryFooter>
          {entry.task.contentTypeId !== "other" && (
            <ContentTypeBadge
              contentTypeId={entry.task.contentTypeId}
              title={contentTypes[entry.task.contentTypeId].title}
              variant="dark"
              size="sm"
            />
          )}
          <CalendarEntryActions
            visible={hover}
            entry={entry}
            editPost={() =>
              handleEditTask({ task: entry.task, type: TASK_TYPES.INSTANCE })
            }
            removePost={toggleConfirmationModal}
          />
        </CalendarEntryFooter>
      </Slot>
    </>
  );
};

const mapStateToProps = state => {
  return {
    draggingItemId: state.dragDrop.draggingItemId
  };
};

export default connect(mapStateToProps, {})(CalendarTask);
