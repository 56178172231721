import { useSelector } from "react-redux";
import { upperFirst } from "lodash/string";
import { Box } from "rebass/styled-components";
import React, { useState, useEffect } from "react";

import Caption from "./caption";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { SystemMessage } from "../../../styles";
import { ChannelIcon, CustomizedCaptionTabs, PillButton } from "./styles";

const { useRef } = require("react");

const Captions = () => {
  const account = useSelector(state => state.account.data);
  const {
    tags,
    postData,
    inProMode,
    inSplitMode,
    editorStateObj,
    visibleCaption,
    validationErrors,
    chosenSuggestedCaption
  } = useComposerState();
  const {
    scrapeUrl,
    setEditorStateObj,
    getEnabledServices,
    onCaptionChange,
    splitEditor,
    setVisibleCaption,
    setValidationErrors
  } = useComposerActions();
  const [wasCaptionUsed, setWasCaptionUsed] = useState(false);
  const prevEnabledServicesRef = useRef();
  const enabledServices = getEnabledServices();

  useEffect(() => {
    prevEnabledServicesRef.current = enabledServices;
  });
  const prevEnabledServices = prevEnabledServicesRef.current;

  useEffect(() => {
    if (
      prevEnabledServices &&
      prevEnabledServices.length !== enabledServices.length &&
      visibleCaption !== "all"
    ) {
      setVisibleCaption(enabledServices[0]);
    }
  }, [prevEnabledServices, enabledServices, visibleCaption, setVisibleCaption]);

  const enabledCaptions = visibleCaption === "all" ? ["all"] : enabledServices;

  const setDefaultEditorState = newState => {
    setEditorStateObj({
      ...editorStateObj,
      ...newState
    });
  };

  const onCaptionUpdate = (text, service, validationErrors) => {
    setValidationErrors(validationErrors);
    onCaptionChange(text, service);
  };

  return (
    <div>
      {visibleCaption !== "all" && (
        <CustomizedCaptionTabs>
          {enabledServices.map(service => {
            return (
              <PillButton
                key={service}
                isActive={visibleCaption === service}
                onClick={() => setVisibleCaption(service)}
              >
                <div className="button-content">
                  <ChannelIcon channel={service} />
                  {visibleCaption === service && (
                    <span className="service-name">{upperFirst(service)}</span>
                  )}
                </div>
              </PillButton>
            );
          })}
        </CustomizedCaptionTabs>
      )}

      {enabledCaptions.map(service => {
        return (
          <Caption
            post={postData}
            key={service}
            service={service}
            inProMode={inProMode}
            captions={[]} // ? caption generation plugin input
            wasSplit={inSplitMode}
            accountId={account.id}
            onUrlAdded={scrapeUrl}
            splitEditor={splitEditor}
            availableTags={tags}
            wasCaptionUsed={wasCaptionUsed} // ? was generated caption used
            enabledServices={enabledServices}
            onCaptionChange={onCaptionUpdate}
            visible={visibleCaption === service}
            setWasCaptionUsed={setWasCaptionUsed} // ? set if generation caption was used
            defaultEditorState={editorStateObj[service]}
            setDefaultEditorState={setDefaultEditorState}
            chosenSuggestedCaption={chosenSuggestedCaption[service]}
            willowAiEnabled={
              !account.isTelenetCustomer &&
              account.features.includes("willow_ai")
            } // ? willow ai plugin input
          />
        );
      })}

      {!!validationErrors.charLimit && (
        <Box my={12}>
          <SystemMessage
            type="error"
            service={validationErrors.charLimit.service}
          >
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.charLimit.characterCount -
                  validationErrors.charLimit.characterLimit}
              </em>{" "}
              characters too much for{" "}
              {upperFirst(validationErrors.charLimit.service)}
            </span>
          </SystemMessage>
        </Box>
      )}

      {!!validationErrors.tagLimit && (
        <Box my={12}>
          <SystemMessage type="error">
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.tagLimit.tagCount -
                  validationErrors.tagLimit.tagLimit}
              </em>{" "}
              tags too much
            </span>
          </SystemMessage>
        </Box>
      )}
    </div>
  );
};

export default Captions;
