export const OPEN_COMPOSER = "OPEN_COMPOSER";
export const CLOSE_COMPOSER = "CLOSE_COMPOSER";
export const ENABLE_PRO_MODE = "ENABLE_PRO_MODE";
export const DISABLE_PRO_MODE = "DISABLE_PRO_MODE";
export const SET_IN_DRAFT_MODE = "SET_IN_DRAFT_MODE";
export const SET_POST_DATA = "SET_POST_DATA";
export const RESET_POST_DATA = "RESET_POST_DATA";
export const ENABLE_SPLIT_MODE = "ENABLE_SPLIT_MODE";
export const DISABLE_SPLIT_MODE = "DISABLE_SPLIT_MODE";
export const SET_TAGS = "SET_TAGS";
export const SET_EDITOR_STATE_OBJ = "SET_EDITOR_STATE_OBJ";
export const SET_VISIBLE_CAPTION = "SET_VISIBLE_CAPTION";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
export const SET_CHOSEN_SUGGESTED_CAPTION = "SET_CHOSEN_SUGGESTED_CAPTION";
export const SET_POST_CONCEPTS = "SET_POST_CONCEPTS";
export const SET_POST_IDEA_CAPTIONS = "SET_POST_IDEA_CAPTIONS";
export const SET_ATTACHMENT_TYPE = "SET_ATTACHMENT_TYPE";
export const SET_IS_UPLOADING_ATTACHMENTS = "SET_IS_UPLOADING_ATTACHMENTS";
export const ADD_ATTACHMENTS = "ADD_ATTACHMENTS";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";
export const REMOVE_ARTICLE_ATTACHMENT = "REMOVE_ARTICLE_ATTACHMENT";
export const REORDER_ATTACHMENT = "REORDER_ATTACHMENT";
export const SET_ATTACHMENT_VALIDATION_ERROR =
  "SET_ATTACHMENT_VALIDATION_ERROR";
export const SET_ATTACHMENTS = "SET_ATTACHMENTS";
export const SET_IS_SUBMITTING = "SET_IS_SUBMITTING";
export const SET_IS_DELETING = "SET_IS_DELETING";
export const SET_WAS_SCHEDULE_CHANGED = "SET_WAS_SCHEDULE_CHANGED";
export const SET_PICKED_DATE = "SET_PICKED_DATE";
export const SET_MOOD_FILTER = "SET_MOOD_FILTER";
