import {
  RESET_STATE,
  UPDATE_STATS,
  SET_FILTER_RANGE,
  UPDATE_ACTIVITIES,
  UPDATE_SOCIAL_HEALTH,
  UPDATE_STATS_FETCHING_STATE,
  UPDATE_ACTIVITIES_FETCHING_STATE,
  UPDATE_SOCIAL_HEALTH_FETCHING_STATE
} from "./actions";
import { IStats, IActivities, IFilterRange, ISocialHealth } from "./reducer";

export interface IResetState {
  type: typeof RESET_STATE;
}

export const resetState = (): IResetState => ({
  type: RESET_STATE
});

export interface ISetFilterRange {
  type: typeof SET_FILTER_RANGE;
  payload: IFilterRange;
}

export const setFilterRange = (payload: IFilterRange): ISetFilterRange => ({
  type: SET_FILTER_RANGE,
  payload
});

export interface IUpdateActivities {
  type: typeof UPDATE_ACTIVITIES;
  payload: IActivities;
}

export const updateActivities = (payload: IActivities): IUpdateActivities => ({
  type: UPDATE_ACTIVITIES,
  payload
});

export interface IUpdateActivitiesFetchingState {
  type: typeof UPDATE_ACTIVITIES_FETCHING_STATE;
  payload: boolean;
}

export const updateActivitiesFetchingState = (
  payload: boolean
): IUpdateActivitiesFetchingState => ({
  type: UPDATE_ACTIVITIES_FETCHING_STATE,
  payload
});

export interface IUpdateSocialHealth {
  type: typeof UPDATE_SOCIAL_HEALTH;
  payload: ISocialHealth;
}

export const updateSocialHealth = (
  payload: ISocialHealth
): IUpdateSocialHealth => ({
  type: UPDATE_SOCIAL_HEALTH,
  payload
});

export interface IUpdateSocialHealthFetchingState {
  type: typeof UPDATE_SOCIAL_HEALTH_FETCHING_STATE;
  payload: boolean;
}

export const updateSocialHealthFetchingState = (
  payload: boolean
): IUpdateSocialHealthFetchingState => ({
  type: UPDATE_SOCIAL_HEALTH_FETCHING_STATE,
  payload
});

export interface IUpdateStats {
  type: typeof UPDATE_STATS;
  payload: IStats;
}

export const updateStats = (payload: IStats): IUpdateStats => ({
  type: UPDATE_STATS,
  payload
});

export interface IUpdateStatsFetchingState {
  type: typeof UPDATE_STATS_FETCHING_STATE;
  payload: boolean;
}

export const updateStatsFetchingState = (
  payload: boolean
): IUpdateStatsFetchingState => ({
  type: UPDATE_STATS_FETCHING_STATE,
  payload
});

export type IActions =
  | IResetState
  | ISetFilterRange
  | IUpdateActivities
  | IUpdateActivitiesFetchingState
  | IUpdateSocialHealth
  | IUpdateSocialHealthFetchingState
  | IUpdateStats
  | IUpdateStatsFetchingState;
