import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import useWindowSize from "../../utils/useWindowSize";
import IconButton from "../common/IconButton";
import {
  Scroller,
  ScrollerButton
} from "components/composer/attachment/styles";

const CarouselContainer = styled.div`
  max-width: 100%;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin-left: -24px;
    max-width: none;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100vw;
  }
`;

const CarouselList = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.itemCount}, 20%);
  grid-template-rows: repeat(
    1,
    ${props =>
      props.itemHeight === "auto" ? props.itemHeight : `${props.itemHeight}px`}
  );
  grid-gap: 16px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  ${props =>
    props.listPadding !== null &&
    css`
      &:first-child {
        margin-left: ${props.listPadding}px;
      }
      &:last-child {
        margin-right: ${props.listPadding}px;
      }
    `}

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    grid-gap: 8px;
    grid-template-columns: repeat(${props => props.itemCount}, 40%);
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    grid-template-columns: repeat(${props => props.itemCount}, 20%);
  }
`;

function Carousel({
  items,
  listPadding = null,
  defaultItemWidth = 240,
  defaultItemHeight = "auto",
  children
}) {
  const windowSize = useWindowSize();
  const itemCount = items.length;
  const carouselContainer = useRef(null);
  const carouselListRef = useRef(null);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselListWidth, setCarouselListWidth] = useState(0);
  const [carouselShift, setCarouselShift] = useState(0);
  const [itemWidth, setItemWidth] = useState(defaultItemWidth);

  const handleCarouselShift = direction => {
    const padding = ["small", "xsmall"].includes(windowSize.size) ? 8 : 16;
    const shift = itemWidth * 3 + padding * 2;
    let current;

    // Snap to grid: floor or ceil to closes item in shift direction
    // in the case of uneven horizontal scroll or mobile drag
    if (direction === "left" && carouselShift >= shift) {
      current = Math.ceil(carouselShift / shift) * shift;
      setCarouselShift(current - shift);
    } else if (
      direction === "right" &&
      carouselShift < shift * itemCount - carouselWidth
    ) {
      current = Math.floor(carouselShift / shift) * shift;
      setCarouselShift(current + shift);
    }
  };

  useEffect(() => {
    if (!carouselListRef.current) return;
    carouselListRef.current.scrollLeft = carouselShift;
  }, [carouselShift]);

  useEffect(() => {
    // Reset carousel shift on props change (topics) or resize
    // Slightly shift it as initial position on mobile (aesthetics)
    if (windowSize.size === "xsmall") {
      setCarouselShift(-16);
      setCarouselListWidth(itemCount * (itemWidth + 8));
    } else {
      setCarouselShift(0);
      setCarouselListWidth(itemCount * (itemWidth + 16));
    }
  }, [itemCount, itemWidth, windowSize.size]);

  useEffect(() => {
    // Recalculate item width on resize depending on window
    if (["small", "xsmall"].includes(windowSize.size)) setItemWidth(256);
    else setItemWidth(defaultItemWidth);

    setCarouselWidth(carouselContainer.current.clientWidth);
  }, [windowSize.size, defaultItemWidth]);

  return (
    <CarouselContainer ref={carouselContainer}>
      {carouselShift > 64 && carouselListWidth > carouselWidth && (
        <Scroller direction="left">
          <ScrollerButton
            direction="left"
            size={40}
            iconSize={18}
            onClick={() => handleCarouselShift("left")}
          >
            <i className="icon-arrowleft" />
          </ScrollerButton>
        </Scroller>
      )}
      <CarouselList
        itemCount={itemCount}
        itemWidth={itemWidth}
        itemHeight={defaultItemHeight}
        listPadding={listPadding}
        ref={carouselListRef}
      >
        {children}
      </CarouselList>
      {carouselListWidth > carouselWidth && (
        <Scroller direction="right">
          <ScrollerButton
            direction="right"
            size={40}
            iconSize={18}
            onClick={() => handleCarouselShift("right")}
          >
            <i className="icon-arrowright" />
          </ScrollerButton>
        </Scroller>
      )}
    </CarouselContainer>
  );
}

export default Carousel;
