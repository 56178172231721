export const FETCH_ACCOUNT = "@@account/FETCH_ACCOUNT";
export const FETCH_ACCOUNT_ERROR = "@@account/FETCH_ACCOUNT_ERROR";

export const FETCH_USER_ACCOUNT = "@@account/FETCH_USER_ACCOUNT";
export const USER_ACCOUNT_FETCHED = "@@account/USER_ACCOUNT_FETCHED";
export const ACCOUNT_FETCHED = "@@account/ACCOUNT_FETCHED";
export const UPDATE_ACCOUNT = "@@account/UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_BILLING = "@@account/UPDATE_ACCOUNT_BILLING";
export const UPDATE_ACCOUNT_ABOUT_ONBOARDING =
  "@@account/UPDATE_ACCOUNT_ABOUT_ONBOARDING";
export const UPDATE_ACCOUNT_ERROR = "@@account/UPDATE_ACCOUNT_ERROR";
export const ACCOUNT_UPDATED = "@@account/ACCOUNT_UPDATED";
export const CHARGEBEE_PORTAL = "@@account/CHARGEBEE_PORTAL";
export const SUBSCRIBE = "@@account/SUBSCRIBE";

export const fetchUserAccount = () => {
  return {
    type: FETCH_USER_ACCOUNT
  };
};

export const fetchAccount = accountId => {
  return {
    type: FETCH_ACCOUNT,
    accountId
  };
};

export const createChargeBeePortal = accountId => {
  return {
    type: CHARGEBEE_PORTAL,
    accountId
  };
};

export const fetchAccountError = (accountId, error) => {
  return {
    type: FETCH_ACCOUNT_ERROR,
    accountId,
    error
  };
};

export const updateAccount = values => {
  return {
    type: UPDATE_ACCOUNT,
    values
  };
};

export const updateAccountBilling = (values, cb) => {
  return {
    type: UPDATE_ACCOUNT_BILLING,
    values,
    cb
  };
};

export const updateAccountAboutOnboarding = values => {
  return {
    type: UPDATE_ACCOUNT_ABOUT_ONBOARDING,
    values
  };
};

export const updateAccountError = error => {
  return {
    type: UPDATE_ACCOUNT_ERROR,
    error
  };
};

export const accountUpdated = account => {
  return {
    type: ACCOUNT_UPDATED,
    account
  };
};

export const userAccountFetched = account => {
  return {
    type: USER_ACCOUNT_FETCHED,
    account
  };
};

export const accountFetched = account => {
  return {
    type: ACCOUNT_FETCHED,
    account
  };
};

export const subscribe = values => {
  return {
    type: SUBSCRIBE,
    values
  };
};
