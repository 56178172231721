import React from "react";
import { sidePanelStyles } from "./styles";
import { useEventListener } from "../../utils/useEventListener";
import Modal from "react-modal";

const SidePanel = ({ onClose, children }) => {
  const styles = sidePanelStyles();
  styles.overlay.background = "rgba(0,0,0,0.4)";
  styles.overlay.zIndex = "999";

  const handleKeyDown = e => {
    if (e.keyCode === 27) onClose();
  };

  useEventListener(window, "keydown", handleKeyDown);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={styles}
      onRequestClose={onClose}
    >
      {children}
    </Modal>
  );
};

export default SidePanel;
