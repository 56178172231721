import styled from "styled-components";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import {
  CALENDAR_VIEW,
  CALENDAR_VIEW_TYPES,
  KEY_LAST_CALENDAR_VIEW
} from "constants/calendar";
import * as storageService from "utils/storage";
import Timeline from "components/schedule/timeline";
import Calendar from "components/schedule/calendar/Calendar";
import MonthComponent from "components/schedule/month/Month";
import useAlertModal from "components/modals/pop-ups/useAlertModal";

const ScheduleContainer = styled.div`
  background-color: ${props => props.theme.colors.background1};
`;

function SchedulePage({ match }) {
  const history = useHistory();
  const addFeatureAlert = useAlertModal();
  const account = useSelector(state => state.account.data);
  const preferredCalendarView = storageService.get(KEY_LAST_CALENDAR_VIEW);
  const defaultCalendarView = CALENDAR_VIEW_TYPES.includes(
    preferredCalendarView
  )
    ? preferredCalendarView
    : CALENDAR_VIEW.WEEK;

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  useEffect(() => {
    const newFeature = {
      id: "calendar_subscription",
      title: "Introducing Calendar Subscription",
      description:
        "Export Willow's Calendar to your Personal Calendar and never miss another post.",
      onProceed: () => {
        history.push(`/accounts/${account.id}/settings/notifications`);
      }
    };

    addFeatureAlert(newFeature);
  }, []);

  return (
    <ScheduleContainer>
      <Switch>
        <Route
          path={match.url + `/${CALENDAR_VIEW.DAY}`}
          component={Timeline}
        />
        <Route
          path={match.url + `/${CALENDAR_VIEW.WEEK}`}
          component={Calendar}
        />
        <Route
          path={match.url + `/${CALENDAR_VIEW.MONTH}`}
          component={MonthComponent}
        />
        <Redirect to={match.url + `/${defaultCalendarView}`} />
      </Switch>
    </ScheduleContainer>
  );
}

export default SchedulePage;
