import React, { useState, useEffect } from "react";
import { MetaInformation } from "../styles";

import useWindowSize from "utils/useWindowSize";

import TagGroup from "components/common/tag";
import ContentItemOperations from "../widgets/ContentItemActions.mobile";
import { ITag } from "components/common/tag/types";
import { Flex } from "rebass";
import styled, { css } from "styled-components";

interface IContentItemFooterProps {
  id: string;
  date: string;
  onHover?: boolean;
  orientation?: string;
  predicted_tags: Array<ITag>;
  handleScheduleClick: () => void;
  handleSaveClick: () => void;
  viewOnly: boolean;
  wasSaved: boolean;
  wasLiked: boolean;
  wasPosted: boolean;
  handlePreviewArticle: () => void;
  handleLikeContent: () => void;
  handleDismiss: () => void;
}

const Status = styled(Flex)<{ status: string | null }>`
  gap: 4px;
  align-items: center;
  justify-content: center;
  ${props => {
    switch (props.status) {
      case "posted":
        return css`
          color: ${props.theme.colors.green};
        `;
      case "saved":
      default:
        return css`
          color: ${props.theme.colors.orange};
        `;
    }
  }}

  > span {
    font-size: 18px;
  }
`;

const ContentItemFooter: React.FC<IContentItemFooterProps> = ({
  id,
  orientation,
  predicted_tags,
  handleScheduleClick,
  handleSaveClick,
  handlePreviewArticle,
  handleLikeContent,
  handleDismiss,
  viewOnly,
  wasSaved,
  wasLiked,
  wasPosted
}) => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const numberOfTags = orientation === "landscape" ? 2 : 1;

  useEffect(() => {
    if (windowSize.size) {
      setIsMobile(["small", "xsmall"].includes(windowSize.size));
    }
  }, [windowSize.size]);

  return (
    <MetaInformation
      // @ts-ignore
      hasTags={predicted_tags.length}
      isSaved={wasSaved || wasPosted}
    >
      <TagGroup tags={predicted_tags.slice(0, numberOfTags)} />
      {(wasPosted || wasSaved) && !isMobile ? (
        <Status status={wasPosted ? "posted" : wasSaved ? "saved" : null}>
          <span
            className={wasPosted ? "icon-send" : "icon-bookmark-filled-20"}
          />
          {wasPosted ? "Posted" : "Saved"}
        </Status>
      ) : null}
      {isMobile && !viewOnly ? (
        <ContentItemOperations
          id={id}
          saved={wasSaved}
          liked={wasLiked}
          onDislike={handleDismiss}
          onLike={handleLikeContent}
          onPost={handleScheduleClick}
          onBookmark={handleSaveClick}
          onPreviewArticle={handlePreviewArticle}
        />
      ) : null}
    </MetaInformation>
  );
};

export default ContentItemFooter;
