import React, { useEffect, useContext, useReducer, createContext } from "react";

import useWindowSize from "utils/useWindowSize";
import { clients } from "constants/clientSettings";
import { IAppState, appReducer, initialValues, WindowSize } from "./reducer";
import { setWindowSizeAction, setClientSettingsAction } from "./actionCreators";

const AppStateContext = createContext<IAppState | undefined>(undefined);
AppStateContext.displayName = "AppStateContext";

export const AppProvider: React.FC<any> = ({ children }) => {
  const currentWindowSize = useWindowSize();
  const [state, dispatch] = useReducer(appReducer, {
    ...initialValues
  });
  const { windowSize } = state;

  useEffect(() => {
    if (
      !!process.env.REACT_APP_CLIENT &&
      Object.keys(clients).includes(process.env.REACT_APP_CLIENT)
    ) {
      dispatch(setClientSettingsAction(clients[process.env.REACT_APP_CLIENT]));
    }
  }, []);

  useEffect(() => {
    if (!!currentWindowSize?.size && windowSize !== currentWindowSize.size) {
      const size = Object.values(WindowSize).find(
        value => value === currentWindowSize.size
      );
      if (!!size) {
        dispatch(setWindowSizeAction(size));
      }
    }
  }, [currentWindowSize, windowSize]);

  return (
    <AppStateContext.Provider value={{ ...state }}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppStateContext);

  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
};
