import React, { useState } from "react";
import moment from "moment";
import SidePanel from "../../modals/SidePanel";
import {
  ArrowBack,
  Dislike,
  Bookmark,
  LinkExternal
} from "styled-icons/boxicons-regular";
import { Flex, Box } from "rebass/styled-components";
import { ArticleContentWrapper, ArticleMetaInfo } from "./styles";
import Button from "../../common/Button";
import { Headline1 } from "../../common/styles";
import { colors } from "../../../theme";
import ContentDismissReasons, {
  ContentDismissWrapper
} from "../ContentDismissReasons";
import IconButton from "../../common/IconButton";

const ArticlePreviewModal = ({
  closeModal,
  content,
  onSchedule,
  onDismiss,
  onSave,
  saved
}) => {
  const [isDismissVisible, setDismissVisible] = useState(false);

  const allTags = Array.from(
    new Set((content.verified_tags || []).concat(content.predicted_tags || []))
  );

  const handleExternalLink = () => {
    window.open(content.url, "_blank");
  };

  return (
    <SidePanel title="Side panel" onClose={closeModal}>
      <Flex
        px={32}
        py={16}
        mb={16}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        sx={{
          position: "sticky",
          top: 0,
          borderBottom: `1px solid ${colors.grey02}`
        }}
      >
        <IconButton onClick={closeModal} icon={ArrowBack} />
        <Flex>
          <IconButton
            mr={8}
            icon={Dislike}
            variant="secondary"
            onClick={() => setDismissVisible(true)}
          />
          <IconButton
            iconColor={saved ? "#ff652b" : null}
            mr={8}
            icon={Bookmark}
            variant="secondary"
            active={saved}
            onClick={onSave}
          />
          <IconButton
            icon={LinkExternal}
            onClick={() => handleExternalLink()}
            variant="secondary"
          />
        </Flex>
        <Button variant="primary" onClick={onSchedule}>
          Post Now
        </Button>
      </Flex>
      <Flex justifyContent="center">
        {isDismissVisible ? (
          <ContentDismissWrapper>
            <ContentDismissReasons
              isModal="true"
              domain={content.domain}
              image={content.favicon || content.logo}
              name={content.publisher}
              topic={null}
              onSubmit={onDismiss}
              onCancel={() => setDismissVisible(false)}
            />
          </ContentDismissWrapper>
        ) : (
          <Box mx="5vw" mt={48} maxWidth={626} minHeight="100vh">
            <Headline1 mb={16}>{content.title}</Headline1>
            <ArticleMetaInfo>
              {content.domain} · Published{" "}
              {moment(content.published_date).fromNow()} · {allTags.join(" / ")}
            </ArticleMetaInfo>
            <ArticleContentWrapper
              dangerouslySetInnerHTML={{ __html: content.article_html }}
            />
          </Box>
        )}
      </Flex>
    </SidePanel>
  );
};

export default ArticlePreviewModal;
