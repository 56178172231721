import Modal from "react-modal";
import React, { useState } from "react";
import { X } from "styled-icons/feather";

import {
  ModalBodyComposer,
  ModalTitleComposer,
  ModalHeaderComposer
} from "./styles";
import { modalStyles } from "../styles";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import { Headline5 } from "components/common/styles";
import { Status } from "components/composer/statusSelector/constants";

export const ComposerModal = ({
  title,
  isDraft,
  postStatus,
  setPostStatus,
  children,
  setConfirmationDialogVisible
}) => {
  const postStatusLabel =
    postStatus === Status.DRAFT ? "Approve draft" : "Approved";

  const handleStatusChange = () => {
    if (postStatus === Status.DRAFT) {
      setPostStatus(Status.APPROVED);
    } else {
      setPostStatus(Status.DRAFT);
    }
  };

  const styles = modalStyles(550);
  styles.content.padding = 0;
  styles.overlay.overflow = "hidden";
  styles.overlay.background = "rgba(28, 30, 31, 0.4)";
  styles.overlay.backdropFilter = "blur(5px)";
  return (
    <>
      <Modal
        bodyOpenClassName="overflow-hidden"
        ariaHideApp={false}
        style={styles}
        isOpen={true}
        onRequestClose={() => setConfirmationDialogVisible(true)}
      >
        <ModalHeaderComposer>
          <ModalTitleComposer as="div">
            <Headline5>{title}</Headline5>
          </ModalTitleComposer>
          {isDraft && (
            <Button
              size="xs"
              isRounded={false}
              className="action-btn"
              onClick={handleStatusChange}
              icon={() => <i className="icon-check" />}
              variant={
                postStatus === Status.DRAFT ? "neutralLite" : "successLite"
              }
            >
              {postStatusLabel}
            </Button>
          )}
          <IconButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => setConfirmationDialogVisible(true)}
            icon={X}
          />
        </ModalHeaderComposer>
        <ModalBodyComposer>{children}</ModalBodyComposer>
      </Modal>
    </>
  );
};
