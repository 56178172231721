import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";

import {
  EmptyState,
  CaptionCard,
  CaptionList,
  SearchInput,
  ActionContainer,
  SearchContainer,
  CaptionCardShimmer
} from "./styles";
import { theme } from "theme";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import MoodFilter from "./moodFilter";
import Button from "components/common/Button";
import Loader from "components/common/loading/Loader";
import { ICaption } from "contextApi/composerContext";
import { fetchGeneratedCaptions } from "services/nlp";
import { fetchCaptionsByPostIdea } from "services/post";
import { Body6, Headline6 } from "components/common/styles";
import emptyState from "assets/images/composer/empty-suggestions.png";
import { ToolContentHeader, ToolContentBody, FeatureTag } from "../styles";

const CaptionSuggestion: React.FC<{}> = () => {
  const addToast = useToaster();
  const account = useSelector<any, any>(state => state.account.data);
  const {
    moodFilter,
    postConcepts,
    visibleCaption,
    postData: { postIdea, contentTypeId }
  } = useComposerState();
  const {
    setPostIdeaCaptions,
    setChosenSuggestedCaption
  } = useComposerActions();
  const [searchKeywords, setSearchKeywords] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestedCaptions, setSuggestedCaptions] = useState<any[]>([]);

  const handleSearchKeywordChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSearchKeywords(e.currentTarget.value);
  };

  const generateNewCaptions = async () => {
    try {
      if (!!postIdea) {
        const captions = await fetchCaptionsByPostIdea(postIdea.title);
        setPostIdeaCaptions(postIdea.id, captions);
      }
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  };

  const generateNLPCaptions = async () => {
    const nlpParams = {
      topic: !!postIdea ? postIdea.title : contentTypeId,
      keywords: searchKeywords.trim().split(" "),
      moods: !!moodFilter ? [moodFilter.value] : [],
      language: "en"
    };

    await fetchGeneratedCaptions(nlpParams, {
      onOpen: () => {
        setIsLoading(true);
        setSuggestedCaptions([]);
      },
      onMessage: data => {
        setIsLoading(false);
        // !important : sometimes doubles arrive
        const regex = /\{.*?\}/g;
        const found = data.toString().match(regex);

        found.forEach(json => {
          const parsedData = JSON.parse(json);
          setSuggestedCaptions(captionSuggestions => {
            const newCaptionSuggestions = [...captionSuggestions];

            if (newCaptionSuggestions[parsedData.index]) {
              newCaptionSuggestions[parsedData.index] = {
                id: parsedData.index,
                caption:
                  newCaptionSuggestions[parsedData.index].caption +
                  parsedData.text
              };
              return newCaptionSuggestions;
            }

            newCaptionSuggestions[parsedData.index] = {
              id: parsedData.index,
              caption: parsedData.text
            };
            return newCaptionSuggestions;
          });
        });
      },
      onClose: () => {
        setIsLoading(false);
      },
      onError: e => {
        setIsLoading(false);
        addToast((e as Error).message, "error");
      }
    });
  };

  const ideaCaptions: ICaption[] = !!postIdea
    ? _.flatMap(postConcepts, postConcept => postConcept.postIdeas).find(
        idea => idea.id === postIdea.id
      )!.captions
    : [];

  const isWillowAIEnabled = account.features.includes("willow_ai");

  const captions = isWillowAIEnabled ? suggestedCaptions : ideaCaptions;

  return (
    <>
      <ToolContentHeader>
        <Headline6>Caption suggestion</Headline6>
        {isWillowAIEnabled && (
          <FeatureTag isLoading={isLoading}>
            {isLoading ? (
              <>
                <Loader size={20} color={theme.colors.primary} />
                <span>Generating...</span>
              </>
            ) : (
              <>
                <i className="icon-sparkle" />
                <span>Willow AI assist</span>
              </>
            )}
          </FeatureTag>
        )}
      </ToolContentHeader>
      <ToolContentBody>
        {isWillowAIEnabled && (
          <>
            <SearchContainer>
              <SearchInput
                placeholder="Start typing at least couple words to see Willow AI suggestions..."
                value={searchKeywords}
                onChange={handleSearchKeywordChange}
              />
            </SearchContainer>

            <ActionContainer>
              <MoodFilter />
              {/* @ts-ignore */}
              <Button
                size={"sm"}
                variant={"primary"}
                onClick={generateNLPCaptions}
                disabled={isLoading}
              >
                Generate
              </Button>
            </ActionContainer>
          </>
        )}
        <CaptionList>
          {isLoading ? (
            <>
              {[...Array(3).fill(0)].map((_, index) => (
                <CaptionCardShimmer key={index} />
              ))}
            </>
          ) : captions.length < 1 ? (
            <EmptyState>
              <div>
                <span className="title">Nothing to suggest yet</span>
                <span className="subtitle">
                  Start typing at least couple words to see Willow AI
                  suggestions
                </span>
              </div>
              <img src={emptyState} />
            </EmptyState>
          ) : (
            captions.map(caption => ( 
              <CaptionCard
                key={caption?.id}
                onClick={() =>
                  setChosenSuggestedCaption({
                    [visibleCaption]: caption?.caption
                  })
                }
              >
                <Body6>{caption.caption}</Body6>
              </CaptionCard>
            ))
          )}
        </CaptionList>
      </ToolContentBody>
    </>
  );
};

export default CaptionSuggestion;
