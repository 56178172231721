import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import Channel from "./channel";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { ChannelGrid } from "./styles";

const ChannelSelector: React.FC<{}> = () => {
  const account = useSelector<any, any>(state => state.account.data);
  const { postData } = useComposerState();
  const { onChannelSelected } = useComposerActions();

  const disabled = !!(
    postData &&
    postData.id &&
    !postData.isDraft &&
    !postData.isPostGroup
  );

  const handleSelectChannels = (selectedChannels: string[]) => {
    const validationErrors = {
      channelEmpty: selectedChannels.length === 0
    };
    onChannelSelected(selectedChannels, validationErrors);
  };

  const handleChannelToggle = (channelId: string) => {
    if (disabled) {
      return null;
    }

    const selectedChannels = postData.channels;
    if (selectedChannels.includes(channelId)) {
      selectedChannels.splice(selectedChannels.indexOf(channelId), 1);
    } else {
      selectedChannels.push(channelId);
    }
    handleSelectChannels(selectedChannels);
  };

  return (
    <ChannelGrid>
      {_.sortBy(account.channels, [
        a => {
          const serviceOrder = [
            "linkedin business",
            "instagram",
            "facebook",
            "twitter",
            "linkedin profile"
          ];

          return serviceOrder.findIndex(o =>
            `${a.service} ${a.serviceType}`.includes(o)
          );
        }
      ]).map(
        channel =>
          !(disabled && !postData.channels.includes(channel.id)) && (
            <Channel
              key={channel.id}
              channel={channel}
              handleChannelToggle={handleChannelToggle}
              checked={postData.channels.includes(channel.id)}
            />
          )
      )}
    </ChannelGrid>
  );
};

export default ChannelSelector;
