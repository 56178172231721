import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Flex, Box } from "rebass/styled-components";

import {
  Avatar,
  Service,
  Headline3,
  Paragraph,
  AvatarWrapper
} from "components/common/styles";
import { MetricTicker } from "./Metrics";
import { SERVICE_LIST } from "constants/services";
import { calculateGrowthRate } from "utils/stats";

export const BreakdownContainer = styled(Box)`
  background-color: white;
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  padding: 16px 0;
`;

const BreakdownMetric = styled(Box)`
  display: grid;
  grid-template-columns: 20px 1fr max-content;
  grid-column-gap: 10px;
  padding: 16px 24px;
  border-top: 1px solid ${props => props.theme.colors.grey02};
`;

const BreakdownMetricBody = styled(Box)`
  display: grid;
  grid-template-rows: 18px 18px;
  grid-row-gap: 10px;
  font-size: 16px;
  font-weight: bold;

  & small {
    color: ${props => props.theme.colors.text03};
    display: block;
  }
`;

const BreakdownMetricLabel = styled(Paragraph)`
  color: ${props =>
    props.isLight ? props.theme.colors.text03 : props.theme.colors.text01};
  line-height: 17.5px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
`;

const Icon = styled.span`
  color: ${props => props.theme.colors.text021};
  font-size: 20px;
`;

const Headline = styled(Headline3)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

function ChannelBreakdown({ data }) {
  const stats = data.stats;
  const { engagements, impressions, followers, posts } = stats;
  const account = useSelector(state => state.account.data);

  const locale = account.billingCountry === "US" ? "en-US" : "nl-BE";
  const metricToLocale = val => new Intl.NumberFormat(locale).format(val);

  const statRates = calculateGrowthRate(stats, locale);

  return (
    <BreakdownContainer marginTop={48}>
      <Flex marginTop={-48} justifyContent="center">
        <AvatarWrapper height={64}>
          <Avatar src={data.avatar} width={64} height={64} noShadow isRounded />
          <Service title={data.username} type={data.service} size={32} />
        </AvatarWrapper>
      </Flex>
      <Box marginY={8} paddingX={8} textAlign="center">
        <Headline>{data.username}</Headline>
        <Paragraph variant={"light"}>
          {SERVICE_LIST[`${data.service}_${data.serviceType}`]}
        </Paragraph>
      </Box>
      <Box marginY={16}>
        <BreakdownMetric>
          <Icon className="icon-team" />
          <BreakdownMetricBody>
            <BreakdownMetricLabel>Audience</BreakdownMetricLabel>
            <BreakdownMetricLabel isLight>
              {followers.total
                ? metricToLocale(followers.total)
                : "Not available"}
            </BreakdownMetricLabel>
          </BreakdownMetricBody>
          {followers.total && <MetricTicker value={statRates.followers} />}
        </BreakdownMetric>
        <BreakdownMetric>
          <Icon className="icon-eye" />
          <BreakdownMetricBody>
            <BreakdownMetricLabel>Impressions</BreakdownMetricLabel>
            <BreakdownMetricLabel isLight>
              {impressions.period
                ? metricToLocale(impressions.period)
                : "Not available"}
            </BreakdownMetricLabel>
          </BreakdownMetricBody>
          {impressions.period > 0 && (
            <MetricTicker value={statRates.impressions} />
          )}
        </BreakdownMetric>
        <BreakdownMetric>
          <Icon className="icon-send" />
          <BreakdownMetricBody>
            <BreakdownMetricLabel>Engagement</BreakdownMetricLabel>
            <BreakdownMetricLabel isLight>
              {engagements.period
                ? metricToLocale(engagements.period)
                : "Not available"}
            </BreakdownMetricLabel>
          </BreakdownMetricBody>
          {engagements.period > 0 && (
            <MetricTicker value={statRates.engagements} />
          )}
        </BreakdownMetric>
        <BreakdownMetric>
          <Icon className="icon-send" />
          <BreakdownMetricBody>
            <BreakdownMetricLabel>Published posts</BreakdownMetricLabel>
            <BreakdownMetricLabel isLight>{posts.total}</BreakdownMetricLabel>
          </BreakdownMetricBody>
          <MetricTicker value={statRates.posts} />
        </BreakdownMetric>
      </Box>
    </BreakdownContainer>
  );
}

export default ChannelBreakdown;
