import { Box, Flex } from "rebass";
import Button from "../common/Button";
import styled from "styled-components";
import ContentItem from "./ContentItem";
import { EmptyImageWrapper } from "./styles";
import EmptyState from "../common/EmptyState";
import Divider from "components/common/Divider";
import { useToaster } from "@hellocontento/maillard";
import { searchArticlesByOptions } from "services/news";
import React, { useCallback, useEffect, useState, useContext } from "react";
import ArticleEmpty from "assets/images/contents/article-empty.png";

import { ArticleSearchContext } from "contextApi/ArticleSearchContext";

const TotalResultLabel = styled.span`
  color: ${props => props.theme.colors.text03};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export default function ContentSearch() {
  const addToast = useToaster();
  const [isLoading, setLoading] = useState(true);

  const ctx = useContext(ArticleSearchContext);

  const [searchResult, setSearchResult] = useState({
    records: [],
    tags: [],
    total: 0,
    page: 1
  });

  const refreshSearchResults = useCallback(async () => {
    if (!ctx.searchValue) {
      setSearchResult({
        records: [],
        tags: [],
        total: 0,
        page: 1
      });
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const tags =
        ctx.selectedTopics.length > 0
          ? ctx.selectedTopics.map(topic => topic.toLowerCase()).join(",")
          : null;

      const response = await searchArticlesByOptions({
        text: ctx.searchValue,
        tags,
        language: ctx.selectedLanguages?.length
          ? ctx.selectedLanguages.map(language => language.value)
          : undefined,
        limit: 20,
        page: 1
      });

      setSearchResult(s => ({
        ...s.searchResult,
        records: response.records,
        tags: response.facets.predicted_tags,
        total: response.totalRecords
      }));

      ctx.setData({
        ...ctx,
        topics: response.facets.predicted_tags
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      addToast(e.message, "error");
    }
  }, [ctx.searchValue, ctx.selectedTopics, ctx.selectedLanguages]);

  const handleLoadMore = async () => {
    try {
      const tags =
        ctx.selectedTopics.length > 0
          ? ctx.selectedTopics.map(topic => topic.toLowerCase()).join(",")
          : null;

      const response = await searchArticlesByOptions({
        text: ctx.searchValue,
        tags,
        language: ctx.selectedLanguages?.length
          ? ctx.selectedLanguages.map(language => language.value)
          : undefined,
        limit: 20,
        page: searchResult.page + 1
      });

      setSearchResult(result => ({
        ...result,
        records: [...searchResult.records, ...response.records],
        page: searchResult.page + 1
      }));
    } catch (e) {
      addToast(e.message, "error");
    }
  };

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      refreshSearchResults();
    }, 300);

    return () => clearTimeout(searchDelay);
  }, [refreshSearchResults]);

  const emptyStateMessage = !ctx.searchValue
    ? {
        header: "You can search for articles",
        description: "Please enter search terms to find related articles."
      }
    : {
        header: "We couldn’t find any articles",
        description:
          "Please check your spelling or try again with different search terms."
      };

  return (
    <Box flex={"1"}>
      {isLoading || searchResult.records?.length === 0 ? (
        <EmptyState
          loading={isLoading}
          center={false}
          image={<EmptyImageWrapper src={ArticleEmpty} />}
          {...emptyStateMessage}
        />
      ) : (
        <>
          <Box maxWidth={928} mx={"auto"}>
            <TotalResultLabel>
              {searchResult?.total} results found
            </TotalResultLabel>
            <Divider my={2} />
            {searchResult?.records?.map(item => (
              <ContentItem
                key={item.id}
                content={item}
                orientation={"landscape"}
                thumbnailHeight={192}
              />
            ))}
          </Box>
          {searchResult.total > searchResult.records.length && (
            <Flex justifyContent={"center"}>
              <Button size={"lg"} variant={"primary"} onClick={handleLoadMore}>
                View more articles
              </Button>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
}
