import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Flex } from "rebass/styled-components";
import Button from "../../components/common/Button";
import FallbackImage from "../../components/common/FallbackImage";
import { FormBox, FormTitle } from "../../components/common/form/styles";
import { ForgotBox, ForgotLink } from "../../components/auth/styles";
import {
  OnboardingLayout,
  OnboardingHeader,
  OnboardingContainer
} from "../onboarding/styles";
import { Paragraph, AvatarWrapper, UserAvatar, AccountName } from "./styles";

import willowLogo from "../../assets/images/willow-text-color@2x.png";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import { accountStatuses } from "@hellocontento/contento-common";

export default function LockoutPage() {
  const history = useHistory();
  const account = useSelector(state => state.account.data);

  if (
    account.status !== accountStatuses.CANCELED &&
    account.billing &&
    account.billing.billingStatus !== "cancelled"
  ) {
    // redirect to dashboard
    window.location = `/accounts/${account.id}/dashboard`;
  }

  const user = useSelector(state => state.auth.currentUser);

  const openHubSpotChat = e => {
    e.preventDefault();

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  };

  const handleSubscribe = () => {
    history.push(`/accounts/${account.id}/checkout`);
  };

  const handleCloseAccount = () => {
    history.push(`/accounts/${account.id}/close`);
  };

  return (
    <OnboardingLayout>
      <OnboardingHeader>
        <img src={willowLogo} alt="Willow" height="40" />
      </OnboardingHeader>
      <Flex
        flexDirection="column"
        alignItems="center"
        marginTop={24}
        marginBottom={-24}
      >
        <AvatarWrapper my={12}>
          <UserAvatar>
            <FallbackImage
              alt={account.companyName}
              width={[1]}
              height={40}
              src={account.logo}
              fallbackSrc={emptyProfileIcon}
              sx={{ borderRadius: "50%", border: "solid 1px #dadde1" }}
            />
          </UserAvatar>
        </AvatarWrapper>
        <AccountName>{account.companyName}</AccountName>
      </Flex>
      <OnboardingContainer>
        <FormTitle>
          {account.billing?.trialEnd
            ? `Sorry ${user.firstName}, your free trial is over`
            : `Hey ${user.firstName}, you cancelled your subscription`}
        </FormTitle>
        <FormBox marginTop={32} maxWidth="480px">
          <Flex flexDirection="column" alignItems="stretch" marginBottom={16}>
            {account.billing?.trialEnd ? (
              <Paragraph>
                We couldn’t log you in to <strong>{account.companyName}</strong>{" "}
                because your trial expired on{" "}
                <strong>
                  {format(parseISO(account.billing.trialEnd), "LLLL d, yyyy")}
                </strong>
                .
              </Paragraph>
            ) : (
              <Paragraph>
                We couldn't log you in to <strong>{account.companyName}</strong>{" "}
                because your subscription expired.
              </Paragraph>
            )}
            <Paragraph>
              All of your data is safe, but no one will be able to log in until
              your account is upgraded.
            </Paragraph>

            <Paragraph>Subscribe now and pick up where you left off.</Paragraph>
            <Button
              variant="primary"
              size="lg"
              marginBottom={8}
              onClick={handleSubscribe}
            >
              Subscribe now
            </Button>
            <Button variant="secondary" size="lg" onClick={handleCloseAccount}>
              Close my account
            </Button>
          </Flex>
        </FormBox>
        <ForgotBox marginBottom={32}>
          Having trouble or questions?{" "}
          <ForgotLink onClick={openHubSpotChat}>We can help</ForgotLink>.
        </ForgotBox>
      </OnboardingContainer>
    </OnboardingLayout>
  );
}
