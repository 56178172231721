import React from "react";
import styled, { css } from "styled-components";
import { Flex } from "rebass/styled-components";

import { useAppState } from "contextApi/appContext";

const sizes = {
  xs: {
    container: {
      borderRadius: "4px",
      px: "2px",
      py: "2px"
    },
    button: {
      borderRadius: "4px",
      fontSize: "14px",
      px: "12px",
      py: "4px"
    }
  },
  sm: {
    container: {
      borderRadius: "4px",
      px: "2px",
      py: "2px"
    },
    button: {
      borderRadius: "4px",
      fontSize: "14px",
      px: "12px",
      py: "8px"
    }
  },
  m: {
    container: {
      borderRadius: "6px",
      px: "4px",
      py: "4px"
    },
    button: {
      borderRadius: "4px",
      fontSize: "16px",
      px: "12px",
      py: "8px"
    }
  },
  lg: {
    container: {
      borderRadius: "8px",
      px: "4px",
      py: "4px"
    },
    button: {
      borderRadius: "2px",
      fontSize: "16px",
      px: "16px",
      py: "12px"
    }
  }
};

const ButtonGroupContainer = styled(Flex)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: ${props =>
    props.isRounded ? "64px" : sizes[props.size].container.borderRadius};
  padding: ${props => sizes[props.size].container.py}
    ${props => sizes[props.size].container.px};
`;

const Button = styled.button`
  appearance: none;
  background-color: transparent;
  border-radius: ${props =>
    props.isRounded ? "64px" : sizes[props.size].button.borderRadius};
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.text01};
  cursor: pointer;
  flex: 1;
  font-size: ${props => sizes[props.size].button.fontSize};
  padding: ${props => sizes[props.size].button.py}
    ${props => sizes[props.size].button.px};
  min-width: fit-content;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      font-weight: 600;

      &:hover {
        background-color: white;
      }
    `}
`;

const ButtonAddon = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: 400;
  margin: 0 2px;
`;

function ButtonGroup({
  size = "m",
  buttons,
  isRounded = false,
  current,
  onClick
}) {
  const {
    clientSettings: { client }
  } = useAppState();

  const handleClick = value => {
    onClick(value);
  };

  const isTelenetClient = client === "telenet";

  return (
    <ButtonGroupContainer size={size} isRounded={isRounded}>
      {buttons.map(button => (
        <Button
          key={button.value}
          size={size}
          isRounded={isRounded}
          isActive={current === button.value}
          onClick={() => handleClick(button.value)}
        >
          {button.label}
          {!isTelenetClient && button.addon && (
            <ButtonAddon>{button.addon}</ButtonAddon>
          )}
        </Button>
      ))}
    </ButtonGroupContainer>
  );
}

export default ButtonGroup;
