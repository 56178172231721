import React, { useRef, useMemo, useState, useCallback } from "react";

import { FeedAddButton } from "./styles";
import Popper from "components/common/Popper";
import { parseStringToDate } from "utils/date";
import { useTimelineActions } from "components/schedule/timeline/context";
import NewActivityOptions from "components/schedule/common/NewActivityOptions";

interface IDayContextMenu {
  date: string;
}

const DayContextMenu: React.FC<IDayContextMenu> = ({ date }) => {
  const [showMenu, setShowMenu] = useState(false);
  const referenceElement = useRef<HTMLDivElement>(null);
  const toggleShowMenu = () => setShowMenu(!showMenu);
  const { createTask, writePost, writeDraft } = useTimelineActions();

  const day = useMemo(() => parseStringToDate(date, "yyyy/M/d"), [date]);

  const handleCreateTask = useCallback(
    (_: React.MouseEvent<HTMLDivElement>) => {
      createTask(day);
    },
    [day, createTask]
  );

  const handleWritePost = useCallback(
    (_: React.MouseEvent<HTMLDivElement>) => {
      writePost(day);
    },
    [day, writePost]
  );

  const handleWriteDraft = useCallback(
    (_: React.MouseEvent<HTMLDivElement>) => {
      writeDraft(day);
    },
    [day, writeDraft]
  );

  return (
    <div className="add" ref={referenceElement}>
      <FeedAddButton onClick={toggleShowMenu}>
        <i className="icon-plus" />
      </FeedAddButton>
      {/* @ts-ignore */}
      <Popper
        visible={showMenu}
        placement="bottom-start"
        onClose={() => setShowMenu(false)}
        exceptions={[referenceElement.current]}
        referenceElement={referenceElement.current}
      >
        <NewActivityOptions
          close={() => setShowMenu(false)}
          handleCreateTask={handleCreateTask}
          handleWritePost={handleWritePost}
          handleWriteDraft={handleWriteDraft}
          day={day}
        />
      </Popper>
    </div>
  );
};

export default DayContextMenu;
