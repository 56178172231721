import React from "react";
import { Box } from "rebass";

import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { Categories } from "./styles";
import ContentTypeSelect from "components/common/contentTypes/ContentTypeSelectV2";

const CategorySelector: React.FC<{}> = () => {
  const { postData } = useComposerState();
  const { setPostData } = useComposerActions();

  const changeContentType = (newValue: string | { [key: string]: any }) => {
    let contentTypeId;
    let postIdea;

    if (typeof newValue === "string") {
      contentTypeId = newValue;
      postIdea = null;
    } else {
      contentTypeId = newValue.contentTypeId;
      postIdea = newValue.postIdea;
    }

    setPostData({
      ...postData,
      contentTypeId,
      postIdea
    });
  };

  return (
    <Categories>
      <Box>
        <ContentTypeSelect
          position="top-start"
          selectedPostConcept={postData.contentTypeId}
          selectedPostIdea={postData.postIdea}
          onChange={changeContentType}
        />
      </Box>
    </Categories>
  );
};

export default CategorySelector;
