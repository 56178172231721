import React from "react";
import { connect } from "react-redux";
import { CreateButton, CalendarAddOptionsWrapper } from "./styles";

import CalendarAddOptions from "./CalendarAddOptions";
import OutsideClickDetector from "components/common/OutsideClickDetector";

const ColumnContextMenu = ({
  isVisible,
  day,
  isDragging,
  handleWritePost,
  handleWriteDraft,
  handleCreateTask,
  toggleShowContextMenu,
  showContentMenu
}) => {
  if (!isVisible) return null;

  return (
    <>
      {showContentMenu && (
        <CalendarAddOptionsWrapper>
          <OutsideClickDetector onClose={toggleShowContextMenu}>
            <CalendarAddOptions
              float={true}
              visible={showContentMenu}
              close={toggleShowContextMenu}
              handleCreateTask={handleCreateTask}
              handleWritePost={handleWritePost}
              handleWriteDraft={handleWriteDraft}
              day={day}
            />
          </OutsideClickDetector>
        </CalendarAddOptionsWrapper>
      )}
      {!isDragging && (
        <CreateButton
          size="lg"
          variant="secondary"
          onClick={() => {
            toggleShowContextMenu();
          }}
        >
          <span className="icon-plus"></span>
          Add New
        </CreateButton>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isDragging: state.dragDrop.isDragging
  };
};

export default connect(mapStateToProps, {})(ColumnContextMenu);
