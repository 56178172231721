import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass";
import { closeModal } from "../../../state/actions/ModalActions";
import { EmojiModal } from "../EmojiModal";
import AvailableService from "../../channelConnect/services/AvailableService";
import Button from "../../common/Button";
import { connectChannel } from "../../../utils/channels";
import { ModalTitle, ModalSubtitle } from "../styles";

const ChannelReconnectModal = ({ closeModal, account }) => {
  const channels = [...account.channels.filter(c => c.needsReconnection)];

  const handleChannelConnect = service => {
    connectChannel(account, service);
  };

  const handleDoLater = () => {
    const now = +new Date();
    const storage = window.localStorage;

    if (storage) {
      for (let channel of channels) {
        storage.setItem(`reconnect_channel_${channel.id}`, now);
      }
    }

    closeModal();
  };

  return (
    <EmojiModal maxWidth={840} title="" emoji="✋" onClose={handleDoLater}>
      <ModalTitle>
        Hey, please reconnect{" "}
        {channels.length === 1
          ? "your channel"
          : `these ${channels.length} channels`}
        .
      </ModalTitle>
      <ModalSubtitle>
        Social channels will become disconnected from time to time. This is
        normal behavior of the social network to keep your account safe and
        secure.
      </ModalSubtitle>
      <Box width="100%" marginY={24}>
        {channels.map(channel => (
          <AvailableService
            key={channel.id}
            service={`${channel.service}_${channel.serviceType}`}
            channel={channel}
            status="toReconnect"
            onConnect={() =>
              handleChannelConnect(`${channel.service}_${channel.serviceType}`)
            }
          />
        ))}
      </Box>
      <Button variant="secondary" onClick={handleDoLater}>
        I'll do this later
      </Button>
    </EmojiModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ChannelReconnectModal);
