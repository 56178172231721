// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  EntryStats,
  EntryContent,
  EntryWrapper,
  PublisherInfo,
  PublishedEntryInfo,
  PublishedEntryHeader
} from "./styles";
import { IFeedEntry } from "./index";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import { SERVICES } from "constants/services";
import { ATTACHMENT_TYPES } from "constants/post";
import EntryText from "components/schedule/common/entryText";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "components/schedule/common/postContextMenu/PostContextMenu";
import PostImageGrid from "components/schedule/common/postImageGrid";
import { useTimelineActions } from "components/schedule/timeline/context";
import { Avatar, ChannelAvatar, Service } from "components/common/styles";
import PostEntryArticle from "components/schedule/common/postEntryArticle";
import ContentTypeBadge from "components/common/contentTypes/ContentTypeBadge";
import PostAttachmentVideo from "components/schedule/common/postAttachmentVideo";
import TaskConfirmationModal from "components/schedule/form/TaskConfirmationModal";

const PublishedEntry: React.FC<IFeedEntry> = ({ entry }) => {
  const {
    editPost,
    onEntryDeleted,
    refetchEntries,
    openPublishedPost
  } = useTimelineActions();
  const ref = useRef<HTMLDivElement | null>(null);
  const { post, time, text, title, picture, channels } = entry;
  const [contextMenuPos, setContextMenuPos] = useState<number[]>([]);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(
    false
  );

  const addToast = useToaster();

  const hasAttachment = !!post?.attachment;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    openPublishedPost(post);
  };

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  const deleteEntry = () => setConfirmationDialogVisible(true);

  const toggleConfirmation = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (!!e) {
      e.stopPropagation();
    }
    setConfirmationDialogVisible(false);
  };

  const removePost = async () => {
    try {
      const params = post.isPostGroup
        ? {
            method: "delete",
            url: `/accounts/${account.id}/post-groups/${post.id}`
          }
        : post.isDraft
        ? {
            method: "delete",
            url: `/accounts/${account.id}/draft-posts/${post.id}`
          }
        : { method: "delete", url: `/posts/${post.id}` };

      await callApi(params);
      onEntryDeleted(post);
    } catch (err) {
      addToast("Couldn't delete the post.", "error");
    }
  };

  const publishedPostDeletionModalProps = {
    title: `Delete ${capitalize(channels[0].service)} post`,
    description:
      channels[0].service === SERVICES.INSTAGRAM
        ? `In order to delete this post you need to go to Instagram and delete it there.`
        : `Are you sure you want to delete this post?`,
    showOptions: false,
    toggle: toggleConfirmation,
    buttonProps: {
      variant:
        channels[0].service === SERVICES.INSTAGRAM ? `primary` : `danger`,
      label:
        channels[0].service === SERVICES.INSTAGRAM
          ? `Go to Instagram`
          : `Delete`,
      action: () => {
        if (channels[0].service === SERVICES.INSTAGRAM) {
          openPublishedPost(post);
        } else {
          removePost();
        }
      }
    }
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    if (!!element) {
      element.addEventListener("contextmenu", handleContextMenu);
    }

    return () => {
      if (!!element) {
        element.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const element = ref.current;

    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
      // ? Move up the DOM to get to the scrollable timeline element
      element?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.addEventListener(
        "scroll",
        handleScroll
      );
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
      // ? Move up the DOM to get to the scrollable timeline element
      element?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.removeEventListener(
        "contextmenu",
        handleScroll
      );
    };
  }, [contextMenuPos]);

  return (
    <EntryWrapper
      ref={ref}
      onClick={handleClick}
      isContextMenuOpen={contextMenuPos.length > 0}
    >
      <TaskConfirmationModal
        isOpen={confirmationDialogVisible}
        {...publishedPostDeletionModalProps}
      />
      <PublishedEntryHeader>
        <PublishedEntryInfo>
          <ChannelAvatar enabled={true}>
            <Avatar
              src={post.channel.avatar}
              width={40}
              height={40}
              noShadow={false}
              isRounded={true}
            />
            <Service
              key={post.channel.id}
              title={post.channel.username}
              type={post.channel.service}
              size={20}
            />
          </ChannelAvatar>
          <PublisherInfo>
            <span className="username">{post.channel.username}</span>
            <span className="time">{time}</span>
          </PublisherInfo>
        </PublishedEntryInfo>
        {post.contentTypeId !== "other" && (
          <ContentTypeBadge
            variant={"dark-info"}
            contentTypeId={post.contentTypeId}
            title={post.postIdea?.title || post.contentTypeLabel}
          />
        )}
      </PublishedEntryHeader>
      <EntryContent>
        {text && (
          <EntryText
            text={text}
            hasAttachment={hasAttachment}
            service={post.channel.service}
          />
        )}
        {hasAttachment &&
          post.attachment.type === ATTACHMENT_TYPES.PHOTO &&
          !title && (
            <PostImageGrid size="sm" rowHeight="auto" data={post.attachment} />
          )}
        {hasAttachment && post.attachment.type === ATTACHMENT_TYPES.VIDEO && (
          <PostAttachmentVideo attachment={post.attachment} />
        )}
        {hasAttachment && title && (
          <PostEntryArticle picture={picture} post={post} title={title} />
        )}
      </EntryContent>
      <EntryStats>
        <div className="stats">
          <i className="icon-analytics-likes">
            <i className="path1"></i>
            <i className="path2"></i>
            <i className="path3"></i>
          </i>
          <span>{post.stats?.likes ?? 0}</span>
        </div>
        <div className="stats">
          <i className="icon-analytics-comment" />
          <span>{post.stats?.comments ?? 0}</span>
        </div>
        <div className="stats">
          <i className="icon-analytics-share" />
          <span>{post.stats?.shares ?? 0}</span>
        </div>
        <div className="stats">
          <span>{post.stats?.impressions ?? 0}</span>
          <span>impressions</span>
        </div>
      </EntryStats>
      <RightClickPostContextMenu
        entry={entry}
        reload={refetchEntries}
        closeMenu={closeMenu}
        editEntry={editPost}
        deleteEntry={deleteEntry}
        contextMenuPos={contextMenuPos}
      />
      <PostContextMenuWithButton
        entry={entry}
        reload={refetchEntries}
        editEntry={editPost}
        deleteEntry={deleteEntry}
        contextWithPortal={true}
      />
    </EntryWrapper>
  );
};

export default PublishedEntry;
