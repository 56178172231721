import timezoneList from "compact-timezone-list";
import countryList from "country-list";

import billingBasicIcon from "../assets/images/billing-plan-personal@2x.png";
import billingSBIcon from "../assets/images/billing-plan-biz@2x.png";

export const timezones = timezoneList.map(tz => {
  return { key: tz.tzCode, value: tz.label };
});

const countryData = countryList.getData();
export const countries = countryData.map(country => ({
  key: country.code,
  value: country.name
}));

// const isDev = process.env.REACT_APP_ENV !== 'production';

export const planMapping = {
  EUR: {
    YEARLY: {
      SMALL_BIZ: "willow-small-biz-eu-yearly",
      PERSONAL_BRANDING: "willow-personal-branding-eu-yearly"
    },
    MONTHLY: {
      SMALL_BIZ: "willow-small-biz-eu-monthly",
      PERSONAL_BRANDING: "willow_personal_branding_eu_monthly"
    }
  },
  USD: {
    YEARLY: {
      SMALL_BIZ: "willow-small-biz-us-yearly",
      PERSONAL_BRANDING: "willow-personal-branding-us-yearly"
    },
    MONTHLY: {
      SMALL_BIZ: "willow-small-biz-us-monthly",
      PERSONAL_BRANDING: "willow-personal-branding-us-monthly"
    }
  }
};

export const telenetPlanMapping = {
  EUR: {
    YEARLY: "Telenet-Social-Media-Package-EUR-Yearly",
    MONTHLY: "Telenet-Social-Media-Package-EUR-Monthly"
  }
};

export const telenetCoachingAddonMapping = {
  EUR: {
    LIGHT: "Light-Onboarding-Telenet-EUR",
    PREMIUM: "Premium-Onboarding-Telenet-EUR"
  }
};

export const coachingAddOnMapping = {
  EUR: {
    YEARLY: "coaching-eu-yearly-1788",
    MONTHLY: "coaching-eu-monthly-149"
  },
  USD: {
    YEARLY: "coaching-us-yearly-1788",
    MONTHLY: "coaching-us-monthly-149"
  }
};

export const plans = {
  PERSONAL_BRANDING: {
    id: "PERSONAL_BRANDING",
    title: "Personal Branding",
    subTitle: "Ideal for solopreneurs",
    color: "#0063fb",
    features: ["1 user", "5 social channels", "Unlimited scheduling"],
    icon: billingBasicIcon
  },
  SMALL_BIZ: {
    id: "SMALL_BIZ",
    title: "Small Business",
    subTitle: "Perfect for B2B & Professional Services",
    color: "#ff652b",
    features: ["10 users", "20 social channels", "Unlimited scheduling"],
    icon: billingSBIcon
  }
};

export const telenetPlans = {
  MONTHLY: {
    id: "Telenet-Social-Media-Package-EUR-Monthly",
    title: "Telenet Monthly Plan",
    subTitle: "Perfect for trying out Willow",
    color: "#ff652b",
    features: ["1 user", "3 social channels", "Unlimited scheduling"],
    icon: billingBasicIcon
  },
  YEARLY: {
    id: "Telenet-Social-Media-Package-EUR-Yearly",
    title: "Telenet Yearly Plan",
    subTitle: "Ideal for solopreneurs",
    color: "#0063fb",
    features: ["1 user", "3 social channels", "Unlimited scheduling"],
    icon: billingBasicIcon
  }
};

export const telenetCoachingAddons = {
  LIGHT: {
    id: "Light-Onboarding-Telenet-EUR",
    title: "Light Onboarding",
    subTitle: "Simple and Informative.",
    color: "#ff652b",
    features: [
      "Koppelen social media kanalen",
      "Opstellen van content kalender",
      "Instellen hashtags en meldingen",
      "Inplannen van de eerste post(s)",
      "Uitleggen hoe de live-chat werkt",
      "Ons coaching aanbod bespreken",
      "Uitstaande vragen behandelen"
    ],
    icon: billingBasicIcon
  },
  PREMIUM: {
    id: "Premium-Onboarding-Telenet-EUR",
    title: "Premium Onboarding",
    subTitle: "For the best onboarding experience.",
    color: "#0063fb",
    features: [
      "Alles uit de Light Onboarding +",
      "Bepalen van de social media doelen",
      "Vastleggen van de doelgroepen",
      "Uitwerken van een content strategie",
      "Definiëren van de content mix",
      "Verduidelijken van de analytics",
      "Live support"
    ],
    icon: billingBasicIcon
  }
};

export const splitChannelsVideoLink =
  "https://storage.googleapis.com/hellocontento-prod-media/static/Willow_-_Mentioning_-_Caption_Split_1.mp4";
