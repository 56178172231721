import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import qs from "qs";
import moment from "moment";
import { useToaster } from "@hellocontento/maillard";
import ArticlePreviewModal from "./articlePreviewModal/ArticlePreviewModal";
import * as analyticsActions from "../../state/actions/AnalyticsActions";
import { Flex } from "rebass/styled-components";
import Tooltip from "../common/Tooltip";
import ContentDismissReasons from "./ContentDismissReasons";
import {
  SuggestedItemContent,
  ItemDomain,
  ContentIconButtonIcon,
  ItemTitle,
  CoverDomain,
  CoverTitle,
  ItemNewDot,
  ItemText,
  ActionZone,
  ContentItemWrapper,
  ContentItemCover,
  ContentScheduleButton,
  ContentIconButton,
  ItemFavicon,
  ContentItemDateWrapper,
  ContentIconButtonGroup
} from "./styles";
import { getRelativeDate } from "utils/date";
import useComposer from "utils/useComposer";
import ContentItemFooter from "./contentItem/ContentItemFooter";
import * as modalActions from "state/actions/ModalActions";
import {
  likeContent,
  unlikeContent,
  blockDomain,
  saveContent,
  unsaveContent,
  dismissContent,
  likeCounts
} from "services/news";
import Thumbnail from "./contentItem/Thumbnail";
import Publisher from "./common/Publisher";
import { OnboardingStepsEnum } from "types/Onboarding.types";

function getContentItemDate(content) {
  return getRelativeDate(content.published_date || content.updated_at);
}

const Favicon = ({ url }) => {
  const [hasError, setError] = useState(false);

  if (url && !hasError) {
    return (
      <ItemFavicon
        src={`${url}`}
        hasError={hasError}
        onError={() => setError(true)}
      />
    );
  } else {
    return null;
  }
};

const ContentItem = props => {
  const {
    openModal,
    content,
    trackAnalyticsEvent,
    account,
    currentUser,
    onScheduled,
    onDismiss,
    onSaveChange,
    sourceId,
    isNew = false,
    showDescription = false,
    enableDismiss = true,
    variant = "regular",
    tooltipPlacement = "top",
    viewOnly
  } = props;
  const {
    id,
    title,
    description,
    article,
    domain,
    favicon,
    logo,
    url,
    thumbnail,
    publisher,
    captions = [],
    computed
  } = content;

  const [showPreview, setShowPreview] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [wasSaved, setWasSaved] = useState(!!computed?.isSaved);
  const [wasLiked, setWasLiked] = useState(!!computed?.isLiked);
  const [isDismissVisible, setDismissVisible] = useState(false);
  const { openComposer } = useComposer();

  const [onHover, setOnHover] = useState(false);

  const [wasPosted, setWasPosted] = useState(computed?.isPosted || false);

  const addToast = useToaster();
  const history = useHistory();

  function handleScheduleClick() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    handleArticlePreview(false);
    openComposer({
      account,
      onPosted: post => {
        setIsChanging(false);
        onScheduled(post);
        setWasPosted(true);

        trackAnalyticsEvent("Scheduled Content");
      },
      composerParams: {
        initialContent: `${title.trim()} ${url}`,
        initialContentTypeId: "educational",
        contentTypeLabel: "News Article",
        initialScheduleTime: query.date
          ? moment.tz(query.date, account.timezone)
          : undefined,
        initialChannelIds: query.channelIds ? query.channelIds : undefined,
        content,
        target: "schedule",
        contentId: id,
        captions: [title.trim(), ...captions]
      }
    });
  }

  const handleArticlePreview = status => {
    document.body.style.overflow = status ? "hidden" : "unset";
    setShowPreview(status);
  };

  async function handleDismiss(event, reason, details) {
    setIsChanging(true);
    try {
      if (reason === "mute_domain") {
        await blockDomain({
          domain: details.domain,
          name: details.name,
          image: details.image
        });
        onDismiss(content.id, details.domain);
      } else {
        await dismissContent(content.id, {
          data: {
            reason: reason,
            dismissed_by: currentUser.id,
            details: details
          }
        });
        onDismiss(content.id);
      }

      trackAnalyticsEvent("Dismissed Content", {
        id,
        domain,
        dismiss_reason: reason
      });
      handleArticlePreview(false);
      setDismissVisible(false);
      setIsChanging(false);
      addToast("Thanks. We'll tune your recommendations", "info");
    } catch (e) {
      setDismissVisible(false);
      setIsChanging(false);
    }
  }

  const handleSaveClick = async () => {
    const savedArticlesPath = `/accounts/${account.id}/content/saved-for-later`;
    try {
      if (wasSaved) {
        setWasSaved(false);
        await unsaveContent(content.id);
        if (onSaveChange) onSaveChange(false, id);
      } else {
        setWasSaved(true);
        await saveContent(content.id);

        if (onSaveChange) onSaveChange(true, id);

        addToast("Added to favorites", "success", {
          label: "View",
          action: () => history.push(savedArticlesPath)
        });
      }
    } catch (err) {
      setWasSaved(wasSaved);
    }
  };

  const handleLikeContent = async () => {
    setWasLiked(!wasLiked);
    try {
      if (wasLiked) {
        await unlikeContent(id);
      } else {
        await likeContent(id);
        if (
          !account.onboardingProgress.includes(OnboardingStepsEnum.LIKE_ARTICLE)
        ) {
          likeCounts().then(counts => {
            if (counts.length >= 3) {
              openModal("ONBOARDING_INFO_MODAL", {
                id: OnboardingStepsEnum.LIKE_ARTICLE,
                triggeredBy: OnboardingStepsEnum.LIKE_ARTICLE
              });
            }
          });
        }

        addToast("Thank you! We will tune your recommendations.", "success");
      }
    } catch (_) {
      setWasLiked(!wasLiked);
    }
  };

  const navigateToDomain = () => {
    const { domain: contentDomain } = computed;

    const domainLink =
      contentDomain && contentDomain.id
        ? `/accounts/${account.id}/content/domains/${contentDomain.id}`
        : null;

    if (domainLink) history.push(domainLink);
  };

  const text = description || article;

  const ContentItemActions = () => (
    <ActionZone isVisible={actionsVisible}>
      <ContentIconButtonGroup px={10} py={10}>
        <Tooltip placement={tooltipPlacement} title="More of this">
          <ContentIconButton
            active={wasLiked}
            disabled={isChanging}
            onClick={handleLikeContent}
          >
            <ContentIconButtonIcon
              className={`${wasLiked ? "icon-like-filled-24" : "icon-like-24"}`}
            />
          </ContentIconButton>
        </Tooltip>
        {enableDismiss && (
          <Tooltip placement={tooltipPlacement} title="Less of this">
            <ContentIconButton
              onClick={() => setDismissVisible(true)}
              disabled={isChanging}
            >
              <ContentIconButtonIcon className="icon-disliked-24" />
            </ContentIconButton>
          </Tooltip>
        )}
        <Tooltip placement={tooltipPlacement} title="Bookmark">
          <ContentIconButton saved={wasSaved} onClick={handleSaveClick}>
            <ContentIconButtonIcon
              className={
                wasSaved ? `icon-bookmark-filled-24` : `icon-bookmark-24`
              }
            />
          </ContentIconButton>
        </Tooltip>
        <Tooltip placement={tooltipPlacement} title="Reader view">
          <ContentIconButton onClick={() => handleArticlePreview(true)}>
            <span className="icon icon-read" />
          </ContentIconButton>
        </Tooltip>
      </ContentIconButtonGroup>
      <Flex justifyContent="center">
        <ContentScheduleButton
          onClick={handleScheduleClick}
          disabled={isChanging}
        >
          <span className="icon-send-filled" />
          Post now
        </ContentScheduleButton>
      </Flex>
      <Flex py={20} />
    </ActionZone>
  );

  if (variant === "cover")
    return (
      <ContentItemWrapper>
        {isDismissVisible ? (
          <ContentDismissReasons
            domain={domain}
            image={favicon || logo}
            name={publisher}
            topic={null}
            onSubmit={handleDismiss}
            onCancel={() => setDismissVisible(false)}
          />
        ) : (
          <ContentItemCover
            onMouseEnter={() => setActionsVisible(true)}
            onMouseLeave={() => setActionsVisible(false)}
            thumbnail={thumbnail}
          >
            {!viewOnly && <ContentItemActions />}
            <SuggestedItemContent variant="cover">
              <Flex paddingY={20}>
                <Favicon url={favicon || logo} />
                <CoverDomain>
                  {!publisher ? domain : publisher} ·{" "}
                  {getContentItemDate(content)}
                </CoverDomain>
              </Flex>
              <CoverTitle href={url} rel={"nofollow"} target={"_blank"}>
                {title}
              </CoverTitle>
            </SuggestedItemContent>
          </ContentItemCover>
        )}
      </ContentItemWrapper>
    );
  else if (props.orientation === "landscape") {
    return (
      <ContentItemWrapper orientation={"landscape"} isChanging={isChanging}>
        {isDismissVisible ? (
          <ContentDismissReasons
            domain={domain}
            image={favicon || logo}
            name={publisher}
            topic={null}
            onSubmit={handleDismiss}
            onCancel={() => setDismissVisible(false)}
          />
        ) : (
          <>
            <Thumbnail
              src={thumbnail}
              size={variant === "regular" ? "md" : "sm"}
              onMouseEnter={() => setActionsVisible(true)}
              onMouseLeave={() => setActionsVisible(false)}
            >
              {!viewOnly && <ContentItemActions />}
            </Thumbnail>
            <SuggestedItemContent orientation={"landscape"}>
              <Flex flexDirection={"column"}>
                <Flex>
                  <Favicon url={favicon || logo} />
                  <ItemDomain>
                    <Publisher
                      hasDomain={computed && computed?.domain}
                      text={!publisher ? domain : publisher}
                      onClick={navigateToDomain}
                    />
                    <ContentItemDateWrapper>
                      {getContentItemDate(content, sourceId)}
                    </ContentItemDateWrapper>
                  </ItemDomain>
                </Flex>
                <ItemTitle
                  size={variant === "regular" ? "md" : "sm"}
                  href={url}
                  rel={"nofollow"}
                  target={"_blank"}
                  showDescription={showDescription}
                >
                  {isNew && <ItemNewDot />} {title}
                </ItemTitle>
                {showDescription && <ItemText>{text}</ItemText>}
              </Flex>
              <ContentItemFooter
                id={id}
                orientation={props.orientation}
                viewOnly={viewOnly}
                wasSaved={wasSaved}
                wasLiked={wasLiked}
                wasPosted={wasPosted}
                onTagClickNavigateTo={""}
                handleDismiss={handleDismiss}
                handleSaveClick={handleSaveClick}
                handleLikeContent={handleLikeContent}
                handleScheduleClick={handleScheduleClick}
                date={getContentItemDate(content, sourceId)}
                handlePreviewArticle={() => setShowPreview(true)}
                predicted_tags={
                  computed?.topics?.map(topic => ({
                    id: topic.id,
                    title: topic.title,
                    lang: topic.language
                  })) ?? []
                }
              />
            </SuggestedItemContent>
            {showPreview && (
              <ArticlePreviewModal
                content={content}
                onSchedule={() => handleScheduleClick()}
                onDismiss={handleDismiss}
                onSave={handleSaveClick}
                saved={wasSaved}
                closeModal={() => handleArticlePreview(false)}
              />
            )}
          </>
        )}
      </ContentItemWrapper>
    );
  } else
    return (
      <ContentItemWrapper
        orientation={props.orientation ?? "portrait"}
        isChanging={isChanging}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        {isDismissVisible ? (
          <ContentDismissReasons
            domain={domain}
            image={favicon || logo}
            name={publisher}
            topic={null}
            onSubmit={handleDismiss}
            onCancel={() => setDismissVisible(false)}
          />
        ) : (
          <>
            <Flex flexDirection={"column"} justifyContent={"flex-start"}>
              <Thumbnail
                src={thumbnail}
                size={variant === "regular" ? "md" : "sm"}
                thumbnailHeight={props.thumbnailHeight}
                onMouseEnter={() => setActionsVisible(true)}
                onMouseLeave={() => setActionsVisible(false)}
              >
                {!viewOnly && <ContentItemActions />}
              </Thumbnail>
              <SuggestedItemContent>
                <Flex>
                  <Favicon url={favicon || logo} />
                  <ItemDomain>
                    <Publisher
                      hasDomain={computed && computed?.domain}
                      text={!publisher ? domain : publisher}
                      onClick={navigateToDomain}
                    />
                    <ContentItemDateWrapper>
                      {getContentItemDate(content, sourceId)}
                    </ContentItemDateWrapper>
                  </ItemDomain>
                </Flex>
                <ItemTitle
                  size={variant === "regular" ? "md" : "sm"}
                  href={url}
                  rel={"nofollow"}
                  target={"_blank"}
                  showDescription={showDescription}
                >
                  {isNew && <ItemNewDot />} {title}
                </ItemTitle>
                {showDescription && <ItemText>{text}</ItemText>}
              </SuggestedItemContent>
            </Flex>
            <ContentItemFooter
              id={id}
              onHover={onHover}
              viewOnly={viewOnly}
              wasSaved={wasSaved}
              wasLiked={wasLiked}
              wasPosted={wasPosted}
              onTagClickNavigateTo={""}
              handleDismiss={handleDismiss}
              handleSaveClick={handleSaveClick}
              handleLikeContent={handleLikeContent}
              handleScheduleClick={handleScheduleClick}
              handlePreviewArticle={() => setShowPreview(true)}
              date={getContentItemDate(content, sourceId)}
              predicted_tags={
                computed?.topics?.map(topic => ({
                  id: topic.id,
                  title: topic.title,
                  lang: topic.language
                })) ?? []
              }
            />
            {showPreview && (
              <ArticlePreviewModal
                content={content}
                onSchedule={() => handleScheduleClick()}
                onDismiss={handleDismiss}
                onSave={handleSaveClick}
                saved={wasSaved}
                closeModal={() => handleArticlePreview(false)}
              />
            )}
          </>
        )}
      </ContentItemWrapper>
    );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal,
  trackAnalyticsEvent: analyticsActions.trackAnalyticsEvent
})(ContentItem);
