import React, { useState } from "react";
import { useSelector } from "react-redux";
import CalendarEntry from "./CalendarEntry";

import {
  CalendarDayColumn,
  CalendarContent,
  CalendarScheduleList
} from "./styles";
import ColumnContextMenu from "./ColumnContextMenu";

import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";
import { PostStatus } from "../types/postStatus";

const DayColumn = ({
  day,
  handleCreateTask,
  handleAddEntry,
  handleAddDraftEntry,
  handleWriteDraft,
  handleEditEntry,
  onDeleted,
  refreshActivities,
  handleWritePost,
  isAddNewButtonVisible,
  height
}) => {
  const account = useSelector(state => state.account.data);
  const [showAddNewBtnOnHover, setShowAddNewBtnOnHover] = useState(false);

  const [showContentMenu, setShowContextMenu] = useState(false);
  const toggleShowContextMenu = () => setShowContextMenu(!showContentMenu);

  return (
    <CalendarDayColumn
      isWeekend={day.isWeekend}
      blank={day.entries.length < 1}
      key={day.date.toISOString()}
      onMouseEnter={() => setShowAddNewBtnOnHover(true)}
      onMouseLeave={() => {
        setShowAddNewBtnOnHover(false);
        setShowContextMenu(false);
      }}
    >
      <CalendarContent
        isWeekend={day.isWeekend}
        isBeforeToday={day.isBeforeToday}
        isBlank={day.entries.length < 1}
      >
        <CalendarScheduleList>
          {day.entries.map((entry, index) => {
            return (
              <DraggableWrapper
                id={`${entry.type}/${entry.cardId}`}
                key={entry.id}
                index={index}
                isDisabled={entry.post?.status === PostStatus.SENT}
              >
                <CalendarEntry
                  accountId={account.id}
                  key={entry.id}
                  addEntry={handleAddEntry}
                  addDraftEntry={handleAddDraftEntry}
                  editEntry={handleEditEntry}
                  onDeleted={onDeleted}
                  entry={entry}
                  reload={refreshActivities}
                  height={height}
                />
              </DraggableWrapper>
            );
          })}
          <ColumnContextMenu
            day={day}
            handleCreateTask={handleCreateTask}
            handleWritePost={handleWritePost}
            handleWriteDraft={handleWriteDraft}
            showContentMenu={showContentMenu}
            toggleShowContextMenu={toggleShowContextMenu}
            isVisible={showAddNewBtnOnHover && isAddNewButtonVisible}
          />
        </CalendarScheduleList>
      </CalendarContent>
    </CalendarDayColumn>
  );
};

export default DayColumn;
