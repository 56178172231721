import React, { useState } from "react";
import { CalendarEntry as Entry } from "./styles";

import CalendarTask from "./CalendarTask";
import CalendarPost from "./CalendarPost";

function CalendarEntry({
  accountId,
  entry,
  editEntry,
  addEntry,
  addDraftEntry,
  onDeleted,
  reload,
  height,
  maxWidth = "",
  contextWithPortal = true,
  parent = null,
  autoHeight = false,
  dashboardEntry = false
}) {
  const [hover, setHover] = useState(false);

  return (
    <Entry
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      height={height}
      maxWidth={maxWidth}
    >
      {entry.post ? (
        <CalendarPost
          hover={hover}
          entry={entry}
          editEntry={editEntry}
          onDeleted={onDeleted}
          contextWithPortal={contextWithPortal}
          reload={reload}
          parent={parent}
          autoHeight={autoHeight}
          dashboardEntry={dashboardEntry}
        />
      ) : (
        <CalendarTask
          accountId={accountId}
          hover={hover}
          entry={entry}
          addEntry={addEntry}
          addDraftEntry={addDraftEntry}
          reload={reload}
        />
      )}
    </Entry>
  );
}

export default CalendarEntry;
