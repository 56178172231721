import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../state/actions/ModalActions";
import contentTypes from "../components/common/contentTypes/data/content-types";

function useComposer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const openComposer = ({
    account,
    task,
    onPosted,
    onDeleted,
    composerParams,
    post
  }) => {
    let params = {
      account: account,
      onPosted: onPosted,
      onDeleted: onDeleted
    };

    if (post) {
      params.post = post;

      const queryParams = new URLSearchParams();
      let paramKey = "postId";
      if (post.isPostGroup) {
        paramKey = "postGroupId";
      } else if (post.isDraft) {
        paramKey = "draftId";
      }

      queryParams.set(paramKey, post.id);
      history.push({
        search: queryParams.toString()
      });
    }

    if (task) {
      // slots may keep references to deleted channels
      const activeChannelIds = task.channels
        .filter(({ id: channelId }) => {
          return !!account.channels.find(channel => channel.id === channelId);
        })
        .map(c => c.id);

      params.initialScheduleTime = task.date;
      params.initialContentTypeId = task.contentTypeId;
      params.contentTypeLabel = contentTypes[task.contentTypeId]?.title;
      params.postIdea = task.postIdea;
      params.initialChannelIds = activeChannelIds;
      params.taskId = task.id;
    }

    dispatch(openModal("COMPOSER_MODAL", { ...params, ...composerParams }));
  };
  return { openComposer };
}

export default useComposer;
