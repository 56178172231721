import { SERVICES } from "constants/services";
import { POST_STATUS, ATTACHMENT_TYPES } from "constants/post";

export const constructInitialPostState = (post, account, initData = {}) => {
  const {
    taskId,
    postIdea,
    contentId,
    initialContent,
    contentTypeLabel,
    initialChannelIds,
    initialContentTypeId
  } = initData;

  let postData;
  if (post && !post?.isDraft && !post?.isPostGroup) {
    const caption = {
      all: post.caption,
      [post.channel.service]: post.caption
    };
    postData = Object.assign(
      {},
      post,
      { caption },
      {
        channels: [post.channel.id],
        status: post.status ? post.status : POST_STATUS.SCHEDULED
      }
    );
  } else if (post && (post?.isDraft || post?.isPostGroup)) {
    // compose an object to accomodate draft and post group objects
    const all =
      post.caption.all ??
      Object.values(post.caption).sort((a, b) => {
        return b.length - a.length;
      })[0];
    postData = Object.assign(
      {},
      post,
      { caption: { ...post.caption, all } },
      {
        channels: post.channels.map(channel => channel.id),
        status: post.status ? post.status : POST_STATUS.SCHEDULED
      }
    );
  } else {
    const linkedinProfileChannelCount = account.channels.filter(
      c => c.service === "linkedin" && c.serviceType === "profile"
    ).length;

    postData = {
      channels:
        initialChannelIds ||
        account.channels
          .filter(channel => channel.service !== SERVICES.INSTAGRAM)
          .filter(c => {
            return !(
              linkedinProfileChannelCount > 1 &&
              c.service === SERVICES.LINKEDIN &&
              c.serviceType === "profile"
            );
          })
          .map(channel => channel.id),
      caption: {
        all: initialContent ? initialContent : ""
      },
      status: POST_STATUS.SCHEDULED,
      contentId: contentId,
      contentTypeId: initialContentTypeId || "educational",
      contentTypeLabel: contentTypeLabel,
      postIdea: postIdea || null,
      taskId: taskId
    };
  }

  return postData;
};
