// @ts-nocheck
import _ from "lodash";
import { Flex } from "rebass";
import moment from "moment-timezone";
import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { X } from "@styled-icons/boxicons-regular";
import { useToaster } from "@hellocontento/maillard";
import {
  DraftsWrapper,
  DraftFooterWrapper,
  DraftHeaderWrapper,
  DraftHeader,
  SubHeadline,
  DraftBodyWrapper
} from "./styles";
import { IAccount } from "@hellocontento/contento-common";

import useComposer from "utils/useComposer";
import { useContentoApi } from "utils/useContentoApi";
import * as activityActions from "state/actions/ActivityActions";
import * as draftActions from "state/actions/DraftActions";

import Button from "components/common/Button";

import CalendarEntry from "components/schedule/calendar/CalendarEntry";
import { Headline5 } from "components/common/styles";
import IconButton from "../common/IconButton";
import EmptyCard from "components/settings/EmptyCard";
import Loader from "components/common/loading/Loader";

interface IDraftPanel extends RouteComponentProps {
  onClose: () => void;
  activities: any;
  account: IAccount;
  draftCount: number;
  fetchCount: () => void;
  saveActivities: (data: any) => void;
  activityParams: any;
  saveDrafts: (data: any) => void;
  drafts: Array<any>;
}

const DraftPanel = ({
  onClose,
  account,
  draftCount = 0,
  fetchCount,
  location,
  saveActivities,
  activityParams,
  saveDrafts,
  drafts
}: IDraftPanel): JSX.Element => {
  const { openComposer } = useComposer();
  const addToast = useToaster();
  const [isFetching, setIsFetching] = useState(false);

  const [fetchDrafts] = useContentoApi(`/accounts/${account.id}/draft-posts`);
  const [removeDraft] = useContentoApi(
    `accounts/${account.id}/draft-posts`,
    "delete"
  );
  const [fetchActivities] = useContentoApi(
    `/accounts/${account.id}/activities/calendar`
  );

  const reloadActivities = () => {
    fetchActivities({ params: activityParams }).then((activities: any) => {
      saveActivities({
        data: activities,
        params: activityParams
      });
    });
  };

  const isSchedulePage = location.pathname.includes("schedule");

  const refreshDrafts = useCallback(() => {
    setIsFetching(true);
    fetchDrafts()
      .then((response: any) => {
        const drafts = response.map((draft: any) => {
          return {
            ...draft,
            isDraft: true
          };
        });

        setIsFetching(false);
        saveDrafts(drafts);
      })
      .catch((e: Error) => {
        setIsFetching(false);
        addToast("Couldn't get draft posts at the moment", "error");
      });
  }, [fetchDrafts, addToast, saveDrafts]);

  useEffect(() => {
    refreshDrafts();
  }, [refreshDrafts]);

  const momentTZ = useCallback(
    date => {
      return moment.tz(date, account.timezone);
    },
    [account.timezone]
  );

  const formatDate = date => {
    const diff =
      moment()
        .add(1, "d")
        .date() - date.date();
    const prefix =
      diff === 1 ? "Today" : diff === 0 ? "Tomorrow" : date.format("MMM M");

    return `${prefix} at ${date.format("HH:mm")}`;
  };

  const entries: any = [];

  drafts.forEach((post: any) => {
    let date =
      !post.postedAt && !post.scheduledAt
        ? null
        : post.status === "SENT"
        ? momentTZ(post.postedAt)
        : momentTZ(post.scheduledAt);
    let picture;
    if (post.attachment) {
      if (post.attachment.type === "photo") {
        picture = _.isArray(post.attachment.url)
          ? post.attachment.url[0]
          : post.attachment.url;
      } else if (post.attachment.type === "article") {
        picture = post.attachment.image;
      }
    }

    entries.push({
      id: `post-${post.id}`,
      dateTime: date,
      time: !!date ? formatDate(date) : null,
      channels: post.isDraft ? post.channels : [post.channel],
      text: post.caption[Object.keys(post.caption)[0]],
      picture: picture,
      title: post.attachment ? post.attachment.title : undefined,
      post: post
    });
  });

  const handleEditDraft = (entry: any) => {
    openComposer({
      account: account,
      post: entry.post
    });
  };

  const handleDeleteDraft = (entry: any) => {
    // delete draft by id
    removeDraft({ url: entry.id })
      .then((response: any) => {
        refreshDrafts();
        fetchCount();
        if (isSchedulePage) {
          reloadActivities();
        }
        addToast("Draft deleted successfully", "success");
      })
      .catch((e: Error) => {
        addToast("Unable to deleted draft", "error");
      });
  };

  const handleWriteDraft = () => {
    openComposer({
      account,
      composerParams: {
        isDraft: true
      }
    });
  };

  return (
    <DraftsWrapper>
      <DraftHeaderWrapper>
        <DraftHeader>
          <Headline5>
            Draft
            <SubHeadline>{draftCount}</SubHeadline>
          </Headline5>
          <IconButton
            variant={"secondary"}
            size={"sm"}
            onClick={onClose}
            icon={X}
          />
        </DraftHeader>
      </DraftHeaderWrapper>
      {isFetching ? (
        <Loader location={"center"} />
      ) : entries.length === 0 ? (
        <EmptyCard
          title="No drafts created yet"
          message="This is the place where you will find all your ideas and drafts."
          support="Write a draft"
          action={handleWriteDraft}
          noBgStyle
          center
        />
      ) : (
        <DraftBodyWrapper>
          {entries.map((entry: any) => {
            return (
              <CalendarEntry
                accountId={account.id}
                key={entry.id}
                addEntry={() => {}}
                addDraftEntry={() => {}}
                editEntry={() => handleEditDraft(entry)}
                onDeleted={() => handleDeleteDraft(entry.post)}
                entry={entry}
                reload={refreshDrafts}
                height={"278px"}
                maxWidth={"298px"}
                contextWithPortal={true}
                parent={"draft-panel"}
              />
            );
          })}
        </DraftBodyWrapper>
      )}
      <DraftFooterWrapper>
        <Button
          variant="primary"
          width={"100%"}
          size={"m"}
          onClick={handleWriteDraft}
        >
          <Flex justifyContent="center" alignItems="center">
            Write Draft
          </Flex>
        </Button>
      </DraftFooterWrapper>
    </DraftsWrapper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account.data,
    drafts: state.draft.drafts,
    activityParams: state.activity.params
  };
};

const mapDispatchToProps = {
  saveActivities: activityActions.saveActivities,
  saveDrafts: draftActions.saveDrafts
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftPanel)
);
