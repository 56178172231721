import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import Channel from "./channel";
import { ChannelGrid } from "./styles";

const ChannelSelector = ({
  channels,
  disabled,
  onChannelSelected,
  selectedChannelIds
}) => {
  const handleSelectChannels = selectedChannels => {
    const validationErrors = {
      CHANNEL_EMPTY: selectedChannels.length === 0
    };
    onChannelSelected(selectedChannels, validationErrors);
  };

  const handleChannelToggle = channelId => {
    if (disabled) {
      return null;
    }

    const selectedChannels = selectedChannelIds;
    if (selectedChannels.includes(channelId)) {
      selectedChannels.splice(selectedChannels.indexOf(channelId), 1);
    } else {
      selectedChannels.push(channelId);
    }
    handleSelectChannels(selectedChannels);
  };

  return (
    <ChannelGrid>
      {_.sortBy(channels, [
        a => {
          const serviceOrder = [
            "linkedin business",
            "instagram",
            "facebook",
            "twitter",
            "linkedin profile"
          ];

          return serviceOrder.findIndex(o =>
            `${a.service} ${a.serviceType}`.includes(o)
          );
        }
      ]).map(
        channel =>
          !(disabled && !selectedChannelIds.includes(channel.id)) && (
            <Channel
              key={channel.id}
              channel={channel}
              handleChannelToggle={handleChannelToggle}
              checked={selectedChannelIds.includes(channel.id)}
            />
          )
      )}
    </ChannelGrid>
  );
};

ChannelSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  channels: PropTypes.array.isRequired,
  onChannelSelected: PropTypes.func.isRequired,
  selectedChannelIds: PropTypes.array.isRequired
};

export default ChannelSelector;
