import React from "react";
import { connect } from "react-redux";

import BlogMain from "../../components/blog/BlogMain";
import BlogSide from "../../components/blog/BlogSide";

import {
  BlogPageWrapper,
  BlogSideColumnWrapper,
  BlogMainColumnWrapper
} from "../../components/blog/styles";

class BlogPage extends React.Component {
  render() {
    return (
      <BlogPageWrapper>
        <BlogSideColumnWrapper>
          <BlogSide />
        </BlogSideColumnWrapper>
        <BlogMainColumnWrapper>
          <BlogMain />
        </BlogMainColumnWrapper>
      </BlogPageWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(BlogPage);
