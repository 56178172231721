import IconButton from "components/common/IconButton";
import React, { useContext, useEffect } from "react";
import {
  GridWrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchInput,
  SearchInputRight,
  SearchInputLeft,
  SearchOptions
} from "./styles";

import {
  ArticleSearchContext,
  ILanguageData
} from "contextApi/ArticleSearchContext";

import SearchByLanguage from "../widgets/SearchByLanguage";
import SearchByTopics from "../widgets/SearchByTopics";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "utils/useQueryParams";

interface IArticleSearchBarProps {}

const ArticleSearchBar: React.FC<IArticleSearchBarProps> = () => {
  const ctx = useContext(ArticleSearchContext);
  const history = useHistory();

  const { query } = useQueryParams();

  const setSearchQuery = (value: string) => {
    history.push({
      search: `?query=${value}`
    });
  };

  useEffect(() => {
    ctx.setData({
      ...ctx,
      searchValue: query as string
    });
  }, [query]);

  return (
    <SearchBarWrapper>
      <GridWrapper>
        <SearchInputWrapper>
          <SearchInputLeft>
            <span className="icon-search" />
            <SearchInput
              type={"text"}
              value={(query as string) || ""}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder={"Search for articles"}
            />
          </SearchInputLeft>
          <SearchInputRight>
            <IconButton
              // @ts-ignore
              icon={"icon-cancel"}
              variant={"secondaryQuiet"}
              size={32}
              iconSize={20}
              onClick={() => {
                setSearchQuery("");
              }}
            />
          </SearchInputRight>
        </SearchInputWrapper>
        <SearchOptions items={2}>
          <SearchByLanguage
            selectedLanguages={ctx.selectedLanguages}
            onSetLanguages={(data: Array<ILanguageData>) => {
              ctx.setData({
                ...ctx,
                selectedLanguages: data
              });
            }}
          />
          <SearchByTopics
            selectedTopics={ctx.selectedTopics}
            topics={ctx.topics}
            onSetTopics={(topics: Array<string>) => {
              ctx.setData({
                ...ctx,
                selectedTopics: topics
              });
            }}
          />
        </SearchOptions>
      </GridWrapper>
    </SearchBarWrapper>
  );
};

export default ArticleSearchBar;
