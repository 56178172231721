import React from "react";
import { TooltipOption, TooltipMenu, TooltipMenuWrapper } from "../styles";

import TASK_TYPES from "../constants/taskType";
import Divider from "components/common/Divider";

const TaskOptions = ({
  task,
  handleAddPost,
  handleAddDraftEntry,
  onEditTask,
  handleRemoveTask,
  close,
  isPastTask = false
}) => {
  return (
    <TooltipMenu onClick={close} radius={12}>
      <TooltipMenuWrapper>
        {!isPastTask && (
          <>
            <TooltipOption onClick={handleAddPost} variant="primary">
              <span className="icon-write"></span>
              Write a post
            </TooltipOption>
            <TooltipOption onClick={handleAddDraftEntry}>
              <span className="icon-draft"></span>
              Write draft
            </TooltipOption>
            <Divider my={-6} mx={0} />
          </>
        )}
        <TooltipOption
          onClick={() => {
            onEditTask({ task: task, type: TASK_TYPES.INSTANCE });
          }}
        >
          <span className="icon-edit"></span>
          Edit this task
        </TooltipOption>
        {task.taskGroupId && (
          <TooltipOption
            onClick={() => onEditTask({ task: task, type: TASK_TYPES.SERIES })}
          >
            <span className="icon-edit-series"></span>
            Edit all tasks in this series
          </TooltipOption>
        )}
        <Divider my={-5} mx={0} />
        <TooltipOption onClick={handleRemoveTask} variant="danger">
          <span className="icon-delete"></span>Delete task
        </TooltipOption>
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default TaskOptions;
