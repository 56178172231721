import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentTypeBadge from "../../common/contentTypes/ContentTypeBadge";
import {
  ImageGrid,
  ImageArea,
  AttachmentImage
} from "../common/postAttachmentImage/styles";
import PostAttachmentVideo from "../common/postAttachmentVideo";

import { callApi } from "utils/ContentoApi";
import {
  CalendarEntryFooter,
  CalendarPost as Post,
  CalendarEntryContent,
  CalendarEntryText,
  CalendarEntryArticle,
  CalendarEntryThumbnail,
  CalendarEntryArticleText,
  CalendarEntryArticleSource,
  CalendarEntryArticleSourceIcon
} from "./styles";

import { capitalize } from "utils/string";
import { SERVICES } from "constants/services";
import { openPostOnChannel } from "utils/channels";
import EmptySourceImage from "assets/images/article/empty-source.png";
import EmptyArticleImage from "assets/images/article/empty-article-image.png";

import { POST_STATUS } from "constants/post";
import { ATTACHMENT_TYPES } from "constants/post";

import PostStatistics from "./PostStatistics";
import CalendarEntryActions from "./CalendarEntryActions";
import { useToaster } from "@hellocontento/maillard";
import TaskConfirmationModal from "../form/TaskConfirmationModal";
import CalendarEntryHeader from "./CalendarEntryHeader";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "../common/postContextMenu/PostContextMenu";
import EntryText from "components/schedule/common/entryText";
import { formatForTimeline } from "utils/date";

const minLines = 1;
const maxLines = 10;

function CalendarPostImageGrid({ data, size, rowHeight }) {
  let urls = Array.isArray(data.url) ? data.url : [data.url];

  return (
    <ImageGrid
      gridSize={Math.min(urls.length, 4)}
      rowHeight={rowHeight}
      size={size}
    >
      {urls.map(
        (item, i) =>
          i < 4 && (
            <ImageArea key={item} gridArea={`grid-item-${i + 1}`}>
              <AttachmentImage src={item} alt={"Attached image"} />
            </ImageArea>
          )
      )}
      {urls.length > 4 && (
        <ImageArea overlay gridArea={"grid-item-4"}>
          <span>+{urls.length - 4}</span>
        </ImageArea>
      )}
    </ImageGrid>
  );
}

const CalendarPost = ({
  height,
  entry,
  editEntry,
  onDeleted,
  hover,
  reload,
  contextWithPortal,
  parent,
  autoHeight,
  dashboardEntry
}) => {
  const ref = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const account = useSelector(state => state.account.data);
  const draggingItemId = useSelector(state => state.dragDrop.draggingItemId);
  const hasAttachment = entry.post?.attachment;
  const addToast = useToaster();
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(
    false
  );

  const isDraftPost = entry.post.isDraft;
  const isGroup = entry.channels?.length > 1 && !isDraftPost;

  async function removePost() {
    try {
      const params = entry.post.isPostGroup
        ? {
            method: "delete",
            url: `/accounts/${account.id}/post-groups/${entry.post.id}`
          }
        : entry.post.isDraft
        ? {
            method: "delete",
            url: `/accounts/${account.id}/draft-posts/${entry.post.id}`
          }
        : { method: "delete", url: `/posts/${entry.post.id}` };

      await callApi(params);
      onDeleted(entry.post);
    } catch (err) {
      addToast("Couldn't delete the post.", "error");
    }
  }

  const deletionModalProps = {
    title: `Delete ${isDraftPost ? "Draft" : "Post"}`,
    description: `Are you sure you want to delete this ${
      isDraftPost ? "draft" : "post"
    }?`,
    showOptions: false,
    toggle: () => setConfirmationDialogVisible(false),
    buttonProps: {
      variant: "danger",
      label: "Delete",
      action: () => {
        removePost();
      }
    }
  };

  const publishedPostDeletionModalProps = {
    title: `Delete ${capitalize(entry.channels[0].service)} post`,
    description:
      entry.channels[0].service === SERVICES.INSTAGRAM
        ? `In order to delete this post you need to go to Instagram and delete it there.`
        : `Are you sure you want to delete this post?`,
    showOptions: false,
    toggle: () => setConfirmationDialogVisible(false),
    buttonProps: {
      variant:
        entry.channels[0].service === SERVICES.INSTAGRAM ? `primary` : `danger`,
      label:
        entry.channels[0].service === SERVICES.INSTAGRAM
          ? `Go to Instagram`
          : `Delete`,
      action: () => {
        if (entry.channels[0].service === SERVICES.INSTAGRAM) {
          openPostOnChannel(entry.post);
        } else {
          removePost();
        }
      }
    }
  };

  const isDragging = draggingItemId === entry.post.id;

  const deleteEntry = () => setConfirmationDialogVisible(true);

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const element = ref.current;

    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
      element.parentNode.parentNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
      element.parentNode.parentNode.removeEventListener(
        "contextmenu",
        handleScroll
      );
    };
  }, [contextMenuPos]);

  return (
    <>
      <TaskConfirmationModal
        isOpen={confirmationDialogVisible}
        {...(entry.post.status === "SENT"
          ? publishedPostDeletionModalProps
          : deletionModalProps)}
      />
      <Post
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          editEntry(entry);
        }}
        contentType={entry.post.contentTypeId}
        isGroup={isGroup}
        isPhantom={entry.isPhantom}
        isDragging={isDragging}
        isContextMenuOpen={contextMenuPos.length > 0}
        height={height}
        autoHeight={autoHeight}
      >
        <CalendarEntryHeader
          status={entry.post.isDraft ? "DRAFT" : entry.post.status}
          time={entry.time}
          channels={entry.channels}
          isStuck={entry.post.isStuck}
          parent={parent}
          date={formatForTimeline(
            new Date(entry.post.postedAt || entry.post.scheduledAt),
            true
          )}
          dashboardEntry={dashboardEntry}
        />
        <CalendarEntryContent>
          {entry.text && (
            <>
              {autoHeight ? (
                <EntryText
                  text={entry.text}
                  hasAttachment={hasAttachment}
                  service={entry.displayedService ?? ""}
                />
              ) : (
                <CalendarEntryText
                  maxLines={
                    hasAttachment ? minLines : entry.title ? minLines : maxLines
                  }
                >
                  {entry.text}
                </CalendarEntryText>
              )}
            </>
          )}
          {entry.post.attachment &&
            entry.post.attachment.type === ATTACHMENT_TYPES.PHOTO &&
            !entry.title && (
              <CalendarPostImageGrid
                data={entry.post.attachment}
                rowHeight="6vw"
                size="sm"
              />
            )}
          {entry.post.attachment &&
            entry.post.attachment.type === ATTACHMENT_TYPES.VIDEO && (
              <PostAttachmentVideo
                height={
                  autoHeight ? undefined : entry.channels.length > 3 ? 118 : 136
                }
                attachment={entry.post.attachment}
                videoLess={entry.isPhantom || isDragging}
              />
            )}
          {hasAttachment && entry.title && (
            <CalendarEntryArticle>
              <CalendarEntryThumbnail
                imgSrc={entry.picture || EmptyArticleImage}
              />
              <CalendarEntryArticleText>
                <p>{entry.title}</p>
                <CalendarEntryArticleSource>
                  <CalendarEntryArticleSourceIcon
                    src={entry.post.attachment?.favicon || EmptySourceImage}
                    alt={entry.post.attachment.domain}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = EmptySourceImage;
                    }}
                  />
                  <span>{entry.post.attachment.domain}</span>
                </CalendarEntryArticleSource>
              </CalendarEntryArticleText>
            </CalendarEntryArticle>
          )}
        </CalendarEntryContent>
        <RightClickPostContextMenu
          entry={entry}
          reload={reload}
          closeMenu={closeMenu}
          editEntry={editEntry}
          deleteEntry={deleteEntry}
          contextMenuPos={contextMenuPos}
        />
        <PostContextMenuWithButton
          entry={entry}
          reload={reload}
          editEntry={editEntry}
          deleteEntry={deleteEntry}
          contextWithPortal={contextWithPortal}
        />
        <CalendarEntryFooter rowReverse={entry.post.contentTypeId === "other"}>
          {entry.post.contentTypeId !== "other" && (
            <ContentTypeBadge
              contentTypeId={entry.post.contentTypeId}
              title={entry.post.postIdea?.title || entry.post.contentTypeLabel}
              size="sm"
              variant={"dark-info"}
            />
          )}
          {entry.post?.status === POST_STATUS.SENT ? (
            <PostStatistics stats={entry.post.stats} />
          ) : (
            <CalendarEntryActions
              visible={hover}
              entry={entry}
              editPost={editEntry}
              removePost={deleteEntry}
            />
          )}
        </CalendarEntryFooter>
      </Post>
    </>
  );
};

export default CalendarPost;
