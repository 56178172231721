import React from "react";

import { TooltipOption, TooltipMenu, TooltipMenuWrapper } from "../styles";

const NewActivityOptions = ({
  day,
  handleWritePost,
  handleWriteDraft,
  handleCreateTask,
  close
}) => {
  return (
    <TooltipMenu onClick={close} radius={16}>
      <TooltipMenuWrapper minWidth={180}>
        <TooltipOption
          onClick={() => {
            handleWritePost(day);
          }}
          variant="primary"
        >
          <span className="icon-write"></span>
          Write a post
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            handleWriteDraft({ ...day, isDraft: true });
          }}
        >
          <span className="icon-draft"></span>
          Write draft
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            handleCreateTask(day);
          }}
        >
          <span className="icon-task-circular" />
          Add task
        </TooltipOption>
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default NewActivityOptions;
