import React from "react";
import {
  PostAttachmentImage,
  ImageGrid,
  ImageArea,
  AttachmentImage
} from "./styles";

const MAX_GRID = 4;

function PostAttachmentImageComponent({ attachment, height }) {
  const urls = Array.isArray(attachment.url)
    ? attachment.url
    : [attachment.url];

  return (
    <PostAttachmentImage>
      <ImageGrid
        gridSize={Math.min(urls.length, MAX_GRID)}
        rowHeight={height ? Math.floor(height / 2) : null}
      >
        {urls.map(
          (item, i) =>
            i < MAX_GRID && (
              <ImageArea key={item} gridArea={`grid-item-${i + 1}`}>
                <AttachmentImage src={item} alt={"Attached image"} />
              </ImageArea>
            )
        )}
        {urls.length > MAX_GRID && (
          <ImageArea overlay gridArea={"grid-item-4"}>
            <span>+{urls.length - MAX_GRID}</span>
          </ImageArea>
        )}
      </ImageGrid>
    </PostAttachmentImage>
  );
}

export default PostAttachmentImageComponent;
