import React from "react";
import { withRouter } from "react-router-dom";
import { NavItemList, NavItem, NavItemIcon } from "./styles";
import { useAppState } from "contextApi/appContext";


function MainNavigation({ account, wasClicked }) {

  const {
    clientSettings
  } = useAppState();

  return (
    <NavItemList onClick={wasClicked}>
      <NavItem to={`/accounts/${account.id}/dashboard`}>
        <NavItemIcon className={"header-icon-homepage"} />
        <label>Home</label>
      </NavItem>
      <NavItem to={`/accounts/${account.id}/schedule`}>
        <NavItemIcon className={"header-icon-calendar"} />
        <label>Calendar</label>
      </NavItem>
      <NavItem to={`/accounts/${account.id}/ideas`}>
        <NavItemIcon className={"header-icon-ideas"} />
        <label>Ideas</label>
      </NavItem>
      { clientSettings?.client != 'telenet' && (
        <NavItem to={`/accounts/${account.id}/content`}>
          <NavItemIcon className={"header-icon-news"} />
          <label>News</label>
        </NavItem>
      )}
      
      <NavItem to={`/accounts/${account.id}/analytics`}>
        <NavItemIcon className={"header-icon-analytics"} />
        <label>Analytics</label>
      </NavItem>
    </NavItemList>
  );
}

export default withRouter(MainNavigation);
