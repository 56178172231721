import React from "react";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { required } from "../../utils/Validation";
import TextInput from "../common/form/TextInput";
import { PlainSelect } from "../common/form/Select";
import { Form } from "../common/form/styles";
import { timezones, countries } from "../../constants";
import Button from "../common/Button";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";

const OnboardingThirdForm = ({
  state,
  handleSubmit,
  submitting,
  submitSucceeded
}) => {
  return (
    <Form
      isTransitioned={true}
      state={state}
      onSubmit={handleSubmit}
      id="willow_onboarding_step_2"
      name="willow_onboarding_step_2"
    >
      <div>
        <Field
          component={TextInput}
          id="industry"
          label="Industry"
          name="industry"
          placeholder="e.g., Marketing and Advertising"
          validate={[required]}
        />
        <Field
          component={PlainSelect}
          helperText="This will be used to set the timezone of your calendar"
          id="country"
          key={"country"}
          label="Country"
          name="billingCountry"
          options={countries}
          placeholder="e.g., Belgium"
          validate={[required]}
        />
        <Field
          component={PlainSelect}
          id="timezone"
          helperText="Please specify where exactly you are located"
          key={"timezone"}
          label="Timezone"
          name="timezone"
          options={timezones}
          validate={[required]}
        />
        <Box pt={8} pb={16}>
          <Button
            size="lg"
            disabled={submitting || submitSucceeded}
            width="100%"
          >
            Next <RightArrowAlt size={20} />
          </Button>
        </Box>
      </div>
    </Form>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "onboardingThird",
  enableReinitialize: true
})(OnboardingThirdForm);
