import styled, { css } from "styled-components";

const imageGridAreas = (rowHeight = "120px") => ({
  "grid-1": css`
    grid-template-areas: "grid-item-1";
    grid-auto-rows: calc(2 * ${rowHeight});
  `,
  "grid-2": css`
    grid-template-areas: "grid-item-1 grid-item-2";
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: calc(2 * ${rowHeight});
  `,
  "grid-3": css`
    grid-template-areas: "grid-item-1 grid-item-2" "grid-item-1 grid-item-3";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, ${rowHeight});
  `,
  "grid-4": css`
    grid-template-areas: "grid-item-1 grid-item-2" "grid-item-3 grid-item-4";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, ${rowHeight});
  `
});

export interface IImageGrid {
  size: "sm" | "lg";
  rowHeight: string | number;
  gridSize: keyof ReturnType<typeof imageGridAreas>;
}

export const ImageGrid = styled.div<IImageGrid>`
  display: grid;
  grid-gap: ${props => (props.size === "sm" ? "2px" : "4px")};
  grid-template-rows: repeat(1, 280px);
  ${props => {
    const rowHeight = props.rowHeight
      ? typeof props.rowHeight === "string"
        ? props.rowHeight
        : props.rowHeight.toString() + "px"
      : "120px";

    return imageGridAreas(rowHeight)[props.gridSize];
  }}

  ${props =>
    props.size === "sm" &&
    css`
      border-radius: 8px;
      overflow: hidden;
    `}
`;

interface IImageArea {
  gridArea: string;
  overlay?: boolean;
}

export const ImageArea = styled.div<IImageArea>`
  background: ${props => props.theme.colors.grey02};
  display: block;
  width: 100%;
  height: 100%;
  grid-area: ${props => props.gridArea};

  ${props =>
    props.overlay &&
    css`
      align-items: center;
      background: ${props => props.theme.colors.text02};
      display: flex;
      justify-content: center;

      & span {
        color: ${props => props.theme.colors.text05};
        font-size: 20px;
        font-weight: 500;
      }
    `}
`;

export const AttachmentImage = styled.img`
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
`;
