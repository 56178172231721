import { DEVICES } from "theme";
import styled, { css } from "styled-components";
import { Flex, Box } from "rebass/styled-components";

import contentTypes from "../../common/contentTypes/data/content-types";
import { PostContextMenuWrapper } from "../common/postContextMenu/styles";

export const CalendarContainer = styled.div`
  background: white;
`;

export const CalendarHeaderZone = styled(Flex)`
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 64px;
  z-index: 5;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowXS};
`;

export const CalendarMonth = styled.h2`
  color: ${props => props.theme.colors.text01};
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin: 0;

  .week_num {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const CalendarDayColumnsContainer = styled(Box)`
  transition: width 0.3s;
`;

export const CalendarDayRow = styled.div`
  display: grid;
  grid-template-columns: ${props => props.layout};
  grid-gap: 12px;
  padding: 4px 16px;
  position: sticky;
  top: 128px;
  z-index: 2;
  background: ${props => props.theme.colors.white};
`;

export const CalendarDayColumns = styled.div`
  display: grid;
  /* grid-template-columns: repeat(7, calc((100% - 24px) / 7)); */
  grid-template-columns: ${props => props.layout};

  /* grid-template-columns: repeat(7, calc((100% - 24px) / 7)); */
  grid-gap: 12px;
  padding: 0 16px;
  max-width: 100vw;
  overflow: hidden;
`;

export const CalendarDayColumn = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 4px;
  ${props =>
    props.isWeekend && props.blank
      ? css`
          min-height: calc(100vh - 60px);
        `
      : css`
          min-height: calc(100vh - 60px);
        `} /* display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  width: calc(100% / 7); */
`;

export const CalendarDay = styled(Box)`
  align-items: center;
  color: ${props => props.theme.colors.text02};
  display: flex;
  height: 34px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 14px;
  margin: 0 4px 0px;

  strong {
    font-weight: 600;
    color: ${props => props.theme.colors.text01};
    ${props =>
      props.isToday &&
      css`
        background: ${props => props.theme.colors.blue};
        padding: 0px 4px;
        color: ${props => props.theme.colors.white};
        border-radius: 4px;
      `}
  }
`;

export const CalendarAddButton = styled.div`
  border-radius: 6px;
  color: ${props => props.theme.colors.text02};
  cursor: pointer;
  padding: 3px;
  font-size: 10px;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const CalendarAdd = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(17, 17, 17, 0.05), 0 2px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  padding: 6px;
  z-index: 5;
  ${props => {
    if (!props.float) {
      return css`
        margin: 5px;
      `;
    } else {
      return css`
        transform: translate(-24px, 8px);
      `;
    }
  }}
`;

export const CalendarAddOption = styled.div`
  padding: 8px 16px;
  font-weight: 500;
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  &:hover {
    border-radius: 12px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue};
  }
`;

export const CalendarContent = styled(Box)`
  z-index: 0;
  flex-grow: 1;
  border-radius: 16px;

  background: ${props => {
    if (props.isBeforeToday) {
      // return `#FDFDFD`;
      return `linear-gradient(rgba(116, 123, 128, 0.13) 0%, rgba(116, 123, 128, 0.04) 100%) 0% 0% / 100% calc(100vh - 128px) no-repeat`;
      // return `none`;
    } else if (props.isBlank) {
      return `linear-gradient(
    180deg,
    rgba(116, 123, 128, 0.08) 0%,
    rgba(196, 196, 196, 0) 100%
  );`;
    } else {
      // return `#FDFDFD`;
      return `none`;
      // return `linear-gradient(180deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 100%);`;
    }
  }};

  ${props =>
    props.isBlank &&
    css`
      background-size: 100% calc(100vh - 128px);
      background-repeat: no-repeat;
    `}
`;

export const CalendarLoading = styled(Box)`
  position: relative;
  margin-left: 15px;
  display: ${props => (props.loading ? "inline-block" : "none")};
  & > div {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const CalendarScheduleList = styled(Box)`
  width: 100%;
`;

export const CalendarEntry = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.height === "auto" ? "0px" : "8px")};
  height: ${props => props.height};
  min-height: 220px;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};

      @media screen and ${DEVICES.mobileL} {
        max-width: 100%;
      }
    `}
`;

const CalendarEntryBox = styled(Box)`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  height: ${props => (props.autoHeight ? "auto" : "248px")};
  padding: 8px;
  overflow: hidden;
  position: relative;
`;

export const CalendarPost = styled(CalendarEntryBox)`
  box-sizing: border-box;
  background: white;
  border: 1px solid transparent;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    box-shadow: 0px 4px 24px rgba(28, 30, 31, 0.08),
      0px 1px 2px rgba(28, 30, 31, 0.04);

    ${PostContextMenuWrapper} {
      display: flex;
    }
  }

  ${props =>
    props.isGroup &&
    css`
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 8px 0 -4px #ffffff,
        0 9px 2px -3px rgba(0, 0, 0, 0.12), 0 14px 0 -6px #ffffff,
        0 16px 4px -7px rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.13);

      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 8px 0 -4px #ffffff,
          0 9px 2px -3px rgba(0, 0, 0, 0.12), 0 14px 0 -6px #ffffff,
          0 16px 4px -7px rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.26);
      }
    `};

  ${props =>
    props.isDragging &&
    css`
      opacity: 1;
      box-shadow: 0px 4px 24px rgba(28, 30, 31, 0.08),
        0px 1px 2px rgba(28, 30, 31, 0.04);
      border: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    props.isContextMenuOpen &&
    css`
      border: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    props.isPhantom &&
    css`
      opacity: 0.5;
      border: 1px solid ${props => props.theme.colors.black};
    `}
`;

export const CalendarPlaceholder = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 12px 0;

  span {
    color: ${props => props.theme.colors.text01};
    font-size: 14px;
    font-weight: bold;

    svg {
      transform: translateY(-1px);
    }
  }

  p {
    color: ${props => props.theme.colors.text02};
    font-size: 13px;
    max-width: calc(100% - 24px);
    margin: 4px 0;
    line-height: 18px;
    text-align: center;
  }
`;

export const CalendarSlot = styled(CalendarEntryBox)`
  background-color: ${props => contentTypes[props.contentType].bgColor};
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${props => contentTypes[props.contentType].overColor};

    ${PostContextMenuWrapper} {
      display: flex;
    }
  }
  ${props =>
    props.border &&
    css`
      border: ${props => contentTypes[props.contentType].border};
    `}

  justify-content: space-between;

  ${props =>
    props.isPast &&
    css`
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    `}

  ${props =>
    props.isDragging &&
    css`
      opacity: 1;
      border: 1px solid ${props => contentTypes[props.contentType].color};
    `}

  ${props =>
    props.isContextMenuOpen &&
    css`
      border: 1px solid ${props => contentTypes[props.contentType].color};
    `}
    
  ${props =>
    props.isPhantom &&
    css`
      opacity: 0.5;
      border: 1px solid ${props => props.theme.colors.black};
    `}
`;

export const CalendarSlotInfoWrapper = styled(Flex)`
  flex-direction: column;
`;

export const CalendarSlotTitle = styled.span`
  color: ${props => props.theme.colors.text01};
  font-weight: 600;
`;

export const CalendarSlotDescription = styled.span`
  color: ${props => props.theme.colors.text03};
  margin-left: 20px;
  margin-top: 6px;
`;

export const CalendarEntryHeader = styled(Flex)`
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 4px;
  justify-content: space-between;
`;

export const CalendarEntryFooter = styled(Flex)`
  height: 20px;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.rowReverse &&
    css`
      flex-direction: row-reverse;
    `};
`;

export const CalendarHeaderInfoWrapper = styled(Flex)`
  display: inline-flex;
  gap: 7px;
  align-items: center;
  margin-top: 4px;
`;

export const CalendarHeaderTime = styled.span`
  color: ${props =>
    !props.isDraft ? props.theme.colors.text01 : props.theme.colors.orange};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 2px;

  .time {
    color: ${props => props.theme.colors.text03};
  }
`;

export const CalendarEntryChannels = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 50%;

  /* margin-left: auto; */
  & > * {
    margin-left: 1px;
  }
`;

export const CalendarControls = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const CalendarEntryContent = styled(Box)`
  margin: 0 -1px;
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
`;

export const CalendarEntryText = styled.p`
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  max-height: min(${props => props.maxLines * 18 + "px"}, 160px);
  overflow: hidden;
  white-space: break-spaces;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CalendarEntryPicture = styled(Box)`
  background-color: ${props => props.theme.colors.grey02};
  background-image: url("${props => props.imgSrc}");
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  flex: 1;
  object-fit: cover;
  width: 100%;
`;

export const CalendarEntryArticle = styled(Box)`
  border-radius: 8px;
  background-color: rgb(243, 244, 245);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const CalendarEntryThumbnail = styled(Box)`
  border-radius: 8px 8px 0 0;
  background-color: ${props => props.theme.colors.grey01};
  background-size: cover;
  background-image: url("${props => props.imgSrc}");
  display: inline-block;
  width: 100%;
  height: 55vw;
  max-height: 250px;
  background-position: center;
`;

export const CalendarEntryArticleText = styled(Box)`
  padding: 6px 8px;
  flex: 0;
  box-sizing: border-box;

  & p {
    color: ${props => props.theme.colors.text01};
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    margin: 0;
    /* height: 15px; */
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }
`;

export const CalendarEntryArticleSource = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 6px;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.text03};
  span {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const CalendarEntryArticleSourceIcon = styled.img`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const PostStatisticsWrapper = styled(Flex)`
  gap: 8px;
`;

export const PostStatWrapper = styled(Flex)`
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

export const PostStatistic = styled.span`
  font-size: 16px;
  ${props =>
    props.fill &&
    css`
      color: ${props.theme.colors[props.fill]};
    `}
`;

export const PostStatValue = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

export const PostActionsWrapper = styled(Flex)`
  gap: 2px;
`;

export const ChangeWeekButton = styled(Box)`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background: ${props => props.theme.colors.grey01} no-repeat center center;
  margin: 0 4px;
  ${props =>
    props.direction === "next"
      ? css`
          background-image: url(arrowNext);
        `
      : css`
          background-image: url(arrowPrev);
        `};
  background-size: 7px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const TodayButton = styled(Box)`
  border-radius: 24px;
  color: ${props => props.theme.colors.text01};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: 32px;
  padding: 0 16px;
  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
  margin: 0 4px;
`;

export const ControlSeparator = styled(Box)`
  display: inline-block;
  height: 32px;
  width: 1px;
  margin: 0 8px;
`;

export const ChannelList = styled(Box)`
  margin: 0 12px;
  @media only screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    display: none;
  }
`;

export const CalendarDateControl = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const CalendarPeriodChange = styled.div`
  display: flex;
  border-right: 1px solid ${props => props.theme.colors.grey03};
  margin-right: 16px;
  padding-right: 16px;

  &:last-child {
    border-right: none;
    margin-right: none;
  }
`;

export const IconBottonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin: 0px 10px;
`;

export const CreateButton = styled(Flex)`
  color: ${props => props.theme.colors.text03};
  padding: 9px 0px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background: none;
  margin: 8px;
  cursor: pointer;
  &:hover {
    border-radius: 12px;
    background: rgba(96, 116, 128, 0.12);
  }
`;

export const CalendarAddOptionsWrapper = styled.div`
  margin: 8px 8px 0px 8px;
`;
