import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  LeftPanel,
  RightPanel,
  CenterPanel,
  ComposerBody,
  ComposerFooter
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Toolkit from "./components/toolkit";
import { formatForScheduler } from "utils/date";
import Properties from "./components/properties";
import { openModal } from "state/actions/ModalActions";
import Scheduler from "./components/postComposer/scheduler";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import Attachment from "./components/postComposer/attachment";
import ComposerModal from "components/modals/composerV2Modal";
import { TimeDisplay } from "components/modals/composerV2Modal/styles";
import PostComposer from "components/composerv2/components/postComposer";
import AttachmentTools from "./components/postComposer/attachment/AttachmentToolbar";

const Composer = () => {
  const {
    isComposerOpen,
    inProMode,
    inDraftMode,
    postData,
    attachments
  } = useComposerState();
  const channelAttachments = attachments["all"]; // ? use channel as param when splitting attachments wrt channels
  const { closeComposer, toggleProMode } = useComposerActions();
  const account = useSelector(state => state.account.data);
  const reduxDispatch = useDispatch();

  const handlePosted = res => {
    const post = (res.posts ? res.posts[0] : undefined) || res.post || res;

    try {
      if (!res.isDraft) {
        reduxDispatch(
          openModal("ONBOARDING_INFO_MODAL", {
            id: OnboardingStepsEnum.CREATE_POST,
            variant: "success",
            title: res.isPostedNow
              ? "Your post has been published."
              : "Your post has been scheduled.",
            subTitle: !res.isPostedNow ? (
              <>
                Your post has been scheduled for <br />
                <TimeDisplay>
                  {formatForScheduler(new Date(post.scheduledAt.split(".")[0]))}
                </TimeDisplay>
              </>
            ) : (
              "Your post has been sent and will appear on your social media."
            ),
            forOnboarding: !account?.onboardingProgress.includes(
              OnboardingStepsEnum.CREATE_POST
            ),
            ...(!account?.onboardingProgress.includes(
              OnboardingStepsEnum.CREATE_POST
            )
              ? { triggeredBy: OnboardingStepsEnum.CREATE_POST }
              : { secondaryAction: false })
          })
        );
      }
    } catch (_) {}
  };

  const title = `${postData.id ? "Edit" : "New"} ${
    inDraftMode ? "Draft" : "Post"
  }`;

  return (
    <ComposerModal
      title={title}
      proMode={inProMode}
      onClose={closeComposer}
      isOpen={isComposerOpen}
      toggleProMode={toggleProMode}
    >
      {isComposerOpen && (
        <ComposerBody>
          {inProMode && (
            <LeftPanel>
              <Properties />
              <Scheduler />
            </LeftPanel>
          )}
          <CenterPanel inProMode={inProMode}>
            <PostComposer />
            {inProMode && channelAttachments.type !== null && <Attachment />}

            {!inProMode && (
              <ComposerFooter>
                <AttachmentTools showBorder={true} />

                <Scheduler />
              </ComposerFooter>
            )}
          </CenterPanel>
          {inProMode && (
            <RightPanel>
              <Toolkit />
            </RightPanel>
          )}
        </ComposerBody>
      )}
    </ComposerModal>
  );
};

export default Composer;
