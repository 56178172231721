import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { pick } from "lodash";
import { useToaster } from "@hellocontento/maillard";
import { callApi } from "utils/ContentoApi";
import { EnclosedContent } from "components/common/styles";
import ReactLoading from "react-loading";
import { BigCheckBoxGroup } from "components/common/form/BigCheckbox";
import CTAFormIcon from "assets/images/cta-form.png";
import CTAButtonIcon from "assets/images/cta-button.png";
import CTAPreview from "components/marketingPlan/CTAPreview";
import {
  SettingsTitle,
  SettingsIcon,
  SettingsDescription,
  SettingsHeader,
  SettingsSection,
  SettingsSubtitle,
  SettingsBrick,
  StyledForm,
  CTAPreviewWrapper,
  CTASettingsWrapper
} from "./styles";
import ctaIcon from "assets/images/icons-settings-call-to-action.png";
import { required, validEmail, validUrl } from "../../utils/Validation";
import TextAreaInput from "components/common/form/TextAreaInput";
import TextInput from "components/common/form/TextInput";
import Button from "components/common/Button";
import Switch from "components/common/Switch";
import { Flex } from "rebass/styled-components";
import {
  Field,
  formValueSelector,
  reduxForm,
  submit,
  change,
  reset,
  isDirty,
  isPristine
} from "redux-form";

const selector = formValueSelector("ctaForm");

const CTAForm = connect(state => {
  return {
    ctaType: selector(state, "ctaType")
  };
})(
  reduxForm({
    form: "ctaForm",
    enableReinitialize: true
  })(({ handleSubmit, ctaType, initialValues }) => {
    return (
      <StyledForm onSubmit={handleSubmit}>
        <SettingsSection>
          <SettingsSubtitle>Company information</SettingsSubtitle>
          <SettingsBrick pb={24}>
            <Field
              label="Company Name"
              component={TextInput}
              name="companyName"
              validate={[required]}
              defaultValue={initialValues.companyName}
            />
          </SettingsBrick>
        </SettingsSection>

        <SettingsSection>
          <SettingsSubtitle>Call-to-action</SettingsSubtitle>
          <SettingsBrick>
            <Field
              validate={[required]}
              label="Headline"
              component={TextInput}
              name="ctaTitle"
            />
            <Field
              type="Description"
              validate={[required]}
              label="Description"
              component={TextAreaInput}
              name="ctaContent"
            />
            <Field
              validate={[required]}
              label="Button Text"
              component={TextInput}
              name="ctaButtonText"
            />
            {ctaType === "button" && (
              <Field
                validate={[validUrl]}
                label="Button Link"
                component={TextInput}
                name="ctaButtonLink"
              />
            )}
            {ctaType === "form" && (
              <Field
                validate={[required, validEmail]}
                label="Send form submissions to"
                component={TextInput}
                name="ctaLeadEmail"
                placeholder="your@email.com"
              />
            )}
          </SettingsBrick>
        </SettingsSection>

        <Flex mt={-24} mb={24} justifyContent="center">
          <Button
            variant="primary"
            size="lg"
            onClick={() => handleSubmit("ctaForm")}
          >
            Save Changes
          </Button>
        </Flex>
      </StyledForm>
    );
  })
);

const CallToAction = ({ account, ctaValues, changeFormValue, isDirty }) => {
  const [loading, setLoading] = useState(false);
  const addToast = useToaster();
  const [cta, setCTA] = useState({
    enabled: false,
    ctaType: "form",
    companyName: account.companyName,
    companyLogo: account.logo,
    ctaTitle: "",
    ctaContent: "",
    ctaButtonText: "Free consultation"
  });

  const getNewCta = useCallback(
    cta => {
      return {
        ...cta,
        companyLogo: account.logo,
        companyName:
          cta.companyName === "" ? account.companyName : cta.companyName,
        ctaButtonLink:
          cta.ctaButtonLink === "" ? account.companyWebsite : cta.ctaButtonLink
      };
    },
    [account.logo, account.companyName, account.companyWebsite]
  );

  // initial CTA load
  useEffect(() => {
    callApi({
      url: `/accounts/${account.id}/cta`
    })
      .then(cta => {
        setLoading(false);
        if (cta) {
          const newCta = { ...cta, companyLogo: account.logo };
          if (newCta.companyName === "")
            newCta.companyName = account.companyName;
          setCTA(getNewCta(newCta));
        }
      })
      .catch(() => {
        addToast("Could not load CTA. Please try again later.", "error");
      });
  }, [account.id, account.logo, account.companyName, addToast, getNewCta]);

  // Saves the changes on the cta object
  const updateCTA = values => {
    callApi({
      method: "put",
      url: `/accounts/${account.id}/cta`,
      data: { ...values, ...(!cta.createdAt && { enabled: true }) } //when it's the first save, enable CTA
    })
      .then(cta => {
        if (cta) {
          setCTA(cta);
        }
        // onCompleted("callToAction", true);
        if (values.hasOwnProperty("enabled") && values.enabled) {
          addToast("Call-to-action enabled", "success");
        } else if (values.hasOwnProperty("enabled") && !values.enabled) {
          addToast("Call-to-action disabled", "success");
        } else {
          addToast("Call-to-action updated", "success");
        }
      })
      .catch(error => {
        addToast(error.message, "error");
      });
  };

  const handleCTASwitch = value => {
    setCTA({ ...cta, enabled: value });
    updateCTA({ enabled: value });
  };

  if (!account.features.includes("cta")) {
    return null;
  }

  if (loading) {
    return (
      <>
        <SettingsTitle>Call-to-action</SettingsTitle>
        <ReactLoading color={"#bbb"} type={"cylon"} />
      </>
    );
  }

  return (
    <>
      <Prompt
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <EnclosedContent size="sm">
        <SettingsHeader>
          <SettingsIcon src={ctaIcon} />
          <SettingsTitle>
            Call-to-action
            <Switch
              checked={cta.enabled !== undefined ? cta.enabled : false}
              wrap={"false"}
              size={18}
              onChange={value => handleCTASwitch(value)}
            />
          </SettingsTitle>
          <SettingsDescription size={"sm"}>
            Add a pop-up to links you share. Note that this does not work on
            every link. You’ll be warned before you do.
          </SettingsDescription>
        </SettingsHeader>

        <CTASettingsWrapper isEnabled={cta.enabled}>
          <SettingsSection>
            <SettingsSubtitle>Format</SettingsSubtitle>
            <BigCheckBoxGroup
              name="ctaType"
              options={[
                {
                  value: "form",
                  title: "Form",
                  icon: CTAFormIcon
                },
                {
                  value: "button",
                  title: "Button",
                  icon: CTAButtonIcon
                }
              ]}
              value={ctaValues.ctaType}
              onChange={value => {
                changeFormValue("ctaForm", "ctaType", value);
              }}
            />
          </SettingsSection>

          <CTAForm
            onSubmit={values => {
              return updateCTA({ ...values });
            }}
            initialValues={pick(cta, [
              "ctaType",
              "companyLogo",
              "companyName",
              "ctaTitle",
              "ctaContent",
              "ctaButtonText",
              "ctaButtonLink",
              "ctaLeadEmail"
            ])}
            account={account.companyWebsite}
            companyLogo={account.logo}
          />

          <CTAPreviewWrapper isEnabled={cta.enabled}>
            <CTAPreview cta={ctaValues} />
          </CTAPreviewWrapper>
        </CTASettingsWrapper>
      </EnclosedContent>
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    ctaValues: selector(
      state,
      "ctaType",
      "companyLogo",
      "companyName",
      "ctaTitle",
      "ctaContent",
      "ctaButtonText",
      "ctaButtonLink"
    ),
    isDirty: isDirty("ctaForm")(state),
    pristine: isPristine("ctaForm")(state)
  };
};

export default connect(mapStateToProps, {
  submitForm: submit,
  changeFormValue: change,
  resetForm: reset
})(CallToAction);
