import React from "react";
import { startOfMonth } from "date-fns";
import { getOffsetDate, getFormattedDate } from "./utils/dateUtils";
import ScheduleViewToggle from "./ScheduleViewToggle";
import Button from "../common/Button";
import ChannelToggle from "../common/ChannelToggle";
import IconButton from "../common/IconButton";
import {
  Header,
  HeaderDate,
  HeaderControls,
  CalendarPeriod,
  IconBottonGroup
} from "./styles";

function ScheduleHeader({
  isSidebarVisible,
  today,
  currentDate,
  onChangeDate,
  onAddTask,
  channels,
  selectedChannelIds,
  handleChannelChange
}) {
  const formattedDate = getFormattedDate(currentDate, "MMMM	yyyy");
  const handleChangeMonth = direction => {
    let newDate = getOffsetDate(startOfMonth(currentDate), {
      months: direction === "next" ? 1 : -1
    });
    onChangeDate(newDate);
  };
  const handleTodayClick = () => {
    onChangeDate(today);
  };

  return (
    <Header>
      <CalendarPeriod>
        <Button size={"sm"} variant={"secondary"} onClick={handleTodayClick}>
          Today
        </Button>
        <IconBottonGroup>
          <IconButton
            icon="icon-arrowleft"
            variant={"secondary"}
            size={36}
            iconSize={24}
            iconColor={"#646769"}
            onClick={() => handleChangeMonth("prev")}
          />
          <IconButton
            icon="icon-arrowright"
            variant={"secondary"}
            size={36}
            iconSize={24}
            iconColor={"#646769"}
            onClick={() => handleChangeMonth("next")}
          />
        </IconBottonGroup>
        <HeaderDate>{formattedDate}</HeaderDate>
      </CalendarPeriod>
      <ScheduleViewToggle defaultValue="month" />
      <HeaderControls>
        {channels.length > 0 && (
          <ChannelToggle
            gap={0}
            adjustPosition={true}
            channels={channels}
            selectedIds={selectedChannelIds}
            onSave={handleChannelChange}
            showDropdown={true}
          />
        )}
        {!isSidebarVisible && (
          <Button onClick={() => onAddTask()} isRounded>
            Add tasks
          </Button>
        )}
      </HeaderControls>
    </Header>
  );
}

export default ScheduleHeader;
