import React, { useState } from "react";
import styled from "styled-components";
import Menu, { MenuContainer } from "../../navigation/Menu";
import { ChevronDown } from "styled-icons/boxicons-regular";
import { StyledButton } from "../../common/Button";
import Popper from "components/common/Popper";

const PeriodButton = styled(StyledButton)`
  background: transparent;
  color: ${props => props.theme.colors.text02};
  column-gap: 6px;
  display: grid;
  font-family: ${props => props.theme.fonts.body};
  grid-template-columns: 20px 1fr 20px;
  height: 36px;
  padding: 0 12px;
  width: 140px;
`;

PeriodButton.defaultProps = {
  variant: "secondary"
};

const Icon = styled.span`
  display: inline-block;
  font-size: 20px;
  margin-right: 8px;
  text-align: center;
  width: 20px;
`;

const periodMenuItems = [
  {
    label: "7 days",
    value: 7
  },
  {
    label: "1 month",
    value: 30
  },
  {
    label: "3 months",
    value: 90
  },
  {
    label: "6 months",
    value: 180
  },
  {
    label: "1 year",
    value: 365
  }
];

export default function PeriodSelect({
  selected = periodMenuItems[0].value,
  onSelect
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleToolTipVisibility = () => setTooltipVisible(!tooltipVisible);

  const [referenceElement, setReferenceElement] = useState(null);

  const selectedObj = periodMenuItems.find(v => v.value === selected);

  return (
    <PeriodButton
      ref={setReferenceElement}
      size="sm"
      onClick={toggleToolTipVisibility}
      variant="secondary"
    >
      <Icon className="icon-calendar" /> <span>{selectedObj?.label}</span>
      <ChevronDown size={20} />
      <Popper
        placement={"bottom-start"}
        referenceElement={referenceElement}
        visible={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer minWidth={240}>
          <Menu
            items={periodMenuItems}
            selected={selected}
            onSelect={value => {
              setTooltipVisible(false);
              onSelect(value);
            }}
          />
        </MenuContainer>
      </Popper>
    </PeriodButton>
  );
}
