import qs from "qs";
import React from "react";
import { useSelector, connect } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

import config from "config";
import LoginForm from "./LoginForm";
import { login } from "state/actions/AuthActions";
import { AuthLayout, AuthImage, AuthBody } from "../styles";
import bgImage from "assets/images/willow-signin-banner.jpg";

function LoginPage({ login }: any) {
  const loginFailed = useSelector((state: any) => state.auth.loginFailed);
  const loginError = useSelector((state: any) => state.auth.loginError);
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  return (
    <GoogleOAuthProvider clientId={config.google.clientId}>
      <AuthLayout>
        <AuthBody>
          <LoginForm
            onSubmit={login}
            // @ts-ignore
            onLogin={login}
            loginFailed={loginFailed}
            loginError={loginError}
            feedback={queryParams.feedback}
          />
        </AuthBody>
        <AuthImage bgImage={bgImage} />
      </AuthLayout>
    </GoogleOAuthProvider>
  );
}

export default connect(() => ({}), {
  login
})(LoginPage);
