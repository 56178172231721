// @ts-nocheck
import _ from "lodash";
import { nanoid } from "nanoid";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  createKeyword,
  getKeywordById,
  updateKeywordById,
  previewContentsForKeyword
} from "services/keyword";

import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";

import {
  Wrapper,
  KeywordHeader,
  HeaderIcon,
  TitleInput,
  TipsButton
} from "./styles";

import { CategoryData, KEYWORD_CATEGORY } from "./constants/category";
import { ConjunctionData, IKeywordAlertState } from "./constants/types";
import { composeFeedObject, makeStateFromFeed } from "./utils/feed";

import Divider from "components/common/Divider";
import Loader from "components/common/loading/Loader";

import ConditionForm from "./ConditionForm";
import AddConditionButton from "./components/AddCondition";
import KeywordContentPreview from "./KeywordContentPreview";
import KeywordActions from "./components/KeywordAction";

const Keyword: React.FC<{
  [key: string]: any;
}> = () => {
  const { keywordId }: { keywordId?: string } = useParams();
  const isEditMode = !!keywordId;

  const addToast = useToaster();
  const history = useHistory();
  const account = useSelector((state: any) => state.account.data);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [keywordAlert, setKeywordAlert] = useState<Array<IKeywordAlertState>>([
    {
      id: nanoid(),
      keywords: [] as Array<{
        id: string;
        keyword: string;
      }>,
      category: KEYWORD_CATEGORY[0],
      conjunction: ConjunctionData.AND
    }
  ]);
  const [previews, setPreviews] = useState([]);
  const [isFetchingPreviews, setIsFetchingPreviews] = useState<boolean>(false);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  const fetchKeyword = useCallback(async () => {
    if (keywordId) {
      try {
        setIsLoading(true);
        const keyword = await getKeywordById(keywordId);

        const state = makeStateFromFeed(keyword);
        setKeywordAlert(state);
        setTitle(keyword.name);

        getPreviewContents(state);
      } catch (e) {
        addToast("Couldn't fetch details for keyword", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [keywordId, addToast, getPreviewContents]);

  const getPreviewContents = async (
    keywordAlert: Array<IKeywordAlertState>
  ) => {
    try {
      setIsFetchingPreviews(true);
      const response = await previewContentsForKeyword({
        name: title,
        rules: { ...composeFeedObject(keywordAlert) }
      });

      setPreviews(response);
      setIsFetchingPreviews(false);
    } catch (_) {
      setIsFetchingPreviews(false);
    }
  };

  useEffect(() => {
    fetchKeyword();
  }, [fetchKeyword]);

  const addCondition = () => {
    setKeywordAlert([
      ...keywordAlert,
      {
        id: nanoid(),
        keywords: [] as Array<{
          id: string;
          keyword: string;
        }>,
        category: KEYWORD_CATEGORY[0],
        conjunction: ConjunctionData.AND
      }
    ]);
  };

  const addKeyword = (id: string, keyword: string) => {
    const keywords = keywordAlert.map(data => {
      if (data.id === id) {
        return {
          ...data,
          keywords: [...data.keywords, { id: nanoid(), keyword }]
        };
      }
      return data;
    });

    setKeywordAlert(keywords);
    getPreviewContents(keywords);
  };

  const removeKeyword = (id: string, keywordId: string) => {
    const keywords = keywordAlert.map(data => {
      if (data.id === id) {
        return {
          ...data,
          keywords: data.keywords.filter(x => x.id !== keywordId)
        };
      }
      return data;
    });

    setKeywordAlert(keywords);
    getPreviewContents(keywords);
  };

  const onEditKeyword = (id: string, keywordId: string, value: string) => {
    const keywords = keywordAlert.map(data => {
      if (data.id === id) {
        return {
          ...data,
          keywords: data.keywords.map(x =>
            x.id !== keywordId ? x : { ...x, keyword: value }
          )
        };
      }
      return data;
    });

    setKeywordAlert(keywords);
    getPreviewContents(keywords);
  };

  const selectCategory = (id: string, category: CategoryData) => {
    const keywords = keywordAlert.map(data => {
      if (data.id === id && data.category.value !== category.value) {
        return {
          ...data,
          category,
          keywords:
            category.value.toLowerCase() === "language" ? [] : data.keywords
        };
      }
      return data;
    });
    getPreviewContents(keywords);
    setKeywordAlert(keywords);
  };

  const onChangeConjunction = (id: string, conj: ConjunctionData) => {
    const keywords = keywordAlert.map(data => {
      if (data.id === id) {
        return {
          ...data,
          conjunction: conj
        };
      }
      return data;
    });

    getPreviewContents(keywords);
    setKeywordAlert(keywords);
  };

  const removeCondition = (id: string) => {
    const latestKeywordAlert = keywordAlert.filter(
      condition => condition.id !== id
    );

    setKeywordAlert(latestKeywordAlert);
    getPreviewContents(latestKeywordAlert);
  };

  const firstKeywordOrTitle = () => {
    return _.isEmpty(title)
      ? keywordAlert.find(alert => alert.conjunction === ConjunctionData.AND)
          ?.keywords[0]?.keyword
      : title;
  };

  const createFeed = async () => {
    try {
      const name = firstKeywordOrTitle();
      if (!name) return;

      setIsProcessing(true);
      const data = await createKeyword({
        name,
        rules: { ...composeFeedObject(keywordAlert) }
      });

      setIsProcessing(false);
      navigateTo(`keywords/${data.id}`);
    } catch (_) {
      setIsProcessing(false);
      addToast("Couldn't create the keyword feed", "error");
    }
  };

  const updateFeed = async () => {
    if (!keywordId) return;
    try {
      const name = firstKeywordOrTitle();
      if (!name) return;

      setIsProcessing(true);
      await updateKeywordById(keywordId, {
        name,
        rules: { ...composeFeedObject(keywordAlert) }
      });
      setIsProcessing(false);
      navigateTo(`keywords/${keywordId}`);
    } catch (_) {
      setIsProcessing(false);
      addToast("Couldn't update the keyword feed", "error");
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Loader location="center" size={64} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <KeywordHeader>
        <HeaderIcon src={KeywordIcon} alt="keyword-icon" />
        <TitleInput
          placeholder="Write a title here"
          onChange={e => setTitle(e.target.value)}
          value={title}
        />
        <TipsButton
          href=" https://support.willow.co/knowledge/keyword-monitor-tips-for-pros"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon-info-20" />
          Tips for pros
        </TipsButton>
      </KeywordHeader>
      {keywordAlert.map((keyword: IKeywordAlertState, index: number) => {
        return (
          <ConditionForm
            id={keyword.id}
            key={keyword.id}
            isFirst={index === 0}
            onRemove={removeCondition}
            keywords={keyword.keywords}
            category={keyword.category}
            conjunction={keyword.conjunction}
            onChangeCategory={selectCategory}
            onChangeConjunction={onChangeConjunction}
            addKeyword={addKeyword}
            editKeyword={onEditKeyword}
            removeKeyword={removeKeyword}
            haveConjunction={keywordAlert.length > 1 && index > 0}
          />
        );
      })}
      <AddConditionButton onClick={addCondition} />
      <KeywordActions
        isEdit={isEditMode}
        updateFeed={updateFeed}
        createFeed={createFeed}
        isProcessing={isProcessing}
      />
      <Divider my={1} />
      <KeywordContentPreview
        isLoading={isFetchingPreviews}
        previews={previews}
      />
    </Wrapper>
  );
};

export default Keyword;
