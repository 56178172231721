import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  AccountSettingsLayout,
  EnclosedContent,
  ContentZone,
  NavigationZone,
  MainContainer,
  Headline3,
  MainWrapper
} from "components/common/styles";
import SideNavigation from "components/navigation/SideNavigation";
import { connect } from "react-redux";
import * as accountActions from "state/actions/AccountActions";
import Account from "components/settings/Account";
import Channels from "components/settings/Channels";
import Hashtags from "components/settings/Hashtags";
import Security from "components/settings/Security";
import Team from "components/settings/Team";
import Notifications from "components/settings/Notifications";
import Billing from "components/settings/Billing";
import CallToAction from "components/settings/CallToAction";
import { settingsSideNav } from "constants/navigation.js";
import Referral from "components/settings/Referral";
import { useAppState } from "contextApi/appContext";

const AccountSettingsPage = ({ match }) => {
  const {
    clientSettings: { client }
  } = useAppState();

  return (
    <AccountSettingsLayout>
      <MainContainer centered>
        <MainWrapper>
          <NavigationZone flexDirection="row-reverse">
            <Headline3 marginBottom="24px">Settings</Headline3>
            <SideNavigation
              match={match}
              links={
                !client || client !== "telenet"
                  ? settingsSideNav
                  : settingsSideNav.filter(setting => setting.to !== "/team")
              }
            />
          </NavigationZone>
          <ContentZone>
            <EnclosedContent>
              <Switch>
                <Route path={match.url + "/account"} component={Account} />
                <Route path={match.url + "/channels"} component={Channels} />
                <Route path={match.url + "/hashtags"} component={Hashtags} />
                <Route path={match.url + "/security"} component={Security} />
                <Route
                  path={match.url + "/notifications"}
                  component={Notifications}
                />
                <Route
                  path={match.url + "/call-to-action"}
                  component={CallToAction}
                />
                {(!client || client !== "telenet") && (
                  <Route path={match.url + "/team"} component={Team} />
                )}
                <Route path={match.url + "/billing"} component={Billing} />
                <Route path={match.url + "/referral"} component={Referral} />
                <Redirect to={match.url + "/account"} />
              </Switch>
            </EnclosedContent>
          </ContentZone>
        </MainWrapper>
      </MainContainer>
    </AccountSettingsLayout>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(AccountSettingsPage);
