import _ from "lodash";
import React, { useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";

import {
  SectionLabel,
  SectionWrapper,
  SectionContent,
  ComposerContent,
  SelectorWrapper
} from "../../styles";
import Captions from "./captions";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Attachment from "./attachment";
import { callApi } from "utils/ContentoApi";
import StatusSelector from "./statusSelector";
import ChannelSelector from "./channelSelector";
import CategorySelector from "./categorySelector";
import { Status } from "./statusSelector/constants";
// import validateAttachments from "./attachment/validation";
import EmptyChannelState from "components/common/EmptyChannelState";
import { createUpdatePost, constructPostData, PostEvent } from "services/post";

function PostComposer(props) {
  const account = useSelector(state => state.account.data);
  const { postData: post, inProMode, attachments } = useComposerState();
  const channelAttachments = attachments["all"]; // ? use channel as param when splitting attachments wrt channels
  const { closeComposer } = useComposerActions();
  const addToast = useToaster();
  const dispatch = useDispatch();

  const [isPosting, setIsPosting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // const { account, initialScheduleTime, captions } = props;

  const deletePost = async post => {
    setIsDeleting(true);
    const params = post.isPostGroup
      ? {
          method: "delete",
          url: `/accounts/${account.id}/post-groups/${post.id}`
        }
      : post.isDraft && props.postStatus === Status.DRAFT
      ? {
          method: "delete",
          url: `/accounts/${account.id}/draft-posts/${post.id}`
        }
      : { method: "delete", url: `/posts/${post.id}` };
    await callApi(params);
    await new Promise(resolve => setTimeout(resolve, 1000));
    props.onDeleted(post);
  };

  // const attachmentValidation = validateAttachments(
  //   account,
  //   post.attachment,
  //   // setPost,
  //   () => {},
  //   enabledServices,
  //   props.postStatus === Status.DRAFT
  // );
  const attachmentValidation = [];

  const [attachmentValidationErrors, isUploadingAttachments] =
    channelAttachments.type === "photo"
      ? [
          channelAttachments.photoAttachments.validationErrors,
          channelAttachments.photoAttachments.isUploading
        ]
      : channelAttachments.type === "video"
      ? [
          channelAttachments.videoAttachments.validationErrors,
          channelAttachments.videoAttachments.isUploading
        ]
      : [
          channelAttachments.articleAttachments.validationErrors,
          channelAttachments.articleAttachments.isScrapping
        ];

  const hasAttachmentValidationErrors =
    attachmentValidationErrors.length > 0 ||
    attachmentValidation.filter(v => v.type === "error").length > 0;

  const isSchedulerDisabled =
    isPosting ||
    hasAttachmentValidationErrors ||
    isUploadingAttachments ||
    isDeleting ||
    account.channels < 1;

  return (
    <ComposerContent inProMode={inProMode}>
      <Captions />
      {!inProMode && channelAttachments.type !== null && <Attachment />}

      {!inProMode && (
        <SelectorWrapper>
          <SectionWrapper>
            <SectionLabel>Channels</SectionLabel>
            <SectionContent>
              {account.channels < 1 ? (
                <EmptyChannelState callback={closeComposer} />
              ) : (
                <ChannelSelector />
              )}
            </SectionContent>
          </SectionWrapper>
          <SectionWrapper center>
            <SectionLabel>Category</SectionLabel>
            <SectionContent>
              <CategorySelector />
            </SectionContent>
          </SectionWrapper>
          <SectionWrapper center>
            <SectionLabel>Status</SectionLabel>
            <SectionContent>
              <StatusSelector />
            </SectionContent>
          </SectionWrapper>
        </SelectorWrapper>
      )}
    </ComposerContent>
  );
}

export default PostComposer;
