// @ts-nocheck
import { useContext, useCallback } from "react";
import { AlertContext, FeatureAlertContext } from "./FeatureAlertProvider";

const useAlertModal = () => {
  const context = useContext(FeatureAlertContext);

  const addFeatureAlert = useCallback((payload: AlertContext) => {
    context.dispatch({
      type: "ADD",
      payload
    });
  }, []);

  return addFeatureAlert;
};

export default useAlertModal;
