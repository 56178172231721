import { Box } from "rebass";
import styled, { css } from "styled-components";

export const EmptyList = styled.div`
  display: flex;
  justify-self: center;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkgray};
`;

export const ImageList = styled.div`
  display: grid;
  width: 100%;
`;

export const ImageCard = styled.div<{
  src: string;
  ratio: number;
  disabled: boolean;
  isUploading: boolean;
}>`
  box-sizing: border-box;
  float: left;
  margin-bottom: 10px;
  width: calc((100% - 10px) / 2);
  height: ${props => (props.ratio < 0.7 ? 102 : 178)}px;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  border-radius: 16px;
  background: url("${props =>
    props.src}") no-repeat scroll center top ${props =>
  props.theme.colors.white};
  background-size: cover;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      cursor: progress;
    `};

  ${props =>
    props.isUploading &&
    css`
      border: unset;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: ${props => props.theme.colors.white};
        opacity: 80%;
      }
    `};
`;

export const ImageCardShimmer = styled.div<{ index: number }>`
  box-sizing: border-box;
  float: left;
  margin-bottom: 10px;
  width: calc((100% - 10px) / 2);
  height: ${props => (props.index % 2 === 0 ? 102 : 178)}px;
  border: 1px solid ${props => props.theme.colors.grey01};
  border-radius: 16px;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: white;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
`;

export const SearchContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 36px;
  margin-bottom: 21px !important;
  padding: 12px;
  border-radius: 48px;
  color: ${props => props.theme.colors.iconFill1};
  background-color: ${props => props.theme.colors.grey01};
`;

export const SearchIcon = styled.span`
  color: ${props => props.theme.colors.text03};
  font-size: 18px;
  text-align: center;
  width: 20px;
`;
SearchIcon.defaultProps = {
  className: "icon-search"
};

export const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.body};
  outline: none;
  font-size: 14px;
  line-height: 125%;
  width: 100%;
`;
