import styled from "styled-components";
import Button from "../common/Button";
import { Headline1 } from "../common/styles";
import { Text, Flex, Box } from "rebass/styled-components";

export const IdeaGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const IdeaPageContainer = styled(Flex)`
  justify-content: center;
  margin-bottom: 120px;
`;

export const IdeaPageHeader = styled(Box)`
  padding: 64px 0;
  max-width: 744px;
`;

export const IdeaPageTitle = styled(Headline1)`
  font-size: 40px;

  @media screen and (max-width: 990px) {
    font-size: 32px;
  }
`;

export const IdeaPageDescription = styled(Text)`
  font-size: 18px;
  line-height: 1.5;

  @media screen and (max-width: 990px) {
    font-size: 16px;
  }
`;

export const IdeaWrapper = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  max-width: 1128px;
  padding: 0 24px;
  width: 100%;
`;

export const IdeaNav = styled(Flex)`
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  margin-bottom: 24px;
  overflow-x: auto;
  padding: 16px 0;
  position: sticky;
  top: 0;

  & button {
    min-width: fit-content;
  }
`;

export const IdeaCard = styled(Box)`
  border-radius: 16px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

export const IdeaCardThumbnail = styled.figure`
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.theme.colors.gray};
  box-sizing: border-box;
  height: 344px;
  padding: 48px;
  overflow: hidden;
  position: relative;
`;

export const IdeaCardImage = styled(Flex)`
  justify-content: center;
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: ${props => (props.isVisible ? 1 : 0.5)};
  transform: scale(${props => (props.isVisible ? 1 : 0.99)});
  transition: all 0.1s ease-in;

  & img {
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const IdeaCardAction = styled(Flex)`
  align-items: center;
  background-color: ${props => props.theme.colors.text02};
  box-sizing: border-box;
  height: 100%;
  left: 0;
  justify-content: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: absolute;
  transition: all 0.15s ease-in;
  top: 0;
  transform: scale(${props => (props.isVisible ? 1 : 1.03)});
  width: 100%;
`;

export const IdeaCardButton = styled(Button)`
  background-color: white;

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }

  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.lightGray};
  }
`;

IdeaCardButton.defaultProps = {
  variant: "secondary",
  size: "lg",
  isRounded: true
};

export const IdeaCardBody = styled(Box)`
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.theme.colors.darkgray};
  padding: 32px 24px;
  text-align: center;
  height: 100%;
`;

export const IdeaCardTitle = styled(Text)`
  color: white;
  font-size: 19px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.57px;
  margin: 6px 0;
`;

export const IdeaCardDescription = styled(Text)`
  color: white;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.66;
`;
