import _ from "lodash";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import Isotope, { IsotopeOptions } from "isotope-layout";
import React, { useState, useLayoutEffect } from "react";
import { useGranularCallback, useGranularEffect } from "granular-hooks";

import {
  EmptyList,
  ImageCard,
  ImageList,
  SearchIcon,
  SearchInput,
  SearchContainer,
  ImageCardShimmer
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Button from "components/common/Button";
import { Headline6 } from "components/common/styles";
import { ToolContentHeader, ToolContentBody } from "../styles";
import { fetchImageSuggestions, uploadImageByUrl } from "services/post";

const isotopeOptions: IsotopeOptions = {
  itemSelector: ".image-suggestion-grid-item",
  layoutMode: "masonry",
  masonry: {
    gutter: 10
  }
};

const ImageSuggestion: React.FC<{}> = () => {
  const addToast = useToaster();
  const {
    visibleCaption,
    postData: { caption }
  } = useComposerState();
  const currentCaption = caption[visibleCaption];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [suggestedImages, setSuggestedImages] = useState<any[]>([]);
  const {
    addUploadedAttachments,
    setIsUploadingAttachments
  } = useComposerActions();

  useLayoutEffect(() => {
    new Isotope(".image-suggestion-grid", isotopeOptions);
  });

  const handleSearch = useGranularCallback(
    _.debounce(
      async (caption: string = "", query: string = "") => {
        let images: any[] = [];

        try {
          setIsLoading(true);
          images = await fetchImageSuggestions(
            `${caption.length >= 20 ? `${caption} ` : ""}${query}`
          );
        } catch (error) {
          addToast((error as Error).message, "error");
        } finally {
          setSuggestedImages(images);
          setIsLoading(false);
        }
      },
      2000,
      {
        leading: true,
        trailing: false
      }
    ),
    [],
    [addToast]
  );

  const debouncedSearchCaption = useGranularCallback(
    _.debounce((caption, query) => {
      if (caption.length >= 20) {
        handleSearch(caption, query);
      }
    }, 2000),
    [],
    [handleSearch]
  );

  useGranularEffect(
    () => {
      debouncedSearchCaption(currentCaption, searchTerm);
    },
    [currentCaption],
    [searchTerm, debouncedSearchCaption]
  );

  useGranularEffect(
    () => {
      handleSearch();
    },
    [],
    [handleSearch]
  );

  const generateNewImages = useGranularCallback(
    () => {
      handleSearch(currentCaption, searchTerm);
    },
    [searchTerm, currentCaption],
    [handleSearch]
  );

  const handleImageUpload = async (image: any) => {
    const index = suggestedImages.findIndex(i => image === i);

    try {
      setIsUploadingAttachments(true, "photo");
      setIsUploading(true);
      setSuggestedImages([
        ...suggestedImages.slice(0, index),
        { ...suggestedImages[index], isUploading: true },
        ...suggestedImages.slice(index + 1)
      ]);
      const uploadedImage = await uploadImageByUrl(image.urls.full);
      addUploadedAttachments([uploadedImage], "photo");
    } catch (error) {
      addToast((error as Error).message, "error");
    } finally {
      setIsUploadingAttachments(false, "photo");
      setIsUploading(false);
      setSuggestedImages([
        ...suggestedImages.slice(0, index),
        { ...suggestedImages[index], isUploading: false },
        ...suggestedImages.slice(index + 1)
      ]);
    }
  };

  const handleKeyPress = useGranularCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSearch(currentCaption, searchTerm);
      } else {
        setSearchTerm(e.currentTarget.value);
      }
    },
    [currentCaption, searchTerm],
    [handleSearch]
  );

  return (
    <>
      <ToolContentHeader>
        <Headline6>Image suggestion</Headline6>
        {/* // TODO: Re-enable this button to be used with a better version of caption generation */}
        {/* @ts-ignore */}
        <Button size={"sm"} variant={"secondary"} onClick={generateNewImages}>
          Generate new
        </Button>
      </ToolContentHeader>
      <ToolContentBody>
        <SearchContainer>
          <SearchIcon />
          <SearchInput
            type="text"
            placeholder="Search images"
            onKeyUp={handleKeyPress}
          />
        </SearchContainer>
        <ImageList className="image-suggestion-grid">
          {isLoading ? (
            <>
              {[...Array(10).fill(0)].map((_, index) => (
                <ImageCardShimmer
                  className="image-suggestion-grid-item"
                  key={index}
                  index={index}
                />
              ))}
            </>
          ) : suggestedImages.length < 1 ? (
            <EmptyList>No photos found</EmptyList>
          ) : (
            suggestedImages.map((image, index) => (
              <ImageCard
                key={index}
                src={image.urls.thumb}
                disabled={isUploading}
                isUploading={image.isUploading}
                ratio={image.height / image.width}
                className="image-suggestion-grid-item"
                onClick={() => !isUploading && handleImageUpload(image)}
              />
            ))
          )}
        </ImageList>
      </ToolContentBody>
    </>
  );
};

export default ImageSuggestion;
