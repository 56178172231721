import styled from "styled-components";

import React, { useState, useEffect, useRef, useContext } from "react";
import { Flex, Box } from "rebass/styled-components";
import { Headline1 } from "../../../common/styles";
import { follow, unfollow, followExternalSources } from "services/news";

import SubscribeButton from "../SubscribeButton";
import SourceDetail from "../SourceDetail";
import { SECTION_TYPES, SOURCE_TYPE } from "services/news.types";
import useOnScreen from "hooks/useOnScreen";
import { ContentPageContext } from "contextApi/ContentPageContext";
import { ContentSectionWrapper, ContentTopicImage } from "../../styles";

const Title = styled(Headline1)`
  color: ${props => props.theme.colors.white};
`;

interface IContentDetailBannerProps {
  pageData: any;
  sourceId: string;
  sourceType: string;
}

const InfluencerDetailBanner: React.FC<IContentDetailBannerProps> = ({
  pageData,
  sourceId,
  sourceType
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const ctx = useContext(ContentPageContext);

  const [isLocalFollowed, setIsLocalFollowed] = useState(
    pageData.source.isFollowed
  );

  const contextSource =
    sourceType === SECTION_TYPES.DOMAINS ? sourceType : SECTION_TYPES.SOURCES;

  useEffect(() => {
    if (!isVisible) {
      // @ts-ignore
      ctx.setContext({
        id: pageData.source.id,
        type: sourceType,
        title: pageData.source.title,
        isFollowing: isLocalFollowed
      });
    } else {
      // @ts-ignore
      ctx.setContext({
        id: null
      });
    }
  }, [isVisible, isLocalFollowed]);

  const addTwitterSource = async (source: any) => {
    const twitterSubscribeData = {
      type: SOURCE_TYPE.TWITTER,
      handle: source.handle
    };

    try {
      setIsProcessing(true);
      await followExternalSources(twitterSubscribeData);
      setIsLocalFollowed(true);
      setIsProcessing(false);
    } catch (_) {
      setIsProcessing(false);
    }
  };

  const addSource = async (source: any) => {
    try {
      setIsProcessing(true);
      await follow(contextSource, source.id);
      setIsLocalFollowed(true);
      setIsProcessing(false);
    } catch (_) {
      setIsProcessing(false);
    }
  };

  const deleteSource = async (id: string) => {
    try {
      setIsProcessing(true);
      await unfollow(contextSource, id);
      setIsLocalFollowed(false);
      setIsProcessing(false);
    } catch (_) {
      setIsProcessing(false);
    }
  };

  const followLabel = (function() {
    if (pageData) {
      switch (pageData.source.type) {
        case SOURCE_TYPE.TWITTER:
          return "Follow Influencer";
        case SOURCE_TYPE.RSS:
          return "Follow";
        default:
          return "Follow";
      }
    }
  })();

  return (
    <ContentSectionWrapper mb={40}>
      <Flex flexDirection="column" alignItems="center" margin={"auto"}>
        <ContentTopicImage
          ref={ref}
          src={pageData.source.image}
          // @ts-ignore
          borderRadius="50%"
        />
        <Title sx={{ textTransform: "capitalize", textAlign: "center" }}>
          {pageData.source.title}
        </Title>
        {pageData?.source ? <SourceDetail source={pageData.source} /> : null}

        <Box mt={32}>
          <SubscribeButton
            id={pageData.source.id}
            isProcessing={isProcessing}
            type={SECTION_TYPES.SOURCES}
            followLabel={followLabel}
            color={pageData.source.color}
            onFollow={() => {
              pageData.source.type === SOURCE_TYPE.TWITTER
                ? addTwitterSource(pageData.source)
                : addSource(pageData.source);
            }}
            onUnfollow={() => deleteSource(sourceId)}
          />
        </Box>
      </Flex>
    </ContentSectionWrapper>
  );
};

export default InfluencerDetailBanner;
