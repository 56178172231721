import React, {
  useContext,
  useReducer,
  useCallback,
  createContext
} from "react";
import _ from "lodash";
import { useGranularEffect } from "granular-hooks";
import { useSelector, useDispatch } from "react-redux";

import {
  IPostData,
  initialValues,
  IComposerState,
  composerReducer,
  IComposerDispatch,
  ICaption,
  IChannelAttachments
} from "./reducer";
import {
  scrapeUrlLink,
  fetchPostConcepts,
  fetchAvailableTags
} from "services/post";
import {
  openComposerAction,
  closeComposerAction,
  enableProModeAction,
  disableProModeAction,
  setInDraftModeAction,
  setPostDataAction,
  resetPostDataAction,
  setTagsAction,
  setEditorStateObjAction,
  setVisibleCaptionAction,
  enableSplitModeAction,
  setChosenSuggestedCaptionAction,
  setPostConceptsAction,
  setPostIdeaCaptionsAction,
  setValidationErrorsAction,
  setAttachmentTypeAction,
  setIsUploadingAttachmentsAction,
  addAttachmentsAction,
  removeAttachmentAction,
  removeArticleAttachmentAction,
  reorderAttachmentAction,
  setAttachmentValidationAction,
  setIsSubmittingAction,
  setIsDeletingAction,
  setWasScheduleChangedAction,
  setPickedDateAction,
  setMoodFilterAction
} from "./actionCreators";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { constructInitialPostState } from "./utils/post";
import { IChannel } from "@hellocontento/contento-common";
import contentTypes from "components/common/contentTypes/data/content-types";
import { createUpdatePost, constructPostData, PostEvent } from "services/post";

const ComposerStateContext = createContext<IComposerState | undefined>(
  undefined
);
ComposerStateContext.displayName = "ComposerStateContext";

const ComposerDispatchContext = createContext<IComposerDispatch | undefined>(
  undefined
);
ComposerDispatchContext.displayName = "ComposerDispatchContext";

export interface IComposerActions {
  closeComposer: VoidFunction;
  toggleProMode: (overrideMode: IComposerState["inProMode"]) => void;
  setInDraftMode: (inDraftMODE: IComposerState["inDraftMode"]) => void;
  setEditorStateObj: (
    newEditorStateObj: IComposerState["editorStateObj"]
  ) => void;
  setVisibleCaption: (service: IComposerState["visibleCaption"]) => void;
  setValidationErrors: (
    validationErrors: IComposerState["validationErrors"]
  ) => void;
  setChosenSuggestedCaption: (
    caption: IComposerState["chosenSuggestedCaption"]
  ) => void;
  setPostIdeaCaptions: (postIdeaId: string, captions: ICaption[]) => void;
  setPostData: (postData: IComposerState["postData"]) => void;
  setAttachmentType: (
    attachmentType: IChannelAttachments["type"],
    channel?: string
  ) => void;
  setIsUploadingAttachments: (
    status: boolean,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  addUploadedAttachments: (
    newAttachments: any[],
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  removeAttachment: (
    index: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  removeArticleAttachment: (channel?: string) => void;
  reorderAttachment: (
    sourceIndex: number,
    destinationIndex: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  setAttachmentValidation: (
    validationErrors: any[],
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  setIsSubmitting: (
    isSubmitting: IComposerState["scheduler"]["isSubmitting"]
  ) => void;
  setIsDeleting: (
    isDeleting: IComposerState["scheduler"]["isDeleting"]
  ) => void;
  setWasScheduleChanged: (
    wasScheduleChanged: IComposerState["scheduler"]["wasScheduleChanged"]
  ) => void;
  setPickedDate: (
    pickedDate: IComposerState["scheduler"]["pickedDate"]
  ) => void;
  setMoodFilter: (mood: IComposerState["moodFilter"]) => void;

  initializePostData: (post: any) => void;
  openComposer: (post?: IComposerState["postData"]) => void;
  scrapeUrl: (url: string, linkOnly?: boolean) => void;
  getEnabledServicesWithType: (
    selectedChannels: string[]
  ) => { service: string; serviceType: string }[];
  getEnabledServices: (selectedChannels: string[]) => string[];
  onCaptionChange: (text: string, service: string) => void;
  splitEditor: VoidFunction;
  onChannelSelected: (
    selectedChannels: string[],
    validationErrors: { [key: string]: any }
  ) => void;
  createPost: (
    scheduleTime: string,
    scheduledAt: null | Date,
    fromModal: boolean
  ) => void;
}

const ComposerActionsContext = createContext<IComposerActions | undefined>(
  undefined
);
ComposerActionsContext.displayName = "ComposerActionsContext";

export const ComposerProvider: React.FC<any> = ({ children }) => {
  const addToast = useToaster();
  const reduxDispatch = useDispatch(); // ? dispatcher for the react-redux
  const account = useSelector<any, any>(state => state.account.data);
  const [state, dispatch] = useReducer(composerReducer, {
    ...initialValues
  });
  // TODO: add action dispatch functions here which can be used by other components as well
  // * <--Action dispatch functions

  // * <--State setters
  const closeComposer = () => {
    dispatch(closeComposerAction());
    dispatch(resetPostDataAction());
  };

  const toggleProMode = (overrideMode?: IComposerState["inProMode"]) => {
    const currentMode =
      typeof overrideMode !== "undefined" ? !overrideMode : state.inProMode;

    if (currentMode) {
      dispatch(disableProModeAction());
    } else {
      dispatch(enableProModeAction());
    }
  };

  const setInDraftMode = (inDraftMode: IComposerState["inDraftMode"]) => {
    dispatch(setInDraftModeAction(inDraftMode));
  };

  const setEditorStateObj = (
    newEditorStateObj: IComposerState["editorStateObj"]
  ) => {
    dispatch(setEditorStateObjAction(newEditorStateObj));
  };

  const setVisibleCaption = (service: IComposerState["visibleCaption"]) => {
    dispatch(setVisibleCaptionAction(service));
  };

  const setValidationErrors = (
    validationErrors: IComposerState["validationErrors"]
  ) => {
    dispatch(setEditorStateObjAction(validationErrors));
  };

  const setChosenSuggestedCaption = (
    caption: IComposerState["chosenSuggestedCaption"]
  ) => {
    dispatch(setChosenSuggestedCaptionAction(caption));
  };

  const setPostIdeaCaptions = (postIdeaId: string, captions: ICaption[]) => {
    dispatch(setPostIdeaCaptionsAction(postIdeaId, captions));
  };

  const setPostData = (postData: IComposerState["postData"]) => {
    dispatch(setPostDataAction(postData));
  };

  const setAttachmentType = (
    attachmentType: IChannelAttachments["type"],
    channel?: string
  ) => {
    dispatch(setAttachmentTypeAction(attachmentType, channel));
  };

  const setIsUploadingAttachments = (
    status: boolean,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => {
    dispatch(setIsUploadingAttachmentsAction(status, attachmentType, channel));
  };

  const addUploadedAttachments = (
    newAttachments: any[],
    attachmentType: Omit<NonNullable<IChannelAttachments["type"]>, "article">,
    channel?: string
  ) => {
    const attachments: any[] = newAttachments.map(attachment => ({
      url: attachment.path ?? attachment,
      metaData: attachment.metaData ?? null
    }));

    dispatch(
      addAttachmentsAction(
        attachments,
        attachmentType as NonNullable<IChannelAttachments["type"]>,
        channel
      )
    );
  };

  const removeAttachment = (
    index: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => {
    dispatch(removeAttachmentAction(index, attachmentType, channel));
  };

  const removeArticleAttachment = (channel?: string) => {
    dispatch(removeArticleAttachmentAction(channel));
  };

  const reorderAttachment = (
    sourceIndex: number,
    destinationIndex: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => {
    dispatch(
      reorderAttachmentAction(
        sourceIndex,
        destinationIndex,
        attachmentType,
        channel
      )
    );
  };

  const setAttachmentValidation = (
    validationErrors: any[],
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => {
    dispatch(
      setAttachmentValidationAction(validationErrors, attachmentType, channel)
    );
  };

  const setIsSubmitting = (
    isSubmitting: IComposerState["scheduler"]["isSubmitting"]
  ) => dispatch(setIsSubmittingAction(isSubmitting));

  const setIsDeleting = (
    isDeleting: IComposerState["scheduler"]["isDeleting"]
  ) => dispatch(setIsDeletingAction(isDeleting));

  const setWasScheduleChanged = (
    wasScheduleChanged: IComposerState["scheduler"]["wasScheduleChanged"]
  ) => dispatch(setWasScheduleChangedAction(wasScheduleChanged));

  const setPickedDate = (
    pickedDate: IComposerState["scheduler"]["pickedDate"]
  ) => dispatch(setPickedDateAction(pickedDate));

  const setMoodFilter = (mood: IComposerState["moodFilter"]) =>
    dispatch(setMoodFilterAction(mood));

  // * State setters-->

  const initializePostData = (post: any, initData: any = {}) => {
    const { task } = initData;

    if (task) {
      // slots may keep references to deleted channels
      const activeChannelIds = task.channels
        .filter(({ id: channelId }: any) => {
          return !!account.channels.find(
            (channel: any) => channel.id === channelId
          );
        })
        .map((c: any) => c.id);

      initData.initialScheduleTime = task.date;
      initData.initialContentTypeId = task.contentTypeId;
      initData.contentTypeLabel =
        contentTypes[task.contentTypeId as keyof typeof contentTypes]?.title;
      initData.postIdea = task.postIdea;
      initData.initialChannelIds = activeChannelIds;
      initData.taskId = task.id;
    }

    const postData = constructInitialPostState(post, account, initData);

    dispatch(setPostDataAction(postData));
  };

  const openComposer = (post?: IPostData) => {
    initializePostData(post);

    dispatch(openComposerAction());
  };

  const scrapeUrl = async (url: string) => {
    // if we dont need to scrape the article again
    const linkOnly =
      url === state.attachments["all"].articleAttachments.attachment?.url;
    try {
      dispatch(setIsUploadingAttachmentsAction(true, "article"));

      const urlInfo = await scrapeUrlLink(url, linkOnly);
      if (urlInfo.shortUrl || urlInfo.url) {
        dispatch(
          addAttachmentsAction(
            {
              ...urlInfo,
              url: urlInfo.shortUrl || urlInfo.url,
              image: urlInfo.image || urlInfo.thumbnail
            },
            "article"
          )
        );
      } else {
        dispatch(removeArticleAttachmentAction());
      }

      return urlInfo;
    } catch (error) {
      addToast((error as Error).message, "error");
    } finally {
      dispatch(setIsUploadingAttachmentsAction(false, "article"));
    }
  };

  const getEnabledServicesWithType = useCallback(
    (selectedChannels?: string[]) => {
      const channels: string[] = !!selectedChannels
        ? selectedChannels
        : state.postData.channels || [];

      return _.uniq(
        channels.map(id => {
          const { service, serviceType } = ((account.channels ||
            []) as IChannel[]).find(
            accountChannel => accountChannel.id === id
          )!;

          return { service, serviceType };
        })
      );
    },
    [state.postData?.channels, account?.channels]
  );

  const getEnabledServices = useCallback(
    (selectedChannels?: string[]) => {
      return _.uniq(
        getEnabledServicesWithType(selectedChannels).map(s => s.service)
      );
    },
    [getEnabledServicesWithType]
  );

  const onCaptionChange = (text: string, service: string) => {
    const caption = state.postData.caption;
    caption[service] = text;

    if (service === "all" && !state.inSplitMode) {
      const enabledServices = getEnabledServices();
      enabledServices.forEach(service => {
        caption[service] = text;
      });
    }

    dispatch(setPostDataAction({ ...state.postData, caption }));
  };

  const splitEditor = () => {
    const enabledServices = getEnabledServices();

    if (!state.inSplitMode) {
      // setWasCaptionUsed(true);
      const newEditorStateObj: IComposerState["editorStateObj"] = {};

      enabledServices.forEach(service => {
        newEditorStateObj[service] = state.editorStateObj["all"];
      });
      setEditorStateObj({ ...state.editorStateObj, ...newEditorStateObj });
    }

    // enabled customized captions
    if (state.visibleCaption === "all") {
      setVisibleCaption(enabledServices[0]);
      dispatch(enableSplitModeAction());
    } else {
      setVisibleCaption("all");
    }
  };

  const onChannelSelected = (
    selectedChannels: string[],
    validationErrors: { [key: string]: any }
  ) => {
    const newPostData = { ...state.postData };

    const enabledServices = getEnabledServices(selectedChannels);
    enabledServices.forEach(service => {
      if (
        newPostData.caption[service] === undefined ||
        newPostData.caption[service] === ""
      ) {
        newPostData.caption[service] = newPostData.caption.all;
      }
    });
    dispatch(setPostDataAction(newPostData));
    dispatch(
      setValidationErrorsAction({
        ...state.validationErrors,
        ...validationErrors
      })
    );
  };

  const createPost = (
    scheduleTime: string = "AUTO_SCHEDULE",
    scheduledAt: null | Date = null,
    fromModal: boolean = false
  ) => {
    setIsSubmitting(true);

    let post: { [key: string]: any } = {
      ...state.postData,
      scheduleTime,
      scheduledAt
    };

    let postEvent: PostEvent | null = null;
    if (fromModal && !post.id) {
      postEvent = PostEvent.CREATE_DRAFT;
    } else if (state.inDraftMode) {
      if (state.postData.isPostGroup) {
        postEvent = PostEvent.POST_GROUP_TO_DRAFT;
      } else if (!post.isDraft && post.id) {
        postEvent = PostEvent.POST_TO_DRAFT;
      } else if (!post.isDraft && !post.id) {
        postEvent = PostEvent.CREATE_DRAFT;
      } else if (post.isDraft && post.id) {
        postEvent = PostEvent.UPDATE_DRAFT;
      }
    } else {
      if (state.postData.isPostGroup) {
        postEvent = PostEvent.UPDATE_POST_GROUP;
      } else if (post.isDraft && post.id) {
        postEvent = PostEvent.DRAFT_TO_POST;
      } else if (!post.isDraft && post.id) {
        postEvent = PostEvent.UPDATE_POST;
      } else if (!post.isDraft && !post.id) {
        postEvent = PostEvent.CREATE_POST;
      }
    }

    post = constructPostData(post);

    createUpdatePost(
      postEvent!,
      post,
      (response, successToast) => {
        addToast(successToast, "success");
        setIsSubmitting(false);

        // if (props.onPosted) {
        //   const res = {
        //     ...response,
        //     isPostedNow: postData.scheduleTime === "NOW"
        //   };

        //   if (state.inDraftMode || post.isDraft || (fromModal && !post.id)) {
        //     res.isDraft = true;
        //   }
        //   if (post.isPostGroup) {
        //     res.isPostGroup = true;
        //   }

        //   props.onPosted(res);
        // }
      },
      errorToast => {
        setIsSubmitting(false);
        addToast(errorToast, "error");
      }
    );
  };
  // * Action dispatch functions-->

  // * <--Common functions (with useEffect if required)
  const fetchTags = useCallback(async () => {
    try {
      const tags = await fetchAvailableTags();

      dispatch(setTagsAction(tags));
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  }, [addToast]);

  const setInitialVisibleCaption = useCallback(() => {
    if (!!account.id) {
      const isCombined =
        state.postData.caption.hasOwnProperty("all") &&
        Object.values(state.postData.caption).every(
          value => value === state.postData.caption.all
        ) &&
        (!state.postData.mentions || state.postData.mentions.length < 1);

      const initialVisibleCaption = !state.postData.id
        ? "all"
        : (state.postData.isDraft || state.postData.isPostGroup) && isCombined
        ? "all"
        : (account.channels as IChannel[]).find(
            channel => channel.id === state.postData.channels[0]
          )!.service;

      dispatch(setVisibleCaptionAction(initialVisibleCaption));
    }
  }, [account?.id, state.postData]);

  const fetchPostConceptsAndIdeas = useCallback(async () => {
    try {
      const postConcepts = await fetchPostConcepts();

      dispatch(setPostConceptsAction(postConcepts));
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  }, [addToast]);

  useGranularEffect(
    () => {
      if (state.isComposerOpen) {
        fetchTags();
        setInitialVisibleCaption();
        fetchPostConceptsAndIdeas();
      }
    },
    [state.isComposerOpen],
    [fetchTags, setInitialVisibleCaption, fetchPostConceptsAndIdeas]
  );
  // * Common functions-->

  return (
    <ComposerStateContext.Provider value={state}>
      <ComposerDispatchContext.Provider value={dispatch}>
        <ComposerActionsContext.Provider
          value={{
            closeComposer,
            toggleProMode,
            setInDraftMode,
            setEditorStateObj,
            setVisibleCaption,
            setValidationErrors,
            setChosenSuggestedCaption,
            setPostIdeaCaptions,
            setPostData,
            setAttachmentType,
            setIsUploadingAttachments,
            addUploadedAttachments,
            removeAttachment,
            removeArticleAttachment,
            reorderAttachment,
            setAttachmentValidation,
            setIsSubmitting,
            setIsDeleting,
            setWasScheduleChanged,
            setPickedDate,
            setMoodFilter,

            initializePostData,
            openComposer,
            scrapeUrl,
            getEnabledServicesWithType,
            getEnabledServices,
            onCaptionChange,
            splitEditor,
            onChannelSelected,
            createPost
          }}
        >
          {children}
        </ComposerActionsContext.Provider>
      </ComposerDispatchContext.Provider>
    </ComposerStateContext.Provider>
  );
};

export const useComposerState = () => {
  const context = useContext(ComposerStateContext);

  if (context === undefined) {
    throw new Error("useComposerState must be used within a ComposerProvider");
  }
  return context;
};

export const useComposerDispatch = () => {
  const context = useContext(ComposerDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useComposerDispatch must be used within a ComposerProvider"
    );
  }
  return context;
};

export const useComposerActions = () => {
  const context = useContext(ComposerActionsContext);

  if (context === undefined) {
    throw new Error(
      "useComposerActions must be used within a ComposerProvider"
    );
  }
  return context;
};
