import React, { useEffect } from "react";
import { connect } from "react-redux";
import { differenceInDays, parseISO } from "date-fns";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import qs from "qs";

import * as accountActions from "../state/actions/AccountActions";
import {
  Layout,
  Loading,
  MainContent,
  TrialBanner,
  PersistentBanner
} from "../components/common/styles";

import ComposerV2 from "components/composerv2";
import MainHeader from "../components/navigation/MainHeader";

import AnalyticsPage from "./analytics/AnalyticsPage";
import ModalRoot from "../components/modals/ModalRoot";
import DashboardPage from "./dashboard/DashboardPage";
import SchedulePage from "./schedule/SchedulePage";
import AccountSettingsPage from "./settings/AccountSettingsPage";
import ContactPage from "./contact/ContactPage";
import ConnectFacebookPage from "./plan/ConnectFacebookPage";
import ConnectLinkedinBusinessPage from "./plan/ConnectLinkedinBusinessPage";
import ConnectInstagramBusinessPage from "./plan/ConnectInstagramBusinessPage";
import BlogPage from "./blog/BlogPage";
import ContentPage from "./content/ContentPage";
import IdeasPage from "./ideas/IdeasPage";
import CheckoutPage from "./checkout/CheckoutPage";
import TelenetCheckoutPage from "./checkout/TelenetCheckoutPage";
import LockoutPage from "./checkout/LockoutPage";
import CloseAccountPage from "./checkout/CloseAccountPage";

import * as modalActions from "../state/actions/ModalActions";
import { accountStatuses } from "@hellocontento/contento-common";

import { callApi } from "utils/ContentoApi";
import { openPostOnChannel } from "utils/channels";
import useComposer from "utils/useComposer";
import { PostStatus } from "components/schedule/types/postStatus";
import _ from "lodash";
import { useToaster } from "@hellocontento/maillard";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "utils/useQueryParams";
import { useAppState } from "contextApi/appContext";

const AppContainer = ({
  match,
  location,
  account,
  isFetchingAccount,
  fetchAccount,
  openModal,
  modalState
}) => {
  const {
    clientSettings: { client }
  } = useAppState();
  const isTelenetClient = !!client && client === "telenet";
  const history = useHistory();
  const addToast = useToaster();
  const queryParams = useQueryParams();
  const { openComposer } = useComposer();

  useEffect(() => {
    fetchAccount(match.params.accountId);
  }, [match.params.accountId, fetchAccount]);

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.modal) {
      switch (queryParams.modal) {
        case "checkout_success":
          openModal("CHECKOUT_SUCCESS_MODAL");
          break;
        default:
          return;
      }
    }
  }, [location.search, openModal]);

  //show modal to reconnect channel
  useEffect(() => {
    if (account && account.channels) {
      const storage = window.localStorage;
      const now = +new Date();
      const period = 6.048e8;

      for (let channel of account.channels.filter(c => c.needsReconnection)) {
        let storageId = `reconnect_channel_${channel.id}`;

        if (
          !storage.getItem(storageId) ||
          now - storage.getItem(storageId) > period
        )
          openModal("CHANNEL_RECONNECT_MODAL");
      }
    }
  }, [account, location.pathname, openModal]);

  const fetchPostByIdAndEdit = async (id, options = {}) => {
    try {
      let url = null;
      if (options.isPostGroup) {
        url = `/accounts/${account.id}/post-groups/${id}`;
      } else if (options.isDraft) {
        url = `/accounts/${account.id}/draft-posts/${id}`;
      } else {
        url = `/posts/${id}`;
      }

      let post = null;
      const result = await callApi({
        method: "get",
        url
      });

      if (!result) {
        throw new Error("Invalid url parameters.");
      }

      post = {
        ...result,
        ...options
      };

      if (post.status === PostStatus.SENT) {
        openPostOnChannel(post);
        history.replace({});
      } else {
        openComposer({
          account: account,
          post: post,
          composerParams: {
            skipAutoReload: location.pathname.includes("schedule/day")
          }
        });
      }
    } catch (err) {
      addToast(err.message, "error");
    }
  };

  useEffect(() => {
    if (account && !modalState.isOpen) {
      const paramKeys = {
        postId: {},
        postGroupId: { isPostGroup: true },
        draftId: { isDraft: true }
      };
      const identifiedQueryParamKeys = _.keys(queryParams).filter(key =>
        _.keys(paramKeys).includes(key)
      );
      if (identifiedQueryParamKeys.length > 1) {
        history.replace({});
        addToast("Invalid url parameters.", "error");
      } else {
        const paramKey = identifiedQueryParamKeys[0];
        const paramValue = queryParams[paramKey];
        const paramOptions = paramKeys[paramKey];

        if (paramValue) {
          fetchPostByIdAndEdit(paramValue, paramOptions);
        }
      }
    }
  }, [
    account,
    queryParams.postId,
    queryParams.postGroupId,
    queryParams.draftId,
    modalState.isOpen
  ]);

  if (isFetchingAccount || !account) {
    return (
      <Loading>
        <ReactLoading type="spin" color="#bbb" />
      </Loading>
    );
  }

  const section = location.pathname.replace(match.url, "").split("/")[1];

  const getMainLayout = () => (
    <Layout>
      <ComposerV2 />
      <MainHeader section={section} />
      <MainContent>
        <Switch>
          <Route path={match.url + "/dashboard"} component={DashboardPage} />
          <Route path={match.url + "/schedule"} component={SchedulePage} />
          <Route path={match.url + "/analytics"} component={AnalyticsPage} />
          {!isTelenetClient && (
            <Route path={match.url + "/content"} component={ContentPage} />
          )}
          <Route path={match.url + "/ideas"} component={IdeasPage} />
          <Route
            path={match.url + "/connect/facebook-page"}
            component={ConnectFacebookPage}
          />
          <Route
            path={match.url + "/connect/instagram-business"}
            component={ConnectInstagramBusinessPage}
          />
          <Route
            path={match.url + "/connect/linkedin-business"}
            component={ConnectLinkedinBusinessPage}
          />
          <Route path={match.url + "/contact"} component={ContactPage} />
          <Route
            path={match.url + "/settings"}
            component={AccountSettingsPage}
          />
          <Route path={match.url + "/blog"} component={BlogPage} />
          <Redirect to={match.url + "/dashboard"} />
        </Switch>
      </MainContent>
      {account.billing && account.billing.billingStatus === "in_trial" && (
        <TrialBanner>
          You have{" "}
          {differenceInDays(parseISO(account.billing.trialEnd), new Date())}{" "}
          days left on your trial.{" "}
          <Link to={`/accounts/${account.id}/checkout/plan`}>
            Buy willow and upgrade today
          </Link>
          .
        </TrialBanner>
      )}
      {(!account.billing || account.billing.billingStatus !== "in_trial") &&
        !isTelenetClient && (
          <PersistentBanner>
            Refer a friend & receive a 50€ gift voucher!
            <Link to={`/accounts/${account.id}/settings/referral`}>
              Refer today
            </Link>
            .
          </PersistentBanner>
        )}
    </Layout>
  );

  const layout =
    // status on our db
    account.status === accountStatuses.CANCELED ||
    // status on chargebee
    account.billing?.billingStatus === "cancelled" ? (
      <>
        <Route path={match.url + "/*"}>
          <Redirect to={match.url + "/deactivated"} />
        </Route>
        <Route path={match.url + "/"}>
          <Redirect to={match.url + "/deactivated"} />
        </Route>
      </>
    ) : (
      <>
        <Route path={match.url + "/*"}>{getMainLayout()}</Route>
      </>
    );
  return (
    <>
      <Switch>
        <Route
          path={match.url + "/checkout"}
          component={isTelenetClient ? TelenetCheckoutPage : CheckoutPage}
        />
        <Route path={match.url + "/deactivated"} component={LockoutPage} />
        <Route path={match.url + "/close"} component={CloseAccountPage} />

        {layout}
      </Switch>
      <ModalRoot />
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    modalState: state.modals,
    isFetchingAccount: state.account.isFetching
  };
};

export default connect(mapStateToProps, {
  fetchAccount: accountActions.fetchAccount,
  openModal: modalActions.openModal
})(AppContainer);
