import React, {
  useEffect,
  useContext,
  useReducer,
  useCallback,
  createContext
} from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  IActivities,
  initialValues,
  IDashboardState,
  dashboardReducer,
  IDashboardDispatch
} from "./reducer";
import {
  updateStats,
  updateActivities,
  updateSocialHealth,
  updateStatsFetchingState,
  updateActivitiesFetchingState,
  updateSocialHealthFetchingState
} from "./actionCreators";
import {
  fetchDashboardStats,
  fetchDashboardSocialHealth
} from "services/dashboard";
import {
  removeDraftById,
  changeDraftCountByValue
} from "state/actions/DraftActions";
import useComposer from "utils/useComposer";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { fetchDashboardActivities } from "services/activities";

const DashboardStateContext = createContext<IDashboardState | undefined>(
  undefined
);
DashboardStateContext.displayName = "DashboardStateContext";

const DashboardDispatchContext = createContext<IDashboardDispatch | undefined>(
  undefined
);
DashboardDispatchContext.displayName = "DashboardDispatchContext";

export interface IDashboardActions {
  editPost: (post: any) => void;
  onEntryDeleted: (post: any) => void;
  addEntryFromTask: (entry: any) => void;
  addDraftFromTask: (entry: any) => void;
  fetchActivities: (date?: string) => void;
}

const DashboardActionsContext = createContext<IDashboardActions | undefined>(
  undefined
);
DashboardActionsContext.displayName = "DashboardActionsContext";

const filterActivitiesByPostType = (activities: any[]): IActivities => {
  const filteredActivities: IActivities = {
    published: [],
    scheduled: [],
    todos: []
  };

  activities.forEach((activity: any) => {
    if (activity.type === "TASK") {
      filteredActivities.todos.push(activity);
    } else if (activity.type === "DRAFT_POST") {
      filteredActivities.todos.push(activity);
      filteredActivities.scheduled.push(activity);
    } else if (!activity.post.isStuck) {
      if (activity.post.status === "SCHEDULED") {
        filteredActivities.scheduled.push(activity);
      } else if (activity.post.status === "SENT") {
        filteredActivities.published.push(activity);
      }
    }
  });

  return filteredActivities;
};

export const DashboardProvider: React.FC<any> = ({ children }) => {
  const addToast = useToaster();
  const reduxDispatch = useDispatch();
  const { openComposer } = useComposer();
  const account = useSelector<any, any>(state => state.account.data);
  const [state, dispatch] = useReducer(dashboardReducer, {
    ...initialValues
  });

  const fetchActivities = useCallback(async () => {
    try {
      dispatch(updateActivitiesFetchingState(true));
      const activities = await fetchDashboardActivities();
      const filteredActivities = filterActivitiesByPostType(activities);

      dispatch(updateActivities(filteredActivities));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateActivitiesFetchingState(false));
    }
  }, [addToast]);

  const fetchSocialHealth = useCallback(async () => {
    try {
      dispatch(updateSocialHealthFetchingState(true));
      const socialHealth = await fetchDashboardSocialHealth(
        state.filterRange.periodInDays
      );

      dispatch(updateSocialHealth(socialHealth));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateSocialHealthFetchingState(false));
    }
  }, [addToast, state.filterRange.periodInDays]);

  const fetchStats = useCallback(async () => {
    try {
      dispatch(updateStatsFetchingState(true));
      const stats = await fetchDashboardStats(state.filterRange.periodInDays);

      dispatch(updateStats(stats));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateStatsFetchingState(false));
    }
  }, [addToast, state.filterRange.periodInDays]);

  useEffect(() => {
    fetchActivities();
    fetchSocialHealth();
    fetchStats();
  }, [fetchActivities, fetchSocialHealth, fetchStats]);

  const onPosted = useCallback(() => {
    fetchActivities();
  }, [fetchActivities]);

  const onDeleted = useCallback(
    (post: any) => {
      addToast(
        `Successfully deleted ${post.isDraft ? "draft" : "post"}`,
        "success"
      );
      fetchActivities();
    },
    [addToast, fetchActivities]
  );

  const editPost = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        post: entry.post,
        onPosted,
        onDeleted,
        composerParams: {
          skipAutoReload: true
        }
      });
    },
    [openComposer, account, onDeleted, onPosted]
  );

  const onEntryDeleted = useCallback(
    (post: any) => {
      if (post.isDraft) {
        reduxDispatch(changeDraftCountByValue(-1));
        reduxDispatch(removeDraftById(post.id));
      }
      onDeleted(post);
    },
    [onDeleted, reduxDispatch]
  );

  const addEntryFromTask = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        onPosted,
        onDeleted,
        task: entry.task,
        composerParams: {
          skipAutoReload: true
        }
      });
    },
    [openComposer, account, onDeleted, onPosted]
  );

  const addDraftFromTask = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        onPosted,
        onDeleted,
        task: entry.task,
        composerParams: {
          isDraft: true,
          skipAutoReload: true
        }
      });
    },
    [openComposer, onPosted, onDeleted, account]
  );

  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        <DashboardActionsContext.Provider
          value={{
            editPost,
            fetchActivities,
            onEntryDeleted,
            addDraftFromTask,
            addEntryFromTask
          }}
        >
          {children}
        </DashboardActionsContext.Provider>
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  );
};

export const useDashboardState = () => {
  const context = useContext(DashboardStateContext);

  if (context === undefined) {
    throw new Error(
      "useDashboardState must be used within a DashboardProvider"
    );
  }
  return context;
};

export const useDashboardDispatch = () => {
  const context = useContext(DashboardDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useDashboardDispatch must be used within a DashboardProvider"
    );
  }
  return context;
};

export const useDashboardActions = () => {
  const context = useContext(DashboardActionsContext);

  if (context === undefined) {
    throw new Error(
      "useDashboardActions must be used within a DashboardProvider"
    );
  }
  return context;
};
