import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ToastProvider, Toaster } from "@hellocontento/maillard";

import "@material/form-field/dist/mdc.form-field.css";
import "@material/textfield/dist/mdc.textfield.css";
import "@material/checkbox/dist/mdc.checkbox.css";
import "react-datepicker/dist/react-datepicker.css";
import "simplebar/dist/simplebar.min.css";
import "./assets/css/fonts.css";
import "./assets/css/date-picker-override.css";

import "./assets/css/mdc-override.css"; //TODO: Override from scss
import "./assets/css/animations.css";
import "./assets/css/styles.css";
// laz testing
import AppContainer from "./containers/AppContainer";
// import LoginPage from "./containers/auth/LoginPage";
import SignUpPage from "./containers/auth/signup";
import Login from "./containers/auth/login/LoginPage";
import ForgotPasswordPage from "./containers/auth/forgot-password/ForgotPasswordPage";
import NewPasswordPage from "./containers/auth/NewPasswordPage";
import GoToPage from "./containers/auth/GoToPage";

import ErrorPage from "./containers/ErrorPage";
import Logout from "./components/auth/Logout";
import WelcomePage from "./containers/WelcomePage";
import SecureRoute from "./components/common/SecureRoute";
import OnboardingPage from "./containers/onboarding/OnboardingPage";

import * as authActions from "./state/actions/AuthActions";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./theme";
import { AppProvider } from "./contextApi/appContext";
import { ComposerProvider } from "./contextApi/composerContext";
import FeatureAlertModal from "components/modals/pop-ups/FeatureAlertModal";
import FeatureAlertProvider from "components/modals/pop-ups/FeatureAlertProvider";

import SetupAccountModal from "components/modals/setupAccountModal";
import OnboardingInfoModal from "components/modals/onboardingInfoModal";

class App extends Component {
  state = {
    hasError: false,
    errorEventId: null
  };

  componentDidMount() {
    if (
      this.props.hasValidToken &&
      !this.props.location.pathname.startsWith("/goto/")
    ) {
      this.props.renewToken();
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV === "production") {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const errorEventId = Sentry.captureException(error);
        this.setState({ errorEventId: errorEventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const handleSentryReport = () => {
        Sentry.showReportDialog({ eventId: this.state.errorEventId });
      };

      return (
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorPage onErrorReport={handleSentryReport} />
        </ThemeProvider>
      );
    }

    return (
      <AppProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <FeatureAlertProvider>
              <>
                <GlobalStyle />
                <Toaster
                  options={{
                    position: "bottom-center"
                  }}
                />
                <OnboardingInfoModal />
                <FeatureAlertModal />
                <ComposerProvider>
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/signup" component={SignUpPage} />
                    <Route
                      path="/forgot-password"
                      component={ForgotPasswordPage}
                    />
                    <Route
                      path="/goto/:action/:nonce/:ref?"
                      component={GoToPage}
                    />
                    <Route path="/logout" component={Logout} />
                    <Route path="/">
                      <SetupAccountModal />
                      <Switch>
                        <SecureRoute path="/" exact component={WelcomePage} />
                        <SecureRoute
                          path="/onboarding"
                          exact
                          component={OnboardingPage}
                        />
                        <SecureRoute
                          path="/accounts/:accountId"
                          component={AppContainer}
                        />
                        <SecureRoute
                          path="/new-password"
                          component={NewPasswordPage}
                        />
                      </Switch>
                    </Route>
                    <Redirect to={"/"} />
                  </Switch>
                </ComposerProvider>
              </>
            </FeatureAlertProvider>
          </ToastProvider>
        </ThemeProvider>
      </AppProvider>

    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.auth.currentUser,
    hasValidToken: state.auth.hasValidToken
  };
};

export default withRouter(
  connect(mapStateToProps, {
    renewToken: authActions.renewToken
  })(App)
);

// trigger build