import styled from "styled-components";
import React, { useState } from "react";
import { Plus } from "styled-icons/feather";
import { Box } from "rebass/styled-components";

import { TooltipInfo } from "./styles";
import { MenuList, MenuItem } from "./Menu";
import Popper from "components/common/Popper";
import IconButton from "../common/IconButton";

const MenuButtonWrapper = styled(Box)`
  box-shadow: ${props =>
    props.isOpen
      ? `0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : `0 0 0 0 white, 0 0 0 -2px ${props.theme.colors.primary}`};
  border-radius: 50%;
`;

const MenuContainer = styled(Box)`
  background: white;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.grey02},
    0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px -20px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
  transition: 0.25s height;
  width: max-content;
`;

const MenuSection = styled(Box)`
  border-bottom: ${props =>
    !props.last ? `1px solid ${props.theme.colors.grey02}` : "none"};
  padding: ${props => (!props.first ? "8px" : "2px")} 0
    ${props => (!props.last ? "8px" : "2px")} 0;

  span.icon-sparkle {
    color: ${props => props.theme.colors.orange};
  }
`;

export default function NewPostMenu({ handleOpenComposer }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const openOldComposer = () => {
    handleOpenComposer();
    setShowOptions(false);
  };

  const openNewComposer = () => {
    handleOpenComposer(true);
    setShowOptions(false);
  };

  return (
    <div ref={setReferenceElement}>
      <MenuButtonWrapper onClick={toggleShowOptions} isOpen={showOptions}>
        <IconButton
          data-tip={"New Post"}
          id={"headerNewPostButton"}
          variant="green"
          icon={Plus}
          isActive={showOptions}
          size={36}
        />
        <TooltipInfo effect={"solid"} place={"bottom"} />
      </MenuButtonWrapper>
      <Popper
        offset={[30, 10]}
        placement="bottom-end"
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <Box>
          <MenuContainer>
            <MenuSection first last>
              <MenuList>
                <MenuItem
                  label="Open Composer"
                  icon="write"
                  onClick={openOldComposer}
                />
                <MenuItem
                  label="Open Composer V2"
                  icon="sparkle"
                  onClick={openNewComposer}
                />
              </MenuList>
            </MenuSection>
          </MenuContainer>
        </Box>
      </Popper>
    </div>
  );
}
