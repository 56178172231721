import { useSelector } from "react-redux";
import { Flex } from "rebass/styled-components";
import { format, parseISO, isPast } from "date-fns";
import React, { useRef, useEffect, useState } from "react";
import { CommentDetail } from "@styled-icons/boxicons-solid";

import {
  EntryTop,
  SlotEntry,
  EntryTime,
  EntryBottom,
  EntryChannels
} from "./styles";
import { theme } from "theme";
import Popper from "components/common/Popper";
import { formatForTimeline } from "utils/date";
import TASK_TYPES from "../constants/taskType";
import TaskOptions from "../common/TaskOptions";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "../common/postContextMenu/PostContextMenu";
import useComposer from "../../../utils/useComposer";
import EntryStatus from "../../common/contentTypes/EntryStatus";
import { RawToolTip, ToolTip, PostCreateButton } from "../common/styles";
import TaskConfirmationModal from "../form/TaskConfirmationModal";
import { Avatar, ChannelAvatar, Service } from "../../common/styles";
import contentTypes from "../../common/contentTypes/data/content-types";
import ContentTypeBadge from "../../common/contentTypes/ContentTypeBadge";

function MonthTask({
  task,
  eventId,
  day,
  onEditTask,
  onAddPost,
  isPreview,
  onRemoveTask,
  isPhantom,
  dashboardEntry
}) {
  const ref = useRef(null);
  const commentRef = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const account = useSelector(state => state.account.data);
  const draggingItemId = useSelector(state => state.dragDrop.draggingItemId);
  const isDragging = draggingItemId === task.id;

  const [isVisited, setIsVisited] = useState(false);

  const formattedTaskDate = formatForTimeline(new Date(task.date), true);
  const displayTime = format(parseISO(task.date), "H:mm");
  const { openComposer } = useComposer();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const toggleConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const handleAddPost = () => {
    openComposer({ account, task, onPosted: onAddPost });
  };

  const handleAddDraftPost = () => {
    openComposer({
      account,
      task,
      composerParams: {
        isDraft: true
      }
    });
  };

  const handleDelete = mode => {
    onRemoveTask({ task: task, type: mode });
  };

  const isPastTask = isPast(new Date(task.date));

  const deletionModalProps = task.taskGroupId
    ? {
        title: "Deleting a series of tasks",
        description:
          "You're deleting a task that is part of a series. Please choose the tasks you want to delete.",
        type: TASK_TYPES.SERIES,
        defaultChecked: TASK_TYPES.INSTANCE,
        showOptions: true,
        toggle: toggleConfirmationModal,
        buttonProps: {
          variant: "danger",
          label: "Delete",
          action: handleDelete
        }
      }
    : {
        title: "Delete task",
        description: "Are you sure you want to delete this task?",
        type: TASK_TYPES.INSTANCE,
        showOptions: false,
        toggle: toggleConfirmationModal,
        buttonProps: {
          variant: "danger",
          label: "Delete",
          action: handleDelete
        }
      };

  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);
  const [showTaskDescription, setShowTaskDescription] = useState(false);

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
    };
  }, [contextMenuPos]);

  return (
    <div>
      <TaskConfirmationModal
        isOpen={showConfirmationModal}
        {...deletionModalProps}
      />
      <SlotEntry
        ref={ref}
        onClick={dashboardEntry ? handleAddPost : toggleShowOptions}
        saved={task.id}
        isFilled={task.posts?.length > 0}
        isPast={isPastTask}
        preview={isPreview}
        previewColor={
          isPreview ? contentTypes[task.contentTypeId]?.previewColor : null
        }
        isPhantom={isPhantom}
        isOtherMonth={day.isOtherMonth}
        color={contentTypes[task.contentTypeId].color}
        bg={contentTypes[task.contentTypeId].bgColor}
        hover={contentTypes[task.contentTypeId].overColor}
        isDragging={isDragging}
        isContextMenuOpen={contextMenuPos.length > 0}
      >
        <EntryTop>
          <EntryTime preview={isPreview}>
            {dashboardEntry ? (
              <span className="date">{formattedTaskDate}</span>
            ) : (
              <>
                <EntryStatus
                  status={isPreview ? "TASK_SCHEDULE_PREVIEW" : "TASK_SCHEDULE"}
                />
                {displayTime} {task.postId && <small>(Filled)</small>}
              </>
            )}
          </EntryTime>
          <EntryChannels>
            {task.channels.map(channel => {
              return (
                <ChannelAvatar enabled={true} key={`${eventId}-${channel.id}`}>
                  <Avatar
                    src={channel.avatar}
                    width={20}
                    height={20}
                    noShadow={true}
                    isRounded={true}
                  />
                  <Service
                    key={channel.id}
                    title={channel.username}
                    type={channel.service}
                    size={10}
                  />
                </ChannelAvatar>
              );
            })}
          </EntryChannels>
        </EntryTop>
        <EntryBottom>
          <Flex alignItems={"center"}>
            {!isPastTask && !isPreview && (
              <>
                <PostCreateButton
                  data-tip
                  data-for={`${task.id}_POST`}
                  onMouseLeave={() => setIsVisited(true)}
                  className="icon-task-check action__create-post"
                  onClick={e => {
                    e.stopPropagation();
                    handleAddPost();
                  }}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                </PostCreateButton>
                <ToolTip
                  id={`${task.id}_POST`}
                  disable={isVisited}
                  place="top"
                  effect="solid"
                  className="tooltip"
                  variant="info"
                >
                  Click here to create and write your post
                </ToolTip>
              </>
            )}
            <ContentTypeBadge
              className="post__content-type"
              idea={task.postIdea ?? null}
              contentTypeId={task.contentTypeId}
              size="sm"
              variant={
                !task.id && !task.postId
                  ? "white"
                  : isPreview
                  ? "dark"
                  : "dark-inverse"
              }
              title={
                task.title
                  ? task.title
                  : task.postIdea
                  ? task.postIdea.title
                  : contentTypes[task.contentTypeId].title
              }
            />
          </Flex>
          {task.description && (
            <>
              <CommentDetail
                color={"#1C1E1F"}
                size={18}
                data-tip
                ref={commentRef}
                onMouseOver={() => setShowTaskDescription(true)}
                onMouseLeave={() => setShowTaskDescription(false)}
              />
              <Popper
                placement="top"
                offset={[0, 5]}
                arrowBg={theme.colors.text01}
                visible={showTaskDescription}
                exceptions={[commentRef.current]}
                referenceElement={commentRef.current}
                onClose={() => setShowTaskDescription(false)}
              >
                <RawToolTip>{task.description}</RawToolTip>
              </Popper>
            </>
          )}
        </EntryBottom>
        {!isPreview && (
          <>
            <RightClickPostContextMenu
              entry={{ task }}
              closeMenu={closeMenu}
              editTask={onEditTask}
              addPostFromTask={handleAddPost}
              contextMenuPos={contextMenuPos}
              deleteEntry={toggleConfirmationModal}
              addTaskDraftEntry={handleAddDraftPost}
            />
            <PostContextMenuWithButton
              entry={{ task }}
              editTask={onEditTask}
              contextWithPortal={true}
              addPostFromTask={handleAddPost}
              deleteEntry={toggleConfirmationModal}
              addTaskDraftEntry={handleAddDraftPost}
            />
          </>
        )}
      </SlotEntry>
      <Popper
        referenceElement={ref.current}
        visible={showOptions}
        exceptions={[ref.current]}
        onClose={() => setShowOptions(false)}
      >
        {!isPreview ? (
          <TaskOptions
            close={() => setShowOptions(false)}
            onEditTask={onEditTask}
            task={task}
            handleAddPost={handleAddPost}
            handleAddDraftEntry={handleAddDraftPost}
            handleRemoveTask={toggleConfirmationModal}
            isPastTask={isPastTask}
          />
        ) : null}
      </Popper>
    </div>
  );
}

export default MonthTask;
