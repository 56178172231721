import { push } from "connected-react-router";
import timezoneList from "compact-timezone-list";
import { getTimezone } from "countries-and-timezones";
import { takeLatest, call, put } from "redux-saga/effects";

import * as Sentry from "@sentry/browser";

import Auth from "../../utils/Auth";
import * as authActions from "../actions/AuthActions";
import { trackAnalyticsEvent } from "../actions/AnalyticsActions";

function* login({ credentials }) {
  try {
    yield call(Auth.login, credentials);
    yield call(fetchUserInfo);
    yield put(authActions.setShowSetupAccountModal(true));
    yield put(push("/")); //TODO: Redirect to previous page
  } catch (err) {
    yield put(authActions.loginFailed(err.message));
  }
}

function* logout() {
  try {
    yield call(Auth.logout);
    yield put(push("/"));
  } catch (err) {
    yield put(push("/"));
  }
}

function* fetchUserInfo() {
  try {
    const userInfo = yield call(Auth.getUserInfo);
    yield put(authActions.userInfoFetched(userInfo));
  } catch (err) {
    yield put(authActions.authenticationRequired());
  }
}

function* renewToken() {
  try {
    yield call(Auth.renewToken);
    yield put(authActions.tokenRenewed());
  } catch (err) {
    //Error renewing. Ignore.
  }
}

function setupProfitWellTracking(userInfo) {
  // retrieves the account id form the url
  const accountId = window.location.href
    ?.split("/accounts/")?.[1]
    ?.split("/")?.[0];

  if (accountId) {
    // looks for the account on th user accounts
    const account = userInfo.accounts?.find(a => a.id === accountId);

    // only tracks with profitwell if the user is part of the account
    // and the chargebee customer id is set. This skips the tracking of admins.
    // TODO: only enable when contento env is production
    if (account?.chargebeeCustomerId && window.profitwell) {
      console.log(
        "Initializing ProfitWell with customer id: " +
          account.chargebeeCustomerId
      );

      window.profitwell("start", {
        user_id: account.chargebeeCustomerId
      });
    }
  }
}

function identifyUser(action) {
  const { userInfo } = action;
  Sentry.configureScope(scope => {
    scope.setUser({ id: userInfo.id });
  });

  setupProfitWellTracking(userInfo);
}

function* startOnboarding({ accountData }) {
  try {
    // TODO: remove this later
    const { isGoogle } = accountData;
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timezoneData = getTimezone(browserTimezone);
    accountData.timezone =
      timezoneList.find(tz => tz.offset === timezoneData.utcOffsetStr)
        ?.tzCode || browserTimezone;
    accountData.billingCountry = timezoneData.countries[0];

    yield call(Auth.startOnboarding, accountData);
    const { email, password } = accountData;
    yield call(login, { credentials: { email, password } });
    yield call(
      trackAnalyticsEvent("Trail Started", {
        isGoogle
      })
    );
  } catch (err) {
    yield put(authActions.startOnboardingFailed(err.message));
  }
}

function* nonceLogin({ nonce }) {
  try {
    yield call(Auth.nonceLogin, nonce);
    yield call(fetchUserInfo);
    yield put(authActions.nonceLoginSuccess());
  } catch (err) {
    yield put(authActions.nonceLoginFailed());
  }
}

function* resetPassword({ email }) {
  try {
    yield call(Auth.resetPassword, email);
    yield put(authActions.resetPasswordSent());
  } catch (err) {
    //TODO: Do something else...
    yield put(authActions.resetPasswordFailed(err.message));
  }
}

function* updateUser({ values }) {
  try {
    const userInfo = yield call(Auth.updateUserInfo, values);
    yield put(authActions.userInfoFetched(userInfo));
  } catch (err) {}
}

export default function* root() {
  yield takeLatest(authActions.LOGIN, login);
  yield takeLatest(authActions.LOGOUT, logout);
  yield takeLatest(authActions.RENEW_TOKEN, renewToken);
  yield takeLatest(authActions.USER_INFO_REQUESTED, fetchUserInfo);
  yield takeLatest(authActions.USER_INFO_FETCHED, identifyUser);
  yield takeLatest(authActions.START_ONBOARDING, startOnboarding);
  yield takeLatest(authActions.NONCE_LOGIN, nonceLogin);
  yield takeLatest(authActions.RESET_PASSWORD, resetPassword);
  yield takeLatest(authActions.UPDATE_USER, updateUser);
}
