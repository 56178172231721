import PropTypes from "prop-types";
import _, { debounce } from "lodash";
import { connect } from "react-redux";
import twitterText from "twitter-text";
import React, { createRef } from "react";

import Editor from "draft-js-plugins-editor";
import {
  Modifier,
  EditorState,
  convertToRaw,
  ContentState,
  SelectionState,
  getDefaultKeyBinding
} from "draft-js";
import "draft-js/dist/Draft.css";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@hellocontento/draft-js-emoji-plugin/lib/plugin.css";
import pluginTheme from "./plugins.module.css";

import createMentionPlugin, {
  defaultSuggestionsFilter,
  defaultTheme as defaultMentionTheme
} from "@draft-js-plugins/mention";

import { callApi } from "utils/ContentoApi";
import createUrlPlugin from "./plugins/url";
import SplitConfirmation from "./SplitConfirmation";
import createGeneratorPlugin from "./plugins/generator";
import createEmojiPlugin from "@hellocontento/draft-js-emoji-plugin";
import { applyAllMentions } from "./plugins/socialMention/utils/addMention";
import MentionSuggestions from "./plugins/socialMention/components/MentionSuggestions";

import HashTagSelector from "./HashTagSelector";
import linkedinGuide from "assets/images/linkedin-mentioning-guide.gif";
import instagramGuide from "assets/images/instagram-mentioning-guide.gif";

import {
  Toolbox,
  EditorWrapper,
  ToolbarButton,
  CaptionWrapper,
  CaptionToolbar,
  ChannelSplitter,
  PopupPluginWrapper
} from "./styles";

import Popper from "components/common/Popper";
import regex from "./plugins/socialMention/regex";
import Switch from "components/common/form/Switch";
import { Headline6 } from "components/common/styles";
import * as analyticsActions from "state/actions/AnalyticsActions";

const entityMutability = "IMMUTABLE";
const mentionTrigger = "@";
const mentionPrefix = "@";

class Caption extends React.Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
    enabledServices: PropTypes.array.isRequired,
    availableTags: PropTypes.array.isRequired,
    onCaptionChange: PropTypes.func.isRequired,
    onUrlAdded: PropTypes.func.isRequired,
    service: PropTypes.oneOf([
      "all",
      "facebook",
      "twitter",
      "linkedin",
      "instagram"
    ]),
    visible: PropTypes.bool.isRequired,
    captions: PropTypes.array,
    splitEditor: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
    defaultEditorState: PropTypes.object,
    setDefaultEditorState: PropTypes.func.isRequired,
    wasCaptionUsed: PropTypes.bool.isRequired,
    setWasCaptionUsed: PropTypes.func.isRequired
  };

  emojiButtonRef = createRef();

  constructor(props) {
    super(props);

    let editorState = EditorState.createEmpty();
    if (props.post.caption[props.service]) {
      editorState =
        props.defaultEditorState ??
        EditorState.createWithContent(
          ContentState.createFromText(props.post.caption[props.service])
        );

      if (!props.defaultEditorState) {
        const mentionsToApply = (props.post.mentions || []).filter(
          mention => mention.service === props.service
        );

        if (mentionsToApply.length) {
          editorState = applyAllMentions(
            editorState,
            mentionsToApply,
            mentionPrefix,
            mentionTrigger,
            entityMutability,
            ["twitter", "instagram"]
          );
        }
      }
    }

    this.state = {
      editorState,
      tags: [],
      characterCount: 0,
      urls: [],
      isFocused: false,
      hashTagTooltipOpen: false,
      suggestedTags: this.props.availableTags.map(tag => {
        return { name: `#${tag.tag}` };
      }),
      offsetEmoji: 0,
      showHashTags: false,
      referenceElement: null,
      isFetchingSocialMentions: false,
      suggestedSocialMentions: [],
      socialMentionTooltipOpen: false,
      showConnectedChannels: false,
      showSplitConfirmation: false
    };

    this.urlPlugin = createUrlPlugin({
      onUrlAdded: async url => {
        const urlInfo = await this.props.onUrlAdded(url);
        this.generatorPlugin.captionHandler.reloadPosition();
        return urlInfo.shortUrl;
      }
    });

    if (
      this.props.captions &&
      this.props.captions.length > 1 &&
      !this.props.wasCaptionUsed
    ) {
      this.generatorPlugin = createGeneratorPlugin({
        captions: this.props.captions,
        setWasCaptionUsed: this.props.setWasCaptionUsed
      });
    }

    const tagTheme = {
      ...defaultMentionTheme,
      mention: pluginTheme.editorHashtag
    };
    this.hashtagListPlugin = createMentionPlugin({
      mentionTrigger: "#",
      theme: tagTheme
    });

    const socialMentionTheme = {
      ...defaultMentionTheme,
      mention: pluginTheme.socialMention,
      mentionSuggestions: pluginTheme.socialMentionSuggestions
    };
    this.socialMentionPlugin = createMentionPlugin({
      entityMutability,
      mentionTrigger,
      mentionPrefix,
      theme: socialMentionTheme,
      supportWhitespace: true,
      mentionSuggestionsComponent: MentionSuggestions,
      mentionRegExp: regex
    });

    this.emojiPlugin = createEmojiPlugin({
      theme: pluginTheme,
      useNativeArt: true
    });
  }

  setReferenceElement = ref => {
    this.setState({
      referenceElement: ref
    });
  };

  scrollHandler = () => {
    if (this?.emojiButtonRef?.current) {
      const boundingRect = this.emojiButtonRef.current.getBoundingClientRect();

      this.setState({
        offsetEmoji: boundingRect.top
      });
    }
  };

  componentDidMount() {
    if (this.props.post.caption[this.props.service]) {
      //disable shortening when this is an existing post, this avoids the user from unshortening the url again
      this.urlPlugin.urlHandler.processUrls(!!this.props.post?.id);
      if (this.generatorPlugin) {
        this.generatorPlugin.captionHandler.processCaptions();
      }
    }

    this.scrollHandler();

    if (
      this.emojiButtonRef.current &&
      this.emojiButtonRef.current.offsetParent
    ) {
      this.emojiButtonRef.current.offsetParent.addEventListener(
        "scroll",
        this.scrollHandler
      );
    }
    window.addEventListener("resize", this.scrollHandler);
  }

  componentWillUnmount() {
    if (
      this.emojiButtonRef.current &&
      this.emojiButtonRef.current.offsetParent
    ) {
      this.emojiButtonRef.current.offsetParent.removeEventListener(
        "scroll",
        this.scrollHandler
      );
    }
    window.removeEventListener("resize", this.scrollHandler);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.enabledServices.length !== this.props.enabledServices.length
    ) {
      this.onContentUpdated();
    }

    //Focus when it gets visible
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.editor.focus();
        this.scrollHandler();

        if (
          this.emojiButtonRef.current &&
          this.emojiButtonRef.current.offsetParent
        ) {
          this.emojiButtonRef.current.offsetParent.addEventListener(
            "scroll",
            this.scrollHandler
          );
        }
        window.addEventListener("resize", this.scrollHandler);
      } else {
        if (
          this.emojiButtonRef.current &&
          this.emojiButtonRef.current.offsetParent
        ) {
          this.emojiButtonRef.current.offsetParent.removeEventListener(
            "scroll",
            this.scrollHandler
          );
        }
        window.removeEventListener("resize", this.scrollHandler);
      }
    }
  }

  onClick = () => {
    this.editor.focus();
  };

  onChange = editorState => {
    this.props.setDefaultEditorState({ [this.props.service]: editorState });
    this.setState({ editorState }, this.onContentUpdated);
  };

  getCharacterLimit = (limitingService = {}) => {
    const charLimits = {
      twitter: 280,
      linkedin: 3000,
      facebook: 5000,
      instagram: 2200
    };
    let limit = null;
    if (this.props.service === "all") {
      for (let service of this.props.enabledServices) {
        if (limit === null || charLimits[service] < limit) {
          limit = charLimits[service];
          limitingService.service = service;
        }
      }
    } else {
      limit = charLimits[this.props.service];
      limitingService.service = this.props.service;
    }
    return limit;
  };

  onContentUpdated = () => {
    const { editorState } = this.state;
    const caption = editorState.getCurrentContent().getPlainText();

    const isTwitterEnabled = this.props.enabledServices.includes("twitter");
    const characterCount = isTwitterEnabled
      ? twitterText.getTweetLength(caption)
      : caption.length;
    this.setState({
      tags: twitterText.extractHashtags(caption),
      characterCount: characterCount
    });

    //Pass an object to extract additional info
    const limitingService = { service: null };
    const characterLimit = this.getCharacterLimit(limitingService);
    const validationErrors = {};
    if (characterLimit && characterCount > characterLimit) {
      validationErrors.charLimit = {
        characterCount,
        characterLimit,
        service: limitingService.service
      };
    }
    const tagLimit = 30;
    const tagCount = (caption.match(/#[\w|\d]+?/gm) ?? []).length;

    if (tagCount > tagLimit) {
      validationErrors.tagLimit = {
        tagCount,
        tagLimit
      };
    }

    const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;
    const entities = Object.values(entityMap)
      .filter(item => item.type === "mention")
      .map(item => item.data.mention);

    if (this.props.post.socialMentions) {
      this.props.post.socialMentions[this.props.service] = entities;
    } else {
      this.props.post.socialMentions = {
        [this.props.service]: entities
      };
    }

    this.props.onCaptionChange(caption, this.props.service, validationErrors);
  };

  onSplit = () => {
    try {
      this.onContentUpdated();
      this.props.splitEditor();
    } catch (_) {}
  };

  cancelSplit = () => {
    this.setState({
      showSplitConfirmation: false
    });
  };

  onEditorFocus = (...props) => {
    this.setState({ isFocused: true });
  };

  onEditorBlur = () => {
    this.setState({ isFocused: false });
  };

  onSearchChange = ({ value }) => {
    const availableTags = this.props.availableTags.map(tag => {
      return { name: `#${tag.tag}` };
    });

    const suggestedTags = defaultSuggestionsFilter(
      value,
      availableTags.filter(tag => {
        return !this.state.tags.includes(tag.name.substr(1));
      })
    );

    this.setState({
      suggestedTags: suggestedTags
    });
  };

  onHashTagSuggestionOpenChange = _open => {
    this.setState({
      hashTagTooltipOpen: _open
    });
  };

  onAddHashTag = () => {
    // get the mention object selected
  };

  onHashTagSelected = tag => {
    const { editorState } = this.state;
    let contentState = editorState.getCurrentContent();

    try {
      contentState = Modifier.insertText(
        contentState,
        EditorState.moveSelectionToEnd(editorState).getSelection(),
        ` ${tag}`
      );
    } catch (_) {
      console.error("Selection range conflict");
    }

    this.setState(
      {
        editorState: EditorState.push(
          editorState,
          contentState,
          "insert-fragment"
        )
      },
      this.onContentUpdated
    );
  };

  handleHashTagButtonState = () => {
    this.setState(
      {
        showHashTags: !this.state.showHashTags
      },
      () => {
        if (this.state.showHashTags) {
          this.props.trackAnalyticsEvent("Toolbar Button Clicked", {
            button: "hashtag"
          });
        }
      }
    );
  };

  onSocialMentionSuggestionOpenChange = _open => {
    this.setState({
      socialMentionTooltipOpen: _open
    });
  };

  setSuggestedSocialMentions = suggestedSocialMentions => {
    this.setState({
      suggestedSocialMentions
    });
  };

  fetchMentions = async value => {
    try {
      let suggestions = await callApi(
        {
          url: `/accounts/${this.props.accountId}/mentions/${
            this.props.service
          }?search-term=${encodeURIComponent(value)}`,
          method: "get"
        },
        false
      );

      this.setSuggestedSocialMentions(suggestions);
    } catch (error) {
      console.error(error);
      this.setSuggestedSocialMentions([]);
    } finally {
      this.setState({
        isFetchingSocialMentions: false
      });
    }
  };

  debouncedFetchMentions = debounce(this.fetchMentions, 500);

  onSocialMentionSearch = async ({ value }) => {
    this.setState({
      isFetchingSocialMentions: true
    });

    this.debouncedFetchMentions(value);
  };

  editorKeyBindingFn = e => {
    const currentContent = this.state.editorState.getCurrentContent();
    const hasGeneratedCaptionEntity = !!_.values(
      convertToRaw(currentContent)?.entityMap ?? {}
    ).find(
      entity =>
        !!entity.type && entity.type.toUpperCase() === "GENERATED_CAPTION"
    );

    if (
      e.key === "@" &&
      this.props.enabledServices.length > 0 &&
      this.props.service === "all"
    ) {
      const selectionState = this.state.editorState.getSelection();
      const anchorKey = selectionState.getAnchorKey();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const currentContentBlockText = currentContentBlock.getText();
      const end = selectionState.getEndOffset();

      const prevChar = currentContentBlockText.charAt(end - 1);
      const nextchar = currentContentBlockText.charAt(end);

      if (
        (prevChar === " " || prevChar === "") &&
        (nextchar === " " || nextchar === "")
      ) {
        return "social-mention";
      }
    } else if (
      (e.keyCode === 8 || e.keyCode === 46) &&
      hasGeneratedCaptionEntity
    ) {
      if (!this.generatorPlugin) {
        return "generated-caption-deletion";
      }

      const captionEntityKey = this.generatorPlugin.captionHandler
        .captionEntityKey;

      // Get caption entity with block data
      const entities = [];
      currentContent.getBlocksAsArray().forEach(block => {
        let selectedEntity = null;
        block.findEntityRanges(
          character => {
            if (!!character.getEntity && character.getEntity() !== null) {
              const entityKey = character.getEntity();
              if (entityKey === captionEntityKey) {
                selectedEntity = {
                  entityKey,
                  blockKey: block.getKey(),
                  entity: currentContent.getEntity(character.getEntity())
                };
                return true;
              }
            }
            return false;
          },
          (start, end) => {
            entities.push({ ...selectedEntity, start, end });
          }
        );
      });

      // Remove entity data and its association
      let newEditorState = this.generatorPlugin.captionHandler.removeCaptionEntity();
      const selectionState = newEditorState.getSelection();
      let newContentState = newEditorState.getCurrentContent();

      // Create selection state with the block key and cursor offset
      const rangeToReplace = SelectionState.createEmpty(
        entities[0].blockKey
      ).merge({
        anchorOffset:
          e.keyCode === 8
            ? selectionState.getAnchorOffset() - 1
            : selectionState.getAnchorOffset(),
        focusOffset:
          e.keyCode === 8
            ? selectionState.getFocusOffset()
            : selectionState.getFocusOffset() + 1
      });

      try {
        // Remove the letter on delete or backspace
        newContentState = Modifier.removeRange(
          newContentState,
          rangeToReplace,
          e.keyCode === 8 ? "backward" : "forward"
        );
      } catch (_) {
        console.error("Selection range conflict");
      }

      // Change editor state with the new content
      newEditorState = EditorState.push(
        newEditorState,
        newContentState,
        e.keyCode === 8 ? "backspace-character" : "delete-character"
      );

      // Place the cursor at the correct location after deleting the letter
      newEditorState = EditorState.forceSelection(
        newEditorState,
        SelectionState.createEmpty(entities[0].blockKey).merge({
          anchorOffset:
            e.keyCode === 8
              ? selectionState.getFocusOffset() - 1
              : selectionState.getFocusOffset(),
          focusOffset:
            e.keyCode === 8
              ? selectionState.getFocusOffset() - 1
              : selectionState.getFocusOffset()
        })
      );

      this.setState({
        editorState: newEditorState
      });

      return "generated-caption-deletion";
    }

    getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command, editorState) => {
    if (command === "social-mention") {
      let contentState = editorState.getCurrentContent();

      try {
        contentState = Modifier.insertText(
          contentState,
          editorState.getSelection(),
          `@`
        );
      } catch (_) {
        console.error("Selection range conflict");
      }

      this.setState(
        {
          editorState: EditorState.push(
            editorState,
            contentState,
            "insert-fragment"
          )
        },
        () => {
          if (!this.props.wasSplit) {
            this.setState({
              showSplitConfirmation: true
            });
          } else {
            this.onSplit();
          }
        }
      );

      return "handled";
    } else if (command === "generated-caption-deletion") {
      return "handled";
    }

    return "not-handled";
  };

  setShowConnectedChannels = value => {
    this.setState({
      showConnectedChannels: value
    });
  };

  handleAtToolClicked = () => {
    let newContentState = this.state.editorState.getCurrentContent();
    const selectionState = this.state.editorState.getSelection();

    try {
      newContentState = Modifier.insertText(
        newContentState,
        selectionState,
        "@"
      );
    } catch (_) {
      console.error("Selection range conflict");
    }

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-fragment"
    );

    this.setState(
      {
        editorState: EditorState.forceSelection(
          newEditorState,
          newContentState.getSelectionAfter()
        )
      },
      () => {
        if (this.props.service === "all") {
          if (!this.props.wasSplit) {
            this.setState({
              showSplitConfirmation: true
            });
          } else {
            this.onSplit();
          }
        }
      }
    );
  };

  render() {
    const { editorState, isFocused } = this.state;
    const { MentionSuggestions: HashTagSuggestions } = this.hashtagListPlugin;
    const {
      MentionSuggestions: SocialMentionSuggestions
    } = this.socialMentionPlugin;
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;

    //const characterLimit = this.getCharacterLimit();
    const plugins = [this.urlPlugin, this.hashtagListPlugin, this.emojiPlugin];

    if (this.generatorPlugin) {
      plugins.push(this.generatorPlugin);
    }

    if (this.props.service !== "all" && this.props.enabledServices.length > 0) {
      plugins.push(this.socialMentionPlugin);
    }

    let mentionGuide = null;
    let emptyTitle = "No results";
    let emptyContent =
      "We couldn’t find the company you’re looking for. Due to restrictions it’s not possible to mention people.";
    let emptySubcontent = null;
    if (this.props.service === "linkedin") {
      mentionGuide = linkedinGuide;
      emptyTitle = "Copy and paste the company handle";
      emptyContent =
        "Find the the company you want to mention on LinkedIn & copy the name in their URL.";
      emptySubcontent = "You can only mention bussines or connected profiles.";
    } else if (this.props.service === "instagram") {
      mentionGuide = instagramGuide;
      emptyTitle = "Copy and paste the instagram handle";
      emptyContent =
        "Find the the company you want to mention on Instagram & copy the handle.";
    }

    return (
      <CaptionWrapper isFocused={isFocused} isVisible={this.props.visible}>
        <EditorWrapper onClick={this.onClick}>
          <Editor
            placeholder="Write something here..."
            editorState={editorState}
            onChange={this.onChange}
            onFocus={this.onEditorFocus}
            onBlur={this.onEditorBlur}
            plugins={plugins}
            stripPastedStyles={true}
            ref={element => {
              this.editor = element;
            }}
            keyBindingFn={this.editorKeyBindingFn}
            handleKeyCommand={this.handleKeyCommand}
          />
          <PopupPluginWrapper>
            <SocialMentionSuggestions
              open={
                this.state.socialMentionTooltipOpen ||
                this.state.showConnectedChannels
              }
              onOpenChange={this.onSocialMentionSuggestionOpenChange}
              onSearchChange={this.onSocialMentionSearch}
              suggestions={this.state.suggestedSocialMentions}
              setSuggestedSocialMentions={this.setSuggestedSocialMentions}
              isFetchingSocialMentions={this.state.isFetchingSocialMentions}
              mentionGuide={mentionGuide}
              emptyTitle={emptyTitle}
              emptyContent={emptyContent}
              emptySubcontent={emptySubcontent}
              showConnectedChannels={this.state.showConnectedChannels}
              setShowConnectedChannels={this.setShowConnectedChannels}
              secondaryMenu={this.props.service === "linkedin"}
            />
          </PopupPluginWrapper>
          <PopupPluginWrapper>
            <HashTagSuggestions
              open={this.state.hashTagTooltipOpen}
              onOpenChange={this.onHashTagSuggestionOpenChange}
              onSearchChange={this.onSearchChange}
              suggestions={this.state.suggestedTags}
              onAddMention={this.onAddHashTag}
            />
          </PopupPluginWrapper>
          <PopupPluginWrapper>
            <EmojiSuggestions />
          </PopupPluginWrapper>
        </EditorWrapper>
        <CaptionToolbar>
          {(!this.props.post.id ||
            this.props.post.isDraft ||
            this.props.post.isPostGroup) &&
            this.props.enabledServices.length > 1 && (
              <ChannelSplitter>
                <Switch
                  htmlFor="channel-split"
                  onToggle={this.props.splitEditor}
                  checked={this.props.service !== "all"}
                />
                <Headline6 className="label">Caption split</Headline6>
              </ChannelSplitter>
            )}
          <Toolbox>
            {this.props.enabledServices.length > 0 && (
              <ToolbarButton
                className="icon-at"
                onClick={this.handleAtToolClicked}
                disabled={this.props.enabledServices.length < 1}
              />
            )}
            <div ref={this.setReferenceElement}>
              <ToolbarButton
                className="icon-hashtag"
                onClick={this.handleHashTagButtonState}
              />

              <Popper
                referenceElement={this.state.referenceElement}
                visible={this.state.showHashTags}
                onClose={() =>
                  this.setState({
                    showHashTags: false
                  })
                }
                exceptions={[this.state.referenceElement]}
              >
                <HashTagSelector
                  tags={this.props.availableTags}
                  selectedTags={this.state.tags}
                  onSelect={this.onHashTagSelected}
                />
              </Popper>
            </div>
            <ToolbarButton
              className="icon-smiley emoji"
              ref={this.emojiButtonRef}
              offset={this.state.offsetEmoji}
            >
              <EmojiSelect />
            </ToolbarButton>
          </Toolbox>
        </CaptionToolbar>
        <SplitConfirmation
          isOpen={this.state.showSplitConfirmation}
          onConfirm={this.onSplit}
          onCancel={this.cancelSplit}
        />
      </CaptionWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  trackAnalyticsEvent: analyticsActions.trackAnalyticsEvent
})(Caption);
