import React from "react";
import ReactLoading from "react-loading";
import { Box } from "rebass/styled-components";
import { Flex } from "rebass/styled-components";
import { Link, useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";

import {
  WidgetCard,
  WidgetBody,
  WidgetTitle,
  WidgetHeader,
  WidgetFooter,
  PostCardWrapper,
  WidgetEmptyIcon,
  WidgetEmptyTitle,
  WidgetEmptyContent,
  WidgetEmptyDescription
} from "./styles";
import {
  useDashboardState,
  useDashboardActions
} from "contextApi/dashboardContext";
import EmptyTaskList from "../emptyTaskList";
import Button from "components/common/Button";
import { useContentoApi } from "utils/useContentoApi";
import MonthPost from "components/schedule/month/MonthPost";
import MonthTask from "components/schedule/month/MonthTask";
import TASK_TYPES from "components/schedule/constants/taskType";
import emptyIcon from "assets/images/dashboard-tasks-empty.svg";
import { ActivityType } from "components/schedule/types/activityTypes";

function TaskEmptyState({ channels, accountId }) {
  const history = useHistory();

  return channels.length !== 0 ? (
    <>
      <WidgetEmptyContent>
        <WidgetEmptyIcon>
          <img src={emptyIcon} width="64" alt="No tasks" />
        </WidgetEmptyIcon>
        <WidgetEmptyTitle>No tasks ahead</WidgetEmptyTitle>
        <WidgetEmptyDescription>
          Planning your calendar ahead helps you stay consistent.
        </WidgetEmptyDescription>
      </WidgetEmptyContent>
      <Box marginTop="auto">
        <Button
          marginBottom={8}
          width="100%"
          onClick={() =>
            history.push(`/accounts/${accountId}/schedule/month?taskId=new`)
          }
        >
          Create task
        </Button>
        <Button
          width="100%"
          variant="tertiary"
          onClick={() => history.push(`/accounts/${accountId}/ideas`)}
        >
          Get inspired
        </Button>
      </Box>
    </>
  ) : (
    <WidgetEmptyContent>
      <WidgetEmptyIcon>
        <img src={emptyIcon} width="64" alt="No tasks" />
      </WidgetEmptyIcon>
      <WidgetEmptyTitle>No tasks ahead</WidgetEmptyTitle>
      <WidgetEmptyDescription>
        Please{" "}
        <Link to={`/accounts/${accountId}/settings/channels`}>
          connect a channel
        </Link>{" "}
        in order to start creating tasks and planning your calendar
      </WidgetEmptyDescription>
    </WidgetEmptyContent>
  );
}

function TaskWidget({ account, fromDate, toDate }) {
  const history = useHistory();
  const addToast = useToaster();
  const {
    activities: {
      isFetching,
      entries: { todos }
    }
  } = useDashboardState();
  const { editPost, onEntryDeleted, fetchActivities } = useDashboardActions();

  const [removeTask] = useContentoApi(`accounts/${account.id}/tasks`, "delete");
  const [removeTaskGroup] = useContentoApi(
    `accounts/${account.id}/task-groups`,
    "delete"
  );

  const handleEditTask = async ({ task, type }) => {
    if (type === TASK_TYPES.SERIES) {
      history.push(
        `/accounts/${account.id}/schedule/month?taskGroupId=${task.taskGroupId}`
      );
    } else {
      history.push(`/accounts/${account.id}/schedule/month?taskId=${task.id}`);
    }
  };

  const handleRemoveTask = async ({ task, type }) => {
    let removePromise = null;

    if (type === TASK_TYPES.SERIES) {
      removePromise = removeTaskGroup({ url: task.taskGroupId });
    } else {
      removePromise = removeTask({ url: task.id || task.taskId });
    }

    removePromise
      .then(() => {
        addToast("Task successfully deleted", "success");
        fetchActivities();
      })
      .catch(err => {
        addToast(
          err.message || "There was an error deleting your task",
          "error"
        );
        fetchActivities();
      });
  };

  const handleViewSchedule = () => {
    history.push(`/accounts/${account.id}/schedule`);
  };

  return (
    <WidgetCard>
      <WidgetHeader>
        <WidgetTitle>Your to-do’s</WidgetTitle>
      </WidgetHeader>
      {isFetching ? (
        <WidgetBody>
          <Flex justifyContent="center" alignItems="center" flex="1">
            <ReactLoading color="#bbb" type="spin" width={20} />
          </Flex>
        </WidgetBody>
      ) : account.channels.length < 1 ? (
        <WidgetBody fullContainer>
          <EmptyTaskList />
        </WidgetBody>
      ) : todos.length > 0 ? (
        <WidgetBody>
          {todos.map(item => (
            <div key={item.id || item.task.id}>
              {item.type === "TASK" ? (
                <MonthTask
                  dashboardEntry
                  day={{ isOtherMonth: false }}
                  eventId={item.task.id}
                  task={item.task}
                  onAddPost={fetchActivities}
                  onEditTask={handleEditTask}
                  onRemoveTask={handleRemoveTask}
                />
              ) : (
                <PostCardWrapper>
                  <MonthPost
                    reload={fetchActivities}
                    onClick={() => editPost(item)}
                    isDraft={item.type === ActivityType.DRAFT_POST}
                    isPostGroup={item.type === ActivityType.POST_GROUP}
                    onPostDeleted={onEntryDeleted}
                    post={item.post}
                    dashboardEntry
                  />
                </PostCardWrapper>
              )}
            </div>
          ))}
        </WidgetBody>
      ) : (
        <TaskEmptyState channels={account.channels} accountId={account.id} />
      )}
      {account.channels.length < 1 ||
        (todos.length > 0 && (
          <WidgetFooter>
            <Button
              size="xs"
              variant="secondaryQuietAlt"
              onClick={handleViewSchedule}
            >
              View calendar
            </Button>
          </WidgetFooter>
        ))}
    </WidgetCard>
  );
}

export default TaskWidget;
