import React from "react";

import { MonthGroup as Group } from "./styles";
import { getPostsGrouped } from "../utils/entryUtils";
import { ActivityType } from "../types/activityTypes";

import MonthPost from "./MonthPost";
import MonthTask from "./MonthTask";
import { MonthGroupPost } from "./MonthEntry";
import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";
import { PostStatus } from "../types/postStatus";

function MonthGroup({
  entries,
  day,
  onEditTask,
  onRemoveTask,
  onAddPost,
  onClickPost,
  onPostDeleted
}) {
  const sorted = [
    ...entries.slot,
    ...entries.scheduled,
    ...entries.drafted,
    ...entries.grouped,
    ...entries.posted
  ].sort((a, b) => new Date(a.time) - new Date(b.time));

  const grouped = getPostsGrouped(sorted);

  return (
    <Group>
      {grouped.map((item, index) =>
        item.length === 0 ? null : item.length === 1 ? (
          [
            ActivityType.POST,
            ActivityType.DRAFT_POST,
            ActivityType.POST_GROUP
          ].includes(item[0].type) ? (
            <DraggableWrapper
              key={item[0].id}
              id={`${item[0].id}`}
              index={index}
              isDisabled={item[0].post?.status === PostStatus.SENT}
            >
              <MonthPost
                day={day}
                reload={onAddPost}
                onClick={onClickPost}
                key={item[0].id}
                isDraft={item[0].type === ActivityType.DRAFT_POST}
                isPostGroup={item[0].type === ActivityType.POST_GROUP}
                isPhantom={item[0].isPhantom}
                onPostDeleted={onPostDeleted}
                post={
                  item[0].type === ActivityType.POST
                    ? item[0].post
                    : item[0].type === ActivityType.POST_GROUP
                    ? { ...item[0].post, isPostGroup: true }
                    : { ...item[0].draft, isDraft: true }
                }
              />
            </DraggableWrapper>
          ) : (
            <DraggableWrapper
              key={item[0].id}
              id={item[0].id}
              index={index}
              isDisabled={item[0].preview}
            >
              <MonthTask
                index={index}
                day={day}
                key={item[0].id}
                eventId={item[0].id}
                task={item[0].task}
                onAddPost={onAddPost}
                isPreview={item[0].preview}
                onEditTask={onEditTask}
                onRemoveTask={onRemoveTask}
                isPhantom={item[0].isPhantom}
              />
            </DraggableWrapper>
          )
        ) : (
          <MonthGroupPost
            key={item[0].id}
            posts={item}
            day={day}
            onClick={onClickPost}
            onPostDeleted={onPostDeleted}
          />
        )
      )}
    </Group>
  );
}

export default MonthGroup;
