import React, {
  RefObject,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  createContext
} from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  today,
  getTime,
  endOfMonth,
  startOfDay,
  difference,
  addDuration,
  startOfMonth,
  getDateLabel,
  parseStringToDate
} from "utils/date";
import {
  PostType,
  DateRange,
  initialValues,
  ITimelineState,
  timelineReducer,
  ITimelineDispatch
} from "./reducer";
import {
  resetState,
  setIsFetching,
  setDateToBeFocused,
  setDateRangeToBeFetched,
  updateTimelineActivities
} from "./actionCreators";
import {
  removeDraftById,
  changeDraftCountByValue
} from "state/actions/DraftActions";
import useComposer from "utils/useComposer";
import { openPostOnChannel } from "utils/channels";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { fetchTimelineActivities } from "services/activities";

export interface ITimelineValues {
  dateRefs: {
    [key in PostType]: {
      [key: string]: RefObject<HTMLDivElement>;
    };
  };
}

const initialDateRefs: ITimelineValues["dateRefs"] = {
  timeline: {},
  scheduled: {},
  published: {},
  drafts: {}
};

const TimelineStateContext = createContext<
  (ITimelineState & ITimelineValues) | undefined
>(undefined);
TimelineStateContext.displayName = "TimelineStateContext";

const TimelineDispatchContext = createContext<ITimelineDispatch | undefined>(
  undefined
);
TimelineDispatchContext.displayName = "TimelineDispatchContext";

export interface ITimelineActions {
  editPost: (post: any) => void;
  writePost: (date: Date) => void;
  createTask: (date: Date) => void;
  writeDraft: (date: Date) => void;
  onEntryDeleted: (post: any) => void;
  openPublishedPost: (post: any) => void;
  addEntryFromTask: (entry: any) => void;
  addDraftFromTask: (entry: any) => void;
  refetchEntries: (date?: string) => void;
}

const TimelineActionsContext = createContext<ITimelineActions | undefined>(
  undefined
);
TimelineActionsContext.displayName = "TimelineActionsContext";

export const TimelineProvider: React.FC<any> = ({ children }: any) => {
  const history = useHistory();
  const addToast = useToaster();
  const reduxDispatch = useDispatch();
  const { openComposer } = useComposer();
  const account = useSelector<any, any>(state => state.account.data);
  const dateRefs: ITimelineValues["dateRefs"] = {
    ...initialDateRefs
  };

  const [state, dispatch] = useReducer(timelineReducer, {
    ...initialValues
  });
  const { postType, activities, dateRangeToBeFetched } = state;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const updateActivities = useCallback(
    async (postType: PostType, params: { [key: string]: any }) => {
      try {
        dispatch(setIsFetching(true));
        const activities = await fetchTimelineActivities(postType, params);
        dispatch(setDateRangeToBeFetched(null));
        dispatch(updateTimelineActivities(activities));
      } catch (error) {
        addToast((error as any).message, "error");
      } finally {
        dispatch(setIsFetching(false));
      }
    },
    [addToast]
  );

  useEffect(() => {
    if (dateRangeToBeFetched !== null && !!postType) {
      const monthLabel = getDateLabel(dateRangeToBeFetched.start).slice(0, 7);
      const lastUpdated = activities[monthLabel]?.[postType]?.lastUpdated;

      let dateRange: DateRange = dateRangeToBeFetched;
      if (postType === "published") {
        const diff = difference(
          startOfMonth(dateRangeToBeFetched.start),
          startOfMonth(today()),
          "months"
        );
        if (diff > 0) {
          dateRange = {
            start: startOfMonth(today()),
            end: endOfMonth(today())
          };
        }
      } else if (postType === "scheduled") {
        const diff = difference(
          startOfMonth(dateRangeToBeFetched.start),
          startOfMonth(today()),
          "months"
        );

        if (diff <= 0) {
          dateRange = {
            start: startOfDay(today()),
            end: endOfMonth(today())
          };
        }
      }

      if (
        !state.isFetching &&
        (dateRange.override ||
          !lastUpdated ||
          difference(today(), lastUpdated, "minutes") >= 1)
      ) {
        updateActivities(postType, {
          fromDate: dateRange.start,
          toDate: dateRange.end
        });
      }
    }
  }, [
    updateActivities,
    dateRangeToBeFetched,
    postType,
    activities,
    state.isFetching
  ]);

  const createTask = useCallback(
    (date: Date) => {
      const { hours, minutes } = getTime(today());
      const taskDate = addDuration(date, { hours, minutes });
      history.push({
        pathname: `/accounts/${account.id}/schedule/month`,
        search: `?taskId=new&date=${taskDate.toISOString()}`
      });
    },
    [account, history]
  );

  const openPublishedPost = useCallback((post: any) => {
    openPostOnChannel(post);
  }, []);

  const refetchEntries = useCallback(
    async (date?: string) => {
      const day = date || state.focusedDate;
      let dateRange: DateRange | undefined;

      if (!!day) {
        dateRange = {
          start: startOfMonth(parseStringToDate(day, "yyyy/M/d")),
          end: endOfMonth(parseStringToDate(day, "yyyy/M/d"))
        };
      }
      if (
        !!dateRange &&
        state.postType === "scheduled" &&
        difference(
          startOfMonth(dateRange.start),
          startOfMonth(today()),
          "months"
        ) === 0
      ) {
        dateRange = {
          start: startOfDay(today()),
          end: endOfMonth(today())
        };
      }

      if (!!state.postType && !!dateRange) {
        await updateActivities(state.postType, {
          fromDate: dateRange.start,
          toDate: dateRange.end
        });
      }
    },
    [state.focusedDate, state.postType, updateActivities]
  );

  const onPosted = useCallback(
    async (post: any, prevDate?: any) => {
      const entry = Array.isArray(post) ? post[0] : post;

      const date = entry.scheduledAt
        ? getDateLabel(new Date(entry.scheduledAt))
        : undefined;
      const oldDate = prevDate ? getDateLabel(new Date(prevDate)) : undefined;

      if (!!date && !!oldDate && date.slice(0, 7) !== oldDate.slice(0, 7)) {
        await refetchEntries(oldDate);
      }

      await refetchEntries(date);

      if (date) {
        dispatch(setDateToBeFocused(date));
      }
    },
    [refetchEntries]
  );

  const onDeleted = useCallback(
    (post: any) => {
      addToast(
        `Successfully deleted ${post.isDraft ? "draft" : "post"}`,
        "success"
      );
      refetchEntries();
    },
    [addToast, refetchEntries]
  );

  const editPost = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        post: entry.post,
        onPosted: (post: any) => {
          onPosted(post, entry.post?.scheduledAt);
        },
        onDeleted,
        composerParams: {
          skipAutoReload: true
        }
      });
    },
    [openComposer, account, onDeleted, onPosted]
  );

  const writePost = useCallback(
    (date: Date) => {
      const composerParams: any = {
        skipAutoReload: true
      };

      const diff = difference(startOfDay(date), startOfDay(today()), "days");

      composerParams.initialScheduleTime = moment(
        diff === 0
          ? addDuration(today(), { hours: 1 })
          : addDuration(today(), { days: diff })
      );

      // @ts-ignore
      openComposer({
        account,
        composerParams,
        onPosted: (post: any) => {
          onPosted(post);
        }
      });
    },
    [account, openComposer, onPosted]
  );

  const writeDraft = useCallback(
    (date: Date) => {
      const composerParams: any = {
        isDraft: true,
        skipAutoReload: true
      };

      const diff = difference(startOfDay(date), startOfDay(today()), "days");

      composerParams.initialScheduleTime = moment(
        diff === 0
          ? addDuration(today(), { hours: 1 })
          : addDuration(today(), { days: diff })
      );

      // @ts-ignore
      openComposer({
        account,
        composerParams,
        onPosted: (post: any) => {
          onPosted(post, date);
        }
      });
    },
    [account, openComposer, onPosted]
  );

  const onEntryDeleted = useCallback(
    (post: any) => {
      if (post.isDraft) {
        reduxDispatch(changeDraftCountByValue(-1));
        reduxDispatch(removeDraftById(post.id));
      }
      onDeleted(post);
    },
    [onDeleted, reduxDispatch]
  );

  const addEntryFromTask = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        onPosted,
        onDeleted,
        task: entry.task,
        composerParams: {
          skipAutoReload: true
        }
      });
    },
    [openComposer, account, onDeleted, onPosted]
  );

  const addDraftFromTask = useCallback(
    (entry: any) => {
      // @ts-ignore
      openComposer({
        account,
        onPosted,
        onDeleted,
        task: entry.task,
        composerParams: {
          isDraft: true,
          skipAutoReload: true
        }
      });
    },
    [openComposer, onPosted, onDeleted, account]
  );

  return (
    <TimelineStateContext.Provider value={{ ...state, dateRefs }}>
      <TimelineDispatchContext.Provider value={dispatch}>
        <TimelineActionsContext.Provider
          value={{
            editPost,
            writePost,
            createTask,
            writeDraft,
            refetchEntries,
            onEntryDeleted,
            addDraftFromTask,
            addEntryFromTask,
            openPublishedPost
          }}
        >
          {children}
        </TimelineActionsContext.Provider>
      </TimelineDispatchContext.Provider>
    </TimelineStateContext.Provider>
  );
};

export const useTimelineState = () => {
  const context = useContext(TimelineStateContext);

  if (context === undefined) {
    throw new Error("useTimelineState must be used within a TimelineProvider");
  }
  return context;
};

export const useTimelineDispatch = () => {
  const context = useContext(TimelineDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useTimelineDispatch must be used within a TimelineProvider"
    );
  }
  return context;
};

export const useTimelineActions = () => {
  const context = useContext(TimelineActionsContext);

  if (context === undefined) {
    throw new Error(
      "useTimelineActions must be used within a TimelineProvider"
    );
  }
  return context;
};
