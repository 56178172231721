import React from "react";

import contentTypes from "./data/content-types";
import { ContentTypeBadge } from "./styles";

export default function ContentTypeBadgeComponent({
  contentTypeId,
  postIdea,
  title = "",
  variant = "light",
  size = "m",
  clickable = false,
  maxWidth,
  ...restProps
}) {
  //"other" content type doesn't have a badge
  if (contentTypeId === "other") {
    return null;
  }

  const contentType = contentTypes[contentTypeId];
  return (
    <ContentTypeBadge
      bgColor={contentType.bgColor}
      color={contentType.color}
      variant={variant}
      size={size}
      clickable={clickable}
      maxWidth={maxWidth}
      {...restProps}
    >
      {title || contentType.title}
      {postIdea && ` / ${postIdea}`}
    </ContentTypeBadge>
  );
}
