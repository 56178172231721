import React from "react";
import {
  AttachmentContainer,
  PostVideo,
  VideoWrapper,
  VideoList
} from "./styles";
import VideoUpload from "./VideoUpload";
import { X } from "@styled-icons/feather";

const VideoAttachment = ({
  videos,
  onError,
  onRemove,
  onUpload,
  isUploading,
  setIsUploading
}) => {
  return (
    <AttachmentContainer>
      <VideoList>
        {videos &&
          videos.length > 0 &&
          videos.map((video, index) => (
            <VideoWrapper key={video} className={"video"}>
              <PostVideo width={300} height={170} controls>
                <source src={video} type={"video/mp4"} />
              </PostVideo>
              <div className={"x"} onClick={() => onRemove(index)}>
                <X color={"#fff"} size={22} />
              </div>
            </VideoWrapper>
          ))}
        {!videos && (
          <VideoUpload
            onUpload={onUpload}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            onError={onError}
          />
        )}
      </VideoList>
    </AttachmentContainer>
  );
};

export default VideoAttachment;
