import React from "react";
import EntryStatus from "../../common/contentTypes/EntryStatus";
import { ChannelAvatar, Avatar, Service } from "../../common/styles";
import {
  CalendarEntryHeader as Header,
  CalendarHeaderTime,
  CalendarHeaderInfoWrapper,
  CalendarEntryChannels
} from "./styles";

function CalendarEntryHeader({
  status = "NEW",
  isStuck = false,
  time,
  channels,
  parent,
  date,
  dashboardEntry
}) {
  return (
    <Header>
      <CalendarHeaderInfoWrapper>
        <EntryStatus status={isStuck ? "STUCK" : status} />
        <CalendarHeaderTime isDraft={status === "DRAFT"}>
          {dashboardEntry ? (
            <>
              <span className="date">{date}</span>
              <span className="time">| {time}</span>
            </>
          ) : (
            <>
              {!!time && time}
              {status === "DRAFT" && parent !== "draft-panel" && (
                <>
                  {!!time && <span>&#183;</span>}
                  <span>Draft</span>
                </>
              )}
            </>
          )}
        </CalendarHeaderTime>
      </CalendarHeaderInfoWrapper>
      <CalendarEntryChannels>
        {channels.map(channel => (
          <ChannelAvatar key={channel.id} enabled={true}>
            <Avatar
              src={channel.avatar}
              width={24}
              height={24}
              noShadow={true}
              isRounded={true}
            />
            <Service
              key={channel.id}
              title={channel.username}
              type={channel.service}
              size={16}
            />
          </ChannelAvatar>
        ))}
      </CalendarEntryChannels>
    </Header>
  );
}

export default CalendarEntryHeader;
