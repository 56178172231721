import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from "react";
import { getDate } from "../schedule/common/timelineDatePicker/utils/dateUtils";

import * as modalActions from "../../state/actions/ModalActions";
import * as timelineActions from "../../state/actions/TimelineActions";
import * as analyticsActions from "../../state/actions/AnalyticsActions";

import useWindowSize from "../../utils/useWindowSize";
import logo from "../../assets/images/willow-text-color@2x.png";

import UserMenu from "./UserMenu";
import HelpMenu from "./HelpMenu";
import DraftMenu from "./DraftMenu";
import NewPostMenu from "./NewPostMenu";

import MainNavigation from "./MainNavigation";
import IconButton from "../common/IconButton";
import { TooltipInfo } from "./styles";

import { Plus, Menu } from "styled-icons/feather";
import {
  StyledHeader,
  HeaderBrand,
  HeaderNav,
  MenuLogo,
  HeaderMeta
} from "./styles";
import useComposer from "../../utils/useComposer";
import { useComposerActions } from "contextApi/composerContext";

function MainHeader({ account, currentUser, history, updateLastUnseen }) {
  const [isExpanded, setExpanded] = useState(false);

  const windowSize = useWindowSize();
  const { openComposer } = useComposer();
  const { openComposer: openComposerV2 } = useComposerActions();

  const handleNavClick = () => {
    if (windowSize.size === "xsmall") setExpanded(false);
  };

  const handleOpenComposer = (openNewComposer = false) => {
    if (openNewComposer) {
      openComposerV2();
    } else {
      openComposer({
        account,
        onPosted: post => {
          let postDate;

          if (_.isArray(post) && post.length > 0) {
            postDate = new Date(post[0].scheduledAt);
          } else if (post?.scheduledAt) {
            postDate = new Date(post.scheduledAt);
          } else postDate = getDate({ timezone: account.timezone });

          updateLastUnseen(postDate);
        }
      });
    }
  };

  const showNewComposer =
    !account?.isTelenetCustomer &&
    (account?.features ?? []).includes("composer_v2");

  return (
    <StyledHeader>
      <HeaderBrand>
        <MenuLogo
          src={logo}
          onClick={() => history.push(`/accounts/${account.id}/dashboard`)}
        />
      </HeaderBrand>
      <HeaderNav>
        {(windowSize.size !== "xsmall" || isExpanded) && (
          <MainNavigation account={account} wasClicked={handleNavClick} />
        )}
      </HeaderNav>
      <HeaderMeta>
        {windowSize.size === "xsmall" && (
          <IconButton
            variant={isExpanded ? "primary" : "secondary"}
            icon={Menu}
            onClick={() => setExpanded(!isExpanded)}
          />
        )}
        {showNewComposer ? (
          <NewPostMenu handleOpenComposer={handleOpenComposer} />
        ) : (
          <>
            <IconButton
              data-tip={"New Post"}
              id={"headerNewPostButton"}
              variant="green"
              icon={Plus}
              onClick={() => handleOpenComposer()}
              size={36}
            />
            <TooltipInfo effect={"solid"} place={"bottom"} />
          </>
        )}

        <DraftMenu />
        <HelpMenu account={account} user={currentUser} />
        <UserMenu account={account} user={currentUser} />
      </HeaderMeta>
    </StyledHeader>
  );
}

const mapStateToProps = state => ({
  account: state.account.data,
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal: modalActions.openModal,
  updateLastUnseen: timelineActions.updateLastUnseen,
  trackAnalyticsEvent: analyticsActions.trackAnalyticsEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
