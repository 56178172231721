import * as Sentry from "@sentry/browser";
import * as authActions from "../actions/AuthActions";

import { LOCATION_CHANGE } from "connected-react-router";

import { select, takeEvery, takeLatest } from "redux-saga/effects";
import * as analyticsActions from "../actions/AnalyticsActions";
import * as accountActions from "../actions/AccountActions";

const trackEvent = (event, eventData) => {
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.track(event, {
      eventData
    });
  } else {
    console.log(`[Event Tracking] ${event}`, eventData);
  }
};

function trackAnalyticsEvent({ event, eventData }) {
  trackEvent(event, eventData);
}

function pageViewedEvent() {
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.page();
  }
}

function identifyUser(action) {
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.identify(action.userInfo.id, {
      email: action.userInfo.email,
      firstName: action.userInfo.firstName,
      lastName: action.userInfo.lastName,
      name: `${action.userInfo.fullName}`
    });
  }
  Sentry.configureScope(scope => {
    scope.setUser({ id: action.userInfo.id });
  });
}

const getCurrentUser = state => {
  return state.auth.currentUser;
};

function* identifyAccount(action) {
  const account = action.account;
  const currentUser = yield select(getCurrentUser);

  const plan =
    account.billing &&
    (account.billing.billingStatus === "in_trial"
      ? "TRIAL"
      : account.billing.willowPlan);

  const groupData = {
    account_id: account.id,
    name: `${account.companyName}`,
    plan: plan
  };
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.identify(currentUser.id, {
      plan: plan,
      willowAccountId: account.id,
      companyName: account.companyName
    });
    window.analytics.group(action.account.id, groupData);
  } else {
    console.log(`[Identify Account] ${action.account.id}`, groupData);
    console.log(`[Identify User] ${currentUser.id}`, plan);
  }
}

export default function* root() {
  yield takeEvery(analyticsActions.TRACK_ANALYTICS_EVENT, trackAnalyticsEvent);
  yield takeLatest(accountActions.ACCOUNT_FETCHED, identifyAccount);
  yield takeLatest(authActions.USER_INFO_FETCHED, identifyUser);
  yield takeEvery(LOCATION_CHANGE, pageViewedEvent);
}
