import { parseISO, isAfter } from "date-fns";
import { getFormattedDate, getOffsetDate } from "./dateUtils";

const formatDate = date => getFormattedDate(date, "EEEE, d MMMM");

export function getDayEntries(date, entries) {
  const dateString = formatDate(date);
  const dayEntries = entries.filter(
    entry => formatDate(parseISO(entry.time)) === dateString
  );

  return {
    slot: dayEntries.filter(entry => entry.type === "TASK"),
    scheduled: dayEntries.filter(
      entry => entry.type === "POST" && entry.post.status !== "SENT"
    ),
    grouped: dayEntries
      .filter(entry => entry.type === "POST_GROUP")
      .map(entry => {
        return {
          ...entry,
          post: entry.group,
          isPostGroup: true
        };
      }),
    drafted: dayEntries
      .filter(
        entry => entry.type === "DRAFT_POST" && entry.draft.scheduledAt !== null
      )
      .map(entry => {
        return {
          ...entry,
          post: entry.draft,
          isDraft: true
        };
      }),
    posted: dayEntries.filter(
      entry => entry.type === "POST" && entry.post.status === "SENT"
    )
  };
}

function clearLinks(text) {
  return String(text).replace(/https?:\/\/\S+/g, " ");
}

export function getPostsGrouped(entries) {
  const groups = [];
  let prev = null;
  let idx = 0;

  for (const entry of entries) {
    idx++;

    // groups.push([entry]);

    // continue;
    if (
      entry.type === "TASK" ||
      entry.type === "DRAFT_POST" ||
      entry.type === "POST_GROUP" ||
      entry.type === "POST" ||
      entry.isPhantom
    ) {
      groups.push([entry]);
    } else if (!prev) {
      prev = [entry];
    } else {
      const last = prev[prev.length - 1].post;
      const lastDate = last.scheduledAt ?? last.postedAt;
      const entryDate = entry.post?.scheduledAt ?? entry.post?.postedAt;

      if (
        lastDate === entryDate &&
        clearLinks(entry.post.caption) === clearLinks(last.caption)
      )
        prev.push(entry);
      else {
        groups.push(prev);
        prev = [entry];
      }
    }
    if (prev?.length > 0 && idx === entries.length) {
      groups.push(prev);
    }
  }
  return groups;
}

export function getEntriesGrouped(entries, period, labels = {}) {
  const { today, yesterday } = labels;
  const groups = [];
  let index = period.start;

  while (isAfter(period.end, index)) {
    const indexDate = formatDate(index);

    const dateEntries = entries.filter(
      entry => formatDate(parseISO(entry.time)) === indexDate
    );

    groups.push({
      date: index,
      dateName:
        indexDate === today
          ? "Today"
          : indexDate === yesterday
          ? "Yesterday"
          : indexDate,
      entries: dateEntries
    });

    index = getOffsetDate(index, { days: 1 });
  }

  return groups;
}
