import _ from "lodash";
import { reset } from "redux-form";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import React, { useState, useEffect, useCallback } from "react";
import { IHubspotReferrals } from "@hellocontento/contento-common";

import {
  AccountCount,
  SettingsWell,
  SettingsTitle,
  SettingsBrick,
  SettingsHeader,
  SettingsSection,
  SettingsSubtitle,
  SettingsDescription,
  SettingsSubtitleWrapper
} from "./styles";
import EmptyCard from "./EmptyCard";
import { ReferralForm } from "./forms";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import Media from "components/common/Media";
import UserAvatarDefault from "assets/images/defaults/user-picture-skeleton.png";

const Referral: React.FC<{}> = () => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const [pendingReferrals, setPendingReferrals] = useState<IHubspotReferrals[]>(
    []
  );
  const [respondedReferrals, setRespondedReferrals] = useState<
    IHubspotReferrals[]
  >([]);
  const account = useSelector<any, any>(state => state.account.data);

  const handleSubmitInvite = (values: any) => {
    callApi({
      method: "post",
      url: `/accounts/${account.id}/referrals`,
      data: {
        contactInfo: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email
        },
        referrer: account.id
      }
    })
      .then(res => {
        const contactInfo = res?.createdContact?.properties;
        const newPending: IHubspotReferrals = {
          createDate: contactInfo.createdate,
          lastModifiedDate: contactInfo.lastmodifieddate,
          email: contactInfo.email,
          firstName: contactInfo.firstname,
          lastName: contactInfo.lastname,
          status: "pending",
          hubspotMetaData: {
            hs_object_id: contactInfo.hs_object_id
          }
        };
        setPendingReferrals([newPending, ...pendingReferrals]);
        addToast("Referral sent successfully", "success");

        dispatch(reset("settingsReferralForm"));
      })
      .catch(err => {
        addToast(
          err.name === "ValidationError"
            ? err.message
            : "Could not send invite. Please try again later.",
          "error"
        );
      });
  };

  const getInvites = useCallback(
    () =>
      callApi({
        url: `/accounts/${account.id}/referrals`
      }).then(res => {
        setPendingReferrals(
          _.orderBy(
            (res.pastReferrals as IHubspotReferrals[]).filter(
              referral => referral.status.toLowerCase() === "pending"
            ),
            [referral => Date.parse(referral.createDate)],
            ["desc"]
          )
        );
        setRespondedReferrals(
          _.orderBy(
            (res.pastReferrals as IHubspotReferrals[]).filter(
              referral => referral.status.toLowerCase() !== "pending"
            ),
            [referral => Date.parse(referral.lastModifiedDate)],
            ["desc"]
          )
        );
      }),
    [account.id]
  );

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  const pendingList =
    pendingReferrals.length > 0 ? (
      pendingReferrals.map(referral => (
        <SettingsWell key={referral.email}>
          {/* @ts-ignore */}
          <Media
            title={`${referral.firstName} ${referral.lastName}`}
            thumbnail={UserAvatarDefault}
            description={`${referral.email}`}
            subDescription={capitalize(referral.status)}
            subDescriptionVariant={"primaryWhite"}
            thumbnailRound
          />
        </SettingsWell>
      ))
    ) : (
      <EmptyCard
        key="team-empty-card"
        title="No pending invitations"
        message=""
      />
    );

  const respondedList =
    respondedReferrals.length > 0
      ? respondedReferrals.map(referral => {
          return (
            <SettingsWell key={referral.email}>
              {/* @ts-ignore */}
              <Media
                title={`${referral.firstName} ${referral.lastName}`}
                description={`${referral.email}`}
                thumbnail={UserAvatarDefault}
                subDescription={capitalize(referral.status)}
                subDescriptionVariant={
                  referral.status === "verified" ? "success" : "danger"
                }
                thumbnailRound
              />
            </SettingsWell>
          );
        })
      : [];

  return (
    <>
      <SettingsHeader>
        <SettingsTitle>Refer Willow</SettingsTitle>
        <SettingsDescription>
          Refer Willow to another business and receive a{" "}
          <strong>50€ gift voucher</strong> when they sign up. Your friend
          receives <strong>a free month of Willow</strong>!
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <SettingsSubtitle>Referral contact</SettingsSubtitle>
        <SettingsBrick>
          <ReferralForm onSubmit={handleSubmitInvite} />
        </SettingsBrick>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubtitleWrapper>
          {/* @ts-ignore */}
          <SettingsSubtitle justifyContent={"flex-start"}>
            Pending Referrals
            {pendingReferrals.length > 0 ? (
              <AccountCount>{pendingReferrals.length}</AccountCount>
            ) : null}
          </SettingsSubtitle>
          {pendingList ? (
            [pendingList]
          ) : (
            /* @ts-ignore */
            <ReactLoading color="#bbb" type="cylon" />
          )}
        </SettingsSubtitleWrapper>
        {/* @ts-ignore */}
        <SettingsSubtitleWrapper marginTop={"64px"}>
          {respondedReferrals.length > 0 ? (
            <>
              {/* @ts-ignore */}
              <SettingsSubtitle justifyContent={"flex-start"}>
                Referrals
                <AccountCount>{respondedReferrals.length}</AccountCount>
              </SettingsSubtitle>
              {respondedList ? (
                [respondedList]
              ) : (
                /* @ts-ignore */
                <ReactLoading color="#bbb" type="cylon" />
              )}
            </>
          ) : null}
        </SettingsSubtitleWrapper>
      </SettingsSection>
    </>
  );
};

export default Referral;
