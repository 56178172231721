import { useToaster } from "@hellocontento/maillard";
import React, { useCallback, useEffect, useState } from "react";

import { MainContainer, MainWrapper } from "../../components/common/styles";
import { connect } from "react-redux";

import { Box } from "rebass";

import { NewsBody, ContentWrapper } from "./styles";

import ContentRouter from "./ContentRouter";
import ContentHeader from "./ContentHeader";
import Sidebar from "components/content/sidebar";
import { ContentPageProvider } from "contextApi/ContentPageContext";
import { ArticleSearchProvider } from "contextApi/ArticleSearchContext";
import { quickSearchByCategory } from "services/news";
import ArticleSearchBar from "components/content/articleSearch/ArticleSearchBar";
import ShowOnRoutes from "hoc/ShowOnRoutes";

const ContentPage = ({ account, match }) => {
  const addToast = useToaster();

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchForSources = useCallback(async () => {
    try {
      const results = await quickSearchByCategory({
        searchTerm: searchValue
      });

      setSearchSuggestions(results);
    } catch (e) {
      addToast("There was an error searching for the sources", "error");
    }
  }, [searchValue, addToast]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (searchValue !== "") searchForSources();
      else setSearchSuggestions([]);
    }, 200);
    return () => clearTimeout(searchDelay);
  }, [searchValue, searchForSources]);

  return (
    <NewsBody>
      <Sidebar match={match} accountId={account.id} />
      <ContentPageProvider>
        <ContentWrapper>
          <ContentHeader
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchSuggestions={searchSuggestions}
          />
          <ArticleSearchProvider>
            <ShowOnRoutes
              component={ArticleSearchBar}
              allowedRoutes={["/search"]}
            />
            <MainContainer>
              <MainWrapper>
                <Box flex={"1"}>
                  <ContentRouter match={match} />
                </Box>
              </MainWrapper>
            </MainContainer>
          </ArticleSearchProvider>
        </ContentWrapper>
      </ContentPageProvider>
    </NewsBody>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(ContentPage);
