import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NewPasswordForm from "../../components/auth/NewPasswordForm";
import { AuthLayout, AuthImage, AuthBody } from "./styles";
import { useToaster } from "@hellocontento/maillard";
import { callApi } from "../../utils/ContentoApi";

const NewPasswordPage = ({ currentUser }) => {
  const history = useHistory();
  const addToast = useToaster();

  function onSubmit(values) {
    const { id: userId } = currentUser;
    const { password } = values;

    return callApi({
      // the password-reset skips the requirement of sending
      // the current password as the user forgot it
      url: `users/${userId}?password-reset=true`,
      method: "put",
      data: { password }
    })
      .then(({ success }) => {
        addToast("Your password has been successfully updated!", "success");
        history.push(`/`);
      })
      .catch(error => {
        const msg =
          error.name === "ValidationError"
            ? error.message
            : "We were unable to update your password, please try again later.";
        addToast(msg, "error");
      });
  }

  return (
    <AuthLayout>
      <AuthImage />
      <AuthBody>
        <NewPasswordForm
          onSubmit={onSubmit}
          initialValues={{ userId: currentUser.id }}
          accountEmail={currentUser.email}
        />
      </AuthBody>
    </AuthLayout>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(NewPasswordPage);
