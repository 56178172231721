import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import ScrollableContentList from "./ScrollableContentList";
import { getSourceById } from "services/news";

import ArticleEmpty from "assets/images/contents/article-empty.png";
import EmptyState from "../common/EmptyState";
import { Twitter } from "styled-icons/boxicons-logos";

import { useParams } from "react-router";

import { EmptyImageWrapper } from "./styles";
import { SECTION_TYPES, SOURCE_TYPE } from "services/news.types";
import InfluencerDetailBanner from "./common/detail/InfluencerDetailBanner";

const PAGE_SIZE = 9;
const FIRST_PAGE = 1;

const ContentInfluencerDetail = ({ account, excludedTld, ...restProps }) => {
  const params = useParams();
  const addToast = useToaster();
  const [pageData, setPageData] = useState(null);

  const contextSource =
    restProps.sourceType === SECTION_TYPES.DOMAINS
      ? restProps.sourceType
      : SECTION_TYPES.SOURCES;

  const fetchContents = async pageNumber => {
    try {
      const response = await getSourceById(params.sourceId, contextSource, {
        page: pageNumber,
        limit: PAGE_SIZE
      });
      setPageData(data => ({
        items:
          pageNumber > 1
            ? [...data.items, ...response.contents]
            : response.contents,
        source: response.section,
        page: pageNumber,
        isFinished: response.contents.length < PAGE_SIZE,
        pageSize: PAGE_SIZE,
        excludedTld
      }));
    } catch (err) {
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [params.sourceId]);

  const refreshContent = useCallback(async () => {
    fetchContents(FIRST_PAGE);
  }, [excludedTld, params.sourceId, addToast]);

  useEffect(() => {
    refreshContent();
  }, [refreshContent, params.sourceId]);

  const emptyState =
    restProps.sourceType === SOURCE_TYPE.TWITTER ? (
      <EmptyState
        loading={!pageData}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        icon={{
          component: Twitter,
          width: "24px",
          height: "24px"
        }}
      />
    ) : (
      <EmptyState
        center={false}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        image={<EmptyImageWrapper src={ArticleEmpty} />}
      />
    );

  return (
    <>
      {pageData && (
        <InfluencerDetailBanner
          pageData={pageData}
          sourceId={params.sourceId}
          sourceType={restProps.sourceType}
        />
      )}
      <ScrollableContentList
        pageData={pageData}
        onScheduled={refreshContent}
        onDismiss={refreshContent}
        onNextPage={fetchContents}
        emptyState={emptyState}
        sourceId={params.sourceId}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld
  };
};

export default connect(mapStateToProps, {})(ContentInfluencerDetail);
