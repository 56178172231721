import _ from "lodash";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { format, getHours, getMinutes, isToday, parse } from "date-fns";
import { withRouter, RouteComponentProps } from "react-router-dom";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { DragDropContext } from "react-beautiful-dnd";

import { IAccount, IChannel } from "@hellocontento/contento-common";
import * as activityActions from "state/actions/ActivityActions";
import * as dragDropActions from "state/actions/DragDropActions";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

import { callApi } from "utils/ContentoApi";
import { removeTimezoneFromDate, setDate } from "utils/date";
import { removeTimezoneOffsetFromDateAsISO } from "../../utils/dateUtils";

import { ActivityType } from "components/schedule/types/activityTypes";

interface IActivityUpdate {
  id: string;
  type: string;
  time: string;
}

interface IDragDropContextWrapperProps extends RouteComponentProps {
  activities: any;
  account: IAccount;
  children: JSX.Element;
  removeDraggingItem: () => void;
  removePhantomActivity: () => void;
  setDraggingItem: (itemId: string) => void;
  createPhantomActivity: (phantomActivity: any) => void;
  updateActivity: (activityUpdate: IActivityUpdate) => void;
}

const DragDropContextWrapper = ({
  location,
  account,
  children,
  activities,
  updateActivity,
  setDraggingItem,
  removeDraggingItem,
  removePhantomActivity,
  createPhantomActivity
}: IDragDropContextWrapperProps) => {
  const addToast = useToaster();
  const dispatch = useDispatch();

  const schedulePage = location.pathname.includes("week") ? "week" : "month";

  const changeDate = (
    newDateValue: string,
    prevDate: string,
    considerToday: boolean = false
  ) => {
    const date = new Date(`${newDateValue}T00:00:00`);

    const time = format(new Date(prevDate), "HH:mm");
    const parsedTime = parse(time, "HH:mm", date);
    const hours =
      isToday(date) && considerToday
        ? getHours(new Date())
        : getHours(parsedTime);
    const minutes =
      isToday(date) && considerToday
        ? getMinutes(new Date()) + 5
        : getMinutes(parsedTime);

    return setDate(date, {
      hours,
      minutes
    });
  };

  const onBeforeDragStart = (response: any) => {
    // create phantom data
    const { draggableId } = response;
    const activity = activities.find((act: any) => act.id === draggableId);
    const type = (function() {
      switch (activity.type) {
        case ActivityType.DRAFT_POST:
          return "draft";
        case ActivityType.POST_GROUP:
          return "group";
        default:
          return activity.type.toLowerCase();
      }
    })();

    setDraggingItem(activity.id.split("/").pop());

    const phantomActivity: any = {
      ...activity,
      id: "PHANTOM_".concat(activity.id),
      isPhantom: true,
      [type]: {
        ...activity[type],
        id: "PHANTOM_".concat(activity[type].id)
      }
    };

    createPhantomActivity(phantomActivity);
  };

  const onDragEnd = async ({ draggableId, destination, source }: any) => {
    // There is issue with id, destination
    // when if source & destination are same
    removePhantomActivity();
    removeDraggingItem();

    if (
      !destination ||
      !draggableId ||
      source.droppableId === destination.droppableId
    ) {
      return;
    }

    const [type, id] = draggableId.split("/");
    let updateDate: null | string = "";

    // fetch the task information
    const activity = activities.find((act: any) => act.id === draggableId);

    let prevDate: null | string = "";
    // call the update api
    let params = {};
    if (type === ActivityType.TASK) {
      const newDate = removeTimezoneOffsetFromDateAsISO(
        changeDate(destination.droppableId, activity.task.date)
      );
      updateDate = newDate.split(".")[0];
      prevDate = activity.task.date;

      const { channels } = activity.task;
      const newData = _.omit(activity.task, [
        "createdAt",
        "updatedAt",
        "taskGroup",
        "posts",
        "taskGroupId"
      ]);

      params = {
        method: "PUT",
        url: `/accounts/${account.id}/tasks/${id}`,
        data: {
          ...newData,
          channels: channels.map((channel: any) => channel.id),
          date: newDate
        }
      };
    } else if (type === ActivityType.POST) {
      const newDate = changeDate(
        destination.droppableId,
        activity.post.scheduledAt,
        true
      );

      updateDate = removeTimezoneOffsetFromDateAsISO(newDate).split(".")[0];
      prevDate = activity.post.scheduledAt;

      params = {
        method: "put",
        url: `/posts/${id}`,
        data: {
          ...activity.post,
          scheduleTime: "CUSTOM",
          caption: {
            [activity.post.channel.service]: activity.post.caption
          },
          scheduledAt: removeTimezoneFromDate(newDate)
        }
      };
    } else if (type === ActivityType.DRAFT_POST) {
      const newDate = changeDate(
        destination.droppableId,
        activity.draft.scheduledAt
      );

      updateDate = removeTimezoneOffsetFromDateAsISO(newDate).split(".")[0];
      prevDate = activity.draft.scheduledAt;

      params = {
        method: "put",
        url: `/accounts/${account.id}/draft-posts/${id}`,
        data: {
          ...activity.post,
          scheduledAt: removeTimezoneFromDate(newDate)
        }
      };
    } else if (type === ActivityType.POST_GROUP) {
      const newDate = changeDate(
        destination.droppableId,
        activity.group.scheduledAt
      );
      updateDate = removeTimezoneOffsetFromDateAsISO(newDate).split(".")[0];
      prevDate = activity.group.scheduledAt;
      params = {
        method: "put",
        url: `/accounts/${account.id}/post-groups/${id}`,
        data: {
          ...activity.group,
          scheduleTime: "CUSTOM",
          channels: activity.group.channels.map(
            (channel: IChannel) => channel.id
          ),
          scheduledAt: removeTimezoneFromDate(newDate)
        }
      };
    }

    // @ts-ignore
    callApi(params)
      .then(() => {
        const activityType = _.capitalize(type.split("_")[0]);

        addToast(`${activityType} was updated`, "success");
        dispatch(
          trackAnalyticsEvent("Dragged Activity", {
            type: activityType,
            view: schedulePage
          })
        );
      })
      .catch(e => {
        if (prevDate) {
          updateActivity({
            id: draggableId,
            type:
              type === ActivityType.DRAFT_POST ? "draft" : type.toLowerCase(),
            time: prevDate
          });
        }
        addToast(`${e.message}`, "error");
      });

    updateActivity({
      id: draggableId,
      type: type === ActivityType.DRAFT_POST ? "draft" : type.toLowerCase(),
      time: updateDate
    });
  };

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={onDragEnd}
    >
      {children}
    </DragDropContext>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account.data,
    activities: state.activity.activities
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveActivities: activityActions.saveActivities,
    updateActivity: activityActions.updateActivity,
    createPhantomActivity: activityActions.createPhantomActivity,
    removePhantomActivity: activityActions.removePhantomActivity,
    setDraggingItem: dragDropActions.setDraggingItem,
    removeDraggingItem: dragDropActions.removeDraggingItem
  })(DragDropContextWrapper)
);
