import React, { useState } from "react";
import { Plus } from "@styled-icons/boxicons-regular";

import { CalendarAddButton } from "./styles";

import Popper from "components/common/Popper";
import NewActivityOptions from "../common/NewActivityOptions";

const ContextMenu = ({
  day,
  handleCreateTask,
  handleWritePost,
  handleWriteDraft
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <div ref={setReferenceElement}>
      <CalendarAddButton
        isVisible={!day?.isBeforeToday}
        onClick={toggleShowOptions}
      >
        <Plus size={18} />
      </CalendarAddButton>
      <Popper
        visible={showOptions}
        referenceElement={referenceElement}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <NewActivityOptions
          close={() => setShowOptions(false)}
          handleCreateTask={handleCreateTask}
          handleWritePost={handleWritePost}
          handleWriteDraft={handleWriteDraft}
          day={day}
        />
      </Popper>
    </div>
  );
};

export default ContextMenu;
