import React from "react";

import { IImageGrid, ImageGrid, ImageArea, AttachmentImage } from "./styles";

interface IPostImageGrid {
  data: any;
  size: IImageGrid["size"];
  rowHeight: IImageGrid["rowHeight"];
}

const PostImageGrid: React.FC<IPostImageGrid> = ({ data, size, rowHeight }) => {
  let urls: string[] = Array.isArray(data.url) ? data.url : [data.url];

  return (
    <ImageGrid
      gridSize={`grid-${Math.min(urls.length, 4)}` as IImageGrid["gridSize"]}
      rowHeight={rowHeight}
      size={size}
    >
      {urls.map(
        (item, i) =>
          i < 4 && (
            <ImageArea key={item} gridArea={`grid-item-${i + 1}`}>
              <AttachmentImage src={item} alt={"Attached image"} />
            </ImageArea>
          )
      )}
      {urls.length > 4 && (
        <ImageArea overlay gridArea={"grid-item-4"}>
          <span>+{urls.length - 4}</span>
        </ImageArea>
      )}
    </ImageGrid>
  );
};

export default PostImageGrid;
