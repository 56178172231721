import React from "react";
import { useSelector } from "react-redux";

import ErrorContent from "../components/errors/ErrorContent";
import ErrorIllustration from "../components/errors/ErrorIllustration";
import { Box, Flex } from "rebass/styled-components";
import { ErrorLayout, ErrorHeader } from "../components/errors/styles";
import Button from "../components/common/Button";

import logo from "../assets/images/willow-text-color@2x.png";

const defaultError = {
  title: "Oops!",
  description:
    "Something went wrong there. Please help us improve Willow by reporting this error."
};

const ErrorPage = ({
  onErrorReport,
  title = defaultError.title,
  description = defaultError.description
}) => {
  const account = useSelector(state => state.account.data);

  const handleGoBack = () => {
    window.location.href = `/accounts/${account.id}/dashboard`;
  };

  return (
    <ErrorLayout>
      <ErrorHeader>
        <img src={logo} height={40} alt="Willow" />
      </ErrorHeader>
      <Box>
        <ErrorContent title={title} description={description} />
        <Flex flexDirection="row" justifyContent="center" mt={16}>
          <Button onClick={handleGoBack} size="lg" variant="secondary" mr={12}>
            Go back
          </Button>
          <Button onClick={onErrorReport} size="lg">
            Report error
          </Button>
        </Flex>
      </Box>
      <ErrorIllustration />
    </ErrorLayout>
  );
};

export default ErrorPage;
