import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../common/Button";
import IconButton from "components/common/IconButton";

import {
  CalendarContainer,
  CalendarDayColumns,
  CalendarDay,
  CalendarLoading,
  CalendarMonth,
  CalendarControls,
  CalendarDateControl,
  CalendarHeaderZone,
  CalendarDayColumnsContainer,
  IconBottonGroup,
  CalendarDayRow
} from "./styles";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import moment from "moment-timezone";
import _ from "lodash";
import { useToaster } from "@hellocontento/maillard";

import * as draftActions from "state/actions/DraftActions";
import * as activityActions from "state/actions/ActivityActions";
import ChannelToggle from "components/common/ChannelToggle";
import ScheduleViewToggle from "../ScheduleViewToggle";
import { withRouter } from "react-router-dom";
import useComposer from "../../../utils/useComposer";
import { openPostOnChannel } from "../../../utils/channels";
import { useContentoApi } from "../../../utils/useContentoApi";
import ContextMenu from "./ContextMenu";
import DayColumn from "./DayColumn";
import DragDropContextWrapper from "../common/drag-and-drop/DragDropContextWrapper";
import DroppableWrapper from "../common/drag-and-drop/DroppableWrapper";
import { difference, startOf, getWeekNumber } from "utils/date";

function Calendar({
  modalState,
  account,
  menuCollapsed,
  saveActivities,
  activities,
  changeDraftCountByValue,
  removeDraftById
}) {
  const history = useHistory();

  const [fetchActivities, cancelFetchActivities] = useContentoApi(
    `/accounts/${account.id}/activities/calendar`
  );

  const [loading, setLoading] = useState(true);
  const [weekOffset, setWeekOffset] = useState(0);

  const [selectedChannelIds, setSelectedChannelIds] = useState(
    account.channels.map(channel => channel.id)
  );
  const [ungroupedEntries, setUngroupedEntries] = useState([]);
  const addToast = useToaster();
  const { openComposer } = useComposer();

  const momentTZ = useCallback(
    date => {
      return moment.tz(date, account.timezone);
    },
    [account.timezone]
  );

  let startDate = useMemo(() => {
    return momentTZ()
      .startOf("isoWeek")
      .add(weekOffset, "week");
  }, [momentTZ, weekOffset]);

  const endDate = useMemo(() => {
    return momentTZ(startDate).add(1, "week");
  }, [momentTZ, startDate]);

  const today = moment();

  const refreshActivities = useCallback(() => {
    const params = {
      fromDate: startDate.toISOString(),
      toDate: endDate.toISOString()
    };

    if (selectedChannelIds.length > 0) {
      params.channelIds = selectedChannelIds.join(",");
    }
    fetchActivities({ params })
      .then(activitiesResult => {
        saveActivities({
          data: activitiesResult,
          params
        });
        setLoading(false);
      })
      .catch(error => {
        if (error.name !== "RequestCancelled") {
          addToast("Could not load activities", "error");
        }
      });
  }, [
    fetchActivities,
    startDate,
    endDate,
    addToast,
    selectedChannelIds,
    saveActivities
  ]);

  useEffect(() => {
    refreshActivities();
    return cancelFetchActivities;
  }, [cancelFetchActivities, refreshActivities]);

  function changeWeek(offset) {
    if (offset === weekOffset) return;
    setLoading(true);
    setWeekOffset(offset);
  }

  useEffect(() => {
    const { post } = modalState.modalProps;
    if (
      !!post &&
      modalState.isOpen &&
      modalState.modalType === "COMPOSER_MODAL"
    ) {
      const activeDate = new Date(post.postedAt || post.scheduledAt);

      if (!!activeDate) {
        const offset = difference(
          startOf(activeDate, "week"),
          startOf(new Date(), "week"),
          "weeks"
        );

        changeWeek(offset);
      }
    }
  }, [modalState.isOpen]);

  function onDeleted(post) {
    addToast(
      `Successfully deleted ${post.isDraft ? "draft" : "post"}`,
      "success"
    );

    if (post.isDraft) {
      changeDraftCountByValue(-1);
      removeDraftById(post.id);
    }

    refreshActivities();
  }

  function onPosted() {
    refreshActivities();
  }

  function handleAddTask() {
    history.push({
      pathname: `/accounts/${account.id}/schedule/month`,
      search: `?taskId=new`
    });
  }

  function handleAddEntry(entry) {
    openComposer({
      account: account,
      onPosted: onPosted,
      onDeleted: onDeleted,
      task: entry.task
    });
  }

  function handleAddDraftEntry(entry) {
    openComposer({
      account: account,
      task: entry.task,
      composerParams: {
        isDraft: true
      }
    });
  }

  function openPostedEntry(entry) {
    openPostOnChannel(entry.post);
  }

  function handleEditEntry(entry) {
    if (entry.group && entry.group.length > 0) {
      setUngroupedEntries(
        _.uniq(
          ungroupedEntries.concat(entry.group.map(groupEntry => groupEntry.id))
        )
      );
      return;
    }
    if (entry.post.status === "SENT") {
      openPostedEntry(entry);
    } else {
      openComposer({
        account: account,
        post: entry.post,
        onPosted: onPosted,
        onDeleted: onDeleted
      });
    }
  }

  // TODO: use this for top-of-the-column add btn
  const handleWritePost = day => {
    let hour = 11;
    const minute = 0;

    if (day.isToday && today.hour() > hour) {
      // adjust time when posting on the same day
      hour = today.hour() + 1;
    }

    const initialScheduleTime = moment(day.date);
    initialScheduleTime.set({ h: hour, m: minute });

    openComposer({
      account: account,
      composerParams: {
        initialScheduleTime
      }
    });
  };

  const handleWriteDraft = day => {
    let hour = 11;
    const minute = 0;

    if (day.isToday && today.hour() > hour) {
      // adjust time when posting on the same day
      hour = today.hour() + 1;
    }

    const initialScheduleTime = moment(day.date);
    initialScheduleTime.set({ h: hour, m: minute });

    openComposer({
      account: account,
      composerParams: {
        initialScheduleTime,
        isDraft: day.isDraft
      }
    });
  };

  const handleCreateTask = day => {
    history.push({
      pathname: `/accounts/${account.id}/schedule/month`,
      search: `?taskId=new&date=${day.date.toISOString()}`
    });
  };

  const tasks =
    activities
      ?.filter(activity => activity.type === "TASK")
      .map(activity => {
        return {
          ...activity.task,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const posts =
    activities
      ?.filter(activity => activity.type === "POST")
      .map(activity => {
        return {
          ...activity.post,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const drafts =
    activities
      ?.filter(
        activity =>
          activity.type === "DRAFT_POST" && activity.draft.scheduledAt !== null
      )
      .map(activity => {
        return {
          ...activity.draft,
          isPhantom: activity.isPhantom,
          isDraft: true
        };
      }) ?? [];

  const groups =
    activities
      ?.filter(activity => activity.type === "POST_GROUP")
      .map(activity => {
        return {
          ...activity.group,
          isPostGroup: true,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const days = [];

  for (let i = 0; i < 7; i++) {
    const date = momentTZ(startDate)
      .startOf("isoWeek")
      .add(i, "day");

    const isToday = date.isSame(today, "day");
    const isBeforeToday = date.isBefore(today, "day");

    days.push({
      date: date,
      isWeekend: ["Sat", "Sun"].includes(date.format("ddd")),
      isToday,
      isBeforeToday,
      entries: []
    });
  }

  const allPosts = [...posts, ...drafts, ...groups];

  if (!loading) {
    allPosts.forEach(post => {
      let date =
        post.status === "SENT"
          ? momentTZ(post.postedAt)
          : momentTZ(post.scheduledAt);
      let dayOfWeek = date.format("E") - 1;
      let picture;
      if (post.attachment) {
        if (post.attachment.type === "photo") {
          picture = _.isArray(post.attachment.url)
            ? post.attachment.url[0]
            : post.attachment.url;
        } else if (post.attachment.type === "article") {
          picture = post.attachment.image;
        }
      }

      days[dayOfWeek].entries.push({
        id: `post-${post.id}`,
        cardId: post.id,
        type: post.isDraft
          ? "DRAFT_POST"
          : `POST${post.channels?.length > 1 ? "_GROUP" : ""}`,
        dateTime: date,
        time: date.format("HH:mm"),
        channels:
          post.isDraft || post.isPostGroup ? post.channels : [post.channel],
        text:
          post.isDraft || post.isPostGroup
            ? Object.values(post.caption).sort((a, b) => {
                return b.length - a.length;
              })[0]
            : post.caption,
        picture: picture,
        title: post.attachment ? post.attachment.title : undefined,
        post: post,
        isPhantom: post.isPhantom
      });
    });

    //Place tasks
    tasks.forEach(task => {
      const date = momentTZ(task.date);
      const dayOfWeek = date.format("E") - 1;

      days[dayOfWeek].entries.push({
        id: `slot-${task.id}`,
        cardId: task.id,
        type: "TASK",
        dateTime: date,
        time: date.format("HH:mm"),
        channels: [...task.channels],
        task: task,
        isPast: !date.isAfter(momentTZ()),
        isPhantom: task.isPhantom
      });
    });

    // Sort and group entries organization
    days.forEach(day => {
      // day.entries = organizeEntries(day.entries, ungroupedEntries);
      day.entries = day.entries.sort((a, b) => {
        return a.time < b.time ? -1 : 1;
      });
    });
  }

  const monthChange = days[0].date.month() !== days[6].date.month();

  const handleChannelToggle = data => {
    setSelectedChannelIds(data);
  };

  const postsOnWeekend = days.reduce((acc, day) => {
    if (!day.isWeekend) return acc;
    const weekend = moment(day.date).format("ddd");
    acc[weekend] = day.entries.length > 0;
    return acc;
  }, []);

  let gridColumnLayout = `repeat(7, 1fr)`;
  let heightAdjust = "13vw";

  if (!postsOnWeekend["Sat"] && !postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(5, 1fr) 90px 90px`;
    heightAdjust = "15.8vw";
  } else if (postsOnWeekend["Sat"] && !postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(6, 1fr) 90px`;
    heightAdjust = "13.9vw";
  } else if (!postsOnWeekend["Sat"] && postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(5, 1fr) 90px 1fr`;
    heightAdjust = "13.9vw";
  }

  const attachAddNewButton = day => {
    if (day.isBeforeToday) return false;

    const dayOfDate = day.date.format("ddd");

    return (
      !["Sat", "Sun"].includes(dayOfDate) ||
      (dayOfDate === "Sat" && postsOnWeekend["Sat"]) ||
      (dayOfDate === "Sun" && postsOnWeekend["Sun"])
    );
  };

  const getListStyle = isDraggingOver => {
    return {
      background: isDraggingOver
        ? "linear-gradient(180deg, rgba(116, 123, 128, 0.13) 0%, rgba(196, 196, 196, 0.08) 100%)"
        : "white",
      borderRadius: "16px"
    };
  };

  const flatEntries = days.reduce((acc, value) => {
    return [...acc, ...value.entries];
  }, []);

  const weekNumber = getWeekNumber(days[0].date.toDate())
    .toString()
    .padStart(2, "0");

  return (
    <CalendarContainer>
      <CalendarHeaderZone>
        <CalendarDateControl>
          <Button
            size={"sm"}
            variant={"secondary"}
            onClick={() => changeWeek(0)}
          >
            Today
          </Button>
          <IconBottonGroup>
            <IconButton
              icon="icon-arrowleft"
              variant={"secondary"}
              size={36}
              iconSize={24}
              iconColor={"#646769"}
              onClick={() => changeWeek(weekOffset - 1)}
            />
            <IconButton
              icon="icon-arrowright"
              variant={"secondary"}
              size={36}
              iconSize={24}
              iconColor={"#646769"}
              onClick={() => changeWeek(weekOffset + 1)}
            />
          </IconBottonGroup>
          <CalendarMonth>
            <em>
              {days[0].date.format("MMMM")}{" "}
              {monthChange && "- " + days[6].date.format("MMMM")}
            </em>
            &nbsp;{days[0].date.format("YYYY")}
            <span className="week_num">Week {weekNumber}</span>
            <CalendarLoading loading={loading ? 1 : 0}>
              <ReactLoading width={20} height={20} color={"#999"} type="spin" />
            </CalendarLoading>
          </CalendarMonth>
        </CalendarDateControl>
        <ScheduleViewToggle defaultValue="week" />
        <CalendarControls>
          {account.channels.length > 0 && (
            <ChannelToggle
              gap={0}
              adjustPosition={true}
              channels={account.channels}
              selectedIds={selectedChannelIds}
              onSave={handleChannelToggle}
              showDropdown={true}
            />
          )}

          <Button onClick={handleAddTask} isRounded>
            Add tasks
          </Button>
        </CalendarControls>
      </CalendarHeaderZone>
      <DragDropContextWrapper>
        <CalendarDayColumnsContainer menuCollapsed={menuCollapsed}>
          <CalendarDayRow layout={gridColumnLayout}>
            {days.map(day => {
              return (
                <CalendarDay isToday={day.isToday} key={day.date.toISOString()}>
                  <span>
                    {day.date.format("ddd")} <strong>{day.date.date()}</strong>
                  </span>
                  <ContextMenu
                    day={day}
                    handleWritePost={handleWritePost}
                    handleWriteDraft={handleWriteDraft}
                    handleCreateTask={handleCreateTask}
                  />
                </CalendarDay>
              );
            })}
          </CalendarDayRow>

          <CalendarDayColumns layout={gridColumnLayout}>
            {days.map((day, idx) => {
              return (
                <DroppableWrapper
                  key={idx}
                  id={day.date.format("YYYY-MM-DD")}
                  entries={flatEntries}
                  height={heightAdjust}
                  isDisabled={day.isBeforeToday}
                  applyStyling={getListStyle}
                >
                  <DayColumn
                    height={heightAdjust}
                    day={day}
                    handleCreateTask={handleCreateTask}
                    handleAddEntry={handleAddEntry}
                    handleAddDraftEntry={handleAddDraftEntry}
                    handleEditEntry={handleEditEntry}
                    onDeleted={onDeleted}
                    refreshActivities={refreshActivities}
                    handleWritePost={handleWritePost}
                    handleWriteDraft={handleWriteDraft}
                    isAddNewButtonVisible={attachAddNewButton(day)}
                  />
                </DroppableWrapper>
              );
            })}
          </CalendarDayColumns>
        </CalendarDayColumnsContainer>
      </DragDropContextWrapper>
    </CalendarContainer>
  );
}

const mapStateToProps = state => {
  return {
    modalState: state.modals,
    account: state.account.data,
    menuCollapsed: state.layout.collapsed,
    activities: state.activity.activities
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveActivities: activityActions.saveActivities,
    updateActivity: activityActions.updateActivity,
    changeDraftCountByValue: draftActions.changeDraftCountByValue,
    removeDraftById: draftActions.removeDraftById
  })(Calendar)
);
