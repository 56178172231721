import { connect } from "react-redux";
import Button from "components/common/Button";
import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "rebass";
import { theme } from "theme";
import { colorWithoutOpacity } from "utils/color";
import Loader from "components/common/loading/Loader";

interface ISubscribeButtonProps {
  id: string;
  type: string;
  color: string;
  sections: any;
  followLabel?: string;
  onFollow: () => void;
  isProcessing: boolean;
  onUnfollow: () => void;
}

const ButtonContentWrapper = styled(Flex)<{ color?: string }>`
  align-items: center;
  justify-content: center;
  gap: 2px;

  > span {
    font-size: 18px;
  }
`;

const SubscribeButton: React.FC<ISubscribeButtonProps> = ({
  id,
  type,
  color,
  sections,
  onFollow,
  onUnfollow,
  followLabel,
  isProcessing
}) => {
  const [onHover, setOnHover] = useState<boolean>(false);

  const isTopicFollowed = !!sections
    .find((section: any) => section.type === type)
    ?.details?.find((detail: any) => detail.id === id);

  return (
    <>
      {isTopicFollowed ? (
        //  @ts-ignore
        <Button
          variant="primaryWhite"
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onClick={onUnfollow}
          bg={color ? `${colorWithoutOpacity(color)}` : theme.colors.white}
          color={color ? theme.colors.white : theme.colors.text01}
          custom={color ? true : false}
          minWidth={124}
          disabled={isProcessing}
        >
          <ButtonContentWrapper>
            {onHover ? (
              <>
                {isProcessing ? (
                  <Loader size={12} />
                ) : (
                  <span className="icon-cancel" />
                )}
                Unfollow
              </>
            ) : (
              <>
                {isProcessing ? (
                  <Loader size={12} />
                ) : (
                  <span className="icon-check" />
                )}
                Following
              </>
            )}
          </ButtonContentWrapper>
        </Button>
      ) : (
        //  @ts-ignore
        <Button
          variant="primaryWhite"
          onClick={onFollow}
          minWidth={124}
          disabled={isProcessing}
        >
          <ButtonContentWrapper>
            {isProcessing ? (
              <Loader size={12} />
            ) : (
              <span className="icon-plus" />
            )}
            {followLabel ?? "Follow"}
          </ButtonContentWrapper>
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    sections: state.content.sections
  };
};

export default connect(mapStateToProps, {})(SubscribeButton);
