import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import ContentForYou from "../../components/content/ContentForYou";
import ContentSearch from "../../components/content/ContentSearch";
import ContentExplore from "../../components/content/explore/ContentExplore";
import ContentFollowed from "../../components/content/ContentFollowed";
import ContentTopicDetail from "../../components/content/ContentTopicDetail";
import ContentPreferences from "../../components/content/ContentPreferences";
import ContentInfluencers from "../../components/content/ContentInfluencers";
import ContentSavedForLater from "../../components/content/ContentSavedForLater";
import ContentInfluencerDetail from "../../components/content/ContentInfluencerDetail";
import Keyword from "components/content/keyword";
import ContentKeywordDetail from "components/content/ContentKeywordDetail";

interface IContentRouterProps extends RouteComponentProps {}

const ContentRouter: React.FC<IContentRouterProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + "/for-you"} component={ContentForYou} />
      <Route path={match.url + "/explore"} component={ContentExplore} />
      <Route
        path={match.url + "/saved-for-later"}
        component={ContentSavedForLater}
      />
      <Route path={match.url + "/preferences"} component={ContentPreferences} />
      <Route path={match.url + "/search"} component={ContentSearch} />
      <Route
        path={match.url + "/topics/:topicId/:language"}
        component={ContentTopicDetail}
      />
      <Route path={match.url + "/topics"} component={ContentFollowed} />
      <Route
        path={match.url + "/domains/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="domains" {...params} />
        )}
      />

      <Route
        path={match.url + "/influencers/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="twitter" {...params} />
        )}
      />
      <Route
        path={match.url + "/influencers"}
        render={() => <ContentInfluencers sourceType="twitter" />}
      />
      <Route
        path={match.url + "/rss/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="rss" {...params} />
        )}
      />
      <Route
        exact
        path={match.url + "/keywords/:sourceId"}
        render={params => (
          <ContentKeywordDetail sourceType="keyword" {...params} />
        )}
      />
      <Route
        path={match.url + "/rss"}
        render={() => <ContentInfluencers sourceType="rss" />}
      />
      <Route exact path={match.url + "/keywords"} render={() => <Keyword />} />
      <Route
        exact
        path={match.url + "/keywords/:keywordId/edit"}
        render={params => <Keyword {...params} />}
      />
      <Redirect to={match.url + "/for-you"} />
    </Switch>
  );
};

export default ContentRouter;
