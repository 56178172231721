import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const generatePdf = (containerId: string, pageClassName: string) => {
  const doc = new jsPDF("p", "px", "a4");
  const pageWidth = doc.internal.pageSize.getWidth() - 20;
  const pageHeight = doc.internal.pageSize.getHeight() - 20;
  let marginY = 10;

  const reportContainer = document.getElementById(containerId);

  if (reportContainer instanceof HTMLElement) {
    try {
      const originalClassName = reportContainer.className;
      reportContainer.className = "report";

      const pages = reportContainer.getElementsByClassName(pageClassName);

      if (pages.length < 1) {
        throw new Error("Error in generatePdf");
      }

      [...pages].forEach((page, index) => {
        html2canvas(page as HTMLElement, { useCORS: true }).then(canvas => {
          const widthRatio = pageWidth / canvas.width;
          const heightRatio = pageHeight / canvas.height;
          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
          const canvasWidth = canvas.width * ratio;
          const canvasHeight = canvas.height * ratio;
          const marginX = (pageWidth - canvasWidth) / 2 + 10;

          if (index > 0) {
            doc.addPage("a4", "p");
          }

          const image = canvas.toDataURL("image/png");
          doc.addImage(
            image,
            "PNG",
            marginX,
            marginY,
            canvasWidth,
            canvasHeight
          );

          if (index === pages.length - 1) {
            reportContainer.className = originalClassName;
            doc.save("report.pdf");
          }
        });
      });
    } catch (e) {
      throw e;
    }
  } else {
    throw new Error("Error in generatePdf");
  }
};
