import React from "react";

import {
  TooltipOption,
  TooltipMenu,
  TooltipMenuWrapper
} from "components/schedule/styles";

const CalendarAddOptions = ({
  day,
  handleWritePost,
  handleWriteDraft,
  handleCreateTask,
  close,
  float = true
}) => {
  return (
    <TooltipMenu float={float} radius={16}>
      <TooltipMenuWrapper minWidth={180}>
        <TooltipOption
          onClick={() => {
            handleWritePost(day);
            close();
          }}
          variant="primary"
        >
          <span className="icon-write"></span>
          Write a post
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            const draftPost = { ...day, isDraft: true };
            handleWriteDraft(draftPost);
            close();
          }}
        >
          <span className="icon-draft"></span>
          Write draft
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            handleCreateTask(day);
            close();
          }}
        >
          <span className="icon-task-circular" />
          Add task
        </TooltipOption>
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default CalendarAddOptions;
