import Modal from "react-modal";
import { X } from "styled-icons/feather";
import { Box } from "rebass/styled-components";
import React, { useState, useEffect } from "react";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";
import { IAccount } from "@hellocontento/contento-common";

import AccountForm from "./AccountForm";
import { modalStyles } from "../styles";
import { Headline5 } from "components/common/styles";
import IconButton from "components/common/IconButton";
import { updateAccount } from "state/actions/AccountActions";
import { ModalBody, ModalTitle, ModalHeader } from "./styles";
import { setShowSetupAccountModal } from "state/actions/AuthActions";

export const SetupAccountModal: React.FC<{}> = () => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);
  const { showSetupAccountModal } = useSelector<any, any>(state => state.auth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const styles = modalStyles(550) as Required<Modal.Styles>;
  styles.content.padding = 0;
  styles.overlay.background = "rgba(28, 30, 31, 0.4)";
  styles.overlay.backdropFilter = "blur(5px)";

  useEffect(() => {
    if (account && !account.setupComplete && showSetupAccountModal) {
      setShowModal(true);
    }
  }, [account, showSetupAccountModal]);

  const closeModal = () => {
    setShowModal(false);
    dispatch(setShowSetupAccountModal(false));
  };

  const handleSubmit = (values: IAccount) => {
    try {
      dispatch(updateAccount(values));

      closeModal();
      addToast("Account setup complete", "success");
    } catch (e) {
      addToast("Account details could not be saved.", "error");
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          bodyOpenClassName="overflow-hidden"
          ariaHideApp={false}
          style={styles}
          isOpen={true}
          onRequestClose={closeModal}
        >
          <ModalHeader>
            <ModalTitle as="div">
              <Headline5>Setup Account</Headline5>
            </ModalTitle>
            <IconButton
              // @ts-ignore
              variant={"secondary"}
              size={"sm"}
              onClick={closeModal}
              icon={X}
            />
          </ModalHeader>
          <ModalBody>
            <Box width="80%" overflow="hidden">
              <AccountForm
                onSubmit={handleSubmit}
                initialValues={{ ...account }}
              />
            </Box>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default SetupAccountModal;
