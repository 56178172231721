import React from "react";
import styled, { css } from "styled-components";

export const InfoWrapper = styled.div<{ variant: string }>`
  display: flex;
  padding: 10px 12px;
  border-radius: 8px;
  gap: 4px;
  align-items: center;
  margin-bottom: 16px;

  ${props => {
    switch (props.variant) {
      case "preview":
        return css`
          background-color: ${props => props.theme.colors.orangeSubtle};
          color: ${props => props.theme.colors.orange};
        `;
      case "info":
      default:
        return css`
          background-color: ${props => props.theme.colors.primarySubtle};
          color: ${props => props.theme.colors.primaryFocus};
        `;
    }
  }}

  > span {
    font-size: 18px;
  }
`;

interface IInfoBlockProps {
  text: string;
  icon: string;
  variant: string;
}

const InfoBlock: React.FC<IInfoBlockProps> = ({ text, icon, variant }) => {
  return (
    <InfoWrapper variant={variant}>
      <span className={icon} />
      {text}
    </InfoWrapper>
  );
};

export default InfoBlock;
